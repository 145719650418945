import React from "react";
import PropTypes from "prop-types";

const CustomTabSelection = ({
  tabs,
  activeTab,
  onTabChange,
  tabContainerClasses = "flex justify-start text-sm space-x-6",
  tabClasses = "flex flex-col items-center",
  activeTabClasses = "text-primaryAccent font-medium border-blue-900",
  inactiveTabClasses = "text-gray-400 font-medium border-transparent hover:text-gray-600",
  disabledTabClasses = "text-gray-400 font-medium border-transparent cursor-not-allowed opacity-50",
  barClasses = "w-full h-2",
}) => {
  return (
    <div className={`w-full font-inter ${tabContainerClasses}`}>
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={`${tabClasses} ${tab.disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
          onClick={() => {
            if (!tab.disabled) {
              onTabChange(tab.value);
            }
          }}>
          {/* Top bar */}
          <div
            className={`${barClasses} ${
              activeTab === tab.value
                ? "bg-primaryAccent"
                : tab.disabled
                  ? "bg-gray-100"
                  : "bg-gray-200"
            } rounded-md`}
          />
          {/* Tab text */}
          <span
            className={`mt-2 ${
              tab.disabled
                ? disabledTabClasses
                : activeTab === tab.value
                  ? activeTabClasses
                  : inactiveTabClasses
            }`}>
            {tab.label}
          </span>
        </div>
      ))}
    </div>
  );
};

CustomTabSelection.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabContainerClasses: PropTypes.string,
  tabClasses: PropTypes.string,
  activeTabClasses: PropTypes.string,
  inactiveTabClasses: PropTypes.string,
  disabledTabClasses: PropTypes.string,
  barClasses: PropTypes.string,
};

export default CustomTabSelection;
