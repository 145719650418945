import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ClockIcon,
  ArrowsExpandIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import groupIcon from "../../../static/images/groupIcon.png";
import PlacementOptionOverview from "./PlacementOptionOverview";
import SlideOverPanel from "#components/common/SlideOverPanel";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import { useSelector } from "react-redux";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import FbaRateShopping from "./FbaRateShopping";
import getSymbolFromCurrency from "currency-symbol-map";
import { Popover } from "antd";

const SelectPlacementOptions = ({
  placementOptions,
  selectedPlacementOption,
  setSelectedPlacementOption,
  selectedRates,
  setSelectedRates,
  pollTransportationOptions,
  isLtlFlow,
}) => {
  // Helper function to format the expiry date
  // Helper function to format the expiry date
  const { getTransportationsOptionsResult } = useSelector(
    (state) => state.fbaPacking,
  );

  const [transportationOptions, setTransportationOptions] = useState([]);
  const [openPlacmentOptionOverview, setOpenPlacementOptionOverview] =
    useState(false);
  const [placementOptionForOverview, setPlacementOptionForOverview] = useState(
    {},
  );

  const [showRateShopping, setShowRateShopping] = useState(false);
  const [selectedOptionForRates, setSelectedOptionForRates] = useState(null);

  // Merge new transportation options into existing state
  useEffect(() => {
    if (
      getTransportationsOptionsResult?.data?.transportationOptionsPerShipment
    ) {
      const newOptions =
        getTransportationsOptionsResult.data.transportationOptionsPerShipment;

      setTransportationOptions((prev) => {
        const updatedOptions = [...prev];

        newOptions.forEach((newOption) => {
          const existingIndex = updatedOptions.findIndex(
            (option) => option.shipmentId === newOption.shipmentId,
          );

          if (existingIndex > -1) {
            // Replace existing option
            updatedOptions[existingIndex] = newOption;
          } else {
            // Add new option
            updatedOptions.push(newOption);
          }
        });

        return updatedOptions;
      });
    }
  }, [getTransportationsOptionsResult]);

  const formatExpiryDate = (expiryDate) => {
    const date = dayjs(expiryDate);
    return `Expires ${date.fromNow()}`;
  };

  // Sort placement options by placement fee
  const sortedPlacementOptions = [...placementOptions].sort((a, b) => {
    const getFee = (option) => {
      const placementFee = option.fees.find(
        (fee) => fee.target === "Placement Services",
      );
      return placementFee ? parseFloat(placementFee.value.amount) : 0;
    };

    return getFee(a) - getFee(b);
  });

  const getCurrencySymbol = (amount, currency) => {
    if (!amount || !currency) return "$"; // Default
    const symbol = getSymbolFromCurrency(currency) || "$";
    return symbol;
  };

  // Update handleRateSelection to accept uniqueId
  const handleRateSelection = (
    shipmentId,
    transportationOptionId,
    uniqueId,
    carrierName,
    isPartnered,
  ) => {
    setSelectedRates((prev) => ({
      ...prev,
      [shipmentId]: {
        transportationOptionId,
        uniqueId:
          uniqueId ||
          `${transportationOptionId}_${
            transportationOptions
              .find((opt) => opt.shipmentId === shipmentId)
              ?.transportationOptions.find(
                (rate) =>
                  rate.transportationOptionId === transportationOptionId,
              )?.carrier.alphaCode
          }`,
        alphaCode: uniqueId?.split("_")?.[1],
        carrierName,
        isPartnered,
      },
    }));
  };

  const handleRateShoppingCancel = () => {
    setShowRateShopping(false);
  };

  const handleRateShoppingConfirm = () => {
    setShowRateShopping(false);
  };

  const getTransportationDetails = (shipments) => {
    return shipments.map((shipment) => {
      const rate = getLocationRate(shipment.shipmentId);
      const selectedOption = selectedRates[shipment.shipmentId];
      const transportOption = transportationOptions
        .find((opt) => opt.shipmentId === shipment.shipmentId)
        ?.transportationOptions.find(
          (opt) =>
            `${opt.transportationOptionId}_${opt.carrier.alphaCode}` ===
            selectedOption?.uniqueId,
        );

      return {
        city: shipment.destination.address.city,
        carrierName: transportOption?.carrier?.name,
        rate: rate || 0,
        isManual: rate === null,
      };
    });
  };

  const getLocationRate = (shipmentId) => {
    const selectedOption = selectedRates[shipmentId];
    if (!selectedOption?.transportationOptionId) return 0;

    const shipmentRates =
      transportationOptions.find((option) => option.shipmentId === shipmentId)
        ?.transportationOptions || [];

    // Find rate using both transportationOptionId and carrier alphaCode
    const selectedRate = shipmentRates.find((rate) => {
      if (selectedOption.uniqueId) {
        // If uniqueId exists, use it for comparison
        return (
          `${rate.transportationOptionId}_${rate.carrier.alphaCode}` ===
          selectedOption.uniqueId
        );
      }
      // Fallback to just transportationOptionId for backward compatibility
      return (
        rate.transportationOptionId === selectedOption.transportationOptionId
      );
    });

    // Don't display rate if carrier is "other"
    if (selectedRate?.carrier?.name?.toLowerCase() === "other") {
      return null;
    }

    return selectedRate ? parseFloat(selectedRate.quote.cost.amount) : 0;
  };

  const getFeeAmount = (option) => {
    const placementFee = option.fees.find(
      (fee) => fee.target === "Placement Services",
    );
    return placementFee ? parseFloat(placementFee.value.amount) : 0;
  };

  const transformShipmentToLocation = (shipment) => {
    const hasTransportation = hasTransportationData(shipment.shipmentId);
    const selectedRate = getLocationRate(shipment.shipmentId);

    return {
      cityName: shipment.destination.address.city,
      cityImage: groupIcon,
      address: shipment?.destination?.address || {},
      boxes: shipment.boxes.length,
      items: shipment.items.reduce((sum, item) => sum + item.quantity, 0),
      cost: selectedRate,
      shipmentId: shipment.shipmentId,
      hasTransportation,
    };
  };

  const hasTransportationData = (shipmentId) => {
    return transportationOptions.some(
      (option) => option.shipmentId === shipmentId,
    );
  };

  const hasAllTransportationData = (option) => {
    return option.shipmentIds.every((shipmentId) =>
      hasTransportationData(shipmentId),
    );
  };

  return (
    <div className="p-6 font-inter">
      <div className="mb-4 text-lg font-semibold">
        Total No of Options: {placementOptions.length}
      </div>
      <div className="flex flex-col gap-12">
        {sortedPlacementOptions.map((option, index) => {
          const feeAmount = getFeeAmount(option);
          const locations = option.shipmentsWithBoxesAndItems.map(
            transformShipmentToLocation,
          );
          const hasAllTransport = hasAllTransportationData(option);

          return (
            <div
              className={`flex flex-col rounded-lg border px-4 pt-4 ${
                selectedPlacementOption === option.placementOptionId
                  ? "border-primaryAccent-500 shadow-lg"
                  : "border-gray-300"
              }`}>
              <div
                key={option.placementOptionId}
                style={{ minHeight: "12rem" }}
                className={`relative flex min-h-60 items-center`}>
                {/* Discount Tag */}
                {option.discounts?.length > 0 && (
                  <div
                    style={{
                      borderTopRightRadius: "1rem",
                      borderBottomRightRadius: "1rem",
                      left: "-1rem",
                      top: "0rem",
                    }}
                    className="absolute inline-block rounded-r-lg bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-600">
                    {`${"option?.discounts[0]?.value || option.discounts[0]"}% Discount Applied`}{" "}
                  </div>
                )}

                {/* Radio Button */}
                <div className="mt-2">
                  <input
                    type="radio"
                    name="placementOption"
                    checked={
                      selectedPlacementOption === option.placementOptionId
                    }
                    onChange={() =>
                      setSelectedPlacementOption(option.placementOptionId)
                    }
                    className="h-5 w-5 cursor-pointer text-primaryAccent focus:ring-primaryAccent"
                  />
                </div>

                {/* Option Details */}
                <div className="ml-4 w-60">
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">
                      Option {index + 1}
                    </div>
                    <div className="text-lg font-medium">
                      {feeAmount > 0
                        ? `${getCurrencySymbol(feeAmount, option.value?.code)}${feeAmount} Placement Fee`
                        : "No Placement Fee"}
                    </div>
                    <div className="text-sm text-gray-500">
                      Applicable for {locations.length} warehouses
                    </div>
                  </div>
                </div>

                {/* Locations */}
                <div className="ml-8 flex flex-1 flex-wrap gap-8">
                  {(locations || []).map((location, locationIndex) => {
                    const {
                      name,
                      companyName,
                      addressLine1,
                      addressLine2,
                      city,
                      stateOrProvinceCode,
                      postalCode,
                      phoneNumber,
                      email,
                    } = location?.address || {};
                    return (
                      <div
                        key={locationIndex}
                        className="flex min-w-[140px] flex-col items-center justify-center text-center">
                        <div className="flex min-h-[40px] items-center justify-center">
                          <Popover
                            content={
                              <div className="flex flex-col gap-2">
                                <div>
                                  <span className="font-medium">Name: </span>
                                  {name}
                                </div>
                                <div>
                                  <span className="font-medium">Company: </span>
                                  {companyName}
                                </div>
                                <div>
                                  <span className="font-medium">Address: </span>
                                  {addressLine1}
                                  {addressLine2 && <>, {addressLine2}</>}
                                </div>
                                <div>
                                  <span className="font-medium">City: </span>
                                  {city}, {stateOrProvinceCode} {postalCode}
                                </div>
                                <div>
                                  <span className="font-medium">Phone: </span>
                                  {phoneNumber}
                                </div>
                                <div>
                                  <span className="font-medium">Email: </span>
                                  {email}
                                </div>
                              </div>
                            }
                            title="Address Details"
                            placement="right">
                            <span className="cursor-pointer whitespace-nowrap text-sm font-medium">
                              {location.cityName}
                            </span>
                          </Popover>
                        </div>
                        {/* Rest of the location display remains the same */}
                        <div className="relative">
                          <img
                            src={location.cityImage}
                            alt={location.cityName}
                            className="my-2 h-12 w-12 rounded-md object-cover"
                          />
                        </div>
                        <span className="text-xs text-gray-500">
                          {location.boxes} Boxes | {location.items} Items
                        </span>
                        <span className="mt-1 text-xs font-medium text-gray-900">
                          {getLocationRate(location.shipmentId) !== null &&
                          getLocationRate(location.shipmentId) > 0
                            ? `${getCurrencySymbol(location.cost, location.code)}${getLocationRate(location.shipmentId)}`
                            : getLocationRate(location.shipmentId) === null
                              ? "Manual Tracking"
                              : ""}
                        </span>
                      </div>
                    );
                  })}
                </div>

                {/* Expiry Tag */}
                <div
                  style={{
                    top: "-2.5rem",
                    borderTopRightRadius: "1rem",
                    borderBottomLeftRadius: "2rem",
                    right: "1rem",
                  }}
                  className="absolute right-4 top-2 flex -translate-y-6 items-center rounded-t-lg bg-yellow-100 px-2 py-1 text-xs text-yellow-700">
                  <ClockIcon className="mr-1 inline-block h-4 w-4" />
                  {formatExpiryDate(option.expiration)}
                </div>

                {/* Expand Icon */}
                <div className="absolute right-4 top-4 cursor-pointer text-gray-400 hover:text-gray-600">
                  <ArrowsExpandIcon
                    onClick={() => {
                      setPlacementOptionForOverview(option);
                      setOpenPlacementOptionOverview(true);
                    }}
                    className="h-5 w-5"
                  />
                </div>
              </div>
              <div className="px-4 py-3">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Popover
                      content={
                        <div className="min-w-[400px]">
                          <table className="w-full">
                            <thead>
                              <tr className="border-b">
                                <th className="px-4 py-2 text-left">Type</th>
                                <th className="px-4 py-2 text-left">Details</th>
                                <th className="px-4 py-2 text-right">Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Placement Fee Row */}
                              <tr className="border-b">
                                <td className="px-4 py-2">Placement Fee</td>
                                <td className="px-4 py-2">
                                  Service charge for placement
                                </td>
                                <td className="px-4 py-2 text-right">
                                  {`${getCurrencySymbol(feeAmount, option.value?.code)}${feeAmount.toFixed(2)}`}
                                </td>
                              </tr>
                              {/* Transportation Costs */}
                              {getTransportationDetails(
                                option.shipmentsWithBoxesAndItems,
                              ).map((detail, idx) => (
                                <tr key={idx} className="border-b">
                                  <td className="px-4 py-2">Transportation</td>
                                  <td className="px-4 py-2">
                                    <div className="flex flex-col">
                                      <span>{detail.city}</span>
                                      <span className="text-sm text-gray-500">
                                        {detail.carrierName || ""}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 text-right">
                                    {detail.isManual ? (
                                      <span className="text-gray-500">
                                        Manual Tracking
                                      </span>
                                    ) : (
                                      `${getCurrencySymbol(detail.rate, option.value?.code)}${detail.rate.toFixed(2)}`
                                    )}
                                  </td>
                                </tr>
                              ))}
                              {/* Total Row */}
                              <tr className="bg-gray-50 font-medium">
                                <td className="px-4 py-2">Total Cost</td>
                                <td className="px-4 py-2"></td>
                                <td className="px-4 py-2 text-right">
                                  {`${getCurrencySymbol(option.value?.amount, option.value?.code)}${(
                                    getFeeAmount(option) +
                                    option.shipmentsWithBoxesAndItems.reduce(
                                      (sum, shipment) => {
                                        const rateAmount = getLocationRate(
                                          shipment.shipmentId,
                                        );
                                        return (
                                          sum +
                                          (rateAmount !== null ? rateAmount : 0)
                                        );
                                      },
                                      0,
                                    )
                                  ).toFixed(2)}`}
                                  {option.shipmentsWithBoxesAndItems.some(
                                    (shipment) =>
                                      getLocationRate(shipment.shipmentId) ===
                                      null,
                                  ) && " + Manual Tracking"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      }
                      title="Cost Breakdown"
                      placement="right">
                      <div className="flex cursor-pointer items-center gap-2">
                        <span className="text-sm font-medium text-gray-600">
                          Total Cost
                        </span>
                        <span className="text-lg font-semibold text-gray-900">
                          {`${getCurrencySymbol(option.value?.amount, option.value?.code)}${(
                            getFeeAmount(option) +
                            option.shipmentsWithBoxesAndItems.reduce(
                              (sum, shipment) => {
                                const rateAmount = getLocationRate(
                                  shipment.shipmentId,
                                );
                                return (
                                  sum + (rateAmount !== null ? rateAmount : 0)
                                );
                              },
                              0,
                            )
                          ).toFixed(2)}`}
                          {option.shipmentsWithBoxesAndItems.some(
                            (shipment) =>
                              getLocationRate(shipment.shipmentId) === null,
                          ) && " + Manual Tracking"}
                        </span>
                      </div>
                    </Popover>
                  </div>
                  {!isLtlFlow && (
                    <div>
                      {!hasAllTransport ? (
                        <span className="text-sm text-gray-500">
                          Transportation options loading...
                        </span>
                      ) : (
                        <PrimaryButton
                          height="2rem"
                          minWidth="13rem"
                          maxWidth="20rem"
                          variant="primary"
                          className="text-sm font-medium"
                          onClick={() => {
                            setSelectedOptionForRates(option);
                            setShowRateShopping(true);
                          }}>
                          Select Transportation Options
                        </PrimaryButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <SlideOverPanel
        open={openPlacmentOptionOverview}
        containerStyle={"max-w-6xl"}
        setOpen={setOpenPlacementOptionOverview}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={`Placement Option ${placementOptions?.findIndex((option) => option.placementOptionId === selectedPlacementOption) + 1} - Overview`}
            description={`Review the breakdown of boxes assigned under Placement Option ${placementOptions?.findIndex((option) => option.placementOptionId === selectedPlacementOption) + 1}.`}
            isLearnMore={false}
            isArrow={true}
            arrowAction={() => setOpenPlacementOptionOverview(false)}
            mainClasses="mb-0"
          />
        }>
        <PlacementOptionOverview placementOption={placementOptionForOverview} />
      </SlideOverPanel>
      <SlideOverPanel
        open={showRateShopping}
        containerStyle={"max-w-6xl"}
        setOpen={setShowRateShopping}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle="Rate Shopping Details"
            description="Select your preferred shipping rate for each location"
            isLearnMore={false}
            isArrow={true}
            arrowAction={() => setShowRateShopping(false)}
            mainClasses="mb-0"
          />
        }>
        {selectedOptionForRates && (
          <FbaRateShopping
            placementOption={selectedOptionForRates}
            transportationOptions={transportationOptions}
            selectedRates={selectedRates}
            onRateSelect={handleRateSelection}
            onCancel={handleRateShoppingCancel}
            onConfirm={handleRateShoppingConfirm}
            pollTransportationOptions={pollTransportationOptions}
            setSlideOverOpen={setShowRateShopping}
            setTransportationOptions={setTransportationOptions}
          />
        )}
      </SlideOverPanel>
    </div>
  );
};

SelectPlacementOptions.propTypes = {
  placementOptions: PropTypes.array.isRequired,
  selectedPlacementOption: PropTypes.string,
  setSelectedPlacementOption: PropTypes.func.isRequired,
};

export default SelectPlacementOptions;
