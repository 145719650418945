import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoCompleteV2 from "#components/utils/MultiSelectAutoCompleteV2";
import _ from "lodash";

const UoMForm = ({ onClose, title, onSubmit, onChangeMultiSelect }) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div>
      <div id="selectingStations">
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Add Labels
          </span>
        </label>
        <MultiSelectAutoCompleteV2
          labelKey={"name"}
          valueKey={"id"}
          setValues={(selectedOptions) =>
            onChangeMultiSelect("labels", selectedOptions)
          }
          values={[]}
        />
      </div>
    </div>
  </Modal>
);

export default UoMForm;
