import { useReducer, createContext } from "react";
import { v4 as uuidv4 } from "uuid";
import _, { filter } from "lodash";
const config = require("config");

const initialState = {
  // global loading state, to be used only in App.jsx
  loading: false,
  // individual page level loading state, to be used only in HOCs. Ideally dependent on the primary fetch API.
  pageLoading: false,
  alerts: [],
  dismissableAlerts: [],
  pageTitle: "Hopstack Inc",
  adminLayoutBg: "bg-EBEBEB",
  confirmation: {
    open: false,
    mainText: "Are you sure",
    subText: "This action cannot be undone",
    negative: () => {},
    positive: () => {},
  },
  newConfirmation: {
    isOpen: false,
    title: "Are you sure ?",
    content: "This action cannot be undone",
    negativeText: "Cancel",
    positiveText: "Confirm",
    negativeAction: () => {},
    positiveAction: () => {},
    showFooterActions: true,
  },
  loadingOverlayProps: {
    isVisible: false,
    loaderTitle: "Please wait",
    description: "This might take a few minutes. Please hold on.",
    htmlContent: <></>,
    loaderClasses: "",
    spinnerSize: "large",
  },
  defaultTimezone: "EST",
  displayFields: [],
  enabledSettings: false,
  subdomain: null,
  tenant: null,
  integrations: [],
  showIntegrationRefreshWarning: true,
  masterData: {
    /** uomLabels Payload structure:
      [
        {
          name: "Each",
          isActive: true,
          createdAt: "2021-09-01T00:00:00Z",
        },
        {
          name: "Case",
          isActive: false,
          createdAt: "2021-09-01T00:00:00Z",
        }
      ]
    */
    uomLabels: [],
  },
  sidebarOpen: JSON.parse(localStorage.getItem("sidebarOpen")) ?? true,
  filterQueryOperator: "",
};

const AppStateContext = createContext({});

function appStateReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }
    case "REMOVE_LOADING": {
      return {
        ...state,
        loading: false,
      };
    }
    case "SET_PAGE_LOADING": {
      return {
        ...state,
        pageLoading: true,
      };
    }
    case "REMOVE_PAGE_LOADING": {
      return {
        ...state,
        pageLoading: false,
      };
    }
    case "SET_ALERT":
      return {
        ...state,
        alerts: [...state.alerts, payload],
      };
    case "REMOVE_ALERT":
      return {
        ...state,
        alerts: _.filter(state.alerts, (alert) => alert.id !== payload),
      };
    case "SET_DISMISSABLE_ALERT":
      return {
        ...state,
        dismissableAlerts: [...state.dismissableAlerts, payload],
      };
    case "REMOVE_DISMISSABLE_ALERT":
      return {
        ...state,
        dismissableAlerts: _.filter(
          state.dismissableAlerts,
          (alert) => alert.id !== payload,
        ),
      };
    case "SET_ACTIONABLE_ALERT":
      return {
        ...state,
        actionableAlert: payload,
      };
    case "REMOVE_ACTIONABLE_ALERT":
      return {
        ...state,
        actionableAlert: null,
      };
    case "SHOW_CONFIRMATION": {
      return {
        ...state,
        confirmation: payload,
      };
    }
    case "HIDE_CONFIRMATION": {
      return {
        ...state,
        confirmation: initialState.confirmation,
      };
    }
    case "SHOW_NEW_CONFIRMATION": {
      return {
        ...state,
        newConfirmation: payload,
      };
    }
    case "HIDE_NEW_CONFIRMATION": {
      return {
        ...state,
        newConfirmation: initialState.newConfirmation,
      };
    }
    case "SET_SUBDOMAIN": {
      return {
        ...state,
        subdomain: payload,
      };
    }
    case "SET_TENANT": {
      return {
        ...state,
        tenant: payload,
      };
    }
    case "SET_INTEGRATIONS": {
      return {
        ...state,
        integrations: payload,
      };
    }
    case "CLEAR_INTEGRATION_WARNING": {
      return {
        ...state,
        showIntegrationRefreshWarning: false,
      };
    }
    case "SET_PRINTERS": {
      return {
        ...state,
        printers: payload?.map((i) => ({ name: i })),
      };
    }
    case "SET_UOM_LABELS": {
      return {
        ...state,
        masterData: {
          ...state.masterData,
          uomLabels: payload,
        },
      };
    }

    case "TOGGLE_SIDEBAR": {
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    }
    case "SET_FILTER_QUERY_OPERATOR":
      return {
        ...state,
        filterQueryOperator: payload,
      };
    case "SET_ADMIN_LAYOUT_BG_COLOR":
      return {
        ...state,
        adminLayoutBg: payload,
      };
    case "SET_LOADING_OVERLAY":
      return {
        ...state,
        loadingOverlayProps: payload,
      };
    case "REMOVE_LOADING_OVERLAY":
      return {
        ...state,
        loadingOverlayProps: initialState.loadingOverlayProps,
      };
    default:
      return state;
  }
}

function AppStateProvider(props) {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  function setLoading() {
    dispatch({
      type: "SET_LOADING",
    });
  }

  function removeLoading() {
    dispatch({
      type: "REMOVE_LOADING",
    });
  }

  function setPageLoading() {
    dispatch({
      type: "SET_PAGE_LOADING",
    });
  }

  function removePageLoading() {
    dispatch({
      type: "REMOVE_PAGE_LOADING",
    });
  }

  function setFilterQueryOperator(operator) {
    dispatch({ type: "SET_FILTER_QUERY_OPERATOR", payload: operator });
  }

  function setAlert(msg, alertType, timeout = 5000) {
    const id = uuidv4();
    dispatch({
      type: "SET_ALERT",
      payload: {
        msg,
        alertType,
        id,
      },
    });

    setTimeout(() => dispatch({ type: "REMOVE_ALERT", payload: id }), timeout);
  }

  function setDismissableAlert(msg, alertType, timeout = 5000) {
    const id = uuidv4();
    dispatch({
      type: "SET_DISMISSABLE_ALERT",
      payload: {
        msg,
        alertType,
        id,
      },
    });

    setTimeout(
      () => dispatch({ type: "REMOVE_DISMISSABLE_ALERT", payload: id }),
      timeout,
    );
  }

  function removeDismissableAlert(id) {
    dispatch({ type: "REMOVE_DISMISSABLE_ALERT", payload: id });
  }

  function setActionableAlert(title, description, mainAction) {
    dispatch({
      type: "SET_ACTIONABLE_ALERT",
      payload: {
        title,
        description,
        mainAction,
      },
    });
  }

  function removeActionableAlert() {
    dispatch({ type: "REMOVE_ACTIONABLE_ALERT" });
  }

  function showConfirmation(
    mainText,
    subText,
    positive,
    negative,
    positiveText,
    negativeText,
  ) {
    const payload = {
      open: true,
      mainText,
      subText,
      negative,
      positive,
      positiveText,
      negativeText,
    };

    dispatch({ type: "SHOW_CONFIRMATION", payload });
  }

  function showNewConfirmation(
    title,
    content,
    positiveAction,
    negativeAction,
    negativeText,
    positiveText,
    showFooterActions,
  ) {
    const payload = {
      isOpen: true,
      title,
      content,
      positiveAction,
      negativeAction,
      negativeText,
      positiveText,
      showFooterActions,
    };
    dispatch({ type: "SHOW_NEW_CONFIRMATION", payload: payload });
  }
  function hideNewConfirmation() {
    dispatch({ type: "HIDE_NEW_CONFIRMATION" });
  }
  function hideConfirmation() {
    dispatch({ type: "HIDE_CONFIRMATION" });
  }

  function setSubdomain(subdomain) {
    localStorage.setItem("subdomain", subdomain);
    dispatch({ type: "SET_SUBDOMAIN", payload: subdomain });
  }

  function setTenant(payload) {
    localStorage.setItem("tenant", JSON.stringify(payload));
    console.log(payload);
    if (payload.apiGateway) {
      localStorage.setItem("baseUrl", payload.apiGateway);
    } else if (process.env.NODE_ENV === "development") {
      localStorage.setItem("baseUrl", config.DEFAULT_API_GATEWAY);
    }
    if (payload.socketService) {
      localStorage.setItem("socketService", payload.socketService);
    } else if (process.env.NODE_ENV === "development") {
      localStorage.setItem("socketService", config.DEFAULT_SOCKET_SERVICE);
    }
    if (payload.cubeService) {
      localStorage.setItem("cubeService", payload.cubeService);
    } else if (process.env.NODE_ENV === "development") {
      localStorage.setItem("cubeService", config.DEFAULT_CUBE_SERVICE);
    }
    dispatch({ type: "SET_TENANT", payload });
  }

  function setIntegrations(payload) {
    dispatch({ type: "SET_INTEGRATIONS", payload });
  }

  function clearIntegrationWarning() {
    dispatch({ type: "CLEAR_INTEGRATION_WARNING" });
  }

  function setPrinters(printers) {
    dispatch({ type: "SET_PRINTERS", payload: printers });
  }

  function setUomLabels(payload) {
    dispatch({ type: "SET_UOM_LABELS", payload });
  }

  function toggleSidebar() {
    dispatch({ type: "TOGGLE_SIDEBAR" });
  }

  function setSidebarPreference(payload) {
    localStorage.setItem("sidebarOpen", JSON.stringify(payload));
  }

  function setAdminPageBgColor(payload) {
    dispatch({ type: "SET_ADMIN_LAYOUT_BG_COLOR", payload });
  }

  function isPrepCenter() {
    return state.tenant?.typeOfCustomer?.includes("Prep Center");
  }

  function setLoadingOverlay(
    loaderTitle,
    description,
    htmlContent,
    loaderClasses,
    spinnerSize,
    colorClasses,
  ) {
    const payload = {
      isVisible: true,
      loaderTitle: loaderTitle || "Please wait",
      description:
        description || "This might take a few minutes... Please hold on.",
      loaderClasses: loaderClasses,
      spinnerSize: spinnerSize || "large",
      colorClasses: {
        titleColor: colorClasses?.titleColor,
        descriptionColor: colorClasses?.descriptionColor,
        loaderColor: colorClasses?.loaderColor,
      },
      htmlContent: htmlContent,
    };
    dispatch({ type: "SET_LOADING_OVERLAY", payload });
  }

  function removeLoadingOverlay() {
    dispatch({ type: "REMOVE_LOADING_OVERLAY" });
  }

  return (
    <AppStateContext.Provider
      value={{
        ...state,
        setLoading,
        removeLoading,
        setPageLoading,
        removePageLoading,
        setAlert,
        setDismissableAlert,
        removeDismissableAlert,
        showConfirmation,
        hideConfirmation,
        showNewConfirmation,
        hideNewConfirmation,
        setSubdomain,
        setTenant,
        setActionableAlert,
        removeActionableAlert,
        setIntegrations,
        clearIntegrationWarning,
        setPrinters,
        setUomLabels,
        toggleSidebar,
        setSidebarPreference,
        setFilterQueryOperator,
        setAdminPageBgColor,
        isPrepCenter,
        setLoadingOverlay,
        removeLoadingOverlay,
      }}
      {...props}
    />
  );
}

export { AppStateContext, AppStateProvider };
