import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_INVENTORY_FOR_LEDGER,
  GET_SKU,
  GET_VENDORS,
  UPLOAD_PRODUCTS,
  UPLOAD_INVENTORY,
  GET_ALL_INVENTORY,
  GET_INVENTORY_FOR_RELEASE,
  EXPLAIN_INVENTORY,
  GET_SKU_BIN_MAPPING,
  GET_BIN_LOCATIONS,
  SEARCH_LOCATIONS,
  GET_INTEGRATIONS,
  GET_PRODUCTS,
  GET_BUNDLES_AS_PRODUCTS,
  FIND_MAX_ALLOCABLE_QUANTITIES_FOR_BUNDLE,
  GET_TRANSACTION_HISTORY_REPORT,
  GET_WAREHOUSES,
  GET_BIN_TRANSFER_ELIGIBILITY,
} from "#queries";
import {
  UPDATE_INVENTORY,
  SAVE_PRODUCT,
  RELEASE_INVENTORY_SHIPMENT_PLAN_CREATION,
  SAVE_ORDER,
  ALLOCATE_INVENTORY_TO_CHANNELS,
  ALLOCATE_INVENTORY_FOR_BUNDLE_AS_PRODUCT,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
import readXlsxFile from "read-excel-file";
import moment from "moment-timezone";
import { ExportToCsv } from "export-to-csv";
import { MOVE_STOCK } from "../../mutations";
import { useFeatureFlags } from "#contexts/featureFlags";

const ALERT_TIMEOUT_IN_MS = 5000;
const HOPSTACK_SOURCE = "Hopstack";

const PACKING_METHODS_OPTIONS = [
  {
    name: "Regular Pack",
    value: false,
  },
  {
    name: "Case Pack",
    value: true,
  },
];

const BOX_CONTENTS_OPTIONS = [
  { name: "HOPSTACK", value: "FEED" },
  { name: "AMAZON", value: "NONE" },
  { name: "MANUAL", value: "MANUAL" },
];

const DEFAULT_AMAZON_CONFIGURATION = {
  intendedBoxContentsSource: "FEED",
  isCasePack: false,
  integrationType: "Amazon",
  typeOfShipment: "SP",
  retry: false,
  minItemLen: 1,
};

const ExportInventoryFields = [
  {
    name: "SKU",
    id: "sku",
    correspondingValue: "sku",
    enabled: true,
    editable: false,
  },
  {
    name: "Name",
    id: "name",
    correspondingValue: "name",
    enabled: true,
    editable: true,
  },
  {
    name: "Last Received",
    id: "lastReceived",
    correspondingValue: "lastReceived",
    enabled: true,
    editable: true,
  },
  {
    name: "Last Putaway",
    id: "lastPutaway",
    correspondingValue: "lastPutaway",
    enabled: true,
    editable: true,
  },
  {
    name: "Last Fulfilled",
    id: "lastFulfilled",
    correspondingValue: "lastFulfilled",
    enabled: true,
    editable: true,
  },
  {
    name: "Client",
    id: "customer",
    correspondingValue: "customer",
    enabled: true,
    editable: true,
  },
  {
    name: "Allocated",
    id: "stock",
    correspondingValue: "stock",
    enabled: true,
    editable: true,
  },
  {
    name: "Stock",
    id: "stock",
    correspondingValue: "quantities.availableToShip",
    enabled: true,
    editable: true,
  },
];

const ALERT_VISIBILITY_IN_MS = 5000;
const withInventoryLedgerLogic = (WrappedComponent) => {
  return (props) => {
    const [addingChannel, setAddingChannel] = useState(null);
    const [shipmentConfiguration, setShipmentConfiguration] = useState(
      DEFAULT_AMAZON_CONFIGURATION,
    );

    const [bundlesWithOverlap, setBundlesWithOverlap] = useState([]);
    const [inventoryExplanations, setInventoryExplanations] = useState([]);
    const [eligibleProducts, setEligibleProducts] = useState(null);
    const [eligibleBundles, setEligibleBundles] = useState(null);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedBundleForAllocation, setSelectedBundleForAllocation] =
      useState(null);
    const [showBundleAllocationForm, setShowBundleAllocationForm] =
      useState(false);
    const [fetchedSku, setFetchedSku] = useState(null);
    const [fetchedInventory, setFetchedInventory] = useState(null);
    const appState = useContext(AppStateContext);
    const getSku = useQuery(GET_SKU);
    const getProductForChannelAllocation = useQuery(GET_INVENTORY_FOR_LEDGER);
    const getSpecificInventory = useQuery(GET_SKU_BIN_MAPPING);
    const updateInventory = useQuery(UPDATE_INVENTORY);
    const inventoryQuery = useQuery(GET_INVENTORY_FOR_LEDGER);
    const uploadProductsQuery = useQuery(UPLOAD_PRODUCTS);
    const uploadInventoryQuery = useQuery(UPLOAD_INVENTORY);
    const saveProductQuery = useQuery(SAVE_PRODUCT);
    const allInventoryQuery = useQuery(GET_ALL_INVENTORY);
    const moveStockQuery = useQuery(MOVE_STOCK);
    const vendorsQuery = useQuery(GET_VENDORS);
    const [vendors, setVendors] = useState([]);
    const releaseInventoryForShipmentCreationQuery = useQuery(
      RELEASE_INVENTORY_SHIPMENT_PLAN_CREATION,
    );
    const getBundlesAsProductsQuery = useQuery(GET_BUNDLES_AS_PRODUCTS);
    const [vendorReturn, setVendorReturn] = useState(null);
    const inventorySearchQuery = useQuery(GET_INVENTORY_FOR_LEDGER);
    const channelProductSearchQuery = useQuery(GET_PRODUCTS);
    const [inventorySearchKeyword, setInventorySearchKeyword] = useState("");
    const [channelProductSearchKeyword, setChannelProductSearchKeyword] =
      useState("");
    const saveOrderQuery = useQuery(SAVE_ORDER);
    const binLocationsQuery = useQuery(GET_BIN_LOCATIONS);
    const searchLocationsQuery = useQuery(SEARCH_LOCATIONS);
    const [binLocationsOptions, setBinLocationsOptions] = useState([]);
    const getInventoryForReleaseQuery = useQuery(GET_INVENTORY_FOR_RELEASE);
    const explainInventoryQuery = useQuery(EXPLAIN_INVENTORY);
    const warehousesQuery = useQuery(GET_WAREHOUSES);
    const [showFilters, setShowFilters] = useState(false);
    const [stockChangeType, setStockChangeType] = useState("add");
    const [
      showExportInventoryFieldSelector,
      setShowExportInventoryFieldSelector,
    ] = useState(false);
    const [inventoryExportFields, setInventoryExportFields] = useState([
      ...ExportInventoryFields,
    ]);
    const [
      fetchedProductForChannelAllocation,
      setFetchedProductForChannelAllocation,
    ] = useState(null);
    const [
      warehousesForSelectedProductInAllocation,
      setWarehousesForSelectedProductInAllocation,
    ] = useState([]);
    const [clientMarketplaceIntegrations, setClientMarketplaceIntegrations] =
      useState([]);
    const [marketplaceProducts, setMarketplaceProducts] = useState([]);
    const [addedChannelProducts, setAddedChannelProducts] = useState([]);
    const masterData = useContext(MasterDataContext);
    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    const allocateInventoryToChannelsQuery = useQuery(
      ALLOCATE_INVENTORY_TO_CHANNELS,
    );
    const allocateInventoryForBundleAsProduct = useQuery(
      ALLOCATE_INVENTORY_FOR_BUNDLE_AS_PRODUCT,
    );
    const findMaxAllocableQuantitiesForBundle = useQuery(
      FIND_MAX_ALLOCABLE_QUANTITIES_FOR_BUNDLE,
    );
    const [multiAccountSupportEnabled, setMultiAccountSupportEnabled] =
      useState(false);

    const [transactionFilters, setTransactionFilters] = useState(null);

    const getTransactionHistoryReportQuery = useQuery(
      GET_TRANSACTION_HISTORY_REPORT,
    );

    const getBinTransferEligibility = useQuery(GET_BIN_TRANSFER_ELIGIBILITY);
    const [binTransferEligibility, setBinTransferEligibility] = useState(null);

    const {
      fbaCasePackEnabled,
      transactionHistoryReportEnabled,
      fba2DBarcodeEnabled,
    } = useFeatureFlags();

    useEffect(() => {
      if (getTransactionHistoryReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getTransactionHistoryReportQuery.data) {
        appState.setAlert(
          getTransactionHistoryReportQuery.data.getTransactionHistoryReport
            .message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setTransactionFilters(null);
      }

      if (getTransactionHistoryReportQuery.error) {
        appState.setAlert(
          getTransactionHistoryReportQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getTransactionHistoryReportQuery.loading,
      getTransactionHistoryReportQuery.error,
      getTransactionHistoryReportQuery.data,
    ]);

    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (
        tenantSettings?.multiAccountIntegrationSupportEnabled &&
        !multiAccountSupportEnabled
      ) {
        setMultiAccountSupportEnabled(true);
      }
    }, [appState]);

    useEffect(() => {
      if (eligibleProducts?.length) {
        setEligibleProducts(
          eligibleProducts.map((item) => ({
            ...item,
            quantity:
              shipmentConfiguration.isCasePack === true
                ? null
                : item.quantities?.availableToShip || 0,
          })),
        );
      }
    }, [shipmentConfiguration?.isCasePack]);

    useEffect(() => {
      if (explainInventoryQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (explainInventoryQuery.data) {
        if (explainInventoryQuery.variables.reload) {
          setInventoryExplanations((prevExplanations) => {
            const updatedExplanations = [...prevExplanations];
            updatedExplanations.pop();
            updatedExplanations.push(
              explainInventoryQuery.data.explainInventory,
            );
            return updatedExplanations;
          });
        } else {
          setInventoryExplanations([
            ...inventoryExplanations,
            explainInventoryQuery.data.explainInventory,
          ]);
        }
      }

      if (explainInventoryQuery.error) {
        appState.setAlert(
          explainInventoryQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      explainInventoryQuery.loading,
      explainInventoryQuery.error,
      explainInventoryQuery.data,
    ]);

    useEffect(() => {
      if (!binTransferEligibility && explainInventoryQuery.data) {
        // REFETCH INVENTORY EXPLANATION
        explainInventoryQuery.fetchData({
          ...explainInventoryQuery.variables,
          reload: true,
        });
      }
    }, [binTransferEligibility]);

    useEffect(() => {
      if (getInventoryForReleaseQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getInventoryForReleaseQuery.data) {
        const products =
          getInventoryForReleaseQuery.data.getInventoryForRelease;
        if (products.length === 0) {
          appState.setAlert(
            `There are no products currently eligible for shipment plan creation`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        } else {
          const foundBundles = products.filter(
            (item) => item.typeOfProduct === "BUNDLE",
          );
          let foundBundlesWithOverlap = [];
          if (foundBundles.length > 0) {
            // FIND BUNDLES WITH THE SAME PRODUCT IN THE PRODUCTS ARRAY IDENTIFIED ID
            // foundBundlesWithOverlap = foundBundles.map((bundle) => {
            //   const bundleProducts = bundle.products.map(
            //     (product) => product.id,
            //   );
            //   const overlap = products.filter((product) =>
            //     bundleProducts.includes(product.id),
            //   );
            //   return {
            //     ...bundle,
            //     overlap,
            //   };
            // });
            // setBundlesWithOverlap(foundBundlesWithOverlap);
          } else {
            setBundlesWithOverlap([]);
          }

          setEligibleProducts(
            products
              .filter(
                (item) =>
                  foundBundlesWithOverlap.map((i) => i.id).includes(item.id) ===
                  false,
              )
              .map((item) => ({
                ...item,
                enabled: true,
                quantity: item.quantities?.availableToShip || 0,
              })),
          );
        }
      }

      if (getInventoryForReleaseQuery.error) {
        appState.setAlert(
          getInventoryForReleaseQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getInventoryForReleaseQuery.error,
      getInventoryForReleaseQuery.data,
      getInventoryForReleaseQuery.loading,
    ]);

    useEffect(() => {
      if (getBundlesAsProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getBundlesAsProductsQuery.data) {
        const bundles = getBundlesAsProductsQuery.data.getBundlesAsProducts;
        if (bundles.length === 0) {
          appState.setAlert(
            `There are no bundles currently available for selected client`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        } else {
          setEligibleBundles(bundles);
        }
      }

      if (getBundlesAsProductsQuery.error) {
        appState.setAlert(
          `Could not fetch bundles. Try again later`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getBundlesAsProductsQuery.error,
      getBundlesAsProductsQuery.data,
      getBundlesAsProductsQuery.loading,
    ]);

    useEffect(() => {
      if (findMaxAllocableQuantitiesForBundle.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (findMaxAllocableQuantitiesForBundle.data) {
        setSelectedBundleForAllocation({
          ...selectedBundleForAllocation,
          maximumAllocableQuantities:
            findMaxAllocableQuantitiesForBundle?.data
              ?.findMaxAllocableQuantitiesForBundle ?? 0,
        });
        setShowBundleAllocationForm(true);
      }

      if (findMaxAllocableQuantitiesForBundle.error) {
        appState.setAlert(
          `Could not fetch maximum allocable quantities for bundle.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      findMaxAllocableQuantitiesForBundle.error,
      findMaxAllocableQuantitiesForBundle.data,
      findMaxAllocableQuantitiesForBundle.loading,
    ]);

    const handleBundleSelection = (selectedBundle) => {
      if (selectedBundle) {
        setSelectedBundleForAllocation(selectedBundle);
        findMaxAllocableQuantitiesForBundle.fetchData({
          bundleId: selectedBundle.id,
          warehouseId: entity.filters["warehouse"][0],
        });
      } else {
        setSelectedBundleForAllocation(null);
        setShowBundleAllocationForm(false);
      }
    };

    useEffect(() => {
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      loadFirstTimeData();
    }, []);

    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        let warehouses = auth.user.warehousesList;
        let customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        }

        const filtersToApply = {
          customer: auth.user.customers,
          warehouse: auth.user.warehouses,
          includeBundles: true,
          includeOutOfStock: false,
          includeAliases: true,
        };

        if (multiAccountSupportEnabled) {
          filtersToApply.marketplace = "All";
          filtersToApply.sellerId = "All";
        }

        entity.setFilters(filtersToApply);

        inventoryQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: filtersToApply,
          paginated: false,
          sort: entity.sort,
        });
      }
    };

    const onChangeInventorySearch = () => {
      inventorySearchQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        filters: {
          keyword: inventorySearchKeyword,
          customer: vendorReturn.customer,
          warehouse: vendorReturn.warehouse,
        },
        paginated: false,
      });
    };

    useEffect(() => {
      if (channelProductSearchQuery.data) {
        setMarketplaceProducts(
          channelProductSearchQuery.data.products.entities,
        );
      }

      if (channelProductSearchQuery.error) {
        setMarketplaceProducts([]);
      }
    }, [
      channelProductSearchQuery.data,
      channelProductSearchQuery.loading,
      channelProductSearchQuery.error,
    ]);

    const onChangeChannelProductSearch = (keyword) => {
      const filtersToApply = {
        keyword,
        customer: [addingChannel.customer],
      };
      let integration = clientMarketplaceIntegrations.find(
        (i) =>
          i.id === addingChannel.integrationId.split("-")[0] &&
          i.integrationType === addingChannel.integrationId.split("-")[1],
      );

      // If its Amazon FBA or FBM above logic of finding it from clientMarketplaceIntegrations will not work.
      // This is because both FBM, FBA objects will have same integrationId so we choose the addingChannel integrationType for source filter
      if (["FBA", "FBM"].includes(addingChannel.integrationType)) {
        integration.integrationType = addingChannel.integrationId.split("-")[1];
      }

      if (
        addingChannel.integrationType?.toLowerCase() !==
        HOPSTACK_SOURCE.toLowerCase()
      ) {
        addingChannel.integrationType = integration.integrationType;
        filtersToApply["source"] = [addingChannel.integrationType];
        // filtersToApply["sellerId"] = integration?.sellerId;         // commenting it out temporary
        filtersToApply["marketplace"] = addingChannel.marketplace;
      } else {
        filtersToApply["source"] = [HOPSTACK_SOURCE];
      }
      channelProductSearchQuery.fetchData({
        perPage: 25,
        pageNumber: 1,
        filters: filtersToApply,
        paginated: false,
      });
    };

    useEffect(() => {
      if (inventorySearchKeyword) {
        debouncedInventorySearch();
      }
    }, [inventorySearchKeyword]);

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

    useEffect(() => {
      if (inventoryQuery.data && inventoryQuery.data.inventoryLedger) {
        entity.setEntities({
          ...inventoryQuery.data.inventoryLedger,
          ...inventoryQuery.variables,
        });
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return inventoryQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    useEffect(() => {
      if (inventoryQuery.loading) {
        appState.setPageLoading();
      } else {
        appState.removePageLoading();
      }
    }, [inventoryQuery.loading]);

    useEffect(() => {
      if (getSku.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getSku.data && getSku.data.specificInventory) {
        const aliasData = entity.entities.find(
          (product) => product.id === getSku.data.specificInventory.id,
        )?.aliases;
        setFetchedSku({ ...getSku.data.specificInventory, aliases: aliasData });
      }

      if (getSku.error) {
        appState.setAlert(
          getSku.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [getSku.loading, getSku.error, getSku.data]);
    const setClientIntegrationsFromIntegrationsQuery = (
      additionalFilter = null,
    ) => {
      if (integrationsQuery.data) {
        const allMarketplaceIntegrations =
          masterData.integrationProviders.filter((item) =>
            ["e-commerce marketplaces", "marketplace_fulfillment"].includes(
              item.classification?.toLowerCase(),
            ),
          );
        let activeMarketplaceIntegrations =
          integrationsQuery.data.integrations.filter((item) =>
            allMarketplaceIntegrations
              .map((i) => i.name)
              .includes(item.integrationType),
          );
        // Amazon FBA and FBM are actually 2 separate channels but uses the same integration "Amazon" as of today
        // If we see Amazon as one of the available active integrations we will replace it with FBA and Amazon FBM
        activeMarketplaceIntegrations = activeMarketplaceIntegrations
          .map((integration) => {
            let suffix = ``;
            if (integration.sellerId) {
              suffix = ` - ${integration.sellerId}`;
            }
            return integration.integrationType === "Amazon"
              ? [
                  {
                    ...integration,
                    integrationType: "FBA",
                    integrationName: "FBA" + suffix,
                  },
                  {
                    ...integration,
                    integrationType: "FBM",
                    integrationName: "FBM" + suffix,
                  },
                ]
              : {
                  ...integration,
                  integrationName: integration.integrationType + suffix,
                };
          })
          .flat();
        if (additionalFilter) {
          activeMarketplaceIntegrations =
            activeMarketplaceIntegrations.filter(additionalFilter);
        }
        setClientMarketplaceIntegrations([
          {
            integrationType: HOPSTACK_SOURCE,
            integrationName: HOPSTACK_SOURCE,
            id: HOPSTACK_SOURCE,
          },
          ...activeMarketplaceIntegrations,
        ]);
      }
    };
    useEffect(() => {
      setClientIntegrationsFromIntegrationsQuery();
    }, [
      integrationsQuery.data,
      integrationsQuery.loading,
      integrationsQuery.error,
    ]);

    useEffect(() => {
      if (getProductForChannelAllocation.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getProductForChannelAllocation.error) {
        appState.setAlert(
          getProductForChannelAllocation.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (
        getProductForChannelAllocation.data &&
        getProductForChannelAllocation.data.inventoryLedger.entities?.length > 0
      ) {
        integrationsQuery.fetchData({
          filters: {
            customer:
              getProductForChannelAllocation.data.inventoryLedger.entities[0]
                .customer,
          },
        });
        setFetchedProductForChannelAllocation(
          getProductForChannelAllocation.data.inventoryLedger.entities[0],
        );
      }
    }, [
      getProductForChannelAllocation.loading,
      getProductForChannelAllocation.error,
      getProductForChannelAllocation.data,
    ]);

    useEffect(() => {
      if (warehousesQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (warehousesQuery.data) {
        setWarehousesForSelectedProductInAllocation(
          warehousesQuery.data.warehouses.entities,
        );
      }
    }, [warehousesQuery.loading, warehousesQuery.data, warehousesQuery.error]);

    useEffect(() => {
      if (
        fetchedProductForChannelAllocation &&
        fetchedProductForChannelAllocation?.quantities?.warehouses?.length > 0
      ) {
        const warehouseIds =
          fetchedProductForChannelAllocation.quantities.warehouses.map(
            (item) => item.warehouseId,
          );
        warehousesQuery.fetchData({
          filters: { id: warehouseIds },
          perPage: warehouseIds.length,
          pageNumber: 1,
        });
      }
    }, [fetchedProductForChannelAllocation]);

    useEffect(() => {
      if (moveStockQuery.loading) {
        appState.setPageLoading();
      } else {
        appState.removePageLoading();
      }

      if (moveStockQuery.error) {
        appState.setAlert(
          moveStockQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (moveStockQuery.data) {
        inventoryQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }
    }, [moveStockQuery.loading, moveStockQuery.error, moveStockQuery.data]);

    useEffect(() => {
      if (
        getSpecificInventory.data &&
        getSpecificInventory.data.skuBinMapping
      ) {
        setFetchedInventory(getSpecificInventory.data.skuBinMapping);
      }

      if (getSpecificInventory.error) {
        appState.setAlert(
          getSpecificInventory.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getSpecificInventory.loading,
      getSpecificInventory.error,
      getSpecificInventory.data,
    ]);

    useEffect(() => {
      if (updateInventory.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (updateInventory.data) {
        appState.setAlert(
          updateInventory.data.updateInventory.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setFetchedInventory(null);
        if (explainInventoryQuery.data) {
          explainInventoryQuery.fetchData({
            ...explainInventoryQuery.variables,
            reload: true,
          });
        }
        inventoryQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }

      if (updateInventory.error) {
        appState.setAlert(
          updateInventory.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [updateInventory.error, updateInventory.loading, updateInventory.data]);

    const submitFetchedInventory = () => {
      appState.showConfirmation(
        "Confirm",
        `Are you sure you want to modify the stock?.`,
        () => {
          updateInventory.fetchData({
            id: fetchedInventory.id,
            stock:
              stockChangeType === "add"
                ? Math.abs(fetchedInventory.revisedStock)
                : -1 * Math.abs(fetchedInventory.revisedStock),
            user: auth.user?.name,
            reason: fetchedInventory.reason,
            otherReason: fetchedInventory.otherReason,
            warehouse: fetchedInventory.warehouse,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (uploadProductsQuery.loading) {
        appState.setLoading();
      }
      if (uploadProductsQuery.error) {
        appState.setAlert(
          uploadProductsQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.removeLoading();
      }

      if (uploadProductsQuery.data) {
        setTimeout(() => {
          appState.setAlert(
            uploadProductsQuery.data.uploadProducts.message,
            "success",
            ALERT_VISIBILITY_IN_MS,
          );
          entity.setFilters({
            includeBundles: true,
          });
          inventoryQuery.fetchData({
            perPage: entity.perPage,
            filters: {
              includeBundles: true,
            },
            paginated: false,
            pageNumber: 1,
            sort: entity.sort,
          });
          appState.removeLoading();
        }, 4000);
      }
    }, [
      uploadProductsQuery.data,
      uploadProductsQuery.loading,
      uploadProductsQuery.error,
    ]);

    useEffect(() => {
      if (saveProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveProductQuery.error) {
        appState.setAlert(
          saveProductQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveProductQuery.data) {
        appState.setAlert(saveProductQuery.data.saveProduct.message);
        setSelectedProduct(null);
        inventoryQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }
    }, [
      saveProductQuery.data,
      saveProductQuery.loading,
      saveProductQuery.error,
    ]);

    useEffect(() => {
      if (vendorsQuery.loading) {
      } else {
        appState.removeLoading();
      }
      if (vendorsQuery.data) {
        setVendors(vendorsQuery.data.vendors.entities);
      }

      if (vendorsQuery.error) {
      }
    }, [vendorsQuery.loading, vendorsQuery.error, vendorsQuery.data]);

    useEffect(() => {
      if (uploadInventoryQuery.loading) {
        appState.setLoading();
      }

      if (uploadInventoryQuery.error) {
        appState.setAlert(
          uploadInventoryQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.removeLoading();
      }

      if (uploadInventoryQuery.data) {
        setTimeout(() => {
          appState.setAlert(
            uploadInventoryQuery.data.uploadInventory.message,
            "success",
            ALERT_VISIBILITY_IN_MS,
          );
          entity.setFilters({
            includeBundles: true,
          });
          inventoryQuery.fetchData({
            perPage: entity.perPage,
            filters: {
              includeBundles: true,
            },
            paginated: false,
            pageNumber: 1,
            sort: entity.sort,
          });
          appState.removeLoading();
        }, 4000);
      }
    }, [
      uploadInventoryQuery.data,
      uploadInventoryQuery.loading,
      uploadInventoryQuery.error,
    ]);

    const addNewProduct = () => {
      const warehouses = auth.user.warehousesList;
      const customers = auth.user.customersList;
      if (warehouses.length === 0 || customers.length === 0) {
        return appState.setAlert(
          `You don't have necessary permission to execute this action.`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      let selectedWarehouse, selectedCustomer;
      let warehouseToBeSelected = true;
      let customerToBeSelected = true;
      if (warehouses.length === 1) {
        selectedWarehouse = warehouses[0].id;
        warehouseToBeSelected = false;
      }
      if (customers.length === 1) {
        selectedCustomer = customers[0].id;
        customerToBeSelected = false;
      }

      setSelectedProduct({
        warehouse: selectedWarehouse,
        customer: selectedCustomer,
        warehouseToBeSelected,
        customerToBeSelected,
      });
    };

    const onChange = (e) => {
      const product = {
        ...selectedProduct,
      };

      if (e.target.type === "number") {
        if (isNaN(parseInt(e.target.value))) {
          product[e.target.name] = null;
        } else {
          product[e.target.name] = parseInt(e.target.value);
        }
      } else if (e.target.name === "upc" || e.target.name === "ean") {
        product[e.target.name] = e.target.value.split(",");
      } else {
        product[e.target.name] = e.target.value;
      }

      setSelectedProduct(product);
    };

    const onChangeDropdown = (field, value) => {
      const product = {
        ...selectedProduct,
      };

      product[field] = value;
      setSelectedProduct({ ...product });
    };

    const verifyProduct = (product) => {
      saveProductQuery.fetchData({ ...product, unverified: false });
    };

    const uploadInventory = async (files) => {
      appState.setLoading();
      const rows = await readXlsxFile(files);
      for (let i = 0; i < rows.length; i++) {
        if (i === 0 || i === 1) {
          continue;
        }
        const row = rows[i];

        if (!row[0]) {
          continue;
        }
        const tenant = auth.user.customersList.find(
          (i) => i.name.toLowerCase() === row[0].toLowerCase(),
        );
        if (!tenant) {
          appState.setAlert(`Invalid tenant ${row[0]}`);
          continue;
        } else {
          const productData = {
            sku: row[2].toString(),
            name: row[1],
            customer: tenant.id,
          };
          if (tenant.warehouses && tenant.warehouses.length > 0) {
            productData.warehouse = tenant.warehouses[0];
          }
          if (row[3]) {
            productData.upc = row[3].toString().split(" ");
          }
          saveProductQuery.fetchData({
            ...productData,
          });
        }
        // break;
      }
    };

    useEffect(() => {
      if (allInventoryQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (allInventoryQuery.data) {
        allInventoryQuery.data.exportInventory =
          allInventoryQuery.data.exportInventory.map((item) => {
            let transformedItem = {};
            inventoryExportFields.forEach((field) => {
              if (!field.nestedKey) {
                if (field.enabled) {
                  transformedItem[field.name] = item[field.correspondingValue];

                  if (
                    item[field.correspondingValue] === null ||
                    item[field.correspondingValue] === undefined
                  ) {
                    transformedItem[field.name] = "N/A";
                  }

                  // Custom Transformations
                  if (
                    field.id === "lastPutaway" &&
                    item[field.correspondingValue] !== null &&
                    item[field.correspondingValue] !== undefined
                  ) {
                    transformedItem[field.name] = moment(
                      item[field.correspondingValue],
                    )
                      .format("ll")
                      .concat(
                        item.lastPutawayQuantity
                          ? `/ ${item.lastPutawayQuantity}`
                          : `/ 0`,
                      );
                  }
                  if (
                    field.id === "lastReceived" &&
                    item[field.correspondingValue] !== null &&
                    item[field.correspondingValue] !== undefined
                  ) {
                    transformedItem[field.name] = moment(
                      item[field.correspondingValue],
                    )
                      .format("ll")
                      .concat(
                        item.lastReceivedQuantity
                          ? `/ ${item.lastReceivedQuantity}`
                          : `/ 0`,
                      );
                  }
                  if (
                    field.id === "lastFulfilled" &&
                    item[field.correspondingValue] !== null &&
                    item[field.correspondingValue] !== undefined
                  ) {
                    transformedItem[field.name] = moment(
                      item[field.correspondingValue],
                    )
                      .format("ll")
                      .concat(
                        item.lastFulfilledQuantity
                          ? `/ ${item.lastFulfilledQuantity}`
                          : `/ 0`,
                      );
                  }

                  if (
                    field.id === "customer" &&
                    item[field.correspondingValue] !== null &&
                    item[field.correspondingValue] !== undefined
                  ) {
                    const customers = auth.user?.customersList
                      ? auth.user.customersList.length > 1
                        ? [
                            { name: "All", id: "all" },
                            ...auth.user.customersList,
                          ]
                        : auth.user.customersList
                      : [];
                    transformedItem[field.name] = customers.find(
                      (customer) =>
                        customer.id === item[field.correspondingValue],
                    )?.name;
                  }
                }
              } else {
                if (field.enabled) {
                  transformedItem[field.name] =
                    item[field.nestedKey][field.correspondingValue];
                  if (
                    item[field.nestedKey][field.correspondingValue] === null ||
                    item[field.nestedKey][field.correspondingValue] ===
                      undefined
                  ) {
                    transformedItem[field.name] = "N/A";
                  }
                }
              }
            });
            return transformedItem;
          });
        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: true,
          title: "Inventory Report",
          useTextFile: false,
          useBom: true,
          // useKeysAsHeaders: true,
          filename: "Inventory Report - ".concat(moment().format("ll")),
          headers: inventoryExportFields
            .filter((item) => item.enabled)
            .map((item) => item.name),
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(allInventoryQuery.data.exportInventory);
      }

      if (allInventoryQuery.error) {
        alert(allInventoryQuery.error.message);
      }
    }, [
      allInventoryQuery.loading,
      allInventoryQuery.error,
      allInventoryQuery.data,
    ]);

    const onChangeInventoryExportField = (key, enabled) => {
      setInventoryExportFields([
        ...inventoryExportFields.map((item) => {
          if (item.id === key) {
            return { ...item, enabled };
          }
          return { ...item };
        }),
      ]);
    };

    useEffect(() => {
      if (searchLocationsQuery.data?.searchLocations?.data) {
        setBinLocationsOptions(
          searchLocationsQuery.data.searchLocations?.data?.locations,
        );
        appState.removeLoading();
      }
    }, [
      searchLocationsQuery.loading,
      searchLocationsQuery.error,
      searchLocationsQuery.data,
    ]);

    useEffect(() => {
      if (releaseInventoryForShipmentCreationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (releaseInventoryForShipmentCreationQuery.error) {
        appState.setAlert(
          releaseInventoryForShipmentCreationQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (releaseInventoryForShipmentCreationQuery.data) {
        appState.setAlert(
          releaseInventoryForShipmentCreationQuery.data
            .releaseInventoryForShipmentPlan.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setEligibleProducts(null);
        setBundlesWithOverlap([]);
      }
    }, [
      releaseInventoryForShipmentCreationQuery.loading,
      releaseInventoryForShipmentCreationQuery.data,
      releaseInventoryForShipmentCreationQuery.error,
    ]);

    const validateDataPresence = (field) => {
      if (!entity.filters?.[field] || entity.filters[field].length === 0) {
        appState.setAlert(
          `Please select a ${field === "customer" ? "client" : field}`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      if (!entity.filters?.[field] || entity.filters[field].length > 1) {
        appState.setAlert(
          `Please select a specific ${field === "customer" ? "client" : field}`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      return true;
    };

    const validateMarketplaceAndSellerSelection = () => {
      if (!multiAccountSupportEnabled) {
        return true;
      }
      if (
        !entity.filters?.marketplace ||
        entity.filters.marketplace === "All"
      ) {
        appState.setAlert(
          `Please select a Marketplace`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return false;
      }
      if (!entity.filters?.sellerId || entity.filters.sellerId === "All") {
        appState.setAlert(
          `Please select a Seller Account`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return false;
      }
      return true;
    };

    const releaseInventoryForShipmentCreation = () => {
      if (
        validateDataPresence("customer") &&
        validateDataPresence("warehouse") &&
        validateMarketplaceAndSellerSelection()
      ) {
        getInventoryForReleaseQuery.fetchData({
          customer: entity.filters.customer[0],
          warehouse: entity.filters.warehouse[0],
          marketplace: entity.filters.marketplace,
          sellerId: entity.filters.sellerId,
        });
      }
    };

    useEffect(() => {
      if (clientMarketplaceIntegrations?.length > 0) {
        setClientIntegrationsFromIntegrationsQuery((integration) => {
          return (
            entity?.filters?.customer?.includes(integration.customer) ||
            integration.id === HOPSTACK_SOURCE
          );
        });
      }
    }, [entity.filters.customer]);

    const allocateBundle = () => {
      if (
        validateDataPresence("customer") &&
        validateDataPresence("warehouse")
      ) {
        getBundlesAsProductsQuery.fetchData({
          customers: [entity.filters.customer[0]],
          warehouses: [entity.filters.warehouse[0]],
        });
      }
    };

    const onSubmitBundleAllocation = (bundleAllocationDetail) => {
      if (
        !bundleAllocationDetail.allocatedQuantity ||
        isNaN(bundleAllocationDetail.allocatedQuantity)
      ) {
        appState.setAlert(
          `Please provide a valid quantity`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      if (!bundleAllocationDetail.formFactor) {
        appState.setAlert(
          `Please provide a valid form factor`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      allocateInventoryForBundleAsProduct.fetchData({
        bundleId: selectedBundleForAllocation.id,
        uom: bundleAllocationDetail.formFactor,
        quantity: bundleAllocationDetail.allocatedQuantity,
        warehouseId: entity.filters.warehouse[0],
        user: auth.user.id,
      });
    };

    const onChangeItem = (id, field, value) => {
      let copiedEligibleProducts = [...eligibleProducts];
      const idx = copiedEligibleProducts.findIndex((item) => item.id === id);
      let foundItem = copiedEligibleProducts[idx];
      if (
        field === "quantity" &&
        value > foundItem.quantities.availableToShip
      ) {
        appState.setAlert(
          `Cannot set quantity greater than the original. Max quantity ${foundItem.quantities.availableToShip}`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      foundItem[field] = value;

      if (field === "quantityInCase") {
        foundItem.numberOfCases = Math.floor(
          foundItem.quantities.availableToShip / foundItem.quantityInCase,
        );
        foundItem.quantity = foundItem.quantityInCase * foundItem.numberOfCases;
      } else if (field === "numberOfCases") {
        foundItem.quantity = foundItem.quantityInCase * foundItem.numberOfCases;
      }

      copiedEligibleProducts[idx] = foundItem;
      setEligibleProducts(copiedEligibleProducts);
    };

    const onChangeBinSearch = (keyword, warehouse = null) => {
      searchLocationsQuery.fetchData({
        perPage: 10,
        pageNumber: 1,
        code: keyword || undefined,
        warehouses: (warehouse && [warehouse]) || undefined,
        pagination: {
          limit: 10,
          offset: 0,
        },
      });
    };
    const debouncedBinLocationSearch = _.debounce(onChangeBinSearch, 1000);

    const onSubmitReleaseInventory = () => {
      if (!entity.filters?.customer || entity.filters.customer.length === 0) {
        appState.setAlert(
          `Please select a client`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      if (!entity.filters?.customer || entity.filters.customer.length > 1) {
        appState.setAlert(
          `Please select only 1 client`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }

      if (!validateMarketplaceAndSellerSelection()) {
        return;
      }

      if (!eligibleProducts || eligibleProducts.length === 0) {
        appState.setAlert(
          `Please provide products`,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }

      const filteredProducts = eligibleProducts.filter(
        (item) => item.enabled === true,
      );

      if (filteredProducts.length === 0) {
        appState.setAlert(`Please select at least 1 product`);
        return;
      }

      if (
        filteredProducts.findIndex(
          (item) => isNaN(item.quantity) || item.quantity < 0,
        ) !== -1
      ) {
        appState.setAlert(
          `Please provide valid quantities for all selected products`,
        );
        return;
      }

      // For case-pack validate that quantityInCase should be present and valid
      if (shipmentConfiguration.isCasePack) {
        if (
          filteredProducts.findIndex(
            (item) => isNaN(item.quantityInCase) || item.quantityInCase <= 0,
          ) !== -1
        ) {
          appState.setAlert(
            `Please provide valid case-pack quantities for all selected products`,
          );
          return;
        }
      }

      appState.showConfirmation(
        "Confirm",
        `Are you sure you want to release this customer's inventory for shipment plan creation?`,
        () => {
          releaseInventoryForShipmentCreationQuery.fetchData({
            customer: entity.filters.customer[0],
            warehouse: entity.filters.warehouse[0],
            products: filteredProducts.map((item) => ({
              id: item.id,
              quantity: item.quantity,
              quantityInCase: item.quantityInCase,
            })),
            intendedBoxContentsSource:
              shipmentConfiguration.intendedBoxContentsSource,
            typeOfShipment: shipmentConfiguration.typeOfShipment,
            retry: shipmentConfiguration.retry,
            minItemLen:
              (shipmentConfiguration.retry === true &&
                shipmentConfiguration.minItemLen) ||
              1,
            isCasePack: shipmentConfiguration.isCasePack,
            sellerId: entity.filters.sellerId,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (saveOrderQuery.error) {
        appState.setAlert(
          saveOrderQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveOrderQuery.data) {
        appState.setAlert(
          saveOrderQuery.data.saveOrder.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setVendorReturn(null);
      }

      if (saveOrderQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [saveOrderQuery.error, saveOrderQuery.data, saveOrderQuery.loading]);

    const onChangeVendorReturnDropdown = (field, value) => {
      setVendorReturn({ ...vendorReturn, [field]: value });
    };

    const onChangeVendorReturn = (e) => {
      setVendorReturn({ ...vendorReturn, [e.target.name]: e.target.value });
    };

    const explainInventory = (id, typeOfBreakdown, typeOfInnerBreakdown) => {
      explainInventoryQuery.fetchData({
        id,
        typeOfBreakdown,
        typeOfInnerBreakdown,
        filters: entity.filters,
      });
    };

    useEffect(() => {
      if (allocateInventoryToChannelsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (allocateInventoryToChannelsQuery.data) {
        appState.setAlert(
          allocateInventoryToChannelsQuery.data.allocateInventoryToChannels
            .message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        setAddedChannelProducts([]);
        setFetchedProductForChannelAllocation(null);
        inventoryQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }

      if (allocateInventoryToChannelsQuery.error) {
        appState.setAlert(allocateInventoryToChannelsQuery.error.message);
      }
    }, [
      allocateInventoryToChannelsQuery.loading,
      allocateInventoryToChannelsQuery.data,
      allocateInventoryToChannelsQuery.error,
    ]);

    useEffect(() => {
      if (allocateInventoryForBundleAsProduct.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (allocateInventoryForBundleAsProduct.data) {
        appState.setAlert(
          allocateInventoryForBundleAsProduct.data
            .allocateInventoryForBundleAsProduct.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        handleBundleSelection(null);
        setEligibleBundles(null);
        inventoryQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
      }

      if (allocateInventoryForBundleAsProduct.error) {
        appState.setAlert(allocateInventoryForBundleAsProduct.error.message);
      }
    }, [
      allocateInventoryForBundleAsProduct.loading,
      allocateInventoryForBundleAsProduct.data,
      allocateInventoryForBundleAsProduct.error,
    ]);

    const submitChannelAllocation = () => {
      if (!addedChannelProducts || addedChannelProducts.length === 0) {
        appState.setAlert(`Please add products to allocate.`);
        return;
      }

      const itemWithZeroQuantityIdx = addedChannelProducts.findIndex(
        (item) => item.quantity <= 0,
      );
      if (itemWithZeroQuantityIdx !== -1) {
        appState.setAlert(
          `Please allocate at least 1 quantity for product ${
            itemWithZeroQuantityIdx + 1
          }.`,
        );
        return;
      }

      let maximumQuantity = 0;
      for (const warehouse of fetchedProductForChannelAllocation?.quantities
        ?.warehouses) {
        // Check if the warehouse is active, through the warehousesForSelectedProductInAllocation array
        const warehouseFound = warehousesForSelectedProductInAllocation.find(
          (ele) => ele.id.toString() === warehouse.warehouseId,
        );
        if (warehouseFound && warehouseFound?.active === true) {
          maximumQuantity += warehouse.availableToShip;
        }
      }
      if (_.sumBy(addedChannelProducts, "quantity") > maximumQuantity) {
        appState.setAlert(
          `Total allocated quantity cannot be greater than ${maximumQuantity} for this product.`,
        );
        return;
      }

      allocateInventoryToChannelsQuery.fetchData({
        sourceProductId: fetchedProductForChannelAllocation.id,
        channelInventoryAllocations: addedChannelProducts.map((item) => ({
          productId: item.id,
          quantity: item.quantity,
        })),
      });
    };

    const getTransactionHistoryReport = () => {
      if (transactionFilters.startDate && transactionFilters.endDate) {
        if (
          moment(transactionFilters.startDate).isAfter(
            moment(transactionFilters.endDate),
          )
        ) {
          appState.setAlert(
            `Start Date cannot be greater than the End Date.`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
          return;
        }
      }
      getTransactionHistoryReportQuery.fetchData({
        filters: {
          ...transactionFilters,
          keyword: entity.filters.keyword || null,
        },
        module: "Export Transaction History",
      });
    };

    const boxContentsOptions = () => {
      if (fba2DBarcodeEnabled && entity.filters?.customer?.length === 1) {
        const customer = auth.user.customersList.find(
          (customer) => customer.id === entity.filters.customer[0],
        );
        if (customer && customer.settings?.fba2dBarcodeEnabled) {
          return [
            ...BOX_CONTENTS_OPTIONS,
            { name: "2D_BARCODE", value: "2D_BARCODE" },
          ];
        }
      }
      return BOX_CONTENTS_OPTIONS;
    };

    const onSetAddingChannel = (value) => {
      if (value === null) {
        setAddingChannel(null);
        return;
      }

      if (value?.customer !== addingChannel?.customer) {
        /*
          Customer is changed and the integrations/channels need to be refreshed
        */
        integrationsQuery.fetchData({
          filters: {
            customer: value?.customer,
          },
        });
        value.integrationId = null;
        value.marketplace = null;
        value.sellerId = null;
      }

      setAddingChannel(value);
    };

    useEffect(() => {
      if (entity.filters.customer?.length === 1) {
        integrationsQuery.fetchData({
          filters: { customer: entity.filters.customer[0] },
        });
      }
    }, [entity.filters.customer]);

    useEffect(() => {
      if (getBinTransferEligibility.data) {
        setBinTransferEligibility(
          getBinTransferEligibility.data.getBinTransferEligibility,
        );
      }
      if (getBinTransferEligibility.error) {
        appState.setAlert(
          getBinTransferEligibility.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      if (getBinTransferEligibility.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getBinTransferEligibility.data,
      getBinTransferEligibility.loading,
      getBinTransferEligibility.error,
    ]);

    const checkTransferSkuBinMapping = (skuBinMappingId) => {
      getBinTransferEligibility.fetchData({
        skuBinMappingId,
      });
    };

    return (
      <WrappedComponent
        {...props}
        inventory={entity.entities}
        displayInventory={entity.displayEntities}
        unverifiedExists={entity.unverifiedExists}
        total={
          inventoryQuery.data ? inventoryQuery.data.inventoryLedger?.total : 0
        }
        totalInWarehouse={
          inventoryQuery.data
            ? inventoryQuery.data.inventoryLedger?.totalInWarehouse
            : 0
        }
        totalAvailableToShip={
          inventoryQuery.data
            ? inventoryQuery.data.inventoryLedger?.totalAvailableToShip
            : 0
        }
        totalDamaged={
          inventoryQuery.data
            ? inventoryQuery.data.inventoryLedger?.totalDamaged
            : 0
        }
        totalAllocated={
          inventoryQuery.data
            ? inventoryQuery.data.inventoryLedger?.totalAllocated
            : 0
        }
        getSku={(id) => {
          getSku.fetchData({ id });
        }}
        getSpecificInventory={(id) => {
          getSpecificInventory.fetchData({ id });
        }}
        vendors={vendors}
        fetchedSku={fetchedSku}
        fetchedInventory={fetchedInventory}
        setFetchedInventory={setFetchedInventory}
        binLocationsOptions={binLocationsOptions}
        setBinLocationsOptions={setBinLocationsOptions}
        submitFetchedInventory={submitFetchedInventory}
        setFetchedSku={setFetchedSku}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          inventoryQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          inventoryQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          inventoryQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        showAllUnverifiedInventory={() => {
          entity.setFilters({
            ...entity.filters,
            ["unverified"]: true,
            ["includeOutOfStock"]: true,
          });
          inventoryQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
              ["unverified"]: true,
              ["includeOutOfStock"]: true,
            },
            sort: entity.sort,
          });
        }}
        onChangeFilter={(field, value, autoSubmit = false) => {
          if (field === "customer" && value?.includes("all")) {
            value = auth.user.customers;
          }
          if (field === "warehouse" && value?.includes("all")) {
            value = auth.user.warehouses;
          }
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            inventoryQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          inventoryQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        verifyProduct={verifyProduct}
        uploadProducts={() => uploadProductsQuery.fetchData()}
        uploadInventory={uploadInventory}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        addNewProduct={addNewProduct}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        saveProduct={() => {
          saveProductQuery.fetchData({
            ...selectedProduct,
          });
        }}
        exportToCsv={() => setShowExportInventoryFieldSelector(true)}
        showExportInventoryFieldSelector={showExportInventoryFieldSelector}
        setShowExportInventoryFieldSelector={
          setShowExportInventoryFieldSelector
        }
        inventoryExportFields={inventoryExportFields}
        onSubmitInventoryExportFields={() => {
          allInventoryQuery.fetchData({
            filters: entity.filters,
            sort: entity.sort,
          });
          setShowExportInventoryFieldSelector(false);
        }}
        onChangeInventoryExportField={onChangeInventoryExportField}
        releaseInventoryForShipmentCreation={
          releaseInventoryForShipmentCreation
        }
        setVendorReturn={setVendorReturn}
        debouncedBinLocationSearch={debouncedBinLocationSearch}
        vendorReturn={vendorReturn}
        inventorySearchKeyword={inventorySearchKeyword}
        setInventorySearchKeyword={setInventorySearchKeyword}
        isInventoryLoading={inventoryQuery?.loading}
        inventorySearchResults={
          inventorySearchQuery?.data?.inventoryLedger.entities || []
        }
        onChangeVendorReturn={onChangeVendorReturn}
        onChangeVendorReturnDropdown={onChangeVendorReturnDropdown}
        submitVendorReturn={() =>
          saveOrderQuery.fetchData({
            ...vendorReturn,
            workflowType: "RETURN",
            source: "RETURN",
            orderLineItems: vendorReturn.orderLineItems.map((lineItem) => ({
              productId: lineItem.productId,
              quantity: parseInt(lineItem.quantity),
              formFactor: lineItem.formFactor,
              fulfillmentType: "VENDOR",
              notes: lineItem.reason,
            })),
          })
        }
        eligibleProducts={eligibleProducts}
        setEligibleProducts={setEligibleProducts}
        onChangeItem={onChangeItem}
        onSubmitReleaseInventory={onSubmitReleaseInventory}
        subdomain={appState.subdomain}
        explainInventory={explainInventory}
        inventoryExplanations={inventoryExplanations}
        setInventoryExplanations={setInventoryExplanations}
        getProductForChannelAllocation={(id) =>
          getProductForChannelAllocation.fetchData({
            filters: {
              id: [id],
            },
            perPage: 1,
            pageNumber: 1,
          })
        }
        moveStock={(id) => moveStockQuery.fetchData({ sourceProductId: id })}
        fetchedProductForChannelAllocation={fetchedProductForChannelAllocation}
        setFetchedProductForChannelAllocation={
          setFetchedProductForChannelAllocation
        }
        warehousesForSelectedProductInAllocation={
          warehousesForSelectedProductInAllocation
        }
        submitChannelAllocation={submitChannelAllocation}
        clientMarketplaceIntegrations={clientMarketplaceIntegrations}
        onChangeChannelProductSearch={onChangeChannelProductSearch}
        marketplaceProducts={marketplaceProducts}
        setMarketplaceProducts={setMarketplaceProducts}
        channelProductSearchKeyword={channelProductSearchKeyword}
        setChannelProductSearchKeyword={setChannelProductSearchKeyword}
        addedChannelProducts={addedChannelProducts}
        setAddedChannelProducts={setAddedChannelProducts}
        stockChangeType={stockChangeType}
        setStockChangeType={setStockChangeType}
        allocateBundle={allocateBundle}
        eligibleBundles={eligibleBundles}
        setEligibleBundles={setEligibleBundles}
        selectedBundleForAllocation={selectedBundleForAllocation}
        showBundleAllocationForm={showBundleAllocationForm}
        onSubmitBundleAllocation={onSubmitBundleAllocation}
        handleBundleSelection={handleBundleSelection}
        packingMethodOptions={
          fbaCasePackEnabled
            ? PACKING_METHODS_OPTIONS
            : PACKING_METHODS_OPTIONS.filter(
                (item) => item.name !== "Case Pack",
              )
        }
        boxContentsOptions={boxContentsOptions()}
        shipmentConfiguration={shipmentConfiguration}
        setShipmentConfiguration={setShipmentConfiguration}
        bundlesWithOverlap={bundlesWithOverlap}
        setBundlesWithOverlap={setBundlesWithOverlap}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        transactionHistoryReportEnabled={transactionHistoryReportEnabled}
        transactionFilters={transactionFilters}
        setTransactionFilters={setTransactionFilters}
        getTransactionHistoryReport={getTransactionHistoryReport}
        addingChannel={addingChannel}
        setAddingChannel={onSetAddingChannel}
        checkTransferSkuBinMapping={checkTransferSkuBinMapping}
        binTransferEligibility={binTransferEligibility}
        setBinTransferEligibility={setBinTransferEligibility}
      />
    );
  };
};

export default withInventoryLedgerLogic;
