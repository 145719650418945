import withUoMLogic from "#components/HOC/withUoMLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import UoMList from "#components/uom/UoMList";
import UoMForm from "#components/uom/UoMForm";
import { UserAddIcon } from "@heroicons/react/outline";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import UploadBulk from "../components/bulkUpload/UploadBulk";

const UoM = ({
  masterData,
  stations,
  selectedStation,
  setSelectedStation,
  saveStation,
  onChangeMultiSelect,
  selectedCustomer,
  setSelectedCustomer,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  writable,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>UoM Management</PageTitle>
      </div>

      <div className="mb-4 flex items-center justify-end space-x-2">
        <AddButton
          text="Add Custom UoM"
          onClick={() => setSelectedStation({})}
          icon={UserAddIcon}
          disabled={!writable}
        />
      </div>
    </div>
    <UoMList
      stations={stations}
      masterData={masterData}
      headers={["Name", "Status", "Creation Date"]}
      noValuesText="No UoM Available"
      total={total}
      pageNumber={pageNumber}
      checkPagination={checkPagination}
      perPage={perPage}
      setPerPage={setPerPage}
    />
    {selectedStation && (
      <UoMForm
        title={"Add Custom UoM"}
        selectedStation={selectedStation}
        onClose={() => setSelectedStation()}
        onSubmit={() => saveStation(selectedStation)}
        onChangeMultiSelect={onChangeMultiSelect}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
      />
    )}
  </div>
);

export default withUoMLogic(UoM);
