import PageTitle from "#components/utils/PageTitle";
import TextField from "#components/utils/TextField";
import withBillingProfileLogic from "#components/HOC/withBillingProfileLogic";
import BillingProfilesList from "#components/billingProfiles/BillingProfilesList";
import BillingProfileSummary from "#components/billingProfiles/BillingProfileSummary";
import { useFeatureFlags } from "#contexts/featureFlags";

export const BillingStatus = {
  ACTIVE: "ACTIVE",
  DRAFT: "DRAFT",
  INACTIVE: "INACTIVE",
};

const BillingProfiles = ({
  billingProfiles,
  billingProfileFormConfig,
  selectedProfile,
  setSelectedProfile,
  fetchBillingProfiles,
  saveBillingProfile,
  setSort,
  sort,
  subdomain,
  perPage,
  fetchAudits,
  setPerPage,
  pageNumber,
  selectedAudit,
  setSelectedAudit,
  total,
  checkPagination,
  role,
}) => {
  const { billingModuleEnabled } = useFeatureFlags();

  if (!role || role.toLowerCase() !== "admin") {
    return (
      <div className="mt-4 flex h-full items-center justify-center text-2xl">
        Coming Soon! Keep watching this space.
      </div>
    );
  }

  if (!billingModuleEnabled)
    return (
      <div className="mt-4 flex h-full items-center justify-center text-2xl">
        Coming Soon! Keep watching this space.
      </div>
    );

  return (
    <div className="bg-white p-4">
      {selectedProfile ? (
        <BillingProfileSummary
          saveBillingProfile={saveBillingProfile}
          setSelectedProfile={setSelectedProfile}
          billingProfileFormConfig={billingProfileFormConfig}
          billingProfile={selectedProfile}
          setBillingProfile={setSelectedProfile}
        />
      ) : (
        <BillingProfilesList
          selectedAudit={selectedAudit}
          setSelectedAudit={setSelectedAudit}
          fetchAudits={fetchAudits}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          setSort={setSort}
          sort={sort}
          total={total}
          checkPagination={checkPagination}
          fetchBillingProfiles={fetchBillingProfiles}
          billingProfiles={billingProfiles}
          setSelectedProfile={setSelectedProfile}
        />
      )}
    </div>
  );
};

export default withBillingProfileLogic(BillingProfiles);
