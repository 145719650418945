// store.js
import { configureStore } from "@reduxjs/toolkit";
import fbaInboundReducer from "./FbaInbound/fbaInboundSlice";
import fbaPackingReducer from "./FbaPacking/fbaPackingSlice";

const store = configureStore({
  reducer: {
    fbaInbound: fbaInboundReducer,
    fbaPacking: fbaPackingReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
