const LoadingIndicator = ({
  shouldShowOnPage = true,
  color = "text-primaryAccent",
  className,
}) => {
  return shouldShowOnPage ? (
    <div
      className={
        `fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center font-bold ${className} ` +
        color
      }>
      <svg
        className="-ml-1 mr-3 h-10 w-10 animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  ) : (
    <div className={`flex items-center justify-center ${className} ` + color}>
      <svg
        className="-ml-1 mr-3 h-8 w-8 animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  );
};

export const Indicator = ({
  color,
  indicatorClasses = "h-12 w-12 animate-spin",
  ringWidth = "4", // New prop for controlling ring width
}) => (
  <svg
    className={indicatorClasses}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24">
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke={color || "currentColor"}
      strokeWidth={ringWidth}></circle>
    <path
      className="opacity-75"
      fill={color || "currentColor"}
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
);

export default LoadingIndicator;
