import React, { useState, useEffect } from "react";
import NewModal from "#newUiComponents/commons/NewModal";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { Tooltip } from "antd";

const ReviewBoxesModal = ({ isOpen, onConfirm, onClose, groups }) => {
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [expandedBoxes, setExpandedBoxes] = useState({});

  useEffect(() => {
    if (groups.length > 0) {
      setExpandedGroups([groups[0].groupName]);
      if (groups[0].boxes.length > 0) {
        setExpandedBoxes({
          [groups[0].groupName]: [groups[0].boxes[0].boxName],
        });
      }
    }
  }, [isOpen, groups]);

  const toggleGroup = (groupName) => {
    setExpandedGroups((prev) =>
      prev.includes(groupName)
        ? prev.filter((g) => g !== groupName)
        : [...prev, groupName],
    );
  };

  const toggleBox = (groupName, boxName) => {
    setExpandedBoxes((prev) => {
      const groupBoxes = prev[groupName] || [];
      const newGroupBoxes = groupBoxes.includes(boxName)
        ? groupBoxes.filter((b) => b !== boxName)
        : [...groupBoxes, boxName];

      return {
        ...prev,
        [groupName]: newGroupBoxes,
      };
    });
  };

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      title="Review and Confirm"
      minWidth="50vw"
      maxHeight="60vh">
      <div className="flex h-full flex-col">
        <div className="flex-1 space-y-4 overflow-auto p-4 font-inter">
          {groups.map((group) => (
            <div
              key={group.groupName}
              className="rounded-lg border border-gray-200 bg-gray-50">
              <div
                className="flex cursor-pointer items-center justify-between p-3"
                onClick={() => toggleGroup(group.groupName)}>
                <div className="flex items-center">
                  {expandedGroups.includes(group.groupName) ? (
                    <ChevronDownIcon className="mr-2 h-5 w-5 text-gray-600" />
                  ) : (
                    <ChevronRightIcon className="mr-2 h-5 w-5 text-gray-600" />
                  )}
                  <span className="text-base font-semibold text-gray-800">
                    {group.groupName}
                  </span>
                  <span className="ml-4 text-sm text-gray-500">
                    {group.boxes.length} Boxes | {group.assignedItemsNo} Items
                  </span>
                </div>
              </div>

              {expandedGroups.includes(group.groupName) && (
                <div className="space-y-4 bg-white p-4">
                  {group.boxes.map((box) => {
                    const groupedItems = box.items.reduce((acc, item) => {
                      if (!acc[item.sku]) {
                        acc[item.sku] = {
                          name: item.productName,
                          sku: item.sku,
                          msku: item.msku,
                          fnsku: item.fnsku || item.fnSku,
                          quantity: 0,
                        };
                      }
                      acc[item.sku].quantity += 1;
                      return acc;
                    }, {});

                    return (
                      <div
                        key={box.boxName}
                        className="rounded-lg border border-gray-200">
                        <div
                          className="flex cursor-pointer items-center justify-between bg-gray-50 p-4"
                          onClick={() =>
                            toggleBox(group.groupName, box.boxName)
                          }>
                          <div className="flex items-center">
                            {expandedBoxes[group.groupName]?.includes(
                              box.boxName,
                            ) ? (
                              <ChevronDownIcon className="mr-2 h-5 w-5 text-gray-600" />
                            ) : (
                              <ChevronRightIcon className="mr-2 h-5 w-5 text-gray-600" />
                            )}
                            <span className="text-base font-semibold text-gray-800">
                              {box.boxName}
                            </span>
                            <span className="ml-4 text-sm text-gray-500">
                              {box.assignedQuantity} Items
                            </span>
                          </div>
                          <div className="text-sm text-gray-500">
                            <span
                              className={
                                box.weight === 0 ? "text-red-500" : ""
                              }>
                              Weight: {box.weight || 0} lbs
                            </span>
                            {" | "}
                            Dimensions: {box.dimensions.length}*
                            {box.dimensions.width}*{box.dimensions.height} in
                          </div>
                        </div>

                        {expandedBoxes[group.groupName]?.includes(
                          box.boxName,
                        ) && (
                          <div className="p-4">
                            {Object.values(groupedItems).map((item) => (
                              <div className="mb-4" key={item.sku}>
                                <Tooltip title={item.name}>
                                  <div className="mb-4 w-96 truncate text-base text-gray-700">
                                    {item.name}
                                  </div>
                                </Tooltip>
                                <div className="grid grid-cols-12 gap-4 text-sm font-medium text-gray-500">
                                  <div className="col-span-3">SKU</div>
                                  <div className="col-span-3">MSKU</div>
                                  <div className="col-span-4">FNSKU</div>
                                  <div className="col-span-2 text-right">
                                    Quantity
                                  </div>
                                </div>
                                <div className="mt-2 grid grid-cols-12 gap-4 text-sm text-gray-700">
                                  <div className="col-span-3">{item.sku}</div>
                                  <div className="col-span-3">{item.msku}</div>
                                  <div className="col-span-4">{item.fnsku}</div>
                                  <div className="col-span-2 text-right">
                                    {item.quantity}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="sticky bottom-0 flex justify-end gap-4 border-t bg-white p-4">
          <PrimaryButton
            height="2.5rem"
            width="7rem"
            variant="secondary"
            onClick={onClose}>
            Cancel
          </PrimaryButton>
          <PrimaryButton
            height="2.5rem"
            width="7rem"
            variant="primary"
            onClick={onConfirm}>
            Confirm
          </PrimaryButton>
        </div>
      </div>
    </NewModal>
  );
};

export default ReviewBoxesModal;
