import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";

const ListView = ({
  currentBatch,
  pickingScanningDisabled,
  confirmAllItems,
  onChangePickedQuantityOfItem,
  onClickDownloadPickList,
}) => {
  const headers = ["SKU", "Bin Location", "Qty", "Picked Qty"];
  if (!pickingScanningDisabled) {
    headers.push("Status");
  }

  const renderButton = (status) => {
    switch (status) {
      case "CONFIRMED":
        return (
          <div className="rounded-full bg-50BFC3 px-2 py-1 text-center text-lg text-454A4F">
            Confirmed
          </div>
        );
      case "INCOMPLETE":
        return (
          <div className="rounded-full bg-red-500 px-2 py-1 text-center text-lg text-white">
            Incomplete
          </div>
        );
      case "UNPROCESSED":
        return (
          <div className="rounded-full bg-EFE9DC px-2 py-1 text-center text-lg text-454A4F">
            Not Picked
          </div>
        );
    }
  };

  return (
    <div className="max-w-4xl flex-1">
      <div className="mb-4 flex justify-end">
        {pickingScanningDisabled && (
          <button
            className="hover:bg-1B5C74 w-48 rounded-lg bg-2C7695 py-2 text-lg text-white transition"
            onClick={() => {
              onClickDownloadPickList(currentBatch.id);
            }}>
            Download Pick List
          </button>
        )}
      </div>

      <div className="text-xl font-semibold text-gray-800">
        Batch ID: {currentBatch?.id}
      </div>
      <div className="text-xl font-medium text-gray-600">
        Total Items: ({currentBatch?.workingList?.length})
      </div>

      <div className="mt-10 rounded-md bg-EBEBEB">
        <table className="-mt-4 min-w-full divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="font-montserratborder-left text-primaryAccent">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {currentBatch.workingList.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {item.sku}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.binLocation}
                </td>
                <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                  {item.quantity}
                </td>

                {!pickingScanningDisabled && (
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.pickedQuantity}
                  </td>
                )}
                {pickingScanningDisabled && (
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    <input
                      type="number"
                      id="pickedQuantity"
                      label="Picked Quantity"
                      placeholder="Picked Quantity"
                      onChange={(e) => {
                        onChangePickedQuantityOfItem(e.target.value, item.id);
                      }}
                      value={item.pickedQuantity}
                    />
                  </td>
                )}
                {!pickingScanningDisabled && (
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {renderButton(item.status)}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {pickingScanningDisabled && (
        <div className="flex items-center justify-center py-4">
          <div
            className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
            onClick={confirmAllItems}>
            Confirm Items
          </div>
        </div>
      )}
    </div>
  );
};

export default ListView;
