import { useState } from "react";
import Modal from "#components/utils/Modal";

const ProductImages = ({ images }) => {
  const [showImage, setShowImage] = useState(null);

  return (
    <>
      <div className="container flex items-center justify-center space-x-4">
        {images?.length > 0 &&
          images.map((image, idx) => {
            return (
              <div
                className="product-image relative col-span-1 inline w-[90%] flex-shrink-0 cursor-pointer rounded transition ease-in-out hover:scale-110"
                key={image.url}
                onClick={() => setShowImage(image.display_url)}>
                <img src={image.display_url} />
              </div>
            );
          })}
      </div>
      {showImage && (
        <Modal
          negativeAction={() => setShowImage(null)}
          title={`Image Preview`}
          noPadding={true}
          positiveAction={() => setShowImage(null)}
          positiveText="OK">
          <div className="space-y-4 p-4">
            <div>
              <img
                className="mx-auto h-3/4 w-3/4 overflow-hidden rounded-lg object-contain"
                src={showImage}
                alt="No Image"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

function isValidURL(string) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
}

export default ProductImages;
