import React, { useEffect, useRef } from "react";

const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useRef();

  // Update the ref to always point to the latest effect
  useEffect(() => {
    callback.current = effect;
  }, [effect]);

  useEffect(() => {
    // If delay is "immediate", call the effect immediately
    if (delay === "immediate") {
      callback.current();
      return;
    }

    // Otherwise, set a timeout to debounce the effect
    const handler = () => {
      callback.current();
    };

    const id = setTimeout(handler, delay);
    return () => clearTimeout(id);
  }, [...(deps || []), delay]);
};

export default useDebouncedEffect;
