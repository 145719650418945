import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CUSTOMERS,
  GET_CUSTOMER,
  GET_WAREHOUSES,
  GET_BILLING_PROFILES,
  GET_CUSTOMER_FIELDS,
} from "#queries";
import {
  SAVE_CUSTOMER,
  DELETE_CUSTOMER,
  ASSIGN_BILLING_PROFILE,
  BULK_UPLOAD_CUSTOMER,
} from "#mutations";
import _ from "lodash";
import readXlsxFile from "read-excel-file";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";
import { useFeatureFlags } from "#contexts/featureFlags";

const withCustomersLogic = (WrappedComponent) => {
  return (props) => {
    const auth = useContext(AuthContext);
    const appState = useContext(AppStateContext);
    const entity = useContext(EntityContext);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [newCreatedClientId, setNewCreatedClientId] = useState(null);
    const [updateConfirmation, setUpdateConfirmation] = useState(false);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const warehousesQuery = useQuery(GET_WAREHOUSES);
    const getCustomerQuery = useQuery(GET_CUSTOMER);
    const saveCustomerQuery = useQuery(SAVE_CUSTOMER);
    const saveMultipleCustomerQuery = useQuery(SAVE_CUSTOMER);
    const deleteCustomerQuery = useQuery(DELETE_CUSTOMER);
    const assignBillingProfileQuery = useQuery(ASSIGN_BILLING_PROFILE);
    const billingProfilesQuery = useQuery(GET_BILLING_PROFILES);
    const [selectedBillingProfile, setSelectedBillingProfile] = useState(null);
    const [showAssignBillingProfileForm, setShowAssignBillingProfileForm] =
      useState(false);
    const getCustomerFields = useQuery(GET_CUSTOMER_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_CUSTOMER);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Customers" }),
    );
    const [validationResult, setValidationResult] = useState(null);

    const { fba2DBarcodeEnabled } = useFeatureFlags();

    useEffect(() => {
      customersQuery.fetchData({
        perPage: entity.perPage,
        filters: {
          includeInactive: true,
        },
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
      billingProfilesQuery.fetchData({
        filters: {
          status: "ACTIVE",
        },
      });
      getCustomerFields.fetchData();
    }, []);

    useEffect(() => {
      if (getCustomerFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getCustomerFields.data) {
        setDashboardFields(getCustomerFields.data.customerFields);
      }
    }, [
      getCustomerFields.loading,
      getCustomerFields.data,
      getCustomerFields.error,
    ]);

    useEffect(() => {
      if (customersQuery.data) {
        entity.setEntities({
          ...customersQuery.data.customers,
          ...customersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [customersQuery.loading, customersQuery.error, customersQuery.data]);

    useEffect(() => {
      if (getCustomerQuery.data) {
        setUpdateConfirmation(true);
        setSelectedCustomer(getCustomerQuery.data.customer);
      }

      if (getCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getCustomerQuery.loading,
      getCustomerQuery.data,
      getCustomerQuery.error,
    ]);

    useEffect(() => {
      if (saveCustomerQuery.data) {
        setConfirmationVisible(true);
        setSelectedCustomer(null);
        customersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }
      if (saveCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveCustomerQuery.error) {
        appState.setAlert(saveCustomerQuery.error.message, "error", 5000);
      }
    }, [
      saveCustomerQuery.loading,
      saveCustomerQuery.data,
      saveCustomerQuery.error,
    ]);

    useEffect(() => {
      if (uploadBulk.data) {
        customersQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadCustomers.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadCustomers
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      if (deleteCustomerQuery.data) {
        appState.hideConfirmation();
        customersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
        appState.setAlert(
          deleteCustomerQuery.data.deleteCustomer.message,
          "success",
          5000,
        );
      }
      if (deleteCustomerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteCustomerQuery.error) {
        appState.setAlert(deleteCustomerQuery.error.message, "error", 5000);
        appState.hideConfirmation();
      }
    }, [
      deleteCustomerQuery.loading,
      deleteCustomerQuery.data,
      deleteCustomerQuery.error,
    ]);

    useEffect(() => {
      if (assignBillingProfileQuery.data) {
        setShowAssignBillingProfileForm(null);
        customersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (assignBillingProfileQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (assignBillingProfileQuery.error) {
        appState.setAlert(
          assignBillingProfileQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      assignBillingProfileQuery.loading,
      assignBillingProfileQuery.error,
      assignBillingProfileQuery.data,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this tenant?",
        () => {
          deleteCustomerQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };
    const roleWise = (customers, role) =>
      _.filter(customers, (e) => e.role.name === role);

    const onChange = (e) => {
      const customer = {
        ...selectedCustomer,
      };

      if (e.target.name?.indexOf("settings") !== -1) {
        const settings = customer.settings
          ? {
              ...customer.settings,
            }
          : {};
        settings[e.target.name.split(".")[1]] = e.target.value;
        customer.settings = settings;
      } else {
        customer[e.target.name] = e.target.value;
      }
      setSelectedCustomer(customer);
    };

    const onChangeMultiSelect = (field, value) => {
      const customer = {
        ...selectedCustomer,
      };

      customer[field] = value;
      setSelectedCustomer(customer);
    };

    const uploadMultipleEntries = async (files) => {
      const rows = await readXlsxFile(files);
      for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
          continue;
        }
        const row = rows[i];
        if (!row[0] || !row[1]) {
          continue;
        }

        const availableWarehouses = warehousesQuery.data
          ? warehousesQuery.data.warehouses
          : [];

        const name = row[0].toString();
        const code = row[1].toString();

        const selectedWarehouses = row[2]
          .toString()
          .split(",")
          .map((s) => {
            const id = availableWarehouses.find((w) => {
              return w.name === s.trim();
            })?.id;
            return id;
          })
          .filter((i) => i !== undefined);

        saveMultipleCustomerQuery.fetchData({
          name,
          code,
          warehouses: selectedWarehouses,
        });
      }

      appState.setLoading();
      setTimeout(() => {
        customersQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        appState.removeLoading();
      }, 1500);
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return customersQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        customers={entity.displayEntities}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        fetchCustomer={(id) => getCustomerQuery.fetchData({ id })}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        saveCustomer={(customer) => {
          setNewCreatedClientId(customer.name);
          saveCustomerQuery.fetchData({ ...customer });
        }}
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        confirmationVisible={confirmationVisible}
        setConfirmationVisible={setConfirmationVisible}
        newCreatedClientId={newCreatedClientId}
        setNewCreatedClientId={setNewCreatedClientId}
        updateConfirmation={updateConfirmation}
        setUpdateConfirmation={setUpdateConfirmation}
        uploadMultipleEntries={uploadMultipleEntries}
        roleWise={roleWise}
        onChange={onChange}
        onChangeMultiSelect={onChangeMultiSelect}
        deleteButtonClicked={deleteButtonClicked}
        setShowAssignBillingProfileForm={setShowAssignBillingProfileForm}
        showAssignBillingProfileForm={showAssignBillingProfileForm}
        onChangeBillingProfile={(billingProfile) =>
          setSelectedBillingProfile(billingProfile)
        }
        billingProfiles={billingProfilesQuery.data?.billingProfiles?.data || []}
        selectedBillingProfile={selectedBillingProfile}
        assignBillingProfile={() => {
          assignBillingProfileQuery.fetchData({
            customerId: showAssignBillingProfileForm.id,
            profileId: selectedBillingProfile,
          });
        }}
        writable={props.writable}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          customersQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          customersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          customersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            customersQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,

                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          customersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={() => {
          entity.setFilters({});
          customersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {},
            sort: entity.sort,
          });
        }}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
        fba2dBarcodeEnabled={fba2DBarcodeEnabled}
      />
    );
  };
};

export default withCustomersLogic;
