import React, { useState } from "react";
import PropTypes from "prop-types";
import { ClockIcon, ArrowsExpandIcon } from "@heroicons/react/outline";
import groupIcon from "../../../static/images/groupIcon.png";
import SlideOverPanel from "#components/common/SlideOverPanel";
import PackingGroupReview from "./PackingGroupReview";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";

const SelectPackingOptions = ({
  packingOptions,
  selectedPackingOption,
  setSelectedPackingOption,
}) => {
  const [openGroupOverview, setOpenGroupOverview] = useState(false);
  const [packingOptionForOverview, setPackingOptionForOverview] = useState({});

  const getApplicableText = (configurations) => {
    if (!configurations || configurations.length === 0) return "Not Applicable";

    const modes = configurations.map((config) => config.shippingMode);

    if (
      modes.some((mode) => mode.includes("SMALL_PARCEL")) &&
      modes.some((mode) => mode.includes("LTL"))
    ) {
      return "Applicable for SP and LTL";
    } else if (modes.some((mode) => mode.includes("SMALL_PARCEL"))) {
      return "Applicable for SP";
    } else if (modes.some((mode) => mode.includes("LTL"))) {
      return "Applicable for LTL";
    }

    return "Not Applicable";
  };

  return (
    <div className="font-inter">
      <div className="mb-4 text-lg font-semibold">
        Total No of Options: {packingOptions.length}
      </div>
      <div className="flex flex-col gap-12">
        {packingOptions.map((option, index) => (
          <div
            key={index}
            className={`relative flex items-center rounded-lg border p-4 ${
              selectedPackingOption === option.packingOptionId
                ? "border-primaryAccent-500 shadow-lg"
                : "border-gray-300"
            }`}>
            <div
              style={{
                borderTopRightRadius: "1rem",
                borderBottomRightRadius: "1rem",
              }}
              className="absolute left-0 top-3 inline-block rounded-r-lg bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-600">
              {option.discounts?.[0]?.description || "No Discount"}
            </div>
            {/* Radio Button */}
            <div className="mt-2">
              <input
                type="radio"
                name="packingOption"
                checked={selectedPackingOption === option.packingOptionId}
                onChange={() =>
                  setSelectedPackingOption(option.packingOptionId)
                }
                className="h-5 w-5 cursor-pointer text-primaryAccent focus:ring-primaryAccent"
              />
            </div>

            {/* Option Details */}
            <div className="ml-4 w-60">
              {/* Option Name and Fee */}
              <div className="mt-2">
                <div className="text-lg font-medium">
                  Option {String.fromCharCode(65 + index)}
                </div>
                <div className="text-sm text-gray-500">
                  {option.fees?.[0]?.description
                    ? `$${option.fees[0].value?.amount || 0} ${option.fees[0].description}`
                    : ""}
                </div>
                <div className="text-sm text-gray-500">
                  {getApplicableText(option.supportedShippingConfigurations)}
                </div>
              </div>
            </div>

            {/* Groups */}
            <div className="ml-8 flex w-full flex-wrap items-center gap-4 overflow-auto">
              {option.packingGroupsWithGroupItems?.map((group, groupIndex) => (
                <div
                  key={groupIndex}
                  className="flex h-28 w-20 flex-col items-center justify-center gap-1 bg-white p-2">
                  <span className="text-sm font-medium">
                    Group {groupIndex + 1}
                  </span>
                  <img
                    key={groupIndex}
                    src={groupIcon}
                    alt={"Group Image"}
                    className="mb-2 h-12 w-12 rounded-md object-cover"
                  />
                  <span className="text-xs text-gray-500">
                    {group.items?.reduce(
                      (acc, curr) => acc + curr.quantity,
                      0,
                    ) || 0}{" "}
                    Items
                  </span>
                </div>
              ))}
            </div>

            {/* Expiry Tag */}
            {option.expiration && (
              <div
                style={{
                  top: "-1.5rem",
                  borderTopRightRadius: "1rem",
                  borderBottomLeftRadius: "2rem",
                  right: "1rem",
                }}
                className="absolute right-4 top-2 flex -translate-y-6 items-center rounded-t-lg bg-yellow-100 px-2 py-1 text-xs text-yellow-700">
                <ClockIcon className="mr-1 inline-block h-4 w-4" />
                {option.expiration || "No Expiry"}
              </div>
            )}

            {/* Expand Icon */}
            <div className="absolute right-4 top-4 cursor-pointer text-gray-400 hover:text-gray-600">
              <ArrowsExpandIcon
                onClick={() => {
                  setPackingOptionForOverview(option);
                  setOpenGroupOverview(true);
                }}
                className="h-5 w-5"
              />
            </div>
          </div>
        ))}
      </div>
      <SlideOverPanel
        open={openGroupOverview}
        containerStyle={"max-w-6xl"}
        setOpen={setOpenGroupOverview}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={"Packing Option - Groups"}
            description={
              "Review the details of the items included in this packing group, including quantities and SKUs."
            }
            isLearnMore={false}
            isArrow
            arrowAction={() => setOpenGroupOverview(false)}
            mainClasses="mb-0"
          />
        }>
        <PackingGroupReview
          groups={packingOptionForOverview?.packingGroupsWithGroupItems}
        />
      </SlideOverPanel>
    </div>
  );
};

SelectPackingOptions.propTypes = {
  packingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      packingOptionId: PropTypes.string,
      discounts: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
        }),
      ),
      packingGroupsWithGroupItems: PropTypes.arrayOf(
        PropTypes.shape({
          items: PropTypes.arrayOf(
            PropTypes.shape({
              image: PropTypes.string,
              quantity: PropTypes.number,
              asin: PropTypes.string,
            }),
          ),
        }),
      ),
      expiration: PropTypes.string,
      fees: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          value: PropTypes.shape({
            amount: PropTypes.number,
          }),
        }),
      ),
      supportedShippingConfigurations: PropTypes.arrayOf(
        PropTypes.shape({
          shippingMode: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default SelectPackingOptions;
