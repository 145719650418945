import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { PlusIcon, MinusIcon } from "@heroicons/react/solid";
import CheckBox from "#newUiComponents/commons/CheckBox";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { Tooltip } from "antd";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";

const AddItemsInBox = ({ itemsData, onConfirm, onCancel }) => {
  const notify = CustomNotification();

  const [data, setData] = useState(
    itemsData.map((item) => ({
      ...item,
      selected: false,
      availableQuantity: item.quantity, // Original quantity becomes available quantity
      // Remove redundant units field
    })),
  );
  const [searchTerm, setSearchTerm] = useState("");

  const handleQuantityChange = (msku, operation, directValue = null) => {
    setData((prevData) =>
      prevData.map((item) => {
        if (item.msku === msku) {
          let newQuantity;

          if (directValue !== null) {
            // Handle direct input value
            newQuantity = parseInt(directValue, 10);

            // Handle invalid inputs
            if (isNaN(newQuantity) || newQuantity < 1) {
              //   notify.warning("Please enter a valid positive number");
              return item;
            }
          } else {
            // Handle increment/decrement
            newQuantity =
              operation === "increment"
                ? item.quantity + 1
                : item.quantity > 1
                  ? item.quantity - 1
                  : 1;
          }

          // Check against availableQuantity
          if (newQuantity > item.availableQuantity) {
            notify.warning("Quantity cannot exceed available quantity");
            return item;
          }

          return {
            ...item,
            quantity: newQuantity,
          };
        }
        return item;
      }),
    );
  };

  const handleCheckboxChange = (msku) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.msku === msku ? { ...item, selected: !item.selected } : item,
      ),
    );
  };

  // Add filtered data computation
  const filteredData = data.filter((item) =>
    item.msku.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Add search handler
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const columns = [
    {
      key: "checkbox",
      title: (
        <CheckBox
          checked={
            filteredData.length > 0 &&
            filteredData.every((item) => item.selected)
          }
          onChange={() => {
            // If all are selected, unselect all. Otherwise, select all
            const allSelected = filteredData.every((item) => item.selected);
            setData((prevData) =>
              prevData.map((item) => ({
                ...item,
                selected: !allSelected,
              })),
            );
          }}
        />
      ),
      render: (_, row) => (
        <CheckBox
          checked={row.selected}
          onChange={() => handleCheckboxChange(row.msku)}
        />
      ),
    },
    {
      key: "productInfo",
      title: "Product Name",
      render: (_, row) => (
        <div className="flex items-center">
          <img
            src={row.image || FALLBACK_IMAGE_URL}
            alt={row.msku}
            className="mr-4 h-16 w-16 rounded-md object-cover"
          />
          <div>
            <Tooltip title={row.productName}>
              <div className="w-56 truncate text-base font-medium text-gray-800">
                {row.productName}
              </div>
            </Tooltip>
            <div className="text-sm text-gray-500">
              FNSKU: {row.fnSku || row.fnsku}
            </div>

            <div className="text-sm text-gray-500">
              SKU: {row.msku || row.sku}
            </div>
            <div className="text-sm text-gray-500">ASIN: {row.asin}</div>
          </div>
        </div>
      ),
    },
    {
      key: "availableQuantity",
      title: "Available Quantity",
      render: (_, row) => <span>{row.availableQuantity}</span>,
    },
    {
      key: "quantity",
      title: "Quantity",
      render: (_, row) => (
        <div className="flex items-center gap-2">
          <button
            onClick={() => handleQuantityChange(row.msku, "decrement")}
            className="rounded border border-gray-300 p-3"
            disabled={row.quantity <= 1}>
            <MinusIcon
              className={`h-4 w-4 ${
                row.quantity <= 1 ? "text-gray-300" : "text-primaryAccent"
              }`}
            />
          </button>

          <input
            type="number"
            value={row.quantity}
            min="1"
            max={row.availableQuantity}
            className="w-20 rounded-md border border-gray-300 px-2 py-1 text-center"
            onChange={(e) => {
              // Remove any negative signs from the input
              const value = e.target.value.replace(/-/g, "");
              handleQuantityChange(row.msku, null, value);
            }}
            onBlur={(e) => {
              // If empty or invalid on blur, reset to 1
              if (!e.target.value || parseInt(e.target.value) < 1) {
                handleQuantityChange(row.msku, null, "1");
              }
            }}
          />

          <button
            onClick={() => handleQuantityChange(row.msku, "increment")}
            className="rounded border border-gray-300 p-3"
            disabled={row.quantity >= row.availableQuantity}>
            <PlusIcon
              className={`h-4 w-4 ${
                row.quantity >= row.availableQuantity
                  ? "text-gray-300"
                  : "text-primaryAccent"
              }`}
            />
          </button>
        </div>
      ),
    },
  ];

  const handleConfirm = () => {
    const selectedItems = data.filter(
      (item) => item.selected && item.quantity > 0,
    );

    // if (selectedItems.length > 0) {
    //   const totalQuantity = selectedItems.reduce(
    //     (sum, item) => sum + item.quantity,
    //     0,
    //   );
    //   // notify.success(`Successfully added ${totalQuantity} items to box`);
    // }

    onConfirm(selectedItems);
  };

  return (
    <div className="p-6 font-inter">
      <div className="mb-4 flex items-center gap-5">
        <span className="text-sm text-gray-400">
          Total No. of Products: {filteredData.length}
        </span>
        <span className="text-sm text-gray-400">
          Total Quantity:{" "}
          {filteredData.reduce((total, item) => total + item.quantity, 0)}
        </span>
      </div>

      <div className="flex-grow" style={{ height: "calc(100vh - 18rem)" }}>
        <CustomTable
          columns={columns}
          data={filteredData}
          isSearchable
          searchTerm={searchTerm}
          searchPlaceholder="Search with SKU..."
          onChangeSearchTerm={handleSearch}
        />
      </div>

      <div className="sticky bottom-0 z-30 flex w-full items-center justify-between bg-white p-4">
        <div className="flex w-full justify-end gap-6">
          <PrimaryButton
            height="3rem"
            width="7rem"
            className="text-base font-medium"
            onClick={onCancel}>
            Cancel
          </PrimaryButton>
          <PrimaryButton
            height="3rem"
            width="7rem"
            variant="primary"
            className="text-base font-medium"
            disabled={!data?.some((item) => item.selected && item.quantity > 0)}
            onClick={handleConfirm}>
            Confirm
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

AddItemsInBox.propTypes = {
  itemsData: PropTypes.arrayOf(
    PropTypes.shape({
      asin: PropTypes.string.isRequired,
      fnsku: PropTypes.string.isRequired,
      labelOwner: PropTypes.string,
      msku: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      image: PropTypes.string,
      prepInstructions: PropTypes.arrayOf(
        PropTypes.shape({
          prepType: PropTypes.string.isRequired,
          prepOwner: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AddItemsInBox;
