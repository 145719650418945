import withOrderPickerLogic from "#components/HOC/withOrderPickerLogic";
import PickerNewBatchRequest from "#components/picker/common/PickerNewBatchRequest";
import SingleLineItemBatch from "#components/picker/order/SingleLineItemBatch";
import SelectCustomerCode from "#components/picker/common/SelectCustomerCode";
import PageHeader from "#components/picker/common/PageHeader";
import BatchListView from "#components/picker/order/BatchListView";

import _ from "lodash";
import Select from "react-select";

const OrderPicker = ({
  getNewBatch,
  currentBatch,
  scanBarcode,
  confirmPickItem,
  confirmDropoff,
  loading,
  simulateTote,
  currentItem,
  workflow,
  customer,
  customers,
  onSubmitCustomer,
  onSkipCustomer,
  confirmDropoffWithConfirmation,
  subdomain,
  skipItem,
  onChangeScannedQuantity,
  setDisplayScan,
  displayScan,
  warehouses,
  batchfilters,
  onChangeDropdown,
  currentProduct,
  pickingScanningDisabled,
  confirmAllItems,
  onChangePickedQuantityOfItem,
  onClickDownloadPickList,
  searchBatches,
  setBatchesDataFilters,
  batchesData,
  usersMap,
  checkPagination,
  workOnBatch,
}) => {
  if (!loading && !currentBatch) {
    if (workflow) {
      if (
        workflow.entryPoints &&
        workflow.entryPoints.findIndex(
          (item) => item.name === "ENTER_CUSTOMER_CODE",
        ) !== -1
      ) {
        if (customer === undefined) {
          return (
            <SelectCustomerCode
              customers={customers}
              onSubmit={onSubmitCustomer}
              onSkip={onSkipCustomer}
            />
          );
        }
      }
    }

    if (!pickingScanningDisabled) {
      return (
        <>
          <PageHeader customer={customer} customers={customers} />
          <PickerNewBatchRequest
            customers={customers}
            warehouses={warehouses}
            batchfilters={batchfilters}
            onChangeDropdown={onChangeDropdown}
            getNewBatch={getNewBatch}
          />
        </>
      );
    } else {
      // For Picking Scanning Disabled and Picking List View

      // If there are no warehouses, show a message to select a warehouse
      // Else, show the BatchListView
      if (!batchesData.filters?.warehouse) {
        return (
          <div className="flex h-screen items-center justify-center">
            <div className="items-center justify-center space-x-4">
              <div className="mb-10 flex w-1/2 min-w-1/2 items-center gap-20 p-5">
                {warehouses && warehouses.length > 1 && (
                  <div className="w-50">
                    <Select
                      options={
                        warehouses &&
                        warehouses
                          .map((item) => ({
                            value: item.id,
                            label: `${item.name} - ${item.code}`,
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))
                      }
                      value={batchesData.filters?.warehouse}
                      className="w-96"
                      placeholder="Select Warehouse"
                      isClearable={true}
                      onChange={(selectedOption) => {
                        const updatedFilters = {
                          ...batchesData.filters,
                          warehouse: selectedOption.value,
                        };
                        setBatchesDataFilters(updatedFilters);
                        searchBatches(updatedFilters);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center self-center py-10">
                <div className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white">
                  Select Warehouse
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <>
            <BatchListView
              setBatchesDataFilters={setBatchesDataFilters}
              searchBatches={searchBatches}
              batchesData={batchesData}
              checkPagination={checkPagination}
              usersMap={usersMap}
              workOnBatch={workOnBatch}
            />
          </>
        );
      }
    }
  }

  if (currentBatch) {
    return (
      <>
        <PageHeader customer={customer} customers={customers} />
        <SingleLineItemBatch
          currentBatch={currentBatch}
          scanBarcode={scanBarcode}
          confirmPickItem={confirmPickItem}
          confirmDropoff={confirmDropoff}
          confirmDropoffWithConfirmation={confirmDropoffWithConfirmation}
          simulateTote={simulateTote}
          currentItem={currentItem}
          workflow={workflow}
          subdomain={subdomain}
          skipItem={skipItem}
          onChangeScannedQuantity={onChangeScannedQuantity}
          displayScan={displayScan}
          setDisplayScan={setDisplayScan}
          currentProduct={currentProduct}
          pickingScanningDisabled={pickingScanningDisabled}
          confirmAllItems={confirmAllItems}
          onChangePickedQuantityOfItem={onChangePickedQuantityOfItem}
          onClickDownloadPickList={onClickDownloadPickList}
        />
      </>
    );
  }

  return null;
};

export default withOrderPickerLogic(OrderPicker);
