// TwoDBarcodeLabel.js
import React from "react";
import bwipjs from "bwip-js";

export const TwoDBarcodeLabel = ({
  barcodeString,
  barcodeStringSplitByLine,
  order,
  group,
  box,
  isProvisional,
}) => {
  let src = "";
  if (!isProvisional) {
    let canvas = document.createElement("canvas");
    bwipjs.toCanvas(canvas, {
      bcid: "pdf417",
      text: barcodeString,
      scale: 2,
      includetext: true,
      textxalign: "center",
    });
    src = canvas.toDataURL("image/png");
  }

  return (
    <div>
      <div
        id={`label-container-${box.boxName}`}
        style={{
          width: "384px",
          height: "576px",
          fontFamily: "Arial",
          margin: "0",
          padding: "20px",
          border: "2px solid black",
          fontSize: "14px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}>
        {isProvisional && (
          <div
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "20px",
              borderBottom: "2px solid black",
              paddingBottom: "10px",
            }}>
            Provisional Box Details
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            borderBottom: "2px solid #000",
            paddingBottom: "15px",
          }}>
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>
            {order?.inboundPlanId} Box{" "}
            {group.boxes.findIndex((i) => i.boxName === box.boxName) + 1} of{" "}
            {group.groupName}
          </div>
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>
            {`${box?.dimensions?.length} x ${box?.dimensions?.width} x ${box?.dimensions?.height} in - ${box.weight} lbs`}
          </div>
        </div>

        {!isProvisional && (
          <div
            style={{
              textAlign: "center",
              margin: "20px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={src} alt="barcode" style={{ maxWidth: "100%" }} />
          </div>
        )}

        <div
          style={{
            textAlign: "left",
            wordWrap: "break-word",
            marginTop: "20px",
            fontSize: "16px",
            fontFamily: "monospace",
            whiteSpace: "pre-line",
            lineHeight: "1.5",
          }}>
          {barcodeStringSplitByLine}
        </div>
      </div>
    </div>
  );
};

export default TwoDBarcodeLabel;
