import { useState } from "react";
import Accordian from "#components/utils/Accordian";
import { InformationCircleIcon } from "@heroicons/react/outline";
import OrderDetailsTable from "./OrderDetailsTable";
import Modal from "#components/utils/Modal";

const UnfulfillableOrders = ({ orders, setOrdersFulfillmentDetails }) => {
  if (!orders || orders.length === 0) {
    return <p>No Fulfillable Orders</p>;
  }

  const [activeIdx, setActiveIdx] = useState(0);
  const [expandedBackOrder, setExpandedBackOrder] = useState(null);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="border-gray mb-4 flex h-48 w-max items-center rounded border bg-gray-100 p-3">
        <InformationCircleIcon className="text-gray h-6 w-6" />
        <ul className="ml-2 text-sm font-normal text-primaryAccent">
          <li>
            These orders will not be batched and remains in unprocessed state.
          </li>
          <li>Click on the individual order to check for reasons.</li>
        </ul>
      </div>
      {orders.map(
        (
          {
            order,
            reasons,
            originalOrder,
            originalOrderFulfillmentDetails,
            availableLineItemsQuantity,
            childOrders,
          },
          idx,
        ) => {
          if (originalOrder && !originalOrder.splitOrders) {
            // this is a back order that can't be fulfilled.
            const originalOrderTitleComponent = (
              <div className="flex gap-x-2">
                <div className="flex flex-col">
                  <p className="text-lg font-semibold">
                    {originalOrder.orderId}
                  </p>
                  {activeIdx === idx && (
                    <p
                      className="font-base text-sm text-primaryAccent underline"
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpandedBackOrder({
                          originalOrder,
                          originalOrderFulfillmentDetails,
                        });
                      }}>
                      View Original Order Details
                    </p>
                  )}
                </div>
              </div>
            );
            return (
              <div key={order.id}>
                <Accordian
                  key={activeIdx}
                  titleComponent={originalOrderTitleComponent}
                  isActive={activeIdx === idx}
                  toggleCallback={(index) => {
                    if (index === activeIdx) setActiveIdx(-1);
                    else setActiveIdx(index);
                  }}
                  index={idx}>
                  <Reasons reasons={reasons} />
                  <OrderDetailsTable
                    orderDetails={order.orderDetails}
                    availableLineItemsQuantity={availableLineItemsQuantity}
                    setOrdersFulfillmentDetails={setOrdersFulfillmentDetails}
                    type="backOrders"
                  />
                </Accordian>
              </div>
            );
          }

          if (childOrders && order.splitOrders) {
            // const originalOrderTitleComponent = (
            //   <div>
            //     <p className="text-lg font-semibold">{`Suborder ${idx + 1}`}</p>
            //     <div className="my-2 w-max">
            //       <div className="grid grid-cols-3 pl-6">
            //         <span className="font-semibold">Status: </span>
            //         <span className="col-span-2 ml-4 font-medium">
            //           {order.orderStatus}
            //         </span>
            //       </div>
            //       <div className="grid grid-cols-3 pl-6">
            //         <span className="font-semibold">SubStatus: </span>
            //         <span className="col-span-2 ml-4 font-medium">
            //           {order.subStatus}
            //         </span>
            //       </div>
            //       {order.tote && (
            //         <div className="grid grid-cols-3 pl-6">
            //           <span className="font-semibold">Tote: </span>
            //           <span className="col-span-2 ml-4 font-medium">
            //             {order.tote}
            //           </span>
            //         </div>
            //       )}
            //     </div>
            //   </div>
            // );
            const originalOrderTitleComponent = (
              <div className="flex gap-x-2">
                <div className="flex flex-col">
                  <p className="text-lg font-semibold">{order.orderId}</p>
                </div>
              </div>
            );
            return (
              <div key={order.id} className="flex gap-x-2">
                <Accordian
                  key={activeIdx}
                  titleComponent={originalOrderTitleComponent}
                  isActive={activeIdx === idx}
                  toggleCallback={(index) => {
                    if (index === activeIdx) setActiveIdx(-1);
                    else setActiveIdx(index);
                  }}
                  index={idx}>
                  {childOrders.map((childOrder, id) => (
                    <div>
                      <p className="text-lg font-semibold">{`Suborder ${id + 1}`}</p>
                      <div className="my-2 w-max">
                        <div className="grid grid-cols-3 pl-6">
                          <span className="font-semibold">OrderId: </span>
                          <span className="col-span-2 ml-4 font-medium">
                            {childOrder.orderId}
                          </span>
                        </div>
                        <div className="grid grid-cols-3 pl-6">
                          <span className="font-semibold">Status: </span>
                          <span className="col-span-2 ml-4 font-medium">
                            {childOrder.orderStatus}
                          </span>
                        </div>
                        <div className="grid grid-cols-3 pl-6">
                          <span className="font-semibold">SubStatus: </span>
                          <span className="col-span-2 ml-4 font-medium">
                            {childOrder.subStatus}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Accordian>
              </div>
            );
          }
          return (
            <div key={order.id}>
              <Accordian
                key={activeIdx}
                title={order.orderId}
                isActive={activeIdx === idx}
                toggleCallback={(index) => {
                  if (index === activeIdx) setActiveIdx(-1);
                  else setActiveIdx(index);
                }}
                index={idx}>
                <Reasons reasons={reasons} />
                <OrderDetailsTable
                  orderDetails={order.orderDetails}
                  availableLineItemsQuantity={availableLineItemsQuantity}
                  setOrdersFulfillmentDetails={setOrdersFulfillmentDetails}
                  type="unfulfillableOrders"
                />
              </Accordian>
            </div>
          );
        },
      )}

      {expandedBackOrder && (
        <Modal
          negativeAction={() => setExpandedBackOrder(null)}
          positiveAction={() => setExpandedBackOrder(null)}
          title={`Order Details (${expandedBackOrder.originalOrder.orderId})`}
          noPadding={true}
          positiveText="OK">
          <div className="space-y-4 p-4">
            <OrderDetailsTable
              orderDetails={expandedBackOrder.originalOrder.orderDetails}
              fulfillmentDetails={
                expandedBackOrder.originalOrderFulfillmentDetails
              }
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

const Reasons = ({ reasons }) => {
  return (
    <ul
      className={`${
        reasons.length > 1 ? "list-disc" : ""
      } mb-4 mt-2 flex h-fit w-max flex-col items-start rounded border border-yellow-400 bg-lightYellow p-3`}>
      {reasons.map((reason) => (
        <li className="ml-2 break-all text-sm font-normal text-lightGray">
          {reason}
        </li>
      ))}
    </ul>
  );
};

export default UnfulfillableOrders;
