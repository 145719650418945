import HollowButton from "../../utils/hollowButton";
import { ChevronDownIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import * as SlateTable from "../common/SlateTable";
import STORAGE_SECTION_STATUSES from "../../constants/storage-section-statuses";
import { Menu } from "@headlessui/react";
import FilledButton from "../../utils/filledButton";
import {
  PencilIcon,
  RefreshIcon,
  DuplicateIcon,
  SwitchHorizontalIcon,
  TrashIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { LocationTree } from "../../utils/storage-management/location-tree";
import { Modal } from "../common/Modal";
import DropdownInput from "../common/DropdownInput";
import MessageDisplay from "../common/MessageDisplay";
import PrintSettingsModal from "#components/common/PrintSettingsModal";
import ReactTooltip from "react-tooltip";
import { PrinterIcon } from "@heroicons/react/solid";

const WarehouseStorageSections = ({
  storageSections,
  setStorageSections,
  warehouses,
  selectedStorageSection,
  setSelectedStorageSection,
  INITIAL_STORAGE_SECTION_STATE,
  setLocationTree,
  locationTypes,
  deleteStorageSection,
  storageSectionStatusUpdate,
  setStorageSectionStatusUpdate,
  submitStorageSectionStatusUpdate,
  setLoading,
  buildLocationTreeFromStorageSection,
  showAlert,
  storageSectionToDelete,
  setStorageSectionToDelete,
  getCurrentHierarchyArray,
  printBarcodesForStorageSection,
  printSelectedBarcodes,
  selectedPrints,
  setSelectedPrints,
}) => {
  const storageSectionMenu = [
    {
      icon: PencilIcon,
      text: "Edit Storage Section",
      action: (storageSection) => {
        setLoading(true);
        buildLocationTreeFromStorageSection(storageSection, true)
          .then(() => {
            setSelectedStorageSection({
              ...storageSection,
              type: storageSection.storageType,
              edit: true,
            });
            setLoading(false);
          })
          .catch((e) => {
            showAlert(e.message, "error", 5000);
            setLoading(false);
          });
      },
    },
    {
      icon: PrinterIcon,
      text: "Print All Barcodes",
      action: async (storageSection) => {
        await printBarcodesForStorageSection(storageSection);
      },
    },
    {
      icon: RefreshIcon,
      text: "Update Status",
      action: (storageSection) => {
        setStorageSectionStatusUpdate({
          ...JSON.parse(JSON.stringify(storageSection)),
          currentStatus: storageSection.status,
        });
      },
    },
    {
      icon: DuplicateIcon,
      text: "Duplicate Storage Type",
      action: (storageSection) => {
        setLoading(true);
        buildLocationTreeFromStorageSection(storageSection, false)
          .then(() => {
            setSelectedStorageSection({
              ...storageSection,
              id: null,
              status: STORAGE_SECTION_STATUSES.ACTIVE,
              type: storageSection.storageType,
              edit: false,
            });
            setLoading(false);
          })
          .catch((e) => {
            showAlert(e.message, "error", 5000);
            setLoading(false);
          });
      },
    },
    // {
    //   icon: SwitchHorizontalIcon,
    //   text: "Audit Trails",
    //   action: (storageSection) => {},
    // },
    {
      icon: TrashIcon,
      text: "Delete Storage Type",
      action: (storageSection) => {
        setStorageSectionToDelete(storageSection);
      },
    },
  ];
  return (
    <>
      {Object.keys(storageSections).map((warehouseId) => (
        <div className="mt-2">
          <div className="flex">
            <div className="flex">
              <div className="text-base font-semibold">
                {`${
                  warehouses.find((w) => w.id === warehouseId)?.name ||
                  warehouseId
                } (${storageSections[warehouseId].storageSections.length})`}
              </div>
              <button
                className="my-0 ml-2 h-6 p-0"
                onClick={() => {
                  setStorageSections({
                    ...storageSections,
                    [warehouseId]: {
                      ...storageSections[warehouseId],
                      collapsed: !storageSections[warehouseId].collapsed,
                    },
                  });
                }}>
                <ChevronDownIcon
                  className={`transform transition-transform ${
                    !storageSections[warehouseId].collapsed ? "rotate-180" : ""
                  } h-5 w-5`}
                />
              </button>
            </div>
            <div className="ml-auto">
              <HollowButton
                text="Create Storage Section"
                colour={"primaryAccent"}
                onClick={() => {
                  setSelectedStorageSection({
                    ...INITIAL_STORAGE_SECTION_STATE,
                    warehouse: warehouseId,
                  });
                  setLocationTree(new LocationTree());
                }}
              />
            </div>
          </div>
          <div
            className={`mt-2 w-full overflow-hidden transition-all duration-500 ${
              storageSections[warehouseId].collapsed ? "max-h-0" : ""
            }`}>
            <SlateTable.SlateTable>
              <SlateTable.SlateTableHeader>
                <SlateTable.SlateTableHeaderCell first={true}>
                  Storage Section
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Location Hierarchy
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Status
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Action
                </SlateTable.SlateTableHeaderCell>
              </SlateTable.SlateTableHeader>
              <SlateTable.SlateTableBody>
                {storageSections[warehouseId].storageSections.map(
                  (storageSection) => (
                    <SlateTable.SlateTableBodyRow>
                      <SlateTable.SlateTableBodyRowCell first={true}>
                        {storageSection.name}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        {storageSection.code +
                          (storageSection.hierarchy.length > 0 ? " > " : "") +
                          storageSection.hierarchy
                            .map((h) => {
                              const locationType = locationTypes.find(
                                (lt) => lt.id === h,
                              );
                              return locationType ? locationType.name : "";
                            })
                            .join(" > ")}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        <StorageSectionColorBadge
                          status={storageSection.status}
                          reason={storageSection.sectionInactiveReason}
                        />
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        <Menu as="div" className="">
                          <Menu.Button>
                            <DotsVerticalIcon className="h-4 w-4 cursor-pointer" />
                          </Menu.Button>
                          <>
                            <Menu.Items
                              as="div"
                              className="absolute right-8 z-10 w-max origin-top-right rounded border border-solid border-grayBorder bg-white p-2">
                              {storageSectionMenu.map((menu) => (
                                <Menu.Item
                                  as="div"
                                  className={`cursor-pointer`}
                                  onClick={() => {
                                    menu.action(storageSection);
                                  }}>
                                  {({ active }) => (
                                    <div
                                      className={`flex rounded transition ${
                                        active ? "bg-lightBlueMenu" : ""
                                      } h-9`}>
                                      <menu.icon
                                        className="mx-2 my-1.5 h-6 w-6 cursor-pointer"
                                        color={active ? "#224E73" : "#737579"}
                                      />
                                      <span
                                        className={`my-2 mr-2 text-sm ${
                                          active
                                            ? "text-primaryAccent"
                                            : "text-grayText"
                                        }`}>
                                        {menu.text}
                                      </span>
                                    </div>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </>
                        </Menu>
                      </SlateTable.SlateTableBodyRowCell>
                    </SlateTable.SlateTableBodyRow>
                  ),
                )}
              </SlateTable.SlateTableBody>
            </SlateTable.SlateTable>
          </div>
        </div>
      ))}

      {storageSectionToDelete && (
        <Modal
          title="Confirm Delete Storage Section"
          onClose={() => {
            setStorageSectionToDelete(null);
          }}
          minWidth={"945px"}
          maxWidth={"945px"}>
          <div className="text-left text-base font-normal leading-6 tracking-normal">
            Are you sure you want to delete storage section “
            <span className="font-semibold">{storageSectionToDelete.name}</span>
            ” ?
          </div>
          <div className="mt-2 flex">
            <MessageDisplay customStyles={"min-w-64 w-max h-max pb-4"}>
              <div className="leading-17 ml-4 mr-4 mt-4 text-left text-base font-medium tracking-normal text-gray-500">
                Location Hierarchy
              </div>
              <div className="leading-19 ml-4 mr-4 mt-1 text-left text-lg font-semibold tracking-normal">
                {getCurrentHierarchyArray(storageSectionToDelete.hierarchy)
                  .length
                  ? getCurrentHierarchyArray(
                      storageSectionToDelete.hierarchy,
                    ).join(" > ")
                  : "-"}
              </div>
              <div className="ml-4 mr-4 mt-1 text-left text-base font-normal leading-5 tracking-normal">
                Ex:{" "}
                {`${storageSectionToDelete.code} > ${getCurrentHierarchyArray(
                  storageSectionToDelete.hierarchy,
                )
                  .map((locationType) => {
                    const type = locationTypes.find(
                      (type) => type.name === locationType,
                    );
                    let code = type.codeType === "NUMERIC" ? "1" : "A";
                    if (type.threeDimRange) {
                      code = "1.1.1";
                    }
                    return `${locationType}-${code}`;
                  })
                  .join(" > ")}`}
              </div>
            </MessageDisplay>
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setStorageSectionToDelete(null);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Submit"}
              colour={"primaryAccent"}
              onClick={() => {
                deleteStorageSection(storageSectionToDelete);
                storageSectionToDelete(null);
              }}
              className="ml-4"
            />
          </div>
        </Modal>
      )}

      {storageSectionStatusUpdate && (
        <Modal
          title={`Update Status`}
          onClose={() => {
            setStorageSectionStatusUpdate(null);
          }}
          minWidth={"845px"}
          maxWidth={"845px"}>
          <div className="flex flex-col">
            <div className="mb-4">
              <div className="leading-22 text-left text-base font-medium tracking-normal text-[#717679]">
                If a location is marked as available, it will be used for
                workflows; if marked as unavailable, it will be excluded, and
                you will be able to add reason for unavailability
              </div>
            </div>
            <div className="mb-4 flex items-center">
              <input
                type="radio"
                id="available"
                name="status"
                value="available"
                checked={storageSectionStatusUpdate.status === "available"}
                onChange={(e) => {
                  setStorageSectionStatusUpdate({
                    ...storageSectionStatusUpdate,
                    status: e.target.value,
                  });
                }}
              />
              <label htmlFor="available" className="ml-2 mr-4">
                <span className="inline-block rounded-2xl bg-green-100 p-2 text-green-800">
                  Available
                </span>
              </label>
              <input
                type="radio"
                id="unavailable"
                name="status"
                value="unavailable"
                checked={storageSectionStatusUpdate.status === "unavailable"}
                onChange={(e) => {
                  setStorageSectionStatusUpdate({
                    ...storageSectionStatusUpdate,
                    status: e.target.value,
                  });
                }}
              />
              <label htmlFor="unavailable" className="ml-2">
                <span className="inline-block rounded-2xl bg-red-100 p-2 text-red-800">
                  Unavailable
                </span>
              </label>
            </div>
            {storageSectionStatusUpdate.status === "available" && (
              <div
                className="mb-4 rounded border p-4"
                style={{ borderColor: "#E3A400", backgroundColor: "#FFF9E9" }}>
                <div className="mb-2 flex items-center font-medium">
                  <i className="fas fa-info-circle mr-2"></i> Points to note
                </div>
                <ul className="ml-4 list-disc">
                  <li>
                    All the sub hierarchies under this location will be made
                    available
                  </li>
                </ul>
              </div>
            )}
            {storageSectionStatusUpdate.status === "unavailable" && (
              <div>
                <div
                  className="mb-4 rounded border p-4"
                  style={{
                    borderColor: "#E3A400",
                    backgroundColor: "#FFF9E9",
                  }}>
                  <div className="mb-2 flex items-center font-medium">
                    <i className="fas fa-info-circle mr-2"></i> Points to note
                  </div>
                  <ul className="ml-4 list-disc">
                    <li>
                      All the sub hierarchies coming under this location will be
                      made unavailable.
                    </li>
                    <li>
                      Changing any sub hierarchies location will have an impact
                      on the main hierarchy if switched from unavailable to
                      available.
                    </li>
                  </ul>
                </div>
                <div className="mb-4">
                  <div className="leading-22 mb-2 text-left text-base font-medium tracking-normal">
                    Reason
                  </div>
                  <textarea
                    className="w-full rounded border p-2"
                    placeholder="Enter reason for making this section unavailable..."
                    onChange={(e) => {
                      setStorageSectionStatusUpdate({
                        ...storageSectionStatusUpdate,
                        sectionInactiveReason: e.target.value,
                      });
                    }}
                    value={
                      storageSectionStatusUpdate.sectionInactiveReason || ""
                    }
                  />
                </div>
              </div>
            )}
            <div className="flex w-full">
              <HollowButton
                text={"Cancel"}
                colour={"primaryAccent"}
                onClick={() => {
                  setStorageSectionStatusUpdate(null);
                }}
                styles="ml-auto"
              />
              <FilledButton
                text={"Save"}
                colour={"primaryAccent"}
                onClick={submitStorageSectionStatusUpdate}
                className="ml-4"
              />
            </div>
          </div>
        </Modal>
      )}

      {selectedPrints && (
        <PrintSettingsModal
          setSelectedPrints={setSelectedPrints}
          selectedPrints={selectedPrints}
          printSelectedBarcodes={printSelectedBarcodes}
          disablePreview={selectedPrints.barcodes.length > 5}
        />
      )}
    </>
  );
};

const StorageSectionColorBadge = ({ status, reason }) => {
  let modifiedStatusToDisplay = status.replace(/_/g, " ");
  // capitalize every word
  modifiedStatusToDisplay = modifiedStatusToDisplay
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  switch (status) {
    case STORAGE_SECTION_STATUSES.ACTIVE:
      return <GreenBadge text={modifiedStatusToDisplay} />;
    case STORAGE_SECTION_STATUSES.UNDER_REPAIR:
      return <YellowBadge text={modifiedStatusToDisplay} />;
    case STORAGE_SECTION_STATUSES.AVAILABLE:
      return <GreenBadge text={modifiedStatusToDisplay} />;
    case STORAGE_SECTION_STATUSES.UNAVAILABLE:
      return (
        <div className="flex items-center">
          <RedBadge text={modifiedStatusToDisplay} />
          <div data-tip={reason}>
            <InformationCircleIcon className="ml-2 h-5 w-5 text-red-800" />
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
      );
    case STORAGE_SECTION_STATUSES.DISABLED:
      return <RedBadge text={modifiedStatusToDisplay} />;
  }
};

const GreenBadge = ({ text }) => {
  return (
    <div className="me-2 h-8 w-max rounded-full bg-green-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-green-800">
      {text}
    </div>
  );
};

const YellowBadge = ({ text }) => {
  return (
    <div className="me-2 h-8 w-max rounded-full bg-yellow-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-yellow-800">
      {text}
    </div>
  );
};

const RedBadge = ({ text }) => {
  return (
    <div className="me-2 h-8 w-max rounded-full bg-red-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-red-800">
      {text}
    </div>
  );
};

export default WarehouseStorageSections;
