import { useState, useEffect, useContext } from "react";

import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import _, { isBoolean } from "lodash";
import Toggle from "#components/utils/Toggle";
import { CheckIcon } from "@heroicons/react/outline";
import AddAttributes from "./AddAttributes";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import { AppStateContext } from "#contexts/appState";
import ProductImages from "./ProductImages";
import UOMConfiguration from "./UOMConfiguration";
import ReactTooltip from "react-tooltip";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import Autocomplete from "#components/utils/Autocomplete";
import CategoryModalForm from "#components/catalogs/CategoryModalForm";
import { GET_PARENT_PRODUCTS, GET_ENTITY_TYPES } from "#queries";
import { useQuery } from "#hooks/useQuery";
import { useFeatureFlags } from "#contexts/featureFlags";

const ProductForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedProduct,
  setSelectedProduct,
  onSubmit,
  customers,
  productSizes,
  productShapes,
  productColors,
  productCategories,
  productsTypes,
  deleteImage,
  customAttributes,
  setCustomAttributes,
  multiAccountSupportEnabled,
  getMarketplaces,
  sellerIds,
  productHasInventory,
  addNewCategory,
  setAddNewCategory,
  submitCategoryForm,
  addCustomEntity,
  setAddCustomEntity,
}) => {
  const appState = useContext(AppStateContext);
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [componentAlert, setComponentAlert] = useState(null);

  const { cogEnabled } = useFeatureFlags();

  const [parentProducts, setParentProducts] = useState([]);
  const [parentProduct, setParentProduct] = useState(null);
  const parentProductsQuery = useQuery(GET_PARENT_PRODUCTS);
  const entityTypesQuery = useQuery(GET_ENTITY_TYPES);
  const [variantAttributes, setVariantAttributes] = useState([]);
  const [entityAttributes, setEntityAttributes] = useState({});

  useEffect(() => {
    const attributes = variantAttributes.reduce((result, obj) => {
      const entityParent = obj["entityParent"];
      if (!result[entityParent]) {
        result[entityParent] = [];
      }
      result[entityParent].push(obj);
      return result;
    }, {});

    setEntityAttributes(attributes);
  }, [variantAttributes]);

  const handleVariantAttributeChange = (entityParent, name) => {
    if (name === "Add Custom") {
      return setAddCustomEntity({
        entityParent: `${entityParent}_ATTRIBUTE_VALUES`,
        name: "",
      });
    }

    setSelectedProduct({
      ...selectedProduct,
      attributes: selectedProduct.attributes
        ? {
            ...selectedProduct.attributes,
            [entityParent.toLowerCase()]: name,
          }
        : {
            [entityParent.toLowerCase()]: name,
          },
    });
  };

  useEffect(() => {
    if (!addCustomEntity) {
      fetchVariantAttributes(
        parentProduct,
        entityTypesQuery,
        setVariantAttributes,
      ).catch((error) => {
        console.error("Error fetching variant attributes:", error);
        // Optionally, you can set an error state or show an alert here
      });
    }
  }, [addCustomEntity]);

  useEffect(() => {
    if (selectedProduct?.parentProduct) {
      findParentProduct(selectedProduct?.parentProduct);
    }
  }, []);

  const findParentProduct = async (parentProduct) => {
    const response = await parentProductsQuery.fetchData({
      perPage: 1,
      pageNumber: 1,
      filters: {
        ids: [parentProduct],
      },
    });
    if (response.data) {
      const fetchedParentProduct = response.data.parentProducts.entities[0];
      setParentProduct(fetchedParentProduct);
      setParentProducts([fetchedParentProduct]);
      await fetchVariantAttributes(
        fetchedParentProduct,
        entityTypesQuery,
        setVariantAttributes,
      );
    }

    if (response.error) {
      setParentProduct(null);
      setParentProducts([]);
    }
  };

  const onChangeProductSearch = async (keyword) => {
    const response = await parentProductsQuery.fetchData({
      perPage: 10,
      pageNumber: 1,
      filters: {
        keyword,
      },
      paginated: false,
    });
    if (response.data) {
      const newProducts = response.data.parentProducts.entities;
      const uniqueProducts = newProducts.filter(
        (product) => !parentProducts.some((p) => p.id === product.id),
      );
      setParentProducts((prevProducts) => [
        ...(parentProduct &&
        !prevProducts.some((p) => p.id === parentProduct.id)
          ? [parentProduct]
          : []),
        ...prevProducts,
        ...uniqueProducts,
      ]);
    }

    if (response.error) {
      setParentProducts(parentProduct ? [parentProduct] : []);
    }
  };
  const debouncedProductSearch = _.debounce(onChangeProductSearch, 1000);

  useEffect(() => {
    let formedSteps = [];
    if (selectedProduct?.customerToBeSelected) {
      formedSteps.push({
        id: "01",
        name: "Client Info",
        href: "#",
        status: "current",
      });
    }

    formedSteps = [
      ...formedSteps,
      { id: "02", name: "Basic Details", href: "#", status: "upcoming" },
      { id: "03", name: "Product Details", href: "#", status: "upcoming" },
      { id: "04", name: "Codes", href: "#", status: "upcoming" },
      { id: "05", name: "Attributes", href: "#", status: "upcoming" },
    ];

    formedSteps.push({
      id: "06",
      name: "Unit of Measurement",
      href: "#",
      status: "upcoming",
    });

    formedSteps.push({
      id: "07",
      name: "Images",
      href: "#",
      status: "upcoming",
    });

    formedSteps[0] = {
      ...formedSteps[0],
      status: "current",
    };
    setSteps(formedSteps);
    setSelectedStep(formedSteps[0]);
  }, []);

  const getFinalSelectedValues = (selectedValues, options) => {
    const selectedIds = selectedValues.map((value) => value.id);
    const result = [];

    for (const option of options) {
      if (selectedIds.includes(option.id)) {
        result.push({
          id: option.id,
          name: option.name,
          description: option.description,
        });
      }
    }

    return result;
  };

  const selectStep = (idx) => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    copiedSteps = copiedSteps.map((item) => ({
      ...item,
      status: "upcoming",
    }));

    copiedSteps[idx].status = "current";

    setSteps(copiedSteps);
    setSelectedStep(copiedSteps[idx]);
  };

  const setNextStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  if (addNewCategory) {
    return (
      <CategoryModalForm
        addNewCategory={addNewCategory}
        setAddNewCategory={setAddNewCategory}
        submitCategoryForm={submitCategoryForm}
        productCategories={productCategories}
        setComponentAlert={setComponentAlert}
        componentAlert={componentAlert}
      />
    );
  }

  return (
    <Modal
      title={title}
      negativeAction={onClose}
      minWidth="1280px"
      onClose={() => {}}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Product Details
          </span>
        </div>
        <nav aria-label="Progress">
          <ol
            role="list"
            className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex md:flex-1">
                {step.status === "complete" ? (
                  <div className="group flex w-full items-center">
                    <span className="text-md flex items-center px-6 py-4 font-medium">
                      <span className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                        <CheckIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      <span className="ml-4 text-lg font-medium text-gray-900">
                        {step.name}
                      </span>
                    </span>
                  </div>
                ) : step.status === "current" ? (
                  <div
                    className="text-md flex items-center px-4 py-4 font-medium"
                    aria-current="step">
                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                      <span className="text-indigo-600">
                        {(
                          steps.findIndex((i) => i.id === step.id) + 1
                        ).toString()}
                      </span>
                    </span>
                    <span className="text-md ml-4 font-medium text-indigo-600">
                      {step.name}
                    </span>
                  </div>
                ) : (
                  <div
                    className="group flex items-center"
                    onClick={() => selectStep(stepIdx)}>
                    <div className="text-md flex items-center px-4 py-4 font-medium">
                      <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">
                          {(
                            steps.findIndex((i) => i.id === step.id) + 1
                          ).toString()}
                        </span>
                      </span>
                      <span className="text-md ml-4 font-medium text-gray-500 group-hover:text-gray-900">
                        {step.name}
                      </span>
                    </div>
                  </div>
                )}

                {stepIdx !== steps.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div
                      className="absolute right-0 top-0 hidden h-full w-5 md:block"
                      aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none">
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
        {selectedStep && selectedStep.id === "01" && (
          <>
            {selectedProduct.customerToBeSelected && (
              <>
                <div>
                  <Autocomplete
                    options={getFilteredCustomerList(
                      selectedProduct.warehouse,
                      customers,
                    )}
                    labelKey="name"
                    valueKey="id"
                    onChange={(selectedOption) => {
                      onChangeDropdown("customer", selectedOption);
                    }}
                    value={selectedProduct.customer}
                    placeholder={"Select Client"}
                    id={`SELECT_CUSTOMER`}
                  />
                </div>
              </>
            )}
          </>
        )}
        {selectedStep && selectedStep.id === "02" && (
          <>
            <>
              <div className="flex items-center space-x-4 px-2">
                <div>
                  <Toggle
                    enabled={selectedProduct.active}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "active",
                          value: e,
                        },
                      })
                    }
                  />
                </div>
                <div className="text-lg">Active</div>
              </div>
            </>
            <>
              {multiAccountSupportEnabled &&
                !selectedProduct.id &&
                ["FBA", "FBM"].includes(selectedProduct.source) && (
                  <div>
                    <Autocomplete
                      options={_.uniq(sellerIds)
                        .filter((s) => s)
                        .map((s) => ({
                          name: s,
                          value: s,
                        }))}
                      labelKey="name"
                      valueKey="value"
                      onChange={(e) => {
                        onChangeDropdown("sellerId", e);
                      }}
                      value={selectedProduct.sellerId}
                      placeholder={"Seller"}
                      id={`seller`}
                    />
                  </div>
                )}
              {multiAccountSupportEnabled &&
                !selectedProduct.id &&
                ["FBA", "FBM"].includes(selectedProduct.source) &&
                selectedProduct.sellerId && (
                  <div>
                    <Autocomplete
                      options={getMarketplaces(selectedProduct.sellerId).map(
                        (m) => ({
                          name: m,
                          value: m,
                        }),
                      )}
                      labelKey="name"
                      valueKey="value"
                      onChange={(selectedOption) => {
                        onChangeDropdown("marketplace", selectedOption);
                      }}
                      value={selectedProduct.marketplace}
                      placeholder={"Marketplace"}
                      id={`Marketplace`}
                    />
                  </div>
                )}
              <div>
                <Autocomplete
                  options={[
                    { name: "Standalone", value: false },
                    { name: "Part of a bundle", value: true },
                  ]}
                  labelKey="name"
                  valueKey="value"
                  onChange={(selectedOption) => {
                    onChangeDropdown("partOfBundle", selectedOption);
                  }}
                  value={selectedProduct.partOfBundle}
                  placeholder={"Type of Item"}
                />
              </div>
              {console.log("parentProducts", parentProducts)}
              <div>
                <Autocomplete
                  options={
                    parentProducts
                      ? parentProducts.map((i) => ({
                          name: `${i.sku} - ${i.name}`,
                          id: i.id,
                        }))
                      : []
                  }
                  labelKey="name"
                  valueKey="id"
                  placeholder="Select Parent Product"
                  onChange={(e) => {
                    setSelectedProduct({
                      ...selectedProduct,
                      parentProduct: e,
                    });
                  }}
                  onKeyDown={(e) =>
                    e.key === "Enter"
                      ? setSelectedProduct({
                          ...selectedProduct,
                          parentProduct: null,
                        })
                      : debouncedProductSearch(e)
                  }
                  id="PRODUCT"
                  value={selectedProduct.parentProduct}
                />
              </div>
            </>
          </>
        )}

        {selectedStep && selectedStep.id === "03" && (
          <>
            <div>
              <TextField
                type="text"
                id="name"
                label="Name"
                placeholder=" "
                onChange={onChange}
                value={selectedProduct.name}
                name="name"
              />
            </div>
            {["FBA", "FBM"].includes(selectedProduct.source) && (
              <>
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="ASIN"
                    placeholder=" "
                    onChange={onChange}
                    value={selectedProduct.asin}
                    name="asin"
                  />
                </div>
                {selectedProduct.source === "FBA" && (
                  <div>
                    <TextField
                      type="text"
                      id="name"
                      label="FN SKU"
                      placeholder=" "
                      onChange={onChange}
                      value={selectedProduct.fnSku}
                      name="fnSku"
                    />
                  </div>
                )}
              </>
            )}
            <div>
              <TextField
                type="text"
                id="name"
                label={
                  selectedProduct.source &&
                  selectedProduct.source?.indexOf("Amazon") !== -1
                    ? "Seller SKU"
                    : "SKU"
                }
                placeholder=" "
                onChange={onChange}
                value={selectedProduct.sku}
                name="sku"
                disabled={selectedProduct.id ? true : false}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="desription"
                label="Product Description"
                placeholder=" "
                onChange={onChange}
                value={selectedProduct?.description}
                name="description"
              />
            </div>
            <div>
              <Autocomplete
                options={
                  productsTypes
                    ? [...productsTypes, { name: "Add Custom" }]
                    : [{ name: "Add Custom" }]
                }
                labelKey="name"
                valueKey="name"
                onChange={(selectedValue) => {
                  onChangeDropdown("type", selectedValue);
                }}
                value={selectedProduct?.type}
                placeholder={"Product Type"}
                id={`type_product`}
              />
            </div>
            <div className="catalog-textfield-wrapper pr-0">
              <label className="catalog-textfield-label">
                Product Category
              </label>

              <Autocomplete
                options={
                  productCategories
                    ? [
                        { name: "Add New", id: "addNewCategory" },
                        ...productCategories,
                      ]
                    : [{ name: "Add New", id: "addNewCategory" }]
                }
                labelKey="name"
                valueKey="id"
                onChange={(selectedValue) => {
                  onChangeDropdown("category", selectedValue);
                }}
                value={selectedProduct?.category}
                placeholder={"Product Category"}
                id={`category_product`}
              />
            </div>
          </>
        )}
        {selectedStep && selectedStep.id === "04" && (
          <>
            <div>
              <TextField
                type="text"
                id="name"
                label="UPC(s)"
                placeholder=" "
                onChange={onChange}
                value={
                  selectedProduct.upc
                    ? selectedProduct.upc.join(",")
                    : selectedProduct.upc
                }
                name="upc"
              />
              <span className="italic">Enter comma separated values</span>
            </div>
            <div>
              <TextField
                type="text"
                id="name"
                label="EAN(s)"
                placeholder=" "
                onChange={onChange}
                value={
                  selectedProduct.ean
                    ? selectedProduct.ean.join(",")
                    : selectedProduct.ean
                }
                name="ean"
              />
              <span className="italic">Enter comma separated values</span>
            </div>
            <div>
              <TextField
                type="text"
                id="name"
                label="LPN(s)"
                placeholder=" "
                onChange={onChange}
                value={
                  selectedProduct.lpn
                    ? selectedProduct.lpn.join(",")
                    : selectedProduct.lpn
                }
                name="lpn"
              />
              <span className="italic">Enter comma separated values</span>
            </div>
          </>
        )}

        {selectedStep && selectedStep.id === "05" && (
          <>
            <>
              <div className="flex items-center space-x-4 px-2">
                <div>
                  <Toggle
                    enabled={selectedProduct?.attributes?.perishable}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "perishable",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                  />
                </div>
                <div className="text-lg">Perishable</div>
              </div>
            </>
            <>
              <div className="flex items-center space-x-4 px-2">
                <div>
                  <Toggle
                    enabled={selectedProduct?.attributes?.lotIdRequired}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "lotIdRequired",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                  />
                </div>
                <div className="text-lg">Lot ID/Batch ID required</div>
              </div>
            </>
            <>
              <div className="flex items-center space-x-4 px-2">
                <div data-for={"serialNumberRequired"} data-tip={true}>
                  <Toggle
                    enabled={selectedProduct?.attributes?.serialNumberRequired}
                    disabled={productHasInventory}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "serialNumberRequired",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                  />
                  {productHasInventory && (
                    <ReactTooltip
                      id="serialNumberRequired"
                      place="top"
                      type="info"
                      effect="solid">
                      <div className="max-w-xl whitespace-normal text-xl">
                        Cannot make changes to Serial Number requirement while
                        the product has inventory.
                      </div>
                    </ReactTooltip>
                  )}
                </div>
                <div className="text-lg">Serial Number Required</div>
              </div>
            </>
            <>
              <div className="flex items-center space-x-4 px-2">
                <div>
                  <Toggle
                    enabled={selectedProduct?.attributes?.expiryDateRequired}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "expiryDateRequired",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                  />
                </div>
                <div className="text-lg">Expiry Date required</div>
              </div>
            </>
            {cogEnabled && (
              <>
                <div className="flex items-center space-x-4 px-2">
                  <div>
                    <TextField
                      type="text"
                      id="commodityCode"
                      label="Commodity Code"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "commodityCode",
                            value: e.target.value,
                            type: "attribute",
                          },
                        })
                      }
                      value={selectedProduct?.attributes?.commodityCode}
                      name="commodityCode"
                    />
                  </div>
                  <div>
                    <TextField
                      type="number"
                      id="cog"
                      label="COG(EUR)"
                      placeholder=" "
                      onChange={(e) =>
                        onChange({
                          target: {
                            name: "cog",
                            value: e.target.value,
                            type: "attribute",
                          },
                        })
                      }
                      value={selectedProduct?.attributes?.cog}
                      name="cog"
                    />
                  </div>
                </div>
              </>
            )}
            <>
              <div className="flex items-center space-x-4 px-2">
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Weight"
                    placeholder=" "
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "weight",
                          value:
                            isNaN(parseFloat(e.target.value)) === false
                              ? parseFloat(e.target.value)
                              : null,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.weight}
                    name="weight"
                  />
                </div>
                <div className="w-auto" style={{ width: "10rem" }}>
                  <Autocomplete
                    options={[
                      { name: "Pounds" },
                      { name: "Kilograms" },
                      { name: "Ounces" },
                      { name: "Litres" },
                    ]}
                    labelKey="name"
                    valueKey="name"
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "weightMeasure",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.weightMeasure}
                    placeholder={"Unit"}
                    id={`unit_weightMeasure`}
                  />
                </div>
              </div>
            </>
            <>
              <div className="flex items-center space-x-4 px-2">
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Length"
                    placeholder=" "
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "length",
                          value:
                            isNaN(parseFloat(e.target.value)) === false
                              ? parseFloat(e.target.value)
                              : null,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.length}
                    name="length"
                  />
                </div>

                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Width"
                    placeholder=" "
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "width",
                          value:
                            isNaN(parseFloat(e.target.value)) === false
                              ? parseFloat(e.target.value)
                              : null,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.width}
                    name="width"
                  />
                </div>

                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Height"
                    placeholder=" "
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "height",
                          value:
                            isNaN(parseFloat(e.target.value)) === false
                              ? parseFloat(e.target.value)
                              : null,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.height}
                    name="height"
                  />
                </div>
                <div className="w-auto" style={{ width: "10rem" }}>
                  <Autocomplete
                    options={[
                      { name: "Inches" },
                      { name: "Centimetres" },
                      { name: "Metres" },
                      { name: "Feet" },
                    ]}
                    labelKey="name"
                    valueKey="name"
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "dimensionsUnit",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.dimensionsUnit}
                    placeholder={"Unit"}
                    id={`dimensions_unit`}
                  />
                </div>
              </div>
              {(!parentProduct || !parentProduct.variantCharacteristics) && (
                <>
                  <div>
                    <Autocomplete
                      options={
                        productSizes
                          ? [...productSizes, { name: "Add Custom" }]
                          : [{ name: "Add Custom" }]
                      }
                      labelKey="name"
                      valueKey="name"
                      onChange={(selectedValue) => {
                        onChangeDropdown("size", selectedValue);
                      }}
                      value={selectedProduct?.attributes?.size}
                      placeholder={"Select Size"}
                      id={`size`}
                    />
                  </div>
                  <div>
                    <Autocomplete
                      options={
                        productShapes
                          ? [...productShapes, { name: "Add Custom" }]
                          : [{ name: "Add Custom" }]
                      }
                      labelKey="name"
                      valueKey="name"
                      onChange={(selectedValue) => {
                        onChangeDropdown("shape", selectedValue);
                      }}
                      value={selectedProduct?.attributes?.shape}
                      placeholder={"Select Shape"}
                      id={"shape"}
                    />
                  </div>
                  <div>
                    <Autocomplete
                      options={
                        productColors
                          ? [...productColors, { name: "Add Custom" }]
                          : [{ name: "Add Custom" }]
                      }
                      labelKey="name"
                      valueKey="name"
                      onChange={(selectedValue) => {
                        onChangeDropdown("color", selectedValue);
                      }}
                      value={selectedProduct?.attributes?.color}
                      placeholder={"Select Color"}
                      id={"color"}
                    />
                  </div>

                  <AddAttributes
                    customAttributes={customAttributes}
                    setCustomAttributes={setCustomAttributes}
                  />
                </>
              )}
              {parentProduct &&
                parentProduct.variantCharacteristics?.length &&
                parentProduct.variantCharacteristics.map((i) => (
                  <div>
                    <Autocomplete
                      options={
                        entityAttributes &&
                        entityAttributes[`${i}_ATTRIBUTE_VALUES`]?.length > 0
                          ? [
                              ...entityAttributes[`${i}_ATTRIBUTE_VALUES`],
                              { name: "Add Custom" },
                            ]
                          : [{ name: "Add Custom" }]
                      }
                      labelKey="name"
                      valueKey="name"
                      onChange={(e) => handleVariantAttributeChange(i, e)}
                      value={selectedProduct.attributes?.[i.toLowerCase()]}
                      placeholder={i}
                      id={"attribute_values"}
                    />
                  </div>
                ))}
            </>
          </>
        )}
        {selectedStep && selectedStep.id === "07" && (
          <ProductImages
            product={selectedProduct}
            onChange={onChange}
            deleteImage={deleteImage}
            onChangeDropdown={onChangeDropdown}
          />
        )}
        {selectedStep && selectedStep.id === "06" && (
          <UOMConfiguration
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            appState={appState}
            onChangeDropdown={onChangeDropdown}
          />
        )}
      </div>
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination">
        <div className="flex flex-1 justify-between sm:justify-end">
          <div className="flex flex-1 justify-between space-x-2 sm:justify-end">
            <div
              onClick={onClose}
              className="text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded border bg-red-600 px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 hover:bg-red-700 focus:outline-none focus:ring-0">
              Cancel
            </div>
            <div
              onClick={!appState.loading ? onSubmit : () => {}}
              className="text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded border bg-primaryAccent px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0">
              Submit
            </div>
            <div
              onClick={setPrevStep}
              className={`text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded border px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0 ${
                selectedStep?.id === steps[0]?.id
                  ? "bg-gray-200 text-gray-400"
                  : "bg-FC8862"
              }`}>
              {"< Previous"}
            </div>
            <div
              onClick={setNextStep}
              className={`text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded border px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0 ${
                selectedStep?.id === steps[steps.length - 1]?.id
                  ? "bg-gray-200 text-gray-400"
                  : "bg-FC8862"
              }`}>
              {"Next >"}
            </div>
          </div>
        </div>
      </nav>
    </Modal>
  );
};

export default ProductForm;
export async function fetchVariantAttributes(
  fetchedParentProduct,
  entityTypesQuery,
  setVariantAttributes,
) {
  if (fetchedParentProduct.variantCharacteristics) {
    const filtersSet = {
      entityParent: fetchedParentProduct.variantCharacteristics.map(
        (i) => `${i}_ATTRIBUTE_VALUES`,
      ),
    };
    const entityTypesResponse = await entityTypesQuery.fetchData({
      filters: filtersSet,
    });
    if (entityTypesResponse.data) {
      setVariantAttributes(entityTypesResponse.data.entityTypes);
    }
  }
}
