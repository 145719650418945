export const Navbar = ({ steps }) => {
  const visibleSteps = steps.filter((item) => item.visible);
  return (
    <nav className="flex w-524 space-x-2">
      {visibleSteps.map((step, index) => {
        const activeStep = step.status === "current";
        return (
          <div className="flex flex-col items-center" key={index}>
            <span
              className={`mb-1 h-2.5 w-44 rounded-md ${
                activeStep ? "bg-blueBackground" : "bg-lightBlueBackground"
              }`}></span>
            <p
              className={`text-sm font-semibold decoration-solid ${
                activeStep ? "text-primaryAccent" : "text-textGray"
              }`}>
              {step.name}
            </p>
          </div>
        );
      })}
    </nav>
  );
};
