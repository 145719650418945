import React from "react";
import { notification } from "antd";
import PropTypes from "prop-types";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

const defaultStyles = {
  success: {
    bgColor: "bg-green-100",
    titleColor: "text-green-800",
    textColor: "text-green-700",
    borderColor: "border-green-800",
    titleSize: "text-base",
    descriptionSize: "text-sm",
  },
  error: {
    bgColor: "bg-red-100",
    titleColor: "text-red-800",
    textColor: "text-red-700",
    borderColor: "border-red-800",
    titleSize: "text-base",
    descriptionSize: "text-sm",
  },
  info: {
    bgColor: "bg-blue-100",
    titleColor: "text-blue-800",
    textColor: "text-blue-700",
    borderColor: "border-blue-800",
    titleSize: "text-base",
    descriptionSize: "text-sm",
  },
  warning: {
    bgColor: "bg-yellow-100",
    titleColor: "text-yellow-800",
    textColor: "text-yellow-700",
    borderColor: "border-yellow-800",
    titleSize: "text-base",
    descriptionSize: "text-sm",
  },
  default: {
    bgColor: "bg-white",
    titleColor: "text-gray-800",
    textColor: "text-gray-700",
    borderColor: "border-gray-800",
    titleSize: "text-base font-bold",
    descriptionSize: "text-sm",
  },
};

const CustomNotification = () => {
  const openNotification = (type, message, description, options = {}) => {
    const {
      bgColor,
      textColor,
      titleColor,
      titleSize,
      descriptionSize,
      pauseOnHover = false,
      defaultColors = true,
      duration = 3000,
      width = "auto",
      minWidth = "20rem",
      maxWidth = "35rem", // Add a maxWidth for better control
      icon,
      iconStyles = "w-6 h-6",
      isDefault = false,
      key,
      placement = "topRight",
    } = options;

    const styles = defaultColors
      ? isDefault
        ? defaultStyles.default
        : defaultStyles[type]
      : {
          bgColor,
          textColor,
          titleColor,
          titleSize,
          descriptionSize,
          iconStyles,
        };

    const iconMap = {
      success: (
        <CheckCircleIcon className={`${iconStyles} ${styles.titleColor}`} />
      ),
      error: <XCircleIcon className={`${iconStyles} ${styles.titleColor}`} />,
      info: (
        <InformationCircleIcon
          className={`${iconStyles} ${styles.titleColor}`}
        />
      ),
      warning: (
        <ExclamationCircleIcon
          className={`${iconStyles} ${styles.titleColor}`}
        />
      ),
    };

    const notificationType = isDefault ? "info" : type;

    notification[notificationType]({
      message: (
        <div className="flex items-center">
          <span className={`${styles.titleColor} ${styles.titleSize}`}>
            {message}
          </span>
        </div>
      ),
      description: (
        <span className={`${styles.descriptionSize} ${styles.textColor}`}>
          {description}
        </span>
      ),
      icon: isDefault ? icon : iconMap[type],
      className: `${styles.bgColor} rounded`,
      duration: pauseOnHover ? 0 : duration / 1000,
      style: {
        width, // Keeps the specified width
        minWidth,
        maxWidth, // Restrict the width to a maximum to avoid overflow
        wordWrap: "break-word", // Ensures long words wrap to prevent overflow
        fontFamily: "inter, sans-serif", // Set the font family
      },
      onMouseEnter: () => {
        if (pauseOnHover) {
          notification.config({
            duration: 0,
          });
        }
      },
      onMouseLeave: () => {
        if (pauseOnHover) {
          notification.config({
            duration: duration / 1000,
          });
        }
      },
      key,
      placement,
    });
  };

  return {
    success: (message, description, options) =>
      openNotification("success", message, description, options),
    error: (message, description, options) =>
      openNotification("error", message, description, options),
    info: (message, description, options) =>
      openNotification("info", message, description, options),
    warning: (message, description, options) =>
      openNotification("warning", message, description, options),
    default: (message, description, options) =>
      openNotification("default", message, description, {
        ...options,
        isDefault: true,
      }),
  };
};

// Define PropTypes for options
const optionsShape = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  titleColor: PropTypes.string,
  titleSize: PropTypes.string,
  descriptionSize: PropTypes.string,
  pauseOnHover: PropTypes.bool,
  defaultColors: PropTypes.bool,
  duration: PropTypes.number,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string, // Added maxWidth for PropTypes
  icon: PropTypes.node,
  iconStyles: PropTypes.string,
  isDefault: PropTypes.bool,
  key: PropTypes.string,
  placement: PropTypes.oneOf([
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
  ]),
};

// Set PropTypes for the returned functions
CustomNotification.propTypes = {
  success: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  info: PropTypes.func.isRequired,
  warning: PropTypes.func.isRequired,
  default: PropTypes.func.isRequired,
};

// Set PropTypes for each function's options
CustomNotification.optionsShape = optionsShape;

export default CustomNotification;
