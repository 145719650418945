import React from "react";

const ExportBanner = ({
  message,
  onClose,
  istrue,
  setShowYellowBanner,
  setShowGreenBanner,
  setShowRedBanner,
  reportUrl,
}) => {
  const classYellow =
    "left-0 right-0 bg-yellow-100 text-black-800 p-4 flex items-center justify-between z-50";
  const classGreen =
    "left-0 right-0 bg-green-100 text-black-800 p-4 flex items-center justify-between z-50";
  const classRed =
    "left-0 right-0 bg-red-100 text-black-800 p-4 flex items-center justify-between z-50";

  let bannerClass = "";
  if (setShowYellowBanner) {
    bannerClass = classYellow;
  } else if (setShowGreenBanner) {
    bannerClass = classGreen;
  } else if (setShowRedBanner) {
    bannerClass = classRed;
  }

  return (
    <div className={bannerClass}>
      <div className="flex items-center">
        <svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.8035 21.5333H16.4702V16.2H15.1369M16.4702 10.8667H16.4835M28.4702 16.2C28.4702 22.8274 23.0976 28.2 16.4702 28.2C9.8428 28.2 4.47021 22.8274 4.47021 16.2C4.47021 9.5726 9.8428 4.20001 16.4702 4.20001C23.0976 4.20001 28.4702 9.5726 28.4702 16.2Z"
            stroke="#48494A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span className="ml-2">{message}</span>

        {reportUrl && (
          <a
            href={reportUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2 underline">
            Download Now !
          </a>
        )}
      </div>
      <button
        className="text-xl font-bold"
        onClick={() => {
          localStorage.removeItem("EXPORT_CONSIGNMENTS_REPORT_ID");
          localStorage.removeItem("EXPORT_CONSIGNMENTS_REPORT_URL");
          localStorage.removeItem("EXPORT_ORDERS_REPORT_ID");
          localStorage.removeItem("EXPORT_ORDERS_REPORT_URL");

          onClose();
        }}>
        &times;
      </button>
    </div>
  );
};

export default ExportBanner;
