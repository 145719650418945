import { gql } from "@apollo/client";

export const GET_CURRENT_TENANT = `
  query GetCurrentTenant {
    currentTenant {
      name
      subdomain
      id
    }
  }
`;

export const VERIFY_SET_PASSWORD_TOKEN = `
  query VerifySetPasswordToken($token: String) {
    verifySetPasswordToken(token: $token) {
      message
    }
  }
`;

export const INITIATE_RESET_PASSWORD = `
  query InitiateResetPassword($email: String) {
    initiateResetPassword(email: $email) {
      message
    }
  }
`;

export const VERIFY_RESET_PASSWORD_TOKEN = `
query VerifyResetPasswordToken ($token: String) {
  verifyResetPasswordToken (token: $token) {
    message
  }
}`;

export const GET_LOGGED_IN_USER = `
  query GetUser {
    me {
      id
      name
      email
      token
      role
      hopstackModules
      activity
      station
      activated
      suspended
      permissions {
        route
        readable
        writable
      }
      warehouses
      customers
      vendors
      warehousesList {
        name
        code
        updatedAt
        id
        isDefault
        typeOfWarehouse
      }
      customersList {
        name
        code
        updatedAt
        warehouses
        id
        isDefault
        settings
      }
      tablePreferences{
        name
        columns
      }
      pageSavedFilters
      termsAndConditionsAccepted
    }
  }
`;

export const GET_MASTER_DATA = `
  query GetMasterData {
    masterData {
      hopstackModules {
        name
        id
      }
      roles {
        name
        id
      }
      deviceTypes {
        name
        id
      }
      warehouses {
        name
        id
      }
    }
  }
`;

export const GET_ALL_DATA_TABLE_FILTERS = `
  query GetAllDataTableFilters($entity: DataTableFilterEntity) {
    getAllDataTableFilters(entity: $entity)
  }
`;

export const GET_RULE_ENGINE_MASTER_DATA = `
  query GetRuleEngineMasterData {
    masterData {
      comparisonOperators {
        name
        value
        scope
      }
      logicalOperators {
        name
        value
      }
      masterFields {
        name
        value
        scope
        fieldType
      }
      allCountries {
        name
      }
      allCustomers {
        name
      }
      allWarehouses {
        name
      }
      allGroups {
        name
      }
      allBooleanChoices {
        name
      }
    }
  }
`;

export const GET_USERS = `
  query GetUsers($perPage: Int, $pageNumber: Int, $sort: String, $filters: UserFilter) {
    users(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        username
        email
        id
        name
        hopstackModules
        role
        customers
        warehouses
        vendors
        activated
        suspended
        termsAndConditionsAccepted
        createdAt
      }
      total
    }
  }
`;

export const GET_USER = `
  query FetchUser($id: ID!) {
    user(id: $id) {
      username
      email
      id
      name
      hopstackModules
      role
      customers
      warehouses
      vendors
      activated
      suspended
      permissions {
        route
        readable
        writable
      }
      pagePreferences {
        route
        visible
      }
      tablePreferences{
        name
        columns
      }
      termsAndConditionsAccepted
      pageSavedFilters
    }
  }
`;

export const GET_STATIONS = `
  query Stations(
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: StationFilter
  ) {
    stations(
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
    ) {
      entities {
        name
        id
        hopstackModule
        devices
        customers
        warehouses
      }
      total
    }
  }
`;

export const GET_STATION = `
  query FetchStation($id: ID!) {
    station(id: $id) {
      name
      id
      hopstackModule
      devices
      customers
      warehouses
    }
  }
`;

export const GET_WORKFLOWS = `
  query GetWorkflows($filters: WorkflowFilter) {
    workflows(filters: $filters) {
      id
      stages {
        stageId
        name
        hopstackModule
        actions
        displayFields
        enabled
        sort
        positiveActions
        negativeActions
        lpnEnabled
      }
      compliance
      workflowType
      customer
      warehouse
      pickingStrategy
      minBatchSize
      maxBatchSize
      waveSize
    }
  }
`;

export const GET_WORKFLOW = `
  query FetchWorkflow($id: ID!) {
    workflow(id: $id) {
      id
      stages {
        name
        hopstackModule
        actions
        displayFields
        enabled
        sort
        positiveActions
        negativeActions
        lpnEnabled
      }
      compliance
      workflowType
      customer
      warehouse
      pickingStrategy
      minBatchSize
      maxBatchSize
      waveSize
      steps
    }
  }
`;

export const GET_WORKFLOW_BY_ATTRIBUTES = `
  query FetchWorkflow($attributes: Random) {
    getWorkflowByAttributes(attributes: $attributes) {
      id
      stages {
        name
        hopstackModule
        actions
        displayFields
        enabled
        sort
        positiveActions
        negativeActions
        lpnEnabled
      }
      compliance
      workflowType
      customer
      warehouse
      pickingStrategy
      minBatchSize
      maxBatchSize
      waveSize
      steps
      entryPoints
    }
  }
`;

export const GET_DEVICES = `
  query GetDevices($filters: DeviceFilter) {
    devices(filters: $filters) {
      name
      hardwareId
      deviceType
      updatedAt
      customers
      warehouses
      id
    }
  }
`;

export const GET_DEVICE = `
  query GetDevice($id: ID!) {
    device(id: $id) {
      name
      hardwareId
      deviceType
      updatedAt
      customers
      warehouses
      id
    }
  }
`;

export const GET_WAREHOUSES = `
query Warehouses($all: Boolean, $perPage: Int, $pageNumber: Int, $sort: String, $filters: WarehouseFilter) {
  warehouses(all: $all, perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
    entities {
      code
      id
      name
      updatedAt
      location
      isDefault
      typeOfWarehouse
      splitOrdersEnabled
      active
      storageTypes
      address {
        name
        email
        phone
        zip
        state
        city
        country
        line1
        line2
      }
    }

    total
  }
}
`;

export const GET_WAREHOUSE = `
  query GetWarehouse($id: ID!) {
    warehouse(id: $id) {
      name
      code
      updatedAt
      id
      typeOfWarehouse
      splitOrdersEnabled
      active
      storageTypes
      address {
        name
        email
        phone
        zip
        state
        city
        country
        line1
        line2
      }
    }
  }
`;

export const GET_SKU_BUNDLES = `
  query GetSkuBundles {
    skuBundles {
      id
      name
      createdAt
      updatedAt
      sku
      enabled
      bundle {
        sku
        quantity
      }
    }
  } 
`;

export const GET_SKU_BUNDLE = `
  query GetSkuBundle($id: ID!) {
    skuBundle(id: $id) {
      id
      name
      createdAt
      updatedAt
      sku
      enabled
      bundle {
        sku
        quantity
      }
    }
  }
`;

export const GET_CUSTOMERS = `
  query Customers(
    $all: Boolean
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: CustomerFilter
  ) {
    customers(
      all: $all
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
    ) {
      entities {
        code
        id
        name
        warehouses
        updatedAt
        isDefault
        active
        currentBillingProfile {
          id
          name
        }
        settings
      }
      total
    }
  }
`;

export const GET_CUSTOMER = `
  query GetCustomer($id: ID!) {
    customer(id: $id) {
      name
      code
      updatedAt
      warehouses
      id
      currency
      active
      settings
    }
  }
`;

// OUTBOUND

export const GET_BATCHES = `
  query GetBatches($perPage: Int, $pageNumber: Int, $sort: String, $filters: BatchFilter) {
    batches(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        id
        createdAt
        rows
        user
      }
      total
    }
  }
`;

export const GET_PICKINGS = `
  query GetPickings($perPage: Int, $pageNumber: Int, $sort: String, $filters: PickingFilter) {
    pickings(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        id
        batch
        user
        createdAt
        firstPickedTime
        lastPickedTime
        completion
        packingStation {
          computerName
        }
        workingList {
          sku
        }
      }
      total
    }
  }
`;

export const GET_PICKING_BATCHES = `
  query GetPickingBatches(
    $perPage: Int
    $pageNumber: Int
    $filters: PickingBatchFilter
    $sort: String
  ) {
    getPickingBatches(
    perPage: $perPage
    pageNumber: $pageNumber
    filters: $filters
    sort: $sort
    )
    {
      entities {
        customer
        warehouse
        id
        tote
        status
        completion
        createdAt
        items {
          id
          sku
          fnSku
          asin
          upc
          sellerSku
          binLocation
          productName
          status
          quantity
          pickedQuantity
          attributes {
            color
            pattern
            size
            collection
          }
        }
        user
        verificationNeeded
        workflow
        orderSubTotes
      }
      total
    }
  }
`;

export const GET_ALL_BATCHES = `
  query GetAllBatches(
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: AllBatchFilter
    $strictTimeRange: Boolean
  ) {
    getAllBatches(
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
      strictTimeRange: $strictTimeRange
    ) {
      entities {
        id
        lotId
        warehouse
        customer
        bestByDate
        availableQuantity
        quantity
        nestedFormFactor
        nestedFormFactorId
        status
        createdAt
        assignedTime
        user
        username
        tote
        palletId
        station
        attributes {
          firstCompletedTime
          lastCompletedTime
          orderIds
          humanReadableOrderIds
          humanReadableOrderIdMapping
          humanReadableOrderMapping
          requestedDropoffPackingStation
          dropoffStation
          multipleLineItemBatch
        }
        completion
        workingList {
          sku
        }
        items {
          sku
          asin
          binLocation
          productName
          quantity
          status
          id
          order
          orderId
          availableQuantity
          attributes {
            color
            collection
            size
          }
          name
        }
        itemsLength
        numberOfUniqueProducts
        quantity
        formFactor
        baseUom
        baseUomQuantity
        classification
        rows
        workflowImages {
          signedImageUrls
        }
        remarks
      }
      itemsInError
      completionPerHour
      activityCompletion
      total
      totalCompleted
    }
  }
`;

export const GET_CYCLE_COUNT_BATCHES = `
  query GetCycleCountBatches(
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: AllBatchFilter
  ) {
    getCycleCountBatches(
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
    ) {
      entities {
        id
        assignedUserId
        planName
        customers
        warehouse
        status
        workingList {
          binLocation
          status
          skuItems {
            sku
            name
            barcodeScanned
            scannedSkus
          }
        }
      }
      total
    }
  }
`;

export const GET_BIN_TO_BIN_TRANSFER_BATCHES = `
  query GetBinToBinTransferBatches(
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: AllBatchFilter
  ) {
    getBinToBinTransferBatches(
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
    ) {
      entities {
        id
        createdAt
        updatedAt
        warehouse
        sku
        status
        assignedUserId
        fromBin
        toBin
        quantity
        items {
          fromBin
          toBin
          quantity
          personnel
        }
      }
      total
    }
  }
`;

export const GET_BATCHES_FOR_STATS = `
  query GetAllBatches($filters: AllBatchFilter) {
    exportBatches(filters: $filters) {
      id
      createdAt
      assignedTime
      user
      username
      tote
      attributes {
        firstCompletedTime
        lastCompletedTime
      }
      completion
      workingList {
        sku
      }
      items {
        sku
        binLocation
        productName
        name
        quantity
        status
        id
        availableQuantity
        attributes {
          color
          collection
          size
        }
      }
      itemsLength
      completedItems
      errorItems
      status
      updatedAt
    }
  }
`;

export const FETCH_SPECIFIC_BATCH = `
  query FetchSpecificBatch($batchId: ID!) {
    batch(batchId: $batchId) {
      id
      createdAt
      assignedTime
      user
      tote
      orderIds
      typeOfBatch
      username
      customer
      warehouse
      attributes {
        firstCompletedTime
        lastCompletedTime
        humanReadableOrderIds
        humanReadableOrderIdMapping
        humanReadableOrderMapping
      }
      completion
      workingList {
        sku
        asin
        quantity
        name
        order
        productId
        binLocation
        lotId
        formFactor
        description
      }
      items {
        sku
        fnSku
        binLocation
        productName
        name
        quantity
        status
        id
        availableQuantity
        lotId
        bestByDate
        formFactor
        attributes {
          color
          collection
          size
        }
        order
      }
      itemsLength
      formFactor
      baseUom
      baseUomQuantity
      status
    }
  }
`;

export const GET_PACKINGS = `
  query GetPackings($perPage: Int, $pageNumber: Int, $sort: String, $filters: PackingFilter) {
    packings(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        batch
        createdAt
        firstPackedTime
        lastPackedTime
        completion
        packingStation {
          computerName
        }
        workingList {
          sku
        }
      }
      total
    }
  }
`;

export const GET_PARCEL_STATUSES = `
  query GetParcelStatuses($perPage: Int, $pageNumber: Int, $filters: ParcelStatusFilter, $sort: String) {
    parcelStatuses(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        trackingNumber
        carrier
        shippingBin
        bagNumber
        truckId
        status
        completionTime
        parcelError
        order
      }
      total
    }
  }
`;

export const GET_SHIPMENTS = `
  query GetShipments($perPage: Int, $pageNumber: Int, $filters: ShipmentFilter, $sort: String) {
    shipments(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        trackingNumber
        deliveryNumber
        palletId
        status
        door
        location
        pickupTime
        pickUpDelayInDays
        carrierCode
        salesOrder
      }
      total
    }
  }
`;

// INBOUND

export const GET_RECEIVINGS = `
  query GetReceivings($perPage: Int, $pageNumber: Int, $filters: ReceivingFilter, $sort: String) {
    receivings(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        completion
        workingList {
          id
          sku
          status
          quantity
          name
        }
        createdAt
        firstReceivedTime
        lastReceivedTime
        status
        consignment
      }
      total
    }
  }
`;

export const GET_INBOUND_BATCHES = `
  query GetBatches($perPage: Int, $pageNumber: Int, $sort: String, $filters: BatchFilter) {
    inboundBatches(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        id
        createdAt
        currentStationStatus
        multipleLineItems
        rows
        tags
      }
      total
      pickingCompleted
      packingCompleted
    }
  }
`;

export const GET_PUTAWAYS = `
  query GetPutaways($perPage: Int, $pageNumber: Int, $filters: PutawayFilter, $sort: String) {
    putaways(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        completion
        workingList {
          id
          sku
          status
          quantity
          name
        }
        createdAt
        firstPutawayTime
        lastPutawayTime
        status
      }
      total
    }
  }
`;

export const GET_QUALITY_CHECKINGS = `
  query GetQualityCheckings($perPage: Int, $pageNumber: Int, $filters: QualityCheckingFilter, $sort: String) {
    qualityCheckings(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        id
        completion
        workingList {
          id
          sku
          status
          quantity
          name
        }
        createdAt
        firstQualityCheckingTime
        lastQualityCheckingTime
        status
      }
      total
    }
  }
`;

export const GET_GROUPS = `
  query GetGroups {
    groups {
      id
      name
      mainCriteria
      conditions {
        masterField
        value
        comparisonOperator
      }
    }
  }
`;

export const GET_GROUP = `
  query FetchGroup($id: ID!) {
    group(id: $id) {
      id
      name
      mainCriteria
      conditions {
        masterField
        value
        comparisonOperator
      }
    }
  }
`;

export const GET_FORECASTS = `
  query GetForecasts {
    forecasts {
      id
      sku
      price
      orderLineItem {
        productName
        productImage
      }
      lastSold
      forecast {
        twoWeeks
        oneMonth
      }
      createdAt
      safetyStock
    }
  }
`;

export const GET_PARENT_PRODUCTS = `
  query ParentProducts(
    $perPage: Int
    $pageNumber: Int
    $filters: ParentProductFilter
    $sort: String
  ) {
    parentProducts(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        id
        name
        sku
        description
        variants
        variantCharacteristics
        active
        customer
        source
      }
      total
    }
  }
`;

export const GET_PRODUCTS = `
  query Products(
    $perPage: Int
    $pageNumber: Int
    $filters: ProductFilter
    $sort: String
    $queryOperator: String
  ) {
    products(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
      queryOperator: $queryOperator
    ) {
      parentProducts {
        id
        name
        sku
        description
        variants
        variantCharacteristics
        active
        source
        customer
      }
      entities {
        sku
        image
        parentProduct
        images {
          display_url
          url
          purpose
          source
          variant
        }
        name
        asin
        fnSku
        sellerSku
        upc
        id
        price
        customer
        warehouse
        source
        partOfBundle
        lpn
        ean
        attributes
        active
        baseUom
        uomConfiguration {
          baseUom
          baseUomQuantity
          targetUom
          isActive
        }
        marketplaceAttributes {
          marketplace
          attributes
        }
        type
        category
        categories {
          id
          name
          description
          associatedProducts
        }
        description
        typeOfProduct
        products {
          id
          quantity
          uom
        }
        pricing {
          standardPrice
          shippingPrice
          dutyPrice
          computedPriceFields {
            cost
            totalPrice
            costPrice
            visualisedCostPrice
          }
        }
        materials {
          sku
          name
          category
          active
          customer
          warehouse
          tenant
          quantity
          source
          typeOfProduct
          pricing {
            cost
            totalPrice
            costPrice
            costPriceUSD
            srpPriceCA
            dutyRate
            visualisedCostPrice
            lastLabourCost
            maxLabourCost
          }
          attributes
        }
        aliases {
          alias
          entityId
          entityName
        }
      }
      total
    }
  }
`;

export const GET_CATALOG_PRODUCTS = `
  query GetCatalogProducts($keyword: String!, $customer: ID!, $warehouse: ID!, $marketplace: String, $sellerId: String) {
    getCatalogProducts(keyword: $keyword, customer: $customer, warehouse: $warehouse, marketplace: $marketplace, sellerID: $sellerId) {
      entities {
        sku
        name
        asin
        fnSku
        sellerSku
        upc
        customer
        warehouse
        source
        ean
        active
        baseUom
        uniqueIdentifier
        images {
          display_url
          url
          purpose
          source
          variant
        }
        listingStatus
        marketplaceAttributes {
          marketplace
          attributes
        }
      }
      total
    }
  }
`;

export const GET_PO_ORDERS = `
query PurchaseOrders($filters: PurchaseOrderFilter, $sort: String, $pageNumber: Int, $perPage: Int) {
  purchaseOrders(filters: $filters, sort: $sort, pageNumber: $pageNumber, perPage: $perPage) {
    metaData {
      total
      perPage
      pageNumber
    }
    data {
      vendorId
      vendorName
      vendorClassification
      poId
      pos {
        poId
        externalPoId
        poVersion
        updatedAt
        shippingDetails {
          trackingNumbers
          carrier
          user
          timestamp
          shippingLineItems {
            lineItemId
            quantity
            consignment {
              orderId
              id
            }
          }
        }
        suggestedChanges {
          user
          timestamp
          suggestions {
            expectedShipDate
            expectedDeliveryDate
            lineItems {
              quantity
              unitPrice
              lineItemId
              uom
              components {
                sku
                name
                quantity
                uom
                unitPrice
                metalLossPercentage
                masterData {
                  uom
                  weight
                  quantity
                  unitPrice
                  componentType
                }
              }
            }
            additionalCharges {
              name
              amount
            }
          }
        }
        cancellationReason
        failureReason
        vendorNote {
          note
          user
          timestamp
        }
        vendor {
          id
          address {
            line1
            line2
            city
            state
            zip
            country
            phone
            email
          }
        }
        warehouse {
          id
          name
        }
        status
        priority
        currency
        lineItems {
          lineItemId
          productId
          sku
          name
          quantity
          uom
          unitPrice
          size
          parentProduct {
            id
            name
            sku
          }
          attributes
          images {
            url
            source
          }
          components {
            sku
            name
            quantity
            uom
            unitPrice
            metalLossPercentage
            masterData {
              uom
              weight
              quantity
              unitPrice
              componentType
            }
          }
        }
        additionalCharges {
          name
          amount
        }
        poNotes {
          note
          user
          timestamp
        }
        orderDate
        expectedShipDate
        expectedDeliveryDate
        audits {
          status
          user
          timestamp
          notes
        }
      }
    }
  }
}
`;

export const GET_BUNDLES_AS_PRODUCTS = `
  query GetBundlesAsProducts($customers: [ID!]!, $warehouses: [ID!]) {
    getBundlesAsProducts(customers: $customers, warehouses: $warehouses) {
      id
      name
      sku
      fnSku
      sellerSku
      asin
      customer
      source
      upc
      ean
      lpn
      listingStatus
      typeOfProduct
      baseUom
      products {
        id
        name
        sku
        fnSku
        sellerSku
        asin
        upc
        ean
        lpn
        unallocatedQuantity
        uom
      }
      uomConfiguration {
        baseUom
        baseUomQuantity
        targetUom
      }
    }
  }
`;

export const FIND_MAX_ALLOCABLE_QUANTITIES_FOR_BUNDLE = `
  query FindMaxAllocableQuantitiesForBundle($bundleId: ID!, $warehouseId: ID!) {
    findMaxAllocableQuantitiesForBundle(bundleId: $bundleId, warehouseId: $warehouseId) {
      uom
      quantity
    }
  }
`;

export const GET_TAGS = `
  query Tags(
    $perPage: Int
    $pageNumber: Int
    $filters: TagFilter
    $sort: String
  ) {
    tags(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        id
        name
        description
        products
      }
      total
    }
  }
`;

export const GET_CATEGORIES = `
  query Categories(
    $perPage: Int
    $pageNumber: Int
    $filters: CategoryFilter
    $sort: String
  ) {
    categories(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        id
        name
        description
        associatedProducts
        updatedAt
      }
      total
    }
  }
`;

export const GET_CATALOGS = `
  query Catalogs(
    $perPage: Int
    $pageNumber: Int
    $filters: CatalogFilter
    $sort: String
  ) {
    catalogs(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        id
        name
        products
      }
      total
    }
  }
`;

export const GET_INVENTORY = `
  query GetInventory($perPage: Int, $pageNumber: Int, $filters: InventoryFilter, $sort: String) {
    inventory(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        sku
        lastPutaway
        lastReceived
        lastReceivedQuantity
        lastPutawayQuantity
        lastFulfilled
        lastFulfilledQuantity
        stock
        image
        name
        binLocation
        currentReceivedQuantity
        fnSku
        asin
        sellerSku
        upc
        id
        totalStock
        blockedStock
        customer
        warehouse
        amazonQuantity
        attributes
        active
        quantities
        baseUom
        marketplaceAttributes {
          marketplace
          attributes
        }
        uomConfiguration {
          baseUom
          targetUom
          baseUomQuantity
          isActive
        }
        source
        images {
          display_url
          url
          purpose
          source
          variant
        }
        typeOfProduct
      }
      total
    }
  }
`;

export const GET_INVENTORY_FOR_LEDGER = `
  query GetInventoryLedger($perPage: Int, $pageNumber: Int, $filters: InventoryFilter, $sort: String) {
    inventoryLedger(perPage: $perPage, pageNumber: $pageNumber, filters: $filters, sort: $sort) {
      entities {
        sku
        stock
        image
        name
        binLocation
        currentReceivedQuantity
        fnSku
        asin
        sellerSku
        upc
        id
        customer
        warehouse
        amazonQuantity
        attributes
        active
        quantities
        currentSource
        source
        unverified
        marketplaceAttributes {
          marketplace
          attributes
        }
        description
        baseUom
        uomConfiguration {
          baseUom
          targetUom
          baseUomQuantity
          isActive
        }
        aliases {
          alias
          entityId
          entityName
        }
      }
      total
      totalInWarehouse
      totalAvailableToShip
      totalAllocated
      totalDamaged
      unverifiedExists
    }
  }
`;

export const GET_TRANSACTION_HISTORY_REPORT = `
  query GetTransactionHistoryReport(
    $filters: TransactionReportFilter
    $sort: String
    $module: String
  ) {
    getTransactionHistoryReport(
      filters: $filters
      sort: $sort
      module: $module
    ) {
      message
    }
  }
`;

export const GET_CATALOG = `
  query GetCatalog($id: ID!) {
    specificCatalog(id: $id) {
      name
      sku
      fnSku
      sellerSku
      asin
      description
      type
      category
      tags {
        id
        name
        description
      }
      upc
      images {
        display_url
        url
        purpose
        source
        variant
      }
      id
      binLocation
      quantity
      stock
      lpn
      ean
      source
      partOfBundle
      customer
      warehouse
      attributes
      marketplaceAttributes {
        attributes
      }
      active
      quantities
      baseUom
      materials {
        sku
        name
        source
        typeOfProduct
        pricing {
          cost
          totalPrice
          costPrice
          costPriceUSD
          srpPriceCA
          dutyRate
          visualisedCostPrice
          lastLabourCost
          maxLabourCost
        }
      }
      uomConfiguration {
        baseUom
        targetUom
        baseUomQuantity
        isActive
      }
      pricing {
        standardPrice
        shippingPrice
        dutyPrice
        labourCost
        computedPriceFields {
          cost
          totalPrice
          costPrice
          visualisedCostPrice
        }
      }
    }
  }
`;

export const EXPLAIN_INVENTORY = `
  query GetExplainInventory(
    $id: ID
    $typeOfBreakdown: String
    $typeOfInnerBreakdown: String
    $filters: InventoryFilter
  ) {
    explainInventory(
      id: $id
      typeOfBreakdown: $typeOfBreakdown
      typeOfInnerBreakdown: $typeOfInnerBreakdown
      filters: $filters
    )
  }
`;

export const GET_INVENTORY_FOR_RELEASE = `
  query GetInventoryForRelease($customer: ID, $warehouse: ID, $marketplace: String, $sellerId: String) {
    getInventoryForRelease(customer: $customer, warehouse: $warehouse, marketplace: $marketplace, sellerId: $sellerId) {
      sku
      lastPutaway
      lastReceived
      lastReceivedQuantity
      lastPutawayQuantity
      lastFulfilled
      lastFulfilledQuantity
      stock
      images {
        display_url
        url
        purpose
        source
        variant
      }
      name
      binLocation
      currentReceivedQuantity
      fnSku
      asin
      sellerSku
      upc
      id
      totalStock
      blockedStock
      attributes
      quantities
      typeOfProduct
      expirationDates {
        expirationDate
        quantity
      }
      marketplaceAttributes {
        marketplace
        attributes
      }
      products {
        id
        asin
        sku
        quantities
      }
      baseUom
    }
  }
`;

export const GET_ALL_INVENTORY = `
  query ExportAllInventory($filters: InventoryFilter, $sort: String) {
    exportInventory(filters: $filters, sort: $sort) {
      sku
      lastPutaway
      lastPutawayQuantity
      lastReceived
      lastReceivedQuantity
      lastFulfilled
      lastFulfilledQuantity
      stock
      image 
      name
      binLocation
      totalReceived
      sold
      id
      totalStock
      blockedStock
      customer
      quantities
    }
  }
`;

export const GET_INVENTORY_FOR_CONSIGNMENT = `
  query GetInventoryForConsignment($filters: InventoryConsignmentFilter) {
    inventoryForConsignment(filters: $filters) {
      sku
      name
      id
      fnSku
      asin
      sellerSku
      upc
    }
  }
`;

export const GET_PARENT_SKU = `
  query GetParentSku($id: ID!) {
    parentSku(id: $id) {
      sku
      id
      variants
      variantCharacteristics
      active
      customer
      source
      name
      description
    }
  }
`;

export const GET_SKU = `
  query GetSpecificInventory($id: ID!) {
    specificInventory(id: $id) {
      name
      sku
      parentProduct
      fnSku
      sellerSku
      asin
      description
      type
      category
      tags {
        id
        name
        description
        products
      }
      categories {
        id
        name
      }
      upc
      images {
        display_url
        url
        purpose
        source
        variant
      }
      id
      binLocation
      quantity
      stock
      lpn
      ean
      source
      partOfBundle
      customer
      warehouse
      attributes
      customAttributes
      marketplaceAttributes {
        marketplace
        attributes
      }
      active
      quantities
      baseUom
      uomConfiguration {
        baseUom
        targetUom
        baseUomQuantity
        isActive
      }
      typeOfProduct
      products {
        id
        name
        sku
        asin
        quantity
        uom
      }
      listingStatus
      additionalPrepInstructions
      prepInstructions {
        PrepInstruction
        PrepOwner
      }
    }
  }
`;

// RECEIVER

export const GET_CURRENT_RECEIVING = `
  query GetCurrentReceiving {
    getCurrentReceiving {
      id
      completion
      workingList {
        id
        sku
        status
        quantity
        name
      }
      currentItem {
        id
        name
        sku
        image
        quantity
      }
    }
  }
`;

// PUTAWAY

export const GET_CURRENT_PUTAWAY = `
  query GetCurrentPutaway {
    getCurrentPutaway {
      id
      completion
      workingList {
        id
        sku
        status
        quantity
        name
        binLocation
      }
      currentItem {
        id
        name
        sku
        image
        quantity
        binLocation
      }
    }
  }
`;

export const GET_NEW_PUTAWAY = `
  query GetNewPutaway {
    getNewPutaway {
      message
    }
  }
`;

// INTEGRATIONS

export const GET_INTEGRATIONS = `
  query GetIntegations($filters: IntegrationFilter) {
    integrations(filters: $filters) {
      integrationType
      username
      customer
      warehouse
      id
      carrierAccounts
      carriers
      createdAt
      updatedAt
      region
      marketplaceCountryCode
      marketplaces
      sellerId
      tags
      configurations{
        marketplace
      }
    }
  }
`;

export const GET_INTEGRATION_PRODUCTS = `
  query GetIntegationProducts($id: ID!) {
    integrationProducts(id: $id) {
      message
    }
  }
`;

export const GET_MARKETPLACE_PRODUCTS = `
  query GetIntegationProducts($customer: ID) {
    marketplaceProducts(customer: $customer) {
      message
    }
  }
`;

export const GET_INTEGRATION_ORDERS = `
  query GetIntegationOrders($id: ID!) {
    integrationOrders(id: $id) {
      # id
      # created_at
      # current_subtotal_price
      # order_number
      # line_items {
      #   name
      # }
      message
    }
  }
`;

export const TEST_INTEGRATION = `
  query TestIntegration($id: ID!) {
    testIntegration(id: $id) {
      message
      testData
    }
  }
`;

// ORDERS

export const GET_ORDER_MASTER_DATA = `
  query GetMasterData {
    masterData {
      allStatuses {
        name
        id
      }
      allSources {
        name
      }
      allCustomers {
        name
      }
      allCountries {
        name
      }
      allShippingNames {
        name
      }
    }
  }
`;

export const GET_ORDERS = `
  query GetOrders(
    $perPage: Int
    $pageNumber: Int
    $filters: OrderFilter
    $paginated: Boolean
    $sort: String
  ) {
    orders(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      paginated: $paginated
      sort: $sort
    ) {
      entities {
        orderValue
        notes
        orderStatus
        subStatus
        reference
        orderId
        inboundPlanId
        carrier
        carrierService
        id
        salesOrder
        orderDate
        source
        invoiceUrls
        orderType
        shippingAddress {
          country
          state
          zip
          name
          phone
          line1
          line2
          email
          city
        }
        billingAddress {
          line1
          line2
          zip
          country
          state
          name
          city
        }
        workflowType
        customerId
        exceptions {
          description
          typeOfException
        }
        shippingLabel
        tote
        subTote
        cannotFulfil
        shipmentPlan
        customer
        warehouse
        trackingNumber
        shippingStatus
        deliveryMethod
        trackingUrl
        email
        boxInformationNeeded
        fbaAudit
        source
        dropship
        consignmentId
        verifiedLabelUpload
        shippingRequests
        attributes
        insuranceRequired
        insuranceProvider
        insuredValue
        toValidAddress
        bolNumber
        scacCode
        bolData {
          bolSealNumber
          temperature
          recorder
        }
        dateOfShipping
        typeOfShipment
        boxLabels
        palletLabels
        bol
        proNumber
        classification
        numberOfProducts
        multipleLineItems
        totalQuantity
        customAttributes
        marketplaceCountryCode
        region
        sellerId
        childOrders
        splitOrders
        originalOrder
        deliveryDate
        thirdPartyFreightChargesBill
        createdAt
      }
      total
      groupedOrders
    }
  }
`;

export const GET_ORDERS_FULFILLMENT_DETAILS = `
  query GetOrderFulfillmentDetails($input: OrderFulfillmentInput) {
    getOrderFulfillmentDetails(input: $input)
  }
`;

export const GET_AUDIT = `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      stages {
        status
        createdAt
        notes
        userId
      }
    }
  }
`;

export const CALCULATE_SHIPPING_RATES = `
  query CalculateShippingRates($orderNo: String!) {
    calculateShippingRates(orderNo: $orderNo) {
      serviceName
      chargeWeight {
        value
        unit
      }
      totalCharge {
        amount
        unit
      }
      selected
    }
  }
`;

export const FETCH_SPECIFIC_ORDER = `
  query FetchSpecificOrder($orderId: ID!) {
    order(orderId: $orderId) {
      id
      boxInformationNeeded
      transportFailed
      typeOfShipment
      hazmatConfirmed
      confirmedTypeOfShipment
      boxLabels
      palletLabels
      pallets {
        length
        width
        height
        weight
      }
      fbaAudit
      orderId
      orderDate
      reference
      orderStatus
      subStatus
      shippingLabel
      shippingLabels
      trackingNumber
      shippingStatus
      notes
      invoiceUrls
      trackingUrl
      carrier
      carrierService
      incoterms
      carrierIntegration {
        carrier
        carrierService
        notes
      }
      selectedCarrierServiceId
      selectedCarrierSource
      bypassCarrierSelection
      tote
      subTote
      cannotFulfil
      shipmentPlan
      source
      orderType
      toWarehouse
      attributes
      email
      exceptions {
        description
        typeOfException
      }
      shippingAddress {
        line1
        line2
        zip
        country
        state
        name
        email
        phone
        city
      }
      billingAddress {
        line1
        line2
        zip
        country
        state
        name
        email
        phone
        city
      }
      orderLineItems {
        sku
        fnSku
        upc
        sellerSku
        asin
        binLocation
        id
        price
        quantity
        bestByDate
        expiryDate
        availableQuantity
        reference
        productName
        productId
        attributes {
          collection
          color
          size
          commodityCode
        }
        status
        formFactor
        fulfillmentType
        lotId
        serialNumber
        nestedFormFactorId
        palletId
        typeOfProduct
        products {
          id
          sku
          quantityInBundle
          quantityToFulfill
          availableToShip
        }
        availableToShip
      }
      orderProducts {
        sku
        fnSku
        upc
        sellerSku
        asin
        binLocation
        id
        bestByDate
        baseUom
        name
        images {
          display_url
          url
          purpose
          source
          variant
        }
        uomConfiguration {
          baseUom
          baseUomQuantity
          targetUom
        }
        price
        quantity
        availableQuantity
        reference
        productName
        attributes {
          collection
          color
          size
          height
          weight
          length
          width
          commodityCode
        }
        status
        formFactor
        fulfillmentType
        lotId
        expiryDate
        serialNumber
        nestedFormFactorId
        palletId
        marketplaceAttributes {
          marketplace
          attributes
        }
        typeOfProduct
        products {
          id
          sku
          quantityInBundle
          quantityToFulfill
          availableToShip
        }
        availableToShip
      }
      dropship
      consignmentId
      verifiedLabelUpload
      shippingRequests
      insuranceRequired
      insuranceProvider
      insuredValue
      completedDate
      toValidAddress
      bolNumber
      scacCode
      bolData {
        bolSealNumber
        temperature
        recorder
      }
      dateOfShipping
      warehouse
      customer
      isCasePack
      classification
      customAttributes
      preSelectedCarrierRate {
        id
        type
        carrier
        source
        service
        price
        currency
        estimated_delivery_date
        estimated_delivery_days
      }
      estimatedBoxes {
        length
        width
        height
        totalWeight
        boxCount
      }
      bol
      proNumber
      marketplaceCountryCode
      sellerId
      region
      childOrders
      originalOrder
      totalQuantity
      numberOfProducts
      isPartneredCarrier
      deliveryDate
      thirdPartyFreightChargesBill
      inboundPlanId
    }
  }
`;

export const GET_BACK_ORDER_DETAILS = `
  query GetBackOrderDetails($id: ID!) {
    getBackOrderDetails(id: $id)
  }
`;

export const GET_SPLIT_ORDER_DETAILS = `
  query getSplitOrderDetails($id: ID!) {
    getSplitOrderDetails(id: $id)
  }
`;

// CONSIGNMENT

export const GET_CONSIGNMENTS = `
  query GetConsignments(
    $perPage: Int
    $pageNumber: Int
    $filters: ConsignmentFilter
    $sort: String
  ) {
    consignments(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        items {
          productName
          fnSku
          upc
          sellerSku
          asin
          quantity
          sku
          formFactor
          availableQuantity
          notes
          warehouseNotes
          formFactors
          productId
        }
        workingList {
          productName
          fnSku
          upc
          sellerSku
          asin
          quantity
          sku
          formFactor
          availableQuantity
          notes
          warehouseNotes
          formFactors
          productId
        }
        status
        createdAt
        consignmentNumber
        trackingNumber
        orderId
        id
        consignmentDate
        supplier
        customer
        warehouse
        notes
        isReturnOrder
        linkedOrders
        dropship
        typeOfShipment
        dropshipType
        isCasePack
        hasDamagedItems
        orderType
      }
      total
      groupedConsignments
    }
  }
`;

export const GET_CONSIGNMENT = `
  query GetConsignment($id: ID!, $requestType: String) {
    consignment(id: $id, requestType: $requestType) {
      status
      createdAt
      updatedAt
      consignmentNumber
      trackingNumber
      orderId
      id
      supplier
      carrier
      customer
      warehouse
      consignmentDate
      notes
      isReturnOrder
      linkedOrders
      orderType
      stoOrderId
      items {
        productId
        sellerSku
        asin
        quantity
        sku
        formFactor
        lotId
        serialNumber
        poId
        lineItemId
        fulfillmentType
        notes
        formFactors
        warehouseNotes
        productName
        quantityInCase
        numberOfCases
        productId
        fnSku
      }
      workingList {
        productName
        fnSku
        upc
        sellerSku
        asin
        quantity
        sku
        formFactor
        availableQuantity
        notes
        formFactors
        warehouseNotes
        quantityInCase
        numberOfCases
        lotId
        serialNumber
        poId
        lineItemId
      }
      dropship
      typeOfShipment
      dropshipType
      isCasePack
      shippingAddress {
        country
        state
        zip
        name
        phone
        line1
        line2
        email
        city
      }
      receivingBatches {
        name
        sku
        fnSku
        asin
        sellerSku
        upc
        image
        quantity
        id
        bestByDate
        tote
        customer
        trackingNumber
        binLocation
        consignmentId
        attributes
        formFactor
        nestedFormFactor
        nestedFormFactorId
        lotId
      }
    }
  }
`;

export const GET_CONSIGNMENT_AUDIT = `
  query GetConsignmentAudit($consignmentNumber: String!) {
    getConsignmentAudit(consignmentNumber: $consignmentNumber) {
      stages {
        status
        createdAt
        extra
      }
    }
  }
`;

export const GET_CONSIGNMENT_MASTER_DATA = `
  query GetConsignmentMasterData {
    masterData {
      allConsignmentStatuses {
        name
      }
      allConsignmentTypes {
        name
      }
    }
  }
`;

export const GET_CONSIGNMENT_DAMAGED_ITEM_DETAILS = `
  query getConsignmentDamagedItemDetails($consignmentId: ID!) {
    getConsignmentDamagedItemDetails(consignmentId: $consignmentId) {
      damagedItems {
        sku
        asin
        name
        quantity
        remarks
        workflowImages {
          signedImageUrls
        }
      }
    }
  }
`;
// RECEIVED SKUs

export const GET_RECEIVED_SKUS = `
  query GetReceivedSkus(
    $perPage: Int
    $pageNumber: Int
    $filters: ReceivedSkuFilter
    $sort: String
  ) {
    receivedSkus(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        status
        name
        sku
        dateReceived
        id
        quantity
        attributes {
          colorName
          sizeName
          collectionName
        }
      }
      total
    }
  }
`;

export const GET_RECEIVED_SKU = `
  query GetReceivedSku(
    $id: ID!
  ) {
    receivedSku(id: $id) {
      status
      name
      sku
      dateReceived
      id
      quantity
    }
  }
`;

export const GET_RECEIVED_SKU_AUDIT = `
  query GetReceivedSkuAudit($id: ID!) {
    getReceivedSkuAudit(id: $id) {
      stages {
        status
        createdAt
        extra
      }
    }
  }
`;

export const GET_RECEIVED_SKUS_MASTER_DATA = `
  query GetReceivedSkusMasterData {
    masterData {
      allReceivedSkuStatuses {
        name
      }
    }
  }
`;

export const GET_UNMAPPED_SKUS = `
  query GetUnmappedSkus {
    getUnmappedSkus {
      sku
    }
  }
`;

export const GET_UNMAPPED_BIN_LOCATIONS = `
  query GetUnmappedBinLocations {
    getUnmappedBinLocations {
      binLocation
    }
  }
`;

// PICKER
export const GET_CURRENT_PICKER_BATCH = `
  query GetCurrentPickerBatch {
    getCurrentPickerBatch {
      customer
      warehouse
      id
      tote
      status
      completion
      attributes {
        dropoffStation
        orderIds
      }
      boxes {
        name
        length
        width
        height
        weight
      }
      currentItem {
        id
        sku
        fnSku
        asin
        upc
        sellerSku
        binLocation
        productName
        status
        quantity
        formFactor
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        scannedSubTote
        subTote
        order
        tote
        bestByDate
        attributes {
          color
          pattern
          size
          collection
        }
        customAttributes
        productId
        productAlias
      }
      workingList {
        id
        sku
        fnSku
        asin
        upc
        sellerSku
        binLocation
        productName
        status
        quantity
        pickedQuantity
        attributes {
          color
          pattern
          size
          collection
        }
      }
      items {
        sku
      }
      confirmationList {
        sku
        fnSku
        asin
        upc
        sellerSku
        productName
        status
        attributes {
          color
          pattern
          size
          collection
        }
      }
      verificationNeeded
      workflow
      orderSubTotes
    }
  }
`;

export const GET_NEW_PICKER_BATCH = `
  query GetNewPickerBatch($customer: ID, $warehouse: ID!, $orderId: ID, $batchId: ID) {
    getNewPickerBatch(customer: $customer, warehouse: $warehouse, orderId: $orderId, batchId: $batchId) {
      message
      batch {
      id
      tote
      currentItem {
        id
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        attributes {
          size
          color
          collection
          pattern
          sizeName
          colorName
          collectionName
          patternName
          height
          weight
          length
          width
        }
        status
        scannedSubTote
        subTote
        order
        scannedBatchId
        batchId
        mfgDate
        expiryDate
        tote
        scannedTote
        formFactor
        bestByDate
        warehouse
        productId
        customAttributes
        productAlias
      }
      workingList {
        id
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      status
      attributes {
        dropoffStation
        orderIds
        orders {
          id
          orderStatus
          carrier
          orderDate
          shippingAddress {
            line1
            line2
            zip
            country
            state
            name
            email
            phone
            city
          }
          shipmentPlan
          reference
          orderId
          orderValue
          orderLineItems {
            reference
            binLocation
            sku
            fnSku
            asin
            sellerSku
            upc
            quantity
            availableQuantity
            baseUom
            uomConfiguration {
              baseUom
              baseUomQuantity
              targetUom
              isActive
            }
            price
            id
            productId
            productName
            productImage
            uniqueIdentifier
            bestByDate
            status
            formFactor
            serialNumber
            name
            fulfillmentType
            lotId
            nestedFormFactorId
            palletId
            marketplaceAttributes {
              marketplace
              attributes
            }
            typeOfProduct
            products {
              id
              sku
              quantityInBundle
              quantityToFulfill
              availableToShip
            }
            availableToShip
          }
          orderProducts {
            reference
            binLocation
            sku
            fnSku
            asin
            sellerSku
            upc
            quantity
            availableQuantity
            baseUom
            price
            id
            productId
            productName
            productImage
            uniqueIdentifier
            bestByDate
            status
            formFactor
            serialNumber
            name
            fulfillmentType
            lotId
            nestedFormFactorId
            palletId
            typeOfProduct
            availableToShip
          }
          subStatus
          exceptions {
            orderLineItem {
              reference
              binLocation
              sku
              fnSku
              asin
              sellerSku
              upc
              quantity
              availableQuantity
              baseUom
              price
              id
              productId
              productName
              productImage
              uniqueIdentifier
              bestByDate
              status
              formFactor
              serialNumber
              name
              fulfillmentType
              lotId
              nestedFormFactorId
              palletId
              typeOfProduct
              availableToShip
            }
            description
            typeOfException
          }
          shippingLabel
          tote
          subTote
          trackingNumber
          shippingStatus
          cannotFulfil
          formFactor
          trackingUrl
          source
          orderType
          toWarehouse
          email
          bol
          shippingLabels
          trackingNumbers
          invoiceUrls
          boxInformationNeeded
          fbaAudit
          typeOfShipment
          confirmedTypeOfShipment
          boxLabels
          pallets {
            name
            length
            width
            height
            weight
          }
          palletLabels
          transportFailed
          hazmatConfirmed
          dropship
          consignmentId
          verifiedLabelUpload
          shippingRequests
          carrierService
          attributes
          insuranceRequired
          insuranceProvider
          insuredValue
          notes
          completedDate
          bolNumber
          bolSealNumber
          scacCode
          dateOfShipping
          toValidAddress
          warehouse
          customer
          selectedCarrierServiceId
          bypassCarrierSelection
          selectedCarrierSource
          classification
          isCasePack
          customAttributes
          preSelectedCarrierRate {
            id
            type
            carrier
            source
            service
            price
            currency
            estimated_delivery_date
            estimated_delivery_days
          }
          estimatedBoxes {
            length
            width
            height
            totalWeight
            boxCount
          }
          proNumber
          numberOfProducts
          totalQuantity
          multipleLineItems
          marketplaceCountryCode
          region
          sellerId
          childOrders
          splitOrders
          originalOrder
          isPartneredCarrier
          deliveryDate
          thirdPartyFreightChargesBill
          carrierIntegration {
            carrier
            carrierService
            notes
          }
        }
        multipleLineItemBatch
        orderLineItemSubTotes
        orderSubTotes
        humanReadableOrderSubTotes
        humanReadableOrderIds
        subTotes
        subTotePositions
        pickingStrategy
        humanReadableOrderMapping
        humanReadableOrderIdMapping
      }
      completion
      confirmationList {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      verificationNeeded
      workflow
      orderSubTotes
      lpn
      customer
      warehouse
      items {
        binLocation
        uniqueIdentifier
        productName
        productImage
        sku
        fnSku
        asin
        sellerSku
        upc
        quantity
        pickedQuantity
        scannedBin
        scannedSkus
        skuConfirmationNeeded
        status
        tote
        scannedTote
        bestByDate
        order
        productId
      }
      boxes {
        length
        width
        height
        weight
        name
        formFactor
        sku
      }
      weight
      classification
      username
    }
    }
  }
`;

export const GET_NEW_SINGLE_PICKER_BATCH = `
  query GetNewSinglePickerBatch {
    getNewSinglePickerBatch {
      message
    }
  }
`;

export const GET_CURRENT_SINGLE_PICKER_BATCH = `
  query GetCurrentPickerBatch {
    getCurrentSinglePickerBatch {
      id
      tote
      status
      completion
      attributes {
        dropoffStation
      }
      workingList {
        sku
        fnSku
        asin
        upc
        sellerSku
        binLocation
        productName
        status
        quantity
        pickedQuantity
      }
    }
  }
`;

// PACKER

export const GET_NEW_PACKER_BATCH = `
  query GetNewPackerBatch($customer: ID, $warehouse: ID, $orderId: ID) {
    getNewPackerBatch(
      customer: $customer
      warehouse: $warehouse
      orderId: $orderId
    ) {
      message
    }
  }
`;

export const GET_CURRENT_PACKER_BATCH = `
  query GetCurrentPackerBatch {
    getCurrentPackerBatch {
      customer
      warehouse
      id
      status
      completion
      confirmedBoxContents
      tote
      source
      currentSubTote
      username
      boxes {
        name
        length
        width
        height
        weight
        sku
      }
      attributes {
        orderIds
        humanReadableOrderIds
        humanReadableOrderIdMapping
        humanReadableOrderMapping
        multipleLineItemBatch
        orderSubTotes
        orderLineItemSubTotes
        subTotes
        subTotePositions
        pickingStrategy
      }
      currentItem {
        uniqueIdentifier
        productImage
        sku
        fnSku
        sellerSku
        asin
        upc
        productName
        order
        id
        attributes {
          color
          size
          collection
        }
        boxName
        mainEntity
        availableQuantity
        productId
        order
        customAttributes
        warehouse
        bestByDate
        validScans {
          scannedItem
          productName
          productImage
          sku
          fnSku
          asin
          sellerSku
          upc
          id
        }
        productAlias
      }
      workingList {
        uniqueIdentifier
        quantity
        sku
        sku
        fnSku
        sellerSku
        asin
        upc
        status
        productName
        productId
        bundle
        order
        id
        boxName
        mainEntity
        availableQuantity
        order
        warehouse
        binLocation
        bestByDate
      }
    }
  }
`;

export const GET_FBA_LABEL_GENERATION_STATUS = `
  query GetFbaLabelGenerationStatus($shipmentId: String!) {
    getFbaLabelGenerationStatus(shipmentId: $shipmentId) {
      stageProgress {
        activityName
        status
        humanReadableActivityName
        timestamp
      }
      status
      errorMessage
      timestamp
    }
  }
`;

export const FETCH_SPECIFIC_ORDER_BATCH = `
  query Query($orderBatchId: ID!, $typeOfBatch: String) {
    orderBatch(id: $orderBatchId, typeOfBatch: $typeOfBatch) {
      id
      orderIds
      createdAt
      attributes {
        firstCompletedTime
        lastCompletedTime
        dropoffTime
        orderIds
        humanReadableOrderIds
      }
      completion
      workingList {
        sku
        asin
        quantity
        name
        boxName
        productId
      }
      items {
        sku
        binLocation
        productName
        name
        attributes {
          size
          color
          collection
          pattern
          sizeName
          colorName
          collectionName
          patternName
          height
          weight
          length
          width
        }
        id
        quantity
        status
        availableQuantity
        formFactor
        baseUom
        baseUomQuantity
      }
      status
      assignedTime
      user
      tote
      itemsLength
      quantity
      completedItems
      errorItems
      updatedAt
      formFactor
      baseUom
      baseUomQuantity
      lotId
      palletId
      nestedFormFactor
      nestedFormFactorId
      bestByDate
      source
      boxes {
        length
        width
        height
        weight
        name
        formFactor
        sku
      }
    }
  }
`;

export const GET_SKU_BIN_MAPPINGS = `
  query GetSkuBinMappings(
    $perPage: Int
    $pageNumber: Int
    $filters: SkuBinMappingFilter
    $sort: String
  ) {
    skuBinMappings(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        sku
        fnSku
        binLocation
        id
        warehouses
        customers
        palletId
        quantity
        customer
        warehouse
        allocatedQuantity
        unallocatedQuantity
        product
        createdAt
        updatedAt
        tote
        bestByDate
        lotId
        serialNumber
        poId
        lineItemId
        formFactor
        nestedFormFactor
        nestedFormFactorId
        binLocationDeleted
        marketplace
        sellerId
        canUse
      }
      total
    }
  }
`;

export const GET_BIN_TRANSFER_ELIGIBILITY = `
  query GetBinTransferEligibility($skuBinMappingId: ID!) {
    getBinTransferEligibility(skuBinMappingId: $skuBinMappingId) {
      isEligible
      selectedSkuBinMapping {
        id
        warehouse
        binLocation
      }
      skuBinMappings {
        sku
        binLocation
        id
        quantity
        allocatedQuantity
        unallocatedQuantity
        tote
        palletId
        nestedFormFactorId
        nestedFormFactor
        formFactor
        customer
        warehouse
        lotId
        tote
      }
    }
  }
`;

export const EXPAND_STORAGE_ENTITY = `
  query ExpandStorageEntity(
    $code: String!
    $warehouse: ID!
    $codeType: String!
    $includeEmptyMappings: Boolean
  ) {
    expandStorageEntity(
      code: $code
      warehouse: $warehouse
      codeType: $codeType
      includeEmptyMappings: $includeEmptyMappings
    ) {
      skuBinMappings {
        sku
        binLocation
        id
        quantity
        allocatedQuantity
        unallocatedQuantity
        tote
        palletId
        nestedFormFactorId
        nestedFormFactor
        formFactor
        customer
        warehouse
        lotId
        tote
      }
    }
  }
`;

export const GET_SKU_BIN_MAPPING = `
  query GetSkuBinMapping($id: ID!) {
    skuBinMapping(id: $id) {
      sku
      binLocation
      id
      quantity
      allocatedQuantity
      unallocatedQuantity
    }
  }
`;

export const GET_UOM_BREAKDOWN_DATA = `
  query GetSkuBinMapping($id: ID!) {
    skuBinMapping(id: $id) {
      sku
      binLocation
      id
      quantity
      allocatedQuantity
      unallocatedQuantity
      formFactor
    }
  }
`;

export const GET_ALL_SKU_BIN_MAPPINGS = `
  query GetAllSkuBinMappings(
    $filters: SkuBinMappingFilter
    $sort: String
  ) {
    exportSkuBinMappings(
      filters: $filters
      sort: $sort
    ) {
      entities {
        sku
        binLocation
        id
        warehouses
        customers
        palletId
        quantity
        unallocatedQuantity
        allocatedQuantity
        customer
        warehouse
        lotId
        tote
        createdAt
        bestByDate
        nestedFormFactor
        nestedFormFactorId
      }
      total
    }
  }
`;

// QUALITY CHECKING

export const GET_CURRENT_QUALITY_CHECKING = `
  query GetCurrentQualityChecking {
    getCurrentQualityChecking {
      id
      completion
      workingList {
        id
        sku
        status
        quantity
        name
        binLocation
      }
      currentItem {
        id
        name
        sku
        image
        quantity
        binLocation
      }
    }
  }
`;

export const GET_NEW_QUALITY_CHECKING = `
  query GetNewQualityChecking {
    getNewQualityChecking {
      message
    }
  }
`;

export const GET_PTL_DEVICES = `
  query GetPtlDevices {
    getPtlDevices {
      devices
      message
    }
  }
`;

export const GET_TOTES = `
query Totes($perPage: Int, $pageNumber: Int, $sort: String, $filters: ToteFilter) {
  totes(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
    entities {
      barcode
      id
      toteType
      customers
      warehouses
      attributes
      subTotes
    }
    total
  }
}
`;

export const GET_TOTE = `
  query FetchTote($id: ID!) {
    tote(id: $id) {
      barcode
      toteType
      id
      warehouses
      customers
      attributes
      subTotes
    }
  }
`;

export const GET_BOX_TYPES = `
query BoxTypes($perPage: Int, $pageNumber: Int, $sort: String, $filters: BoxTypeFilter) {
  boxTypes(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
    entities {
      id
      customers
      warehouses
      name
      length
      width
      height
      formFactor
      contents {
        quantity
        formFactor
      }
    }
    total
  }
}
`;

export const GET_BOX_TYPE = `
  query FetchBoxType($id: ID!) {
    boxType(id: $id) {
      name
      id
      warehouses
      customers
      length
      width
      height
      formFactor
      contents {
        quantity
        formFactor
      }
    }
  }
`;

export const GET_BIN_LOCATIONS = `
  query GetBinLocations(
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: BinLocationFilter
  ) {
    binLocations(
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
    ) {
      entities {
        barcode
        binLocationType
        id
        customers
        warehouses
        attributes {
          type
        }
      }
      total
    }
  }
`;

export const GET_BIN_LOCATION = `
  query FetchBinLocation($id: ID!) {
    binLocation(id: $id) {
      barcode
      binLocationType
      id
      customers
      warehouses
      attributes {
        type
      }
    }
  }
`;

export const GET_SORTING_LOCATIONS = `
  query GetSortingLocations {
    sortingLocations {
      barcode
      sortingLocationType
      id
      position
      currentToteBarcode
      currentMainEntityId
      warehouses
      customers
    }
  }
`;

export const GET_SORTING_LOCATION = `
  query FetchSortingLocation($id: ID!) {
    sortingLocation(id: $id) {
      barcode
      sortingLocationType
      id
      position
      warehouses
      customers
    }
  }
`;

// OUTBOUND SORTED

export const GET_SORTINGS = `
  query GetSortings {
    getSortings {
      sku
      status
      originalTote
      attributes {
        color
        pattern
        size
        collection
      }
      id
      order
    }
  }
`;

// INWARD RECEIVING
export const GET_CURRENT_INWARD_ITEM = `
  query GetCurrentInwardItem {
    getCurrentInwardItem {
      name
      sku
      fnSku
      asin
      sellerSku
      upc
      image
      quantity
      id
      bestByDate
      tote
      customer
      trackingNumber
      binLocation
      consignmentId
      attributes
      productId
      formFactor
      consignmentIds
      warehouse
    }
  }
`;

// STANDALONE PUTAWAY

// export const GET_CURRENT_STANDALONE_PUTAWAY_ITEM = `
//   query GetCurrentStandalonePutawayItem {
//     getCurrentStandalonePutawayItem {
//       name
//       sku
//       fnSku
//       asin
//       sellerSku
//       upc
//       binLocation
//       id
//       scannedBin
//       quantity
//     }
//   }
// `;

export const GET_CURRENT_STANDALONE_PUTAWAY_ITEM = `
  query GetCurrentStandalonePutawayItem {
    getCurrentStandalonePutawayItem {
      currentItem {
        tote
        nestedFormFactorId
        palletId
        binLocation
        id
        scannedBin
        receivedItemsContents
        customer
        warehouse
        name
        sku
        quantity
        formFactor
        suggestedBinLocation
        putawayBinLocation
        isStrictPutaway
        productId
        upc
        consignmentId
        receivingBatchId
        scannedItemFromNestedEntity
        totalQuantityAvailableForPutaway
        scannedEntity
        fnSku
      }
      workflow
      relatedReceivingBatches {
        id
        name
        sku
        availableQuantity
        quantity
        formFactor
        tote
        bestByDate
        productId
        fnSku
      }
    }
  }
`;

export const GET_NESTED_ENTITY_ITEMS = `
  query GetNestedEntityItems($batchId: ID!) {
    getNestedEntityItems(batchId: $batchId) {
      sku
      asin
      upc
      ean
      lpn
      fnSku
      sellerSku
      tote
      availableQuantity
      productType
      name
      productName
      formFactor
      bestByDate
      receivingBatchId
    }
  }
`;

export const UPLOAD_ORDERS = `
  query UploadOrders {
    uploadOrders {
      message
    }
  }
`;

export const UPLOAD_PRODUCTS = `
  query UploadProducts {
      uploadProducts {
      message
    }
  }
`;

export const UPLOAD_INVENTORY = `
  query UploadInventory {
    uploadInventory {
      message
    }
  }
`;

export const UPLOAD_SKU_BINS = `
  query UploadSkuBins {
    uploadSkuBins {
      message
    }
  }
`;

// PREPPING / PREPPER

export const GET_NEW_PREPPER_BATCH = `
  query GetNewPrepperBatch($customer: ID, $warehouse: ID) {
    getNewPrepperBatch(customer: $customer, warehouse: $warehouse) {
      message
    }
  }
`;

export const GET_CURRENT_PREPPER_BATCH = `
  query GetCurrentPrepperBatch {
    getCurrentPrepperBatch {
      customer
      warehouse
      id
      status
      completion
      station
      attributes {
        dropoffStation
      }
      currentItem {
        uniqueIdentifier
        productImage
        sku
        fnSku
        sellerSku
        asin
        upc
        productName
        order
        id
        attributes {
          color
          size
          collection
        }
        bestByDate
        validScans {
          scannedItem
          productName
          productImage
          sku
          fnSku
          asin
          sellerSku
          upc
          id
        }
        prepInstructions {
          PrepInstruction
          PrepOwner
        }
        additionalPrepInstructions
        mainEntity
        productId
        productAlias
      }
      workingList {
        uniqueIdentifier
        quantity
        sku
        fnSku
        sellerSku
        asin
        upc
        status
        productName
        bestByDate
        order
        id
        bundle
        bundleId
        mainEntity
        availableQuantity
        productId
      }
    }
  }
`;

export const GET_ENTITY_TYPES = `
  query GetEntityTypes($filters: EntityTypeFilter) {
    entityTypes(filters: $filters) {
      name
      entityParent
      id
      subEntityParents
      code
      attributes
    }
  }
`;

// RULE ENGINE

export const GET_CONFIGURATION = `
  query GetConfiguration($customer: ID, $warehouse: ID) {
    configuration(customer: $customer, warehouse: $warehouse) {
      id
      warehouse
      customer
      outboundVariables
      inboundVariables
    }
  }
`;

// NOTIFICATIONS

export const GET_NOTIFICATIONS = `
  query GetNotifications(
    $perPage: Int
    $pageNumber: Int
    $filters: NotificationFilter
    $sort: String
  ) {
    notifications(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
      sort: $sort
    ) {
      entities {
        typeOfNotification
        mainEntity
        status
        editableFields
        visibleDetails
        requestor
        approvers
        id
        createdAt
        updatedAt
        approvalAction
        rejectionAction
        remarks
        customer
        warehouse
      }
      total
      unreadTotal
    }
  }`;
// BILLING

export const GET_BILLING_PROFILES = `
  query BillingProfiles(
    $all: Boolean
    $pageNumber: Int
    $perPage: Int
    $sort: String
    $filters: BillingProfileFilters
  ) {
    billingProfiles(
      all: $all
      pageNumber: $pageNumber
      perPage: $perPage
      sort: $sort
      filters: $filters
    ) {
      data {
        id
        name
        currency
        metricSystem
        status
        schemaVersion
        createdAt
        updatedAt
        customers {
          id
          name
          code
        }
        tariffs {
          fulfillmentType
          tariffType
          subTariffType
          resetTime
          flatRate
          tiers {
            rate
            ranges {
              id
              start
              end
            }
          }
          uoms {
            id
            uom
            entity
          }
          options {
            endOfPeriodCalculation
          }
        }
      }
      meta {
        total
        perPage
        pageNumber
      }
    }
  }
`;

export const GET_BILLING_PROFILE_FORM_CONFIG = `
query getBillingProfileFormConfig {
  billingProfileFormConfig
}`;

// TODO: remove this query - old billing profile query
export const GET_BILLING_PROFILE = `
  query GetBillingProfiles($id: String) {
    billingProfile(id: $id) {
      id
      createdAt
      updatedAt
      name
      uom
      type
      recurringPayments {
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      inboundPayments {
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      outboundPayments {
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      storagePayments {
        uomList
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      activityPayments {
        uomList
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      manHourPayments {
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      materialPayments {
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      shippingPayments {
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      labelPayments {
        chargeType
        chargeSubType
        markup
        timePeriod
        unitEntity
        unitType
        feeType
        dimensionType
        isPaidByClient
        start
        end
        rate
      }
      currency
    }
  }
`;

export const GET_CUSTOMER_USAGE = `
query Usages($pageNumber: Int, $perPage: Int, $all: Boolean, $filters: UsagesFilter, $sort: String) {
  usages(pageNumber: $pageNumber, perPage: $perPage, all: $all, filters: $filters, sort: $sort) {
    data {
      id
      tenant
      customer {
        code
        id
        name
      }
      profile {
        id
        name
      }
      startDate
      endDate
      charges {
        tariff {
          fulfillmentType
          tariffType
          subTariffType
          resetTime
          flatRate
          uoms {
            id
            uom
            entity
          }
          tiers {
            rate
            ranges {
              id
              start
              end
            }
          }
        }
        summary {
          uom
          entity
          quantity
          charge
          quantities {
            quantity
            charge
          }
        }
        csvKey
      }
      nonBilled {
        fulfillmentType
        tariffType
        subTariffType
        csvKey
      }
      createdAt
      updatedAt
    }
    meta {
      total
      perPage
      pageNumber
    }
  }
}`;

export const GENERATE_CUSTOMER_INVOICE = `
query GenerateCustomerInvoice ($customerId: String, $startDate: String, $endDate: String) {
  generateInvoice (customerId: $customerId, startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
    inbound
    outbound
    storage
    activity
    label
    recurring
    shipping
    manHour
    usage
  }
}`;

export const GET_INVOICES = `
query Invoices($all: Boolean, $filters: InvoiceFilter, $pageNumber: Int, $perPage: Int, $sort: String) {
  invoices(all: $all, filters: $filters, pageNumber: $pageNumber, perPage: $perPage, sort: $sort) {
    data {
      id
      tenant
      customer {
        code
        id
        name
      }
      profile {
        id
        name
      }
      startDate
      endDate
      invoiceNumber
      status
      usage
      generatedLineItems {
        tariff {
          fulfillmentType
          tariffType
          subTariffType
          resetTime
          flatRate
        }
        quantities {
          quantity
          charge
        }
        quantity
        charge
        csvKey
      }
      additionalLineItems {
        description
        quantity
        unitCharge
        charge
      }
      totalCharge
      createdAt
      updatedAt
      deletedAt
    }
    meta {
      total
      perPage
      pageNumber
    }
  }
}
`;

export const GET_INVOICE = `
  query GetInvoice ($id: String) {
    invoice (id: $id) {
    id
    usage {
      startDate
      endDate
      inbound
      outbound
      storage
      label
      recurring
      shipping
      manHour
      activity
    }
    customOutboundCharges {
      chargeType
      chargeCategory
      rate
      numUnits
    }
    customInboundCharges {
      chargeType
      chargeCategory
      rate
      numUnits
    }
    customRecurringCharges {
      chargeType
      chargeCategory
      rate
      numUnits
    }
    customStorageCharges {
      chargeType
      chargeCategory
      rate
      numUnits
    }
    customManHourCharges {
      chargeType
      chargeCategory
      rate
      numUnits
    }
    customMaterialCharges {
      chargeType
      chargeCategory
      rate
      numUnits
    }
    customShippingCharges {
      chargeType
      chargeCategory
      rate
      numUnits
    }
    customLabelCharges {
      chargeType
      chargeCategory
      rate
      numUnits
    }
    customerId
    updatedAt
    createdAt
    startDate
    endDate
    total
    }
  }
`;

// MATERIALS

export const GET_MATERIALS = `
  query Materials(
    $pageNumber: Int
    $perPage: Int
    $all: Boolean
    $filters: MaterialFilters
    $sort: String
  ) {
    materials(
      pageNumber: $pageNumber
      perPage: $perPage
      all: $all
      filters: $filters
      sort: $sort
    ) {
      data {
        id
        name
        createdAt
        updatedAt
        quantity
        weight
        dimensions
        threshold
      }
      meta {
        total
        perPage
        pageNumber
      }
    }
  }
`;

export const GET_NOTIFICATION = `
  query GetNotification($id: ID!) {
    notification(id: $id) {
      typeOfNotification
      mainEntity
      status
      editableFields
      visibleDetails
      requestor
      approvers
      id
      createdAt
      updatedAt
      approvalAction
      rejectionAction
      remarks
      approveText
      rejectText
      message
      rawError
      customer
      warehouse
    }
  }
`;

export const GET_MATERIAL = `
  query Material($id: ID!) {
    material(id: $id) {
      id
      name
      createdAt
      updatedAt
      quantity
      weight
      dimensions
      threshold
    }
  }
`;

// CYCLE COUNT PLANS

export const GET_CYCLE_COUNT_PLANS = `
  query GetCycleCountPlans {
    cycleCountPlans {
      id
      name
      warehouse
      type
      frequency
      startDate
      skus
      customers
      binRanges {
        rows
        sections
        bins
      }
      specificBins
      executions {
        id
        binRanges {
          rows
          sections
          bins
        }
        specificBins
        skus
        createdAt
        status
        stockAdjustments {
          timestamp
          reason
          adjustments {
            sku
            binLocation
            previousQty
            adjustedQty
          }
        }
      }
    }
  }
`;
export const GET_STOCK_TRANSFERS = `
  query StockTransfers {
    stockTransfers {
      id
      createdAt
      updatedAt
      warehouse
      sku
      status
      customer
      productId
      items {
        fromBin
        toBin
        quantity
        personnel
      }
    }
  }
`;

export const GET_CYCLE_COUNT_PLAN = `
  query GetCycleCountPlan($id: ID!) {
    cycleCountPlan(id: $id) {
      id
      name
      warehouse
      type
      frequency
      startDate
      skus
      customers
      binRanges {
        rows
        sections
        bins
      }
      specificBins
      executions {
        id
        binRanges {
          rows
          sections
          bins
        }
        specificBins
        skus
        createdAt
        status
        stockAdjustments {
          timestamp
          reason
          adjustments {
            sku
            binLocation
            previousQty
            adjustedQty
          }
        }
      }
    }
  }
`;
export const GET_STOCK_TRANSFER = `
  query StockTransfer($id: ID!) {
    stockTransfer(id: $id) {
      id
      createdAt
      updatedAt
      warehouse
      sku
      status
      customer
      productId
      items {
        fromBin
        toBin
        quantity
        personnel
      }
    }
  }
`;

export const GET_NEW_CYCLE_COUNTER_BATCH = `
  query GetNewCycleCounterBatch {
    getNewCounterBatch {
      message
    }
  }
`;
export const GET_CURRENT_CYCLE_COUNTER_BATCH = `
  query GetCurrentCycleCounterBatch {
    getCurrentCounterBatch {
      id
      tote
      status
      completion
      attributes {
        dropoffStation
      }
      currentItem {
        binLocation
        skuItems {
          sku
          name
          image
          barcodeScanned
          availableQuantity
          scannedSkus
          lpn
          upc
          fnSku
          customer
          attributes {
            colorName
            patternName
            sizeName
            collectionName
          }
          baseUom
          uom
          identifier
          nestedFormFactorId
        }
        status
      }
      workingList {
        binLocation
        skuItems {
          sku
          name
          image
          scannedSkus
          lpn
          upc
          barcodeScanned
          customer
          fnSku
          availableQuantity
          attributes {
            colorName
            patternName
            sizeName
            collectionName
          }
          baseUom
          uom
          identifier
          nestedFormFactorId
        }
        status
        timestamp
      }
      items {
        binLocation
        skuItems {
          sku
          name
          image
          lpn
          barcodeScanned
          upc
          fnSku
          customer
          availableQuantity
          scannedSkus
          attributes {
            colorName
            patternName
            sizeName
            collectionName
          }
          uom
          baseUom
          identifier
          nestedFormFactorId
        }
        status
      }
      verificationNeeded
      workflow
    }
  }
`;
export const GET_CURRENT_STOCK_TRANSFER_COUNTER_BATCH = `
  query GetCurrentStockTransferCounterBatch {
    getCurrentStockTransferCounterBatch {
      id
      createdAt
      updatedAt
      assignedTime
      completionTime
      assignedUserId
      user
      currentItem {
        fromBin
        toBin
        sku
        quantity
        scannedSkus
        status
        isCompletedPick
      }
      status
      sku
      typeOfBatch
      transferId
      fromBin
      toBin
      quantity
    }
  }
`;

export const GET_PLAN_EXECUTIONS = `
  query GetPlanExecutions($planId: String!, $executionId: String!) {
    planExecutions(planId: $planId, executionId: $executionId) {
      id
      status
      assignedUserId
      executionId
      warehouse
      workingList {
        binLocation
        skuItems {
          sku
          name
          image
          lpn
          upc
          barcodeScanned
          customer
          fnSku
          scannedSkus
          availableQuantity
          attributes {
            colorName
            patternName
            sizeName
            collectionName
          }
          uom
          baseUom
          identifier
          nestedFormFactorId
        }
        status
        timestamp
      }
      items {
        binLocation
        skuItems {
          sku
          name
          image
          lpn
          upc
          barcodeScanned
          fnSku
          customer
          availableQuantity
          scannedSkus
          attributes {
            colorName
            patternName
            sizeName
            collectionName
          }
          uom
          baseUom
          identifier
          nestedFormFactorId
        }
        status
      }
      verificationNeeded
    }
  }
`;
export const GET_NEW_STOCK_TRANSFER_COUNTER_BATCH = `
  query GetNewStockTransferCounterBatch {
    getNewStockTransferCounterBatch {
      message
      url
    }
  }
`;

export const GET_VENDORS = `
  query vendors($perPage: Int, $pageNumber: Int, $sort: String, $filters: VendorFilter) {
    vendors(perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
      id
      name
      code
      emails
      classification
      active
      phoneNumbers
      addresses {
        line1
        line2
        city
        state
        zip
        country
        phone
        email
      }
      createdAt
      updatedAt
    }
    total
  }
 }
`;

export const GET_VENDOR = `
query Vendor($vendorId: ID!) {
  vendor(id: $vendorId) {
    id
    name
    code
    emails
    classification
    active
    phoneNumbers
    addresses {
      line1
      line2
      city
      state
      zip
      country
      phone
      email
    }
  }
}

`;

export const GET_WAREHOUSE_FIELDS = `
query WarehouseFields {
  warehouseFields {
    name
    code
    typeOfWarehouse
    storageTypes
  }
}
`;

export const GET_CUSTOMER_FIELDS = `
query customerFields  {
  customerFields 
   
  
}
`;

export const GET_ORDER_FIELDS = `
query  orderFields{
  orderFields
}
`;

export const GET_NON_EDITABLE_FIELDS_FOR_ORDER = `
query GetNonEditableFieldsOfOrder($orderId: ID!, $userId: ID!) {
  getNonEditableFieldsOfOrder(orderId: $orderId, userId: $userId)
}
`;

export const GET_USER_FIELDS = `
query UserFields {
  userFields
}
`;

export const GET_CONSIGNMENT_FIELDS = `
query ConsignmentFields {
  consignmentFields
}
`;

export const GET_PRODUCT_FIELDS = `
query ProductFields{
  productFields
}
`;

export const GET_SKU_BIN_MAPPING_FIELDS = `
query SkuBinMappingFields {
  skuBinMappingFields
}
`;

export const GET_BIN_LOCATIONS_FIELDS = `
query BinLocationFields{
  binLocationFields
}
`;

export const GET_TOTES_FIELDS = `
query ToteFields {
  toteFields
}
`;

export const GET_STATION_FIELDS = `
query StationFields {
  stationFields
}
`;

export const GET_VENDOR_FIELDS = `
query VendorFields {
  vendorFields
}
`;

export const GET_FBA_SHIPMENTS = `
  query GetFBAShipments(
    $customer: ID!
    $shipmentIds: [String!]!
    $marketplaceCountryCode: String
    $sellerId: String
  ) {
    getFBAShipments(
      customer: $customer
      shipmentIds: $shipmentIds
      marketplaceCountryCode: $marketplaceCountryCode
      sellerId: $sellerId
    ) {
      message
      shipments {
        ShipmentId
        ShipmentName
        DestinationFulfillmentCenterId
        ShipmentStatus
        LabelPrepType
        AreCasesRequired
        ConfirmedNeedByDate
        BoxContentsSource
        EstimatedBoxContentsFee {
          TotalUnits
          FeePerUnit {
            CurrencyCode
            Value
          }
          TotalFee {
            CurrencyCode
            Value
          }
        }
        Items {
          ShipmentId
          SellerSKU
          FulfillmentNetworkSKU
          QuantityShipped
          QuantityReceived
          QuantityInCase
          ReleaseDate
          PrepDetailsList {
            PrepInstruction
            PrepOwner
          }
        }
        ShipFromAddress {
          Name
          AddressLine1
          AddressLine2
          DistrictOrCounty
          City
          StateOrProvinceCode
          CountryCode
          PostalCode
          city
          stateOrProvinceCode
          postalCode
          countryCode
          residential
          streetLines
          classification
          urbanizationCode
          countryName
        }
      }
    }
  }
`;

export const GET_REPORTS = `
query GetReports($perPage: Int, $sort: String, $pageNumber: Int, $filters: GetReportsFilter) {
  getReports(perPage: $perPage, sort: $sort, pageNumber: $pageNumber, filters: $filters) {
    entities {
      id
      status
      reportName
      selectedVariables
      startDate
      endDate
      customers
      warehouses
      module
      csvUrl
      pdfUrl
      previewUrl
      createdAt
      jobId
    }
    total
  }
}
`;

export const GET_REPORTS_METADATA = `
  query ReportsMetadata {
    reportsMetadata {
      modules {
        name
        variables {
          name
          dbName
          type
          default
        }
        dateRangeRequired
      }
    }
  }
`;
export const GET_EXPORT_REPORT = `
  query GetReport($reportId: ID!) {
    getReport(reportId: $reportId) {
      reportName
      id
      status
      csvUrl
    }
  }
`;

export const GET_REPORT = `
  query GetReport($reportId: ID!) {
    getReport(reportId: $reportId) {
      id
      status
      reportName
      csvUrl
      previewUrl
    }
  }
`;

export const GET_PRESIGNED_URL_FOR_S3_BUCKET_CONTAINING_PRODUCT_IMAGES = `
query GetPresignedUrlForS3BucketContainingProductImages($key: String!, $method: String!) {
  getPresignedUrlForS3BucketContainingProductImages(key: $key, method: $method) {
    message
    url
  }
}
`;

export const CHECK_IF_ORDER_WAREHOUSE_CAN_BE_CHANGED = `
query CheckIfOrderWarehouseCanBeChanged($orderId: ID!) {
  checkIfOrderWarehouseCanBeChanged(orderId: $orderId)
}
`;

export const GET_INTEGRATION_PROVIDERS = `
query IntegrationProviders {
  integrationProviders {
    classification
    description
    integrationType
    logo
    scope
    marketplaces {
      countryCode
      id
      name
      region
    }
    multipleAccountSupport
    name
    regions {
      code
      name
    }
    tags
  }
}
`;

export const GET_SHIPMENT_RATES = `
  query GetShipmentRates(
    $warehouse: ID!
    $toAddress: RateShoppingAddressInput!
    $boxes: [RateShoppingBoxInput!]!
    $customer: ID!
    $orderSource: String
    $transportMode: String
    $shipmentReference: String
    $pallets: [RateShoppingPalletInput!]!
    $validateAddress: Boolean
    $incoterms:String
  ) {
    getShipmentRates(
      warehouse: $warehouse
      toAddress: $toAddress
      boxes: $boxes
      customer: $customer
      orderSource: $orderSource
      transportMode: $transportMode
      shipmentReference: $shipmentReference
      pallets: $pallets
      validateAddress: $validateAddress
      incoterms: $incoterms
    ) {
      rates {
        id
        type
        carrier
        source
        service
        price
        currency
        estimated_delivery_date
        estimated_delivery_days
      }
      errors {
        source
        errors
        errorCode
        message
      }
    }
  }
`;

export const GET_SHIPPERS = `
  query GetShippers(
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: ShipperFilter
    $all: Boolean
  ) {
    shippers(
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
      all: $all
    ) {
      entities {
        name
        id
        warehouses
        currentBin
        currentBarcode
      }
      total
    }
  }
`;

export const GET_SHIPPER = `
  query FetchShipper($id: ID!) {
    shipper(id: $id) {
      name
      id
      warehouses
    }
  }
`;

export const GET_CURRENT_TRUCK = `
  query GetCurrentTruck($filters: TruckFilter) {
    getCurrentTruck(filters: $filters) {
      name
      barcode
      truckType
      truckIndicator
    }
  }
`;

export const GET_BATCH_SETTING = `
  query GetBatchSetting {
    getBatchSetting {
      id
      pickingStrategy
      minAutoBatchSize
      maxOrdersPerBatchForSingleLineItems
      maxOrdersPerBatchForMultipleLineItems
      rowIncrements
      numberOfAdjacentRows
    }
  }
`;

export const GET_ORDER_CHANGES_PREVIEW = `
  query GetOrderChangesPreview($getOrderChangesPreviewId: ID!, $operationType: String!) {
    getOrderChangesPreview(id: $getOrderChangesPreviewId, operationType: $operationType) {
      batches {
        id
        type
        status
        user
        userName
      }
      skuAudits {
        id
        typeOfTransaction
        amount
        binLocation
        customer
        warehouse
        sku
      }
    }
  }
`;

export const GET_LOCATION_BY_ID = `
  query GetLocationById($locationId: ID!) {
  getLocationById(id: $locationId) {
    error
    data {
      id
      code
      name
      tenant
      warehouse
      isDeleted
      inactiveReason {
        inactiveType
        referenceId
        reason
      }
      type
      typeName
      storageType
      parentLocation
      reservations
      reservationsDetail {
        id
        strategy
        customers {
          id
          name
          code
        }
        products {
          id
          name
          sku 
        }
      }
      activityLocks {
        activityId
        type
        products
        startTimestamp
        endTimestamp
      }
      v
      createdAt
      updatedAt
    }
  }
}
`;

export const SEARCH_LOCATIONS = `
  query SearchLocations(
    $warehouses: [ID]
    $name: String
    $code: String
    $parentLocation: ID
    $storageSection: ID
    $topLevelLocation: Boolean
    $type: ID
    $pagination: PaginationConfiguration
    $onlyUnreservedLocations: Boolean
  ) {
    searchLocations(
      warehouses: $warehouses
      name: $name
      code: $code
      parentLocation: $parentLocation
      storageSection: $storageSection
      topLevelLocation: $topLevelLocation
      type: $type
      pagination: $pagination
      onlyUnreservedLocations: $onlyUnreservedLocations
    ) {
      error
      data {
        locations {
          id
          code
          name
          tenant
          warehouse
          isDeleted
          inactiveReason {
            inactiveType
            referenceId
            reason
          }
          activationStatus
          isVirtualLocation
          isSystemLocation
          type
          typeName
          storageType
          parentLocation
          storageSection
          topLevelLocation
          reservations
          reservationsDetail {
            id
            strategy
            customers {
              id
              name
              code
            }
            products {
              id
              name
              sku 
            }
          }
          activityLocks {
            activityId
            type
            products
            startTimestamp
            endTimestamp
          }
          v
          createdAt
          updatedAt
          capacity {
            amount
            unit
          }
          hierarchicalPath
        }
        total
      }
    }
  }
`;

export const getLocationAuditLogs = `
query GetLocationAuditLogs($getLocationAuditLogsId: ID!) {
  getLocationAuditLogs(id: $getLocationAuditLogsId) {
    error
    data {
      locationId
      eventName
      userId
      username
      tenant
      notes
      previousLocation {
        id
        code
        name
        tenant
        warehouse
        isDeleted
        inactiveReason {
          inactiveType
          referenceId
          reason
        }
        type
        storageType
        parentLocation
        reservations
        reservationsDetail {
          id
          strategy
          customers {
            id
            name
            code
          }
          products {
            id
            name
            sku 
          }
        }
        activityLocks {
          activityId
          type
          products
          startTimestamp
          endTimestamp
        }
        v
        createdAt
        updatedAt
      }
      createdAt
    }
  }
}
`;

export const GET_LOCATION_TYPE = `
  query GetLocationType($getLocationTypeId: ID!) {
  getLocationType(id: $getLocationTypeId) {
    data {
      id
      name
      code
      tenant
      isDeleted
      createdAt
      updatedAt
      v
    }
    error
  }
}
`;

export const SEARCH_LOCATION_TYPES = `
query SearchLocationTypes($name: String, $code: String, $pagination: PaginationConfiguration) {
  searchLocationTypes(name: $name, code: $code, pagination: $pagination) {
    error
    data {
      id
      name
      code
      tenant
      isDeleted
      createdAt
      updatedAt
      codeType
      category
      threeDimRange
      v
    }
  }
}
`;
export const GET_SHOPIFY_LOCATIONS = `
query GetShopifyLocations($integrationId: ID, $shopifyStoreDomain: String, $shopifyAccessToken: String) {
  getShopifyLocations(integrationId: $integrationId, shopifyStoreDomain: $shopifyStoreDomain, shopifyAccessToken: $shopifyAccessToken) {
    activeLocations {
      name
      locationId
    }
    enabledLocation {
      name
      locationId
    }
  }
}`;

export const GET_SHIPSTATION_CARRIERS = `
query GetShipstationCarriers($shipstationApiKey: String, $shipstationApiSecret: String) {
  getShipstationCarriers(shipstationApiKey: $shipstationApiKey, shipstationApiSecret: $shipstationApiSecret) {
    name
    code
  }
}`;

export const GET_DELMAR_PARCEL_STATUSES = `
  query GetDelmarParcelStatuses(
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: DelmarParcelStatusFilter
  ) {
    delmarParcelStatuses(
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
    ) {
      entities
      total
    }
  }
`;

export const GET_CONSOLIDATED_OUTBOUND_BATCHES = `
  query getConsolidatedOutboundBatches(
    $perPage: Int
    $pageNumber: Int
    $sort: String
    $filters: AllBatchFilter
  ) {
    getConsolidatedOutboundBatches(
      perPage: $perPage
      pageNumber: $pageNumber
      sort: $sort
      filters: $filters
    ) {
      entities {
        id
        warehouse
        customer
        status
        createdAt
        assignedTime
        user
        username
        tote
        station
        attributes {
          firstCompletedTime
          lastCompletedTime
          orderIds
          humanReadableOrderIds
          humanReadableOrderIdMapping
          humanReadableOrderMapping
          requestedDropoffPackingStation
          dropoffStation
          multipleLineItemBatch
        }
        completion
        workingList {
          sku
        }
        items {
          sku
          asin
          binLocation
          productName
          quantity
          status
          id
          order
          orderId
          availableQuantity
          attributes {
            color
            collection
            size
          }
          name
        }
        itemsLength
        numberOfUniqueProducts
        quantity
        formFactor
        baseUom
        baseUomQuantity
        classification
        rows
        remarks
        linkedBatches
      }
      total
    }
  }
`;

export const SEARCH_STORAGE_SECTIONS = `
  query SearchStorageSections($name: String) {
    searchStorageSections(name: $name) {
      error
      data {
        storageSections {
          name
          code
          id
          storageType
          hierarchy
          status
          tenant
          warehouse
          isDeleted
          sectionInactiveReason
        }
        total
      }
    }
  }
`;

export const GET_LINKED_PRODUCTS = `query GetLinkedProducts($id: ID!) {
  getLinkedProducts(id: $id) {
    sku
    image
    name
    asin
    fnSku
    sellerSku
    upc
    id
    customer
    warehouse
    source
    partOfBundle
    lpn
    ean
    attributes
    pricing {
      standardPrice
      shippingPrice
      dutyPrice
      labourCost
      computedPriceFields {
        cost
        totalPrice
        costPrice
        costPriceUSD
        srpPriceCA
        dutyRate
        visualisedCostPrice
        lastLabourCost
        maxLabourCost
      }
    }
    materials {
      sku
      name
      category
      active
      customer
      warehouse
      tenant
      quantity
      source
      typeOfProduct
      pricing {
        cost
        totalPrice
        costPrice
        costPriceUSD
        srpPriceCA
        dutyRate
        visualisedCostPrice
        lastLabourCost
        maxLabourCost
      }
      attributes
    }
    active
    baseUom
    uomConfiguration {
      baseUom
      baseUomQuantity
      targetUom
      isActive
    }
    marketplaceAttributes {
      marketplace
      attributes
    }
    type
    category
    description
    images {
      display_url
      url
      purpose
      source
      variant
    }
    typeOfProduct
    products {
      id
      quantity
      name
      asin
      fnSku
      sku
      uom
    }
    listingStatus
    uniqueIdentifier
    prepInstructions {
      PrepInstruction
      PrepOwner
    }
    additionalPrepInstructions
  }
}`;

/**
 * Master Data: UoM Labels (Start)
 */

export const GET_UOM_LABELS = `
  query GetUomLabels($includeInactives: Boolean) {
    getUomLabels(includeInactives: $includeInactives) {
      labels {
        name
        isActive
        createdAt
      }
      totalLabels
    }
  }
`;

/**
 * Master Data: UoM Labels (End)
 */
export const GET_PRODUCT_ALIASES = `
query GetProductAliases($id: ID!) {
  getProductAliases(id: $id) {
    id
    alias
    entityId
    entityName
    productId
  }
}`;

export const CRM_CUSTOMERS = `
  query CrmCustomers ($perPage: Int, $pageNumber: Int, $sort: String, $filters: CrmCustomerFilter) {
    crmCustomers (perPage: $perPage, pageNumber: $pageNumber, sort: $sort, filters: $filters) {
      entities {
        id
        businessName
        firstName
        lastName
        email
        contactNumber
        notes
        hrid
        isVIP
        dataSharing
        paymentTerms
        taxExemption
        taxExemptCertificateExpiryDate
        notesDateTimestamp
        catalogMetadata {
          catalogRequestedVE
          catalogSuppressionVE
          lastCatalogSentDateVE
          catalogRequestedJS
          catalogSuppressionJS
          lastCatalogSentDateJS
          catalogRequestedKGS
          catalogSuppressionKGS
          lastCatalogSentDateKGS
        }
        accountAddress {
          id
          firstName
          lastName
          line1
          line2
          city
          zip
          state
          country
          contactNumber
          email
          alternateContactNumbers
          alternateEmails
          isPlantingZoneAddress
          plantingZoneCode
          isDefault
        }
        shippingAddresses {
          id
          firstName
          lastName
          line1
          line2
          city
          zip
          state
          country
          contactNumber
          email
          alternateContactNumbers
          alternateEmails
          isPlantingZoneAddress
          plantingZoneCode
          isDefault
        }
      }
      total
    }
  }
`;

export const CREATE_AVALARA_TAX_INVOICE = `
  query CreateAvalaraTaxInvoice(
    $customer: ID!
    $warehouse: ID!
    $shippingAddress: CrmAddressInput!
    $orderLineItems: [OrderLineItemInput!]
    $test: Boolean
  ) {
    createAvalaraTaxInvoice(
      customer: $customer
      warehouse: $warehouse
      shippingAddress: $shippingAddress
      orderLineItems: $orderLineItems
      test: $test
    ) {
      totalTaxable
      totalTaxCalculated
    }
  }
`;

export const VALIDATE_PRODUCT_FOR_HARDINESS_ZONE = `
  query ValidateProductForHardinessZone(
    $validateProductForHardinessZoneInput: ValidateProductForHardinessZoneInput
  ) {
    validateProductForHardinessZone(
      validateProductForHardinessZoneInput: $validateProductForHardinessZoneInput
    ) {
      isShippingAllowedToAddress
      errorMessage
      hardinessWarningMessage
    }
  }
`;

export const CHECK_PAYMENT_GATEWAY_CUSTOMER_EXISTS = `
query CheckPaymentGatewayCustomerExists($crmCustomerId: String!) {
  checkPaymentGatewayCustomerExists(crmCustomerId: $crmCustomerId) {
    message
    data {
      isCustomerExists
      crmCustomerId
      paymentGatewayCustomerId
    }
  }
}
`;

export const GET_PAYMENT_GATEWAY_CUSTOMER_DETAILS = `
  query GetPaymentGatewayCustomerDetails(
    $provider: String, $payload: getStripeCustomerDetailsInput
  ) {
    getPaymentGatewayCustomerDetails(
      provider: $provider, payload: $payload
    ) {
      id
      email
      fullName
      phone
      address {
        line1
        line2
        city
        state
        country
        postalCode
      }
    }
  }
`;

export const GET_PAYMENT_GATEWAY_PAYMENT_METHODS = `
  query GetPaymentGatewayCustomerPaymentMethods($provider: String, $payload: getStripeCustomerDetailsInput) {
    getPaymentGatewayCustomerPaymentMethods(provider: $provider, payload: $payload) {
      customerId
      paymentMethods {
        id
        type
        billingDetails {
          email
          name
          phone
          address {
            line1
            line2
            city
            state
            country
            postalCode
          }
        }
        card {
          brand
          last4
          expiryMonth
          expiryYear
          country
          fingerprint
          funding
        }
        bankAccount {
          accountHolderType
          accountType
          bankName
          financialConnectionsAccount
          fingerprint
          last4
          routingNumber
          networks {
            preferred
            supported
          }
          statusDetails
        }
        default
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PRODUCT_SALES_PRICING = `
query GetProductSalesPricing($filters: ProductSalesPricingFilter, $perPage: Int, $pageNumber: Int) {
  getProductSalesPricing(filters: $filters, perPage: $perPage, pageNumber: $pageNumber) {
    entities {
      tenant
      sku
      salesChannel
      isGiftBoxProduct
      prices {
        units
        bulbs
        each
        price
        currency
      }
    }
    total
  }
}
`;

export const GET_SALES_ORDER = `
  query GetSalesOrders(
    $perPage: Int
    $pageNumber: Int
    $filters: SalesOrdersFilters
  ) {
    getSalesOrders(
      perPage: $perPage
      pageNumber: $pageNumber
      filters: $filters
    ) {
      entities {
        id
        tenant
        customer
        warehouse
        salesOrderId
        salesChannel
        orderLineItems {
          productId
          quantity
          formFactor
          sku
          price
          currency
          name
          attributes
          units
        }
        customerIdOnCrm
        shippingAddress {
          id
          firstName
          lastName
          line1
          line2
          city
          zip
          state
          country
          contactNumber
          email
          alternateContactNumbers
          alternateEmails
          isPlantingZoneAddress
          plantingZoneCode
          isDefault
        }
        orderType
        source
        paymentDetails {
          paymentProvider
          paymentMethodDetails
          paymentStatus
          billingDetails {
            email
            name
            phone
            address {
              line1
              line2
              city
              state
              country
              postalCode
            }
          }
        }
        cartDetails {
          currency
          subTotal
          shippingCharges
          salesTax
          discount
          orderValue
        }
        attributes {
          isGiftOrder
          giftMessage
          isFundRaiserOrder
        }
        userId
        createdAt
      }
      total
    }
  }
`;

export const GET_CRM_SALES_ORDERS = `
  query GetCrmSalesOrders(
    $perPage: Int
    $pageNumber: Int
    $sortBy: String
    $searchParams: CrmSoSearchParamsInput
  ) {
    getCrmSalesOrders(
      perPage: $perPage
      pageNumber: $pageNumber
      sortBy: $sortBy
      searchParams: $searchParams
    ) {
      zohoSalesOrders{
        id
        Status
        Grand_Total
        Created_Time
        Customer_Id
        Quantity
        Company_Name
      }
    }
  }
`;

export const GET_BATCHING_ATTEMPTS = `
  query getBatchingAttempts($ids: [ID!]) {
    getBatchingAttempts(ids: $ids)
  }
`;

export const GET_SCHEDULE_REPORTS = `
  query Jobs($perPage: Int, $sort: String, $pageNumber: Int, $filters: JobsFilter) {
  jobs(perPage: $perPage, sort: $sort, pageNumber: $pageNumber, filters: $filters) {
    entities {
      id
      status
      selectedVariables
      scheduleStartDate
      scheduleStartTime
      customers
      warehouses
      module
      frequency
      lastRun
      nextRun
      cron
      recipients{
        name
        email
      }
      createdAt
      scheduleRunDate
      localCron
    }
    total
  }
}
`;

export const GET_CUSTOMER_DETAILS_AUDIT_LOGS = `
    query GetCustomerDetailsAudit($page: Int!, $limit: Int!) {
      getCustomerDetailsAudit(page: $page, limit: $limit) {
        userId
        fromValue
        toValue
        activityType
        crmCustomerId
        createdAt
      }
    }
`;

export const GET_SO_PDF_DOWNLOAD_HISTORY = `
  query GetCrmSalesOrderPdfDownloadHistory($perPage: Int!, $pageNumber: Int!) {
      getCrmSalesOrderPdfDownloadHistory(perPage: $perPage, pageNumber: $pageNumber) {
        crmCustomerId
        createdAt
        crmSalesOrderId
        user
    }
}`;

export const SEARCH_RESERVATIONS = `
query SearchReservations($locationId: ID!, $strategy: String, $pagination: PaginationConfiguration) {
  searchReservations(locationId: $locationId, strategy: $strategy, pagination: $pagination) {
    data {
      reservations {
        id
        locationId
        locationCode
        strategy
        customers {
          id
          name
          code
        }
        products {
          id
          name
          sku
        }
        createdAt
        updatedAt
      }
      total
    }
    error
  }
}
`;

export const DOWNLOAD_PRESIGNED_URL_FILES = `
  query GetS3PresignedUrl($key: String!, $method: String!, $expirationInSeconds: Int) {
  getS3PresignedUrl(key: $key, method: $method, expirationInSeconds: $expirationInSeconds) {
    url
    message
  }
}
`;

export const SEARCH_ORDERS = `
  query SearchOrders(
    $keyword: String
    $field: String
    $filters: JSON
    $nextCursor: String
  ) {
    searchOrders(
      keyword: $keyword
      field: $field
      filters: $filters
      nextCursor: $nextCursor
    ) {
      total
      nextCursor
      entities {
        score
        searchHighlights
        childOrders
        id
        orderData
      }
      prevCursor
      count
    }
  }
`;

export const GET_SEARCH_FILTERS = `
  query GetSearchFilters {
    getSearchFilters {
      label
      displayLabel
      data
      typeOfField
    }
  }
`;

export const SEARCH_CONSIGNMENTS = `
  query SearchConsignments(
    $keyword: String
    $field: String
    $filters: JSON
    $nextCursor: String
  ) {
    searchConsignments(
      keyword: $keyword
      field: $field
      filters: $filters
      nextCursor: $nextCursor
    ) {
      total
      nextCursor
      entities {
        score
        searchHighlights
        id
        consignmentData
      }
      prevCursor
      count
    }
  }
`;

export const GET_CONSIGNMENT_SEARCH_FILTERS = `
  query GetConsignmentSearchFilters {
    getConsignmentSearchFilters {
      label
      displayLabel
      data
      typeOfField
    }
  }
`;

export const SEARCH_PRODUCTS = `
  query SearchProducts(
    $keyword: String
    $field: String
    $filters: JSON
    $nextCursor: String
  ) {
    searchProducts(
      keyword: $keyword
      field: $field
      filters: $filters
      nextCursor: $nextCursor
    ) {
      total
      nextCursor
      entities {
        score
        searchHighlights
        id
        productData
      }
      prevCursor
      count
    }
  }
`;

export const GET_PRODUCT_SEARCH_FILTERS = `
  query GetProductSearchFilters {
    getProductSearchFilters {
      label
      displayLabel
      data
      typeOfField
    }
  }
`;

export const GET_PRODUCT_FIELDS_V2 = `
  query ProductFieldsV2 {
    productFieldsV2
  }
`;

export const GET_CONSIGNMENT_FIELDS_V2 = `
query ConsignmentFieldsV2{
  consignmentFieldsV2
}
`;

export const GET_ORDER_FIELDS_V2 = `
query OrderFieldsV2{
  orderFieldsV2
}
`;

export const GET_BUNDLE_FIELDS_V2 = `
  query BundleFieldsV2 {
    bundleFieldsV2
  }
`;

export const LIST_INBOUND_PLANS = `
query ListInboundPlans($listInboundPlansInput: ListInboundPlansInput) {
  listInboundPlans(listInboundPlansInput: $listInboundPlansInput) {
    entities {
      id
      inboundPlanId
      orders
      status
      shipFromAddress {
        name
        email
        phone
        zip
        state
        city
        country
        line1
        line2
      }
      marketplace
      tenant
      customer
      warehouse
      isDeleted
      items {
        productId
        sku
        asin
        fnsku
        quantity
        price
        lotId
        uniqueIdentifier
        ShipmentId
        SellerSKU
        FulfillmentNetworkSKU
        QuantityShipped
        QuantityReceived
        QuantityInCase
        ReleaseDate
        expiryDate
        images
        PrepDetailsList {
          PrepInstruction
          PrepOwner
        }
      }
      createdAt
      updatedAt
      sellerId
      inboundPlanDetails {
        amazonInboundPlanId
        packingOptions {
          packingOptionId
          status
          discounts {
            description
            target
            type
            value {
              amount
              code
            }
          }
          packingGroups
        }
        placementOptions {
          placementOptionId
          status
          expiration
          shipmentIds
          fees {
            description
            target
            type
            value {
              amount
              code
            }
          }
        }
        packageGroupings {
          boxes {
            contentInformationSource
            dimensions {
              height
              length
              unitOfMeasurement
              width
            }
            quantity
            weight {
              unit
              value
            }
            items {
              id
              name
              asin
              msku
              quantity
              labelOwner
              prepOwner
              image
            }
          }
          packingGroupId
          shipmentId
        }
        transportationOptions {
          transportOptionId
          shipmentId
          shippingSolution
          carrier {
            alphaCode
            name
          }
          carrierAppointment {
            startTime
            endTime
          }
          preconditions
          qoute {
            cost {
              amount
              code
            }
          }
        }
        deliveryWindowOptions {
          deliveryWindowOptionId
          startDate
          endDate
          availabilityType
          validUntil
        }
        selectedTransportationOption {
          transportationOptionId
          shipmentId
          shippingSolution
          preconditions
        }
        selectedPackingOption {
          packingOptionId
          status
          packingGroups
        }
        selectedDeliveryWindowOption {
          deliveryWindowOptionId
          startDate
          endDate
          availabilityType
          validUntil
        }
        selectedPlacementOption {
          placementOptionId
          status
          expiration
          shipmentIds
        }
      }
    }
    metaData {
      total
      perPage
      pageNumber
    }
  }
}
`;

export const DELETE_INBOUND_PLAN = `query DeleteInboundPlan($id: ID) {
  deleteInboundPlan(id: $id) {
    message
  }
}`;

export const GET_ELIGIBILITY_CHECK_RESULTS = `query GetEligibilityResults($inboundId: String!) {
  getEligibilityResults(inboundId: $inboundId) {
    itemsEligibilityAndRestrictionDetails {
      asin
      errors {
        code
        details
        message
        severity
      }
      isEligible
      isRestricted
      msku
      id
      name
      quantity
      expiryDate
    }
    percentComplete
    status
    errors {
      code
      details
      message
      severity
    }
  }
}
`;

export const GET_ERRORS_IN_INBOUND_PLAN = `query GetErrors($inboundId: String!) {
  getErrors(inboundId: $inboundId) {
    errors {
      code
      details
      message
      severity
    }
  }
}`;

export const GET_PACKING_OPTIONS = `query GetPackingOptions($inboundId: String!) {
  getPackingOptions(inboundId: $inboundId) {
    packingOptionsWithGroupItems {
      packingOptionId
      packingGroups
      expiration
      discounts {
        type
        target
        description
        value {
          code
          amount
        }
      }
      fees {
        description
        target
        type
        value {
          amount
          code
        }
      }
      packingGroupsWithGroupItems {
        items {
          asin
          fnsku
          labelOwner
          msku
          quantity
          image
          prepInstructions {
            prepType
            prepOwner
          }
        }
        packingGroupId
      }
      status
      supportedShippingConfigurations {
        shippingMode
        shippingSolution
      }
    }
    errors {
      severity
      message
      details
      code
    }
  }
}`;

export const GET_PLACEMENTS_OPTIONS = `
  query GetPlacementOptions($inboundId: String!) {
    getPlacementOptions(inboundId: $inboundId) {
      placementOptionsWithShipmentBoxesAndItems {
        expiration
        discounts {
          description
          target
          type
          value {
            amount
            code
          }
        }
        fees {
          description
          target
          type
          value {
            amount
            code
          }
        }
        placementOptionId
        shipmentIds
        status
        shipmentsWithBoxesAndItems {
          shipmentId
          destination {
            destinationType
            address {
              addressLine1
              addressLine2
              city
              companyName
              countryCode
              email
              name
              phoneNumber
              postalCode
              stateOrProvinceCode
            }
          }
          placementOptionId
          status
          boxes {
            contentInformationSource
            dimensions {
              height
              length
              unitOfMeasurement
              width
            }
            quantity
            weight {
              unit
              value
            }
            items {
              id
              name
              asin
              msku
              quantity
              labelOwner
              prepOwner
              image
            }
          }
          items {
            id
            name
            asin
            msku
            quantity
            labelOwner
            prepOwner
            image
          }
        }
      }
    }
  }
`;

export const GET_TRANSPORTATION_OPTIONS = `query GetTransportationOptions($inboundId: String!) {
  getTransportationOptions(inboundId: $inboundId) {
    errors {
      code
      details
      message
      severity
    }
    transportationOptionsPerShipment {
      shipmentId
      transportationOptions {
        transportOptionId
        shipmentId
        shippingSolution
        carrier {
          alphaCode
          name
        }
        carrierAppointment {
          startTime
          endTime
        }
        preconditions
        quote {
          cost {
            amount
            code
          }
          expiration
          voidableUntil
        }
        shippingMode
        transportationOptionId
      }
    }
  }
}`;

export const GET_DELIVERY_WINDOW_OPTIONS = `query GetDeliveryWindowOptions($inboundId: String!) {
  getDeliveryWindowOptions(inboundId: $inboundId) {
    errors {
      code
      details
      message
      severity
    }
    deliveryWindowOptionsPerShipment {
      shipmentId
      deliveryWindowOptions {
        deliveryWindowOptionId
        startDate
        endDate
        availabilityType
        validUntil
      }
    }
  }
}
`;

export const LIST_SHIPMENTS = `query ListShipments($input: ListShipmentsInput!) {
  listShipments(input: $input) {
    entities {
      shipmentId
      customer
      warehouse
      tenant
      status
      boxLabels
      shippingLabel
      carrier
      boxes {
        contentInformationSource
        quantity
        boxId
      }
      trackingNumbers {
        boxId
        trackingId
      }
    }
  }
}`;

export const START_FBA_INBOUND_SHIPMENT_PLAN = `mutation StartFbaInboundShipmentPlan($input: StartFbaInboundShipmentPlanInput!) {
  startFbaInboundShipmentPlan(input: $input) {
    workflowId
    errors {
      code
      details
      message
      severity
    }
  }
}`;
