import { useContext } from "react";
import Dropdown from "#components/utils/Dropdown";
import TextField from "#components/utils/TextField";
import { useState } from "react";
import { AppStateContext } from "#contexts/appState";
import {
  useDimensionUnitAbbreviation,
  useWeightUnitAbbreviation,
} from "../../utils/Metrics";

const SelectBoxType = ({
  boxTypes,
  overflow,
  weight,
  submitBoxes,
  addBox,
  setAddBox,
  setBoxTypes,
  setWeight,
  order,
  picker,
  restartActivity,
  pauseActivity,
}) => {
  const appState = useContext(AppStateContext);
  const [selectedBoxType, setSelectedBoxType] = useState(null);
  const preferredDimensionUnit = useDimensionUnitAbbreviation();
  const initialState = {
    name: picker
      ? `Pallet ${
          boxTypes.filter((item) => item.name.indexOf("Pallet ") !== -1)
            .length + 1
        }`
      : `Custom ${
          boxTypes.filter((item) => item.name.indexOf("Custom ") !== -1)
            .length + 1
        }`,
  };
  const [newBox, setNewBox] = useState(initialState);

  const submitCustomBox = () => {
    if (!newBox) {
      appState.setAlert(`Please provide all details`, "error", 5000);
      return;
    }
    // if (!newBox.name) {
    //   appState.setAlert(`Please provide a name`, "error", 5000);
    //   return;
    // }
    if (!newBox.height) {
      appState.setAlert(`Please provide a height`, "error", 5000);
      return;
    }
    if (!newBox.width) {
      appState.setAlert(`Please provide a width`, "error", 5000);
      return;
    }
    if (!newBox.length) {
      appState.setAlert(`Please provide a length`, "error", 5000);
      return;
    }
    // if (
    //   order.typeOfShipment === "LTL" &&
    //   !newBox.objectWeight &&
    //   (isNaN(newBox.objectWeight) || parseFloat(newBox.objectWeight) <= 0)
    // ) {
    //   appState.setAlert(`Please provide a valid weight`, "error", 5000);
    //   return;
    // }
    setBoxTypes([...boxTypes, { ...newBox }]);
    setSelectedBoxType(newBox.name);
  };

  if (selectedBoxType === "Add Custom") {
    return (
      <div className="-mt-20 flex h-screen w-full items-center justify-center">
        <div className="flex-col">
          <div className="rounded-2xl border bg-white shadow-lg">
            <div className="flex-col items-center justify-center space-y-2 p-10 pb-5 pt-5">
              <TextField
                type="number"
                id="length"
                label={`Length(${preferredDimensionUnit})`}
                placeholder=" "
                value={newBox.length}
                onChange={(e) =>
                  setNewBox({
                    ...newBox,
                    length: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
              <TextField
                type="number"
                id="width"
                label={`Width(${preferredDimensionUnit})`}
                placeholder=" "
                value={newBox.width}
                onChange={(e) =>
                  setNewBox({
                    ...newBox,
                    width: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
              <TextField
                type="number"
                id="height"
                label={`Height(${preferredDimensionUnit})`}
                placeholder=" "
                value={newBox.height}
                onChange={(e) =>
                  setNewBox({
                    ...newBox,
                    height: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
              <div className="flex space-x-2">
                <div
                  className={`mt-4 flex-1 rounded-md bg-red-400 py-2 text-center text-xl text-white`}
                  onClick={() => {
                    setNewBox(initialState);
                    setSelectedBoxType(null);
                  }}>
                  Cancel
                </div>
                <div
                  className={`mt-4 flex-1 rounded-md py-2 text-center text-xl text-white ${
                    !!selectedBoxType ? "bg-2C7695" : "bg-gray-400"
                  }`}
                  onClick={submitCustomBox}>
                  Submit
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (selectedBoxType === "Add Pallet") {
    return (
      <div className="-mt-20 flex h-screen w-full items-center justify-center">
        <div className="flex-col">
          <div className="rounded-2xl border bg-white shadow-lg">
            <div className="flex-col items-center justify-center space-y-2 p-10 pb-5 pt-5">
              <TextField
                type="number"
                id="length"
                label="Length"
                placeholder=" "
                value={newBox.length}
                onChange={(e) =>
                  setNewBox({
                    ...newBox,
                    length: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
              <TextField
                type="number"
                id="width"
                label="Width"
                placeholder=" "
                value={newBox.width}
                onChange={(e) =>
                  setNewBox({
                    ...newBox,
                    width: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
              <TextField
                type="number"
                id="height"
                label="Height"
                placeholder=" "
                value={newBox.height}
                onChange={(e) =>
                  setNewBox({
                    ...newBox,
                    height: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />

              {!picker && (
                <TextField
                  type="number"
                  id="objectWeight"
                  label="Weight of Pallet"
                  placeholder=" "
                  value={newBox.objectWeight}
                  onChange={(e) =>
                    setNewBox({
                      ...newBox,
                      objectWeight: parseFloat(e.target.value),
                    })
                  }
                  min="0"
                  step="0.01"
                />
              )}

              <div className="flex space-x-2">
                <div
                  className={`mt-4 flex-1 rounded-md bg-red-400 py-2 text-center text-xl text-white`}
                  onClick={() => {
                    setNewBox({});
                    setSelectedBoxType(null);
                  }}>
                  Cancel
                </div>
                <div
                  className={`mt-4 flex-1 rounded-md py-2 text-center text-xl text-white ${
                    !!selectedBoxType ? "bg-2C7695" : "bg-gray-400"
                  }`}
                  onClick={submitCustomBox}>
                  Submit
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="-mt-20 flex h-screen w-full items-center justify-center">
      <div className="flex-col">
        <div className="rounded-2xl border bg-white shadow-lg">
          {overflow?.isValid === false && (
            <>
              <div className="w-96 px-5 py-5 text-xl font-medium text-454A4F">
                Current Weight: {weight} lbs
              </div>
              <div className="w-96 px-5 py-5 pt-0 text-xl font-medium text-red-400">
                Current Weight has exceeded the maximum threshold. Please adjust
                the weight OR load a new box below.
              </div>
              <div className="flex p-4">
                <input
                  type="number"
                  value={weight}
                  onChange={(e) =>
                    isNaN(e.target.value) === false &&
                    parseFloat(e.target.value) > 0
                      ? setWeight(parseInt(e.target.value))
                      : setWeight(0)
                  }
                  placeholder="Weight"
                  min="0"
                  step="0.01"
                />
              </div>
            </>
          )}
          {(!overflow || overflow?.isValid === true) && (
            <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
              <Dropdown
                placeholder={"Select Box Type"}
                list={boxTypes.map((item) => ({
                  name: item.name,
                  display: `${item.name ? item.name + " - " : ""}${
                    item.length
                  }x${item.width}x${item.height}(${preferredDimensionUnit})`,
                }))}
                labelKey="display"
                valueKey="name"
                name="boxType"
                setSelected={(e) => setSelectedBoxType(e)}
                selectedValue={selectedBoxType}
              />
              <div className="flex space-x-2">
                {addBox && (
                  <div
                    className={`mt-4 flex-1 rounded-md bg-red-400 py-2 text-center text-xl text-white`}
                    onClick={() => setAddBox(false)}>
                    Cancel
                  </div>
                )}
                <div
                  className={`mt-4 flex-1 rounded-md py-2 text-center text-xl text-white ${
                    !!selectedBoxType ? "bg-2C7695" : "bg-gray-400"
                  }`}
                  onClick={() =>
                    !!selectedBoxType
                      ? submitBoxes(selectedBoxType)
                      : console.log()
                  }>
                  Submit
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-8 flex space-x-4">
          <div
            className={`mt-2 w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white`}
            onClick={restartActivity}>
            Restart Packing
          </div>
          <div
            className={`mt-2 w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white`}
            onClick={pauseActivity}>
            Pause Packing
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectBoxType;
