import React, { useState } from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";

const PlacementOptionOverview = ({ placementOption }) => {
  const [expandedCities, setExpandedCities] = useState([]);
  const [expandedBoxes, setExpandedBoxes] = useState({});

  const toggleCity = (cityName) => {
    setExpandedCities((prev) =>
      prev.includes(cityName)
        ? prev.filter((city) => city !== cityName)
        : [...prev, cityName],
    );
  };

  const toggleBox = (cityName, boxName) => {
    setExpandedBoxes((prev) => ({
      ...prev,
      [cityName]: prev[cityName]?.includes(boxName)
        ? prev[cityName].filter((box) => box !== boxName)
        : [...(prev[cityName] || []), boxName],
    }));
  };

  return (
    <div className="p-6 font-inter">
      {placementOption.shipmentsWithBoxesAndItems.map((shipment) => {
        const cityName = shipment.destination.address.city;
        const totalBoxes = shipment.boxes?.length || 0;
        const totalItems = shipment.items.reduce(
          (sum, item) => sum + item.quantity,
          0,
        );
        const isExpanded = expandedCities.includes(cityName);

        return (
          <div key={shipment.shipmentId} className="mb-4">
            {/* City Header */}
            <div
              onClick={() => toggleCity(cityName)}
              className="flex cursor-pointer items-center justify-between bg-gray-50 p-4 hover:bg-gray-100">
              <div className="flex items-center">
                {isExpanded ? (
                  <ChevronDownIcon className="mr-2 h-5 w-5 text-gray-600" />
                ) : (
                  <ChevronRightIcon className="mr-2 h-5 w-5 text-gray-600" />
                )}
                <span className="font-medium text-gray-900">{cityName}</span>
                {totalBoxes > 0 && (
                  <span className="ml-2 text-sm text-gray-500">
                    {totalBoxes} Boxes
                  </span>
                )}
              </div>
              <span className="text-sm text-gray-500">
                {totalItems} Items Assigned
              </span>
            </div>

            {/* Boxes List */}
            {isExpanded && (
              <div className="mt-2 pl-8">
                {shipment.boxes && shipment.boxes.length > 0 ? (
                  shipment.boxes.map((box, boxIndex) => {
                    const boxName = `Box ${boxIndex + 1}`;
                    const isBoxExpanded =
                      expandedBoxes[cityName]?.includes(boxName);
                    const boxItems = box.items || [];
                    const boxTotalItems = boxItems.reduce(
                      (sum, item) => sum + item.quantity,
                      0,
                    );

                    return (
                      <div key={boxIndex} className="mb-2">
                        {/* Box Header */}
                        <div
                          onClick={() => toggleBox(cityName, boxName)}
                          className="flex cursor-pointer items-center justify-between p-2 hover:bg-gray-50">
                          <div className="flex items-center">
                            {isBoxExpanded ? (
                              <ChevronDownIcon className="mr-2 h-4 w-4 text-gray-400" />
                            ) : (
                              <ChevronRightIcon className="mr-2 h-4 w-4 text-gray-400" />
                            )}
                            <span className="text-sm font-medium">
                              {boxName}
                            </span>
                          </div>
                          <span className="text-sm text-gray-500">
                            {boxTotalItems} Items
                          </span>
                        </div>

                        {/* Box Items */}
                        {isBoxExpanded && (
                          <div className="pl-8">
                            {boxItems.map((item, itemIndex) => (
                              <div
                                key={itemIndex}
                                className="flex items-center justify-between p-2 hover:bg-gray-50">
                                <div className="flex items-center">
                                  <img
                                    src={item.image || FALLBACK_IMAGE_URL}
                                    alt={item.name || item.msku}
                                    className="mr-3 h-10 w-10 rounded-md object-cover"
                                  />
                                  <div>
                                    <div className="text-sm font-medium text-gray-900">
                                      {item.name || `${item.msku} ${item.asin}`}
                                    </div>
                                    <div className="text-xs text-gray-500">
                                      SKU: {item.msku}
                                      <br />
                                      ASIN: {item.asin}
                                    </div>
                                  </div>
                                </div>
                                <div className="rounded-full bg-green-100 px-3 py-1">
                                  <span className="text-sm text-green-600">
                                    {item.quantity}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="pl-4">
                    {/* Show Items Directly */}
                    {shipment.items.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        className="flex items-center justify-between p-2 hover:bg-gray-50">
                        <div className="flex items-center">
                          <img
                            src={item.image || FALLBACK_IMAGE_URL}
                            alt={item.name || item.msku}
                            className="mr-3 h-10 w-10 rounded-md object-cover"
                          />
                          <div>
                            <div className="text-sm font-medium text-gray-900">
                              {item.name || `${item.msku} ${item.asin}`}
                            </div>
                            <div className="text-xs text-gray-500">
                              SKU: {item.msku}
                              <br />
                              ASIN: {item.asin}
                            </div>
                          </div>
                        </div>
                        <div className="rounded-full bg-green-100 px-3 py-1">
                          <span className="text-sm text-green-600">
                            {item.quantity}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PlacementOptionOverview;
