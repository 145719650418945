import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import moment from "moment-timezone";
import _ from "lodash";
import PaginationNavigationButtons from "#components/common/PaginationNavigationButtons";
import Modal from "#components/utils/Modal";
import CustomSelectDropDown from "../common/CustomSelectDropDown";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

const headers = [
  "Order ID",
  "# expected",
  "# received",
  "Carrier",
  "Tracking #s",
  "Consignment Date",
  "Action",
];

const ListView = ({
  consignments,
  checkConsignmentById,
  filters = {},
  setFilters,
  submitFilters,
  consignmentsData,
  checkPagination,
  statusList,
  onSubmit,
}) => {
  const [consignmentItems, setConsignmentItems] = useState(null);
  const renderTrackingNumbers = (trackingNumbers) => {
    if (!trackingNumbers || trackingNumbers.length === 0) return "N/A";
    if (typeof trackingNumbers === "string") return trackingNumbers;

    return trackingNumbers.map((trackingNumber, idx) => (
      <div
        key={idx}
        className="cursor-pointer font-medium text-primaryAccent"
        onClick={() => onSubmit({ data: trackingNumber })}>
        {trackingNumber}
      </div>
    ));
  };
  return (
    <div className="max-w-7xl flex-1">
      <div className="flex justify-between">
        <PageTitle>Total ({consignmentsData.total}) </PageTitle>
        <div className="items-right flex">
          <CustomSelectDropDown
            options={statusList}
            defaultValue={statusList[0]}
            placeholder="Status"
            onChange={(option) => {
              setFilters({
                ...filters,
                status: option.filterValue,
              });
            }}
            className="min-w-1/4"
          />
          <PaginationNavigationButtons
            perPage={consignmentsData.perPage}
            pageNumber={consignmentsData.pageNumber}
            total={consignmentsData.total}
            nextPage={() => checkPagination("forward")}
            prevPage={() => checkPagination("backward")}
          />
        </div>
      </div>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            aria-hidden="true"
            className="h-5 w-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="text-md block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          placeholder="Search"
          onChange={(e) =>
            setFilters({
              ...filters,
              keyword: e.target.value,
            })
          }
          value={filters["keyword"]}
          onKeyDown={(e) => (e.key === "Enter" ? submitFilters() : {})}
        />
      </div>
      <div className="mt-10 rounded-md bg-EBEBEB">
        <table className="-mt-4 min-w-full divide-y divide-gray-200">
          <thead className="rounded-full p-4">
            <tr className="border-left font-montserrat text-primaryAccent">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {(!consignments || consignments.length === 0) && (
              <tr>
                <td
                  className={`tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent`}>
                  No Consignments.
                </td>
              </tr>
            )}
            {consignments &&
              consignments.map((item, index) => (
                <tr
                  key={item.id}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  } cursor-pointer`}>
                  <td
                    className={`tracking-widerrounded-tl cursor-pointer rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}
                    onClick={() => {
                      item.workingList && item.workingList.length > 0
                        ? setConsignmentItems(
                            item.workingList.map((i) => ({
                              ...i,
                              item: i.id,
                            })),
                          )
                        : setConsignmentItems(item.workingList);
                    }}>
                    {item.orderId}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {_.sumBy(item.workingList, "quantity")}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {_.sumBy(item.workingList, "quantity") -
                      _.sumBy(item.workingList, "availableQuantity")}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.supplier}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {renderTrackingNumbers(item.trackingNumber)}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.consignmentDate
                      ? moment(item.consignmentDate).format("MMM Do YYYY")
                      : null}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {["COMPLETED", "RECEIVING_COMPLETED"].includes(
                      item.status,
                    ) ? (
                      <div className="rounded-full bg-gray-500 px-2 py-1 text-center text-lg text-white">
                        COMPLETED
                      </div>
                    ) : (
                      <div
                        className="cursor-pointer rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white"
                        onClick={() => checkConsignmentById({ data: item.id })}>
                        Work on this
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {consignmentItems && consignmentItems.length > 0 && (
        <Modal
          negativeAction={() => setConsignmentItems(null)}
          title={`Items in consignment (${consignmentItems.length})`}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="rounded-full p-4">
              <tr className="border-left bg-primaryAccent font-montserrat text-white">
                {consignmentItems.some((item) => !!item.asin) && (
                  <th
                    scope="col"
                    className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                    ASIN
                  </th>
                )}

                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  SKU
                </th>

                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Name
                </th>

                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Fn SKU
                </th>

                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Quantity
                </th>
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  To be received
                </th>
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Form Factor
                </th>
              </tr>
            </thead>
            <tbody>
              {consignmentItems.map((item, index) => (
                <tr
                  key={item.sku}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  {consignmentItems.some((item) => item.asin) ? (
                    <>
                      <td
                        className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                          index % 2 === 0
                            ? "border-F4C261"
                            : "border-primaryAccent"
                        }`}>
                        {buildMarketplaceHyperlink(
                          item.asin,
                          "ASIN",
                          item.source,
                        )}
                      </td>
                      <td className="max-w-2xl px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {item.sku}
                      </td>
                    </>
                  ) : (
                    <td
                      className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {item.sku}
                    </td>
                  )}
                  <td className="max-w-2xl px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.productName}
                  </td>
                  <td className="max-w-2xl px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.fnSku}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.quantity}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.availableQuantity}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.formFactor}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default ListView;
