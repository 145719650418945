import React, { createContext, useContext, useState, useEffect } from "react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";

const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }) => {
  const [ldFlags, setLdFlags] = useState({
    isBranded: undefined,
    storageManagementEnabled: undefined,
    salesPortalEnabled: undefined,
    isBundlingWorkflowEnabled: undefined,
    batchSettingsEnabled: undefined,
    lotIdSelectionEnabled: undefined,
    fbaCasePackEnabled: undefined,
    bolUploadEnabled: undefined,
    fbaSkuLabelGeneration: undefined,
    shippingWorkflowDesktopEnabled: undefined,
    csvPackingListDownloadEnabled: undefined,
    transactionHistoryReportEnabled: undefined,
    billingModuleEnabled: undefined,
    lpnSelectionEnabled: undefined,
    cogEnabled: undefined,
    marketplaceImport: undefined,
    associateUsernameInputEnabled: undefined,
    fba2DBarcodeEnabled: undefined,
    defineBundlesOnConsignment: undefined,
    productLinkageAllowed: undefined,
    warehouseBulkUpload: undefined,
    enhancedSearchUx: undefined,
    newRateshoppingUx: undefined,
    fbaV1Enabled: undefined,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const ldClient = useLDClient();
  const flags = useFlags();

  // console.log(flags, "flagx");

  const fetchFlags = async () => {
    if (ldClient) {
      const isBranded =
        flags?.isBranded ||
        (await ldClient.variation(featureFlags.IS_BRANDED, false));

      const storageManagementEnabled =
        flags?.hierarchicalStorageManagementEnabled ||
        (await ldClient.variation(
          featureFlags.HIERARCHICAL_STORAGE_MANAGEMENT_ENABLED,
          false,
        ));

      const salesPortalEnabled =
        flags?.salesPortalEnabled ||
        (await ldClient.variation(featureFlags.SALES_PORTAL_ENABLED, false));

      const batchSettingsEnabled =
        flags?.batchSettingsEnabled ||
        (await ldClient.variation(featureFlags.BATCH_SETTINGS_ENABLED, false));

      const isBundlingWorkflowEnabled =
        flags?.bundlingWorkflow ||
        (await ldClient.variation(featureFlags.IS_BUNDLING_WORKFLOW, false));

      const lotIdSelectionEnabled =
        flags?.lotIdSelectionEnabled ||
        (await ldClient.variation(
          featureFlags.LOT_ID_SELECTION_ENABLED,
          false,
        ));

      const fbaCasePackEnabled =
        flags?.fbaCasePackEnabled ||
        (await ldClient.variation(featureFlags.FBA_CASE_PACK_ENABLED, false));

      const bolUploadEnabled =
        flags?.bolUploadEnabled ||
        (await ldClient.variation(featureFlags.BOL_UPLOAD_ENABLED, false));

      const fbaSkuLabelGeneration =
        flags?.fbaSkuLabelGeneration ||
        (await ldClient.variation(
          featureFlags.FBA_SKU_LABEL_GENERATION,
          false,
        ));

      const shippingWorkflowDesktopEnabled =
        flags?.shippingWorkflowDesktopEnabled ||
        (await ldClient.variation(
          featureFlags.SHIPPING_DESKTOP_WORKFLOW_ENABLED,
          false,
        ));

      const csvPackingListDownloadEnabled =
        flags?.csvPackingListDownloadEnabled ||
        (await ldClient.variation(
          featureFlags.CSV_PACKING_LIST_DOWNLOAD_ENABLED,
          false,
        ));

      const transactionHistoryReportEnabled =
        flags?.transactionHistoryReportEnabled ||
        (await ldClient.variation(
          featureFlags.TRANSACTION_HISTORY_REPORT_ENABLED,
          false,
        ));

      const billingModuleEnabled =
        flags?.billingModuleEnabled ||
        (await ldClient.variation(featureFlags.BILLING_MODULE_ENABLED, false));

      const lpnSelectionEnabled =
        flags?.lpnSelectionEnabled ||
        (await ldClient.variation(featureFlags.LPN_SELECTION_ENABLED, false));

      const cogEnabled =
        flags?.cogEnabled ||
        (await ldClient.variation(featureFlags.COG_ENABLED, false));

      const marketplaceImport =
        flags?.marketplaceImport ||
        (await ldClient.variation(featureFlags.IS_MARKETPLACE_IMPORT, false));

      const associateUsernameInputEnabled =
        flags?.associateUsernameInputEnabled ||
        (await ldClient.variation(
          featureFlags.ASSOCIATE_USERNAME_INPUT_ENABLED,
          false,
        ));

      const fba2DBarcodeEnabled =
        flags?.fba2DBarcodeEnabled ||
        (await ldClient.variation(featureFlags.FBA_2D_BARCODE_ENABLED, false));

      const defineBundlesOnConsignment =
        flags?.defineBundlesOnConsignment ||
        (await ldClient.variation(
          featureFlags.DEFINE_BUNDLE_ON_CONSIGNMENT,
          false,
        ));

      const productLinkageAllowed =
        flags?.productLinkageAllowed ||
        (await ldClient.variation(featureFlags.PRODUCT_LINKAGE_ALLOWED, false));

      const warehouseBulkUpload =
        flags?.warehouseBulkUpload ||
        (await ldClient.variation(featureFlags.BULK_UPLOAD_V2_ENABLED, false));

      const enhancedSearchUx =
        flags?.enhancedSearchUx ||
        (await ldClient.variation(featureFlags.ENHANCED_SEARCH_UX, false));

      const newRateshoppingUx =
        flags?.newRateshoppingUx ||
        (await ldClient.variation(featureFlags.NEW_RATESHOPPING_UX, false));

      const fbaV1Enabled =
        flags?.fbaV1Enabled ||
        (await ldClient.variation(featureFlags.FBA_V1_ENABLED, false));

      setLdFlags({
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
        isBundlingWorkflowEnabled,
        batchSettingsEnabled,
        lotIdSelectionEnabled,
        fbaCasePackEnabled,
        bolUploadEnabled,
        fbaSkuLabelGeneration,
        shippingWorkflowDesktopEnabled,
        csvPackingListDownloadEnabled,
        transactionHistoryReportEnabled,
        billingModuleEnabled,
        lpnSelectionEnabled,
        cogEnabled,
        marketplaceImport,
        associateUsernameInputEnabled,
        fba2DBarcodeEnabled,
        defineBundlesOnConsignment,
        productLinkageAllowed,
        warehouseBulkUpload,
        enhancedSearchUx,
        newRateshoppingUx,
        fbaV1Enabled,
      });
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, [ldClient]);

  useEffect(() => {
    // const handleFlagChange = () => {
    //   const isBranded = ldClient.variation(featureFlags.IS_BRANDED, false);

    //   const storageManagementEnabled = ldClient.variation(
    //     featureFlags.HIERARCHICAL_STORAGE_MANAGEMENT_ENABLED,
    //     false,
    //   );

    //   const salesPortalEnabled = ldClient.variation(
    //     featureFlags.SALES_PORTAL_ENABLED,
    //     false,
    //   );

    //   const isBundlingWorkflowEnabled = ldClient.variation(
    //     featureFlags.IS_BUNDLING_WORKFLOW,
    //     false,
    //   );

    //   setLdFlags({
    //     isBranded,
    //     storageManagementEnabled,
    //     salesPortalEnabled,
    //     isBundlingWorkflowEnabled,
    //   });
    // };

    if (ldClient) {
      ldClient.on("change", fetchFlags);
    }

    return () => {
      if (ldClient) {
        ldClient.off("change", fetchFlags);
      }
    };
  }, [ldClient]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <FeatureFlagsContext.Provider value={ldFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
