import { useState, useMemo, useContext, useEffect } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import BatchConfirmation from "./BatchConfirmation";
import PreviewFulfillmentDetails from "./PreviewFulfillmentDetails";
import { AppStateContext } from "#contexts/appState";
import BatchPrioritisation from "./BatchPrioritisation";
import { EntityContext } from "#contexts/entity";

const ALERT_TIMEOUT_MS = 5000;

const ManualBatch = ({
  createManualBatchFinal,
  negativeAction,
  manualBatchConfig,
  setManualBatchConfig,
  pickers,
  stations,
  orders,
  selectedOrderIds = [],
  setSelectedOrderIds,
  tenant,
  batchSettings,
  ordersFulfillmentDetails,
  isBackOrderEnabledForTenant,
  showBatchingPreview,
  isManualBatchExecuting,
  showBatchingPrioritisation,
  setShowBatchingPrioritisation,
  orderQuantityDetails,
  setOrderQuantityDetails,
  refetchOrderDetails,
  setRefetchOrderDetails,
  updatedOrderBatchingDetails,
  setUpdatedOrderBatchingDetails,
  createManualBatch,
  expandOrder,
}) => {
  const appState = useContext(AppStateContext);

  const [loading, setLoading] = useState(false);

  // By default the fulfillable orders will be selected for batching.
  // We give option to user to select the partially fulfillable orders. hence we maintain this state and send it to createManualBatchFinal method
  const [selectedOrderIdsForBatching, setSelectedOrderIdsForBatching] =
    useState([]);

  // Orders that can be completely fulfilled
  const [fulfillableOrders, setFulfillableOrders] = useState([]);
  // Orders that are eligible for back orders
  const [partiallyFulfillableOrders, setPartiallyFulfillableOrders] = useState(
    [],
  );
  // Orders that went into exceptions
  const [exceptionOrders, setExceptionOrders] = useState([]);

  const [selectedStep, setSelectedStep] = useState(
    showBatchingPrioritisation ? "01" : showBatchingPreview ? "02" : "03",
  );

  /**
   * Orders that might not be eligible for back order due to client, integration level setup,
   * Orders where all items have no inventory,
   * Orders which is having any exception like sku missing.
   * Orders which can only be fulfilled by alternate warehouse.
   */
  const [unfulfillableOrders, setUnfulfillableOrders] = useState([]);
  useMemo(() => {
    if (!showBatchingPreview) {
      setSelectedOrderIdsForBatching(selectedOrderIds);
    } else {
      if (!ordersFulfillmentDetails) {
        setLoading(true);
      } else {
        setFulfillableOrders(ordersFulfillmentDetails.fulfillableOrders);
        setUnfulfillableOrders(ordersFulfillmentDetails.unfulfillableOrders);
        setPartiallyFulfillableOrders(ordersFulfillmentDetails.backOrders);
        setExceptionOrders(ordersFulfillmentDetails.exceptionOrders);
        setSelectedOrderIdsForBatching(
          ordersFulfillmentDetails.fulfillableOrders.map(
            ({ order }) => order.id,
          ),
        );
      }
      setLoading(false);
    }
  }, [ordersFulfillmentDetails]);

  useEffect(() => {
    if (selectedOrderIds?.length > 1) {
      setShowBatchingPrioritisation(true);
      setSelectedStep("01");
    } else {
      setShowBatchingPrioritisation(false);
      setSelectedStep("02");
    }
  }, [selectedOrderIds]);

  const onCancel = () => {
    if (exceptionOrders.length > 0) {
      appState.setAlert(
        `${exceptionOrders.length} order went into exception.`,
        "error",
        ALERT_TIMEOUT_MS,
      );
    }
    negativeAction();
  };

  return (
    <SlideOverModal
      open={true}
      onClose={onCancel}
      title={"Create Manual Batch"}
      width={"w-1/2"}>
      <div className="flex h-full w-full select-none flex-col">
        <main className="grow">
          {loading ? (
            <div className="mt-2 flex h-full w-full flex-col items-center justify-center rounded border">
              <div className="h-10 w-10 animate-spin rounded-full border-t-4 border-solid border-hyperlinkColor"></div>
            </div>
          ) : (
            <>
              {selectedStep === "01" && (
                <BatchPrioritisation
                  selectedOrderIds={selectedOrderIds}
                  setSelectedOrderIds={setSelectedOrderIds}
                  orders={orders}
                  expandOrder={expandOrder}
                />
              )}

              {selectedStep === "02" && (
                <PreviewFulfillmentDetails
                  fulfillableOrders={fulfillableOrders}
                  partiallyFulfillableOrders={partiallyFulfillableOrders}
                  unfulfillableOrders={unfulfillableOrders}
                  exceptionOrders={exceptionOrders}
                  selectedOrderIds={selectedOrderIds}
                  selectedOrderIdsForBatching={selectedOrderIdsForBatching}
                  setSelectedOrderIdsForBatching={
                    setSelectedOrderIdsForBatching
                  }
                  isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
                  orderQuantityDetails={orderQuantityDetails}
                  setOrderQuantityDetails={setOrderQuantityDetails}
                  refetchOrderDetails={refetchOrderDetails}
                  setRefetchOrderDetails={setRefetchOrderDetails}
                  updatedOrderBatchingDetails={updatedOrderBatchingDetails}
                  setUpdatedOrderBatchingDetails={
                    setUpdatedOrderBatchingDetails
                  }
                  tenant={tenant}
                />
              )}

              {selectedStep === "03" && (
                <BatchConfirmation
                  pickers={pickers}
                  manualBatchConfig={manualBatchConfig}
                  setManualBatchConfig={setManualBatchConfig}
                  stations={stations}
                  tenant={tenant}
                  batchSettings={batchSettings}
                  orders={orders}
                  fulfillableOrders={fulfillableOrders}
                  partiallyFulfillableOrders={partiallyFulfillableOrders}
                  selectedOrderIdsForBatching={selectedOrderIdsForBatching}
                  showBatchingPreview={showBatchingPreview}
                />
              )}
            </>
          )}
        </main>
        <footer>
          <FooterActions
            negativeAction={onCancel}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
            createManualBatchFinal={createManualBatchFinal}
            selectedOrderIdsForBatching={selectedOrderIdsForBatching}
            fulfillableOrders={fulfillableOrders}
            partiallyFulfillableOrders={partiallyFulfillableOrders}
            showBatchingPreview={showBatchingPreview}
            isManualBatchExecuting={isManualBatchExecuting}
            selectedOrderIds={selectedOrderIds}
            createManualBatch={createManualBatch}
          />
        </footer>
      </div>
    </SlideOverModal>
  );
};

const FooterActions = ({
  negativeAction,
  selectedStep,
  setSelectedStep,
  createManualBatchFinal,
  selectedOrderIdsForBatching,
  fulfillableOrders,
  partiallyFulfillableOrders,
  showBatchingPreview,
  isManualBatchExecuting,
  selectedOrderIds,
  createManualBatch,
}) => {
  const entity = useContext(EntityContext);
  const previewSelectionFailure =
    showBatchingPreview &&
    (fulfillableOrders.length > 0 || partiallyFulfillableOrders.length > 0);
  const appState = useContext(AppStateContext);

  if (previewSelectionFailure) {
    <div className="mr-2 flex h-16 items-center justify-end space-x-2">
      <button
        onClick={negativeAction}
        className={`text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded-md border bg-primaryAccent px-8 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0`}>
        OK
      </button>
    </div>;
  }

  return (
    <div className="mr-2 flex h-16 items-center justify-end space-x-2">
      <button
        onClick={negativeAction}
        className={`text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded-md border bg-red-600 px-8 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 hover:bg-red-700 focus:outline-none focus:ring-0`}>
        Cancel
      </button>
      {selectedStep !== "01" && (
        <button
          onClick={() => {
            if (selectedStep === "02") setSelectedStep("01");
            else setSelectedStep("02");
          }}
          className={`text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded-md border px-8 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0 ${
            selectedStep === "01"
              ? "cursor-not-allowed bg-gray-200 text-gray-400"
              : "bg-FC8862"
          }`}>
          Previous
        </button>
      )}
      <button
        onClick={() => {
          if (selectedStep === "01") {
            createManualBatch({
              entity,
              selectedOrders: selectedOrderIds,
            });
            setSelectedStep("02");
          } else if (
            selectedStep === "02" &&
            selectedOrderIdsForBatching.length > 0
          ) {
            setSelectedStep("03");
          } else if (selectedStep === "03") {
            createManualBatchFinal(selectedOrderIdsForBatching);
          }
        }}
        className={`text-md textWhite-300 inline-flex cursor-pointer items-center justify-center rounded-md border px-8 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0 ${
          selectedStep !== "02"
            ? "bg-primaryAccent"
            : selectedOrderIdsForBatching.length > 0 && !appState.loading
              ? "bg-FC8862"
              : "cursor-not-allowed bg-gray-200 text-gray-400"
        }`}
        disabled={isManualBatchExecuting || appState.loading}>
        {selectedStep === "03" ? "Create" : "Next"}
      </button>
    </div>
  );
};

export default ManualBatch;
