import _ from "lodash";
import JSPM from "jsprintmanager";
import { jsPDF } from "jspdf";
import JsBarcode from "jsbarcode";

// Existing Supported Sizes
export const SUPPORTED_SIZES_WITHOUT_CANVAS = [
  "2x1",
  "2.25x1.25",
  "2.25x1.57",
  "3x1",
  "3x2",
  "4x3",
  "4x2",
  "4x6",
  // "4x6-Landscape",
];

// TODO: Add 4x6-Landscape to the supported sizes with rotating the page
// with 90 degrees

// Utility Function to Truncate Text
const truncate = (str, maxLength) => {
  return str && str.length > maxLength
    ? str.substring(0, maxLength) + "..."
    : str;
};

// Dimension Variables for Different Label Sizes
const dimensionVariables = {
  "2.25x1.25": {
    width: 2.25,
    height: 1.25,
    defaultFontSize: 14,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 3,
    barcodeRatioWithAdditionalText: 4,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "2x1": {
    width: 2,
    height: 1,
    defaultFontSize: 12,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 3,
    barcodeRatioWithAdditionalText: 4,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "3x1": {
    width: 3,
    height: 1,
    defaultFontSize: 14,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 5,
    barcodeRatioWithAdditionalText: 6,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "2.25x1.57": {
    width: 2.25,
    height: 1.57,
    defaultFontSize: 12,
    fontSizeWithAdditionalText: 12,
    defaulltBarcodeRatio: 2,
    barcodeRatioWithAdditionalText: 3,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  // Add new supported sizes
  "3x2": {
    width: 3,
    height: 2,
    defaultFontSize: 16,
    fontSizeWithAdditionalText: 14,
    defaulltBarcodeRatio: 2.5,
    barcodeRatioWithAdditionalText: 3,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 35,
  },
  "4x2": {
    width: 4,
    height: 2,
    defaultFontSize: 18,
    fontSizeWithAdditionalText: 16,
    defaulltBarcodeRatio: 3,
    barcodeRatioWithAdditionalText: 4,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 40,
  },
  "4x3": {
    width: 4,
    height: 3,
    defaultFontSize: 22,
    fontSizeWithAdditionalText: 18,
    defaulltBarcodeRatio: 1.8,
    barcodeRatioWithAdditionalText: 2.2,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 45,
  },
  "4x6": {
    width: 4,
    height: 6,
    defaultFontSize: 24,
    fontSizeWithAdditionalText: 20,
    defaulltBarcodeRatio: 1.5,
    barcodeRatioWithAdditionalText: 1.8,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 50,
  },
  "4x6-Landscape": {
    width: 6,
    height: 4,
    defaultFontSize: 24, // Increased from 18
    fontSizeWithAdditionalText: 20, // Increased from 16
    defaulltBarcodeRatio: 4, // Reduced from 8
    barcodeRatioWithAdditionalText: 5, // Reduced from 9
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 60, // Increased from 50 since we have more horizontal space
  },
};
/**
 * Generates a PDF with multiple pages, each containing a barcode for a given code.
 *
 * @param {Array<string>} codes - Array of codes to generate barcodes for.
 * @param {number} noOfCopies - Number of copies per code.
 * @param {string} message - Message to display during printing.
 * @param {string|null} printer - Specific printer to use. If null, default printer is used.
 * @param {string} dimensions - Dimensions of the label.
 * @param {Object|null} appState - Application state for setting alerts and loading indicators.
 * @param {boolean} print - Whether to send to printer or save as PDF.
 * @param {string} text1 - Additional text to display below the barcode.
 * @param {string} text2 - Additional text to display below the first text.
 */
export const printBarcodesInMultiPage = async (
  codes,
  noOfCopies = 1,
  message = "Printing labels",
  printer = null,
  dimensions = "2x1",
  appState = null,
  print = true,
  text1,
  text2,
) => {
  if (!codes || !Array.isArray(codes) || codes.length === 0) {
    return (
      appState &&
      appState.setAlert("No codes provided for printing.", "error", 5000)
    );
  }

  if (!SUPPORTED_SIZES_WITHOUT_CANVAS.includes(dimensions)) {
    return (
      appState &&
      appState.setAlert("Unsupported label dimensions.", "error", 5000)
    );
  }

  // Generate a unique container for all barcodes
  const uniqueContainerId = `barcode_container_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  let container = document.createElement("div");
  container.id = uniqueContainerId;
  container.style.display = "none";
  document.body.appendChild(container);

  const {
    width,
    height,
    defaultFontSize,
    fontSizeWithAdditionalText,
    defaulltBarcodeRatio,
    barcodeRatioWithAdditionalText,
    horizontalBorder,
    verticalBorder,
    textTruncateLength,
  } = dimensionVariables[dimensions];

  const doc = new jsPDF({
    orientation: width > height ? "landscape" : "portrait",
    unit: "in",
    format: [width, height],
  });

  for (let codeIndex = 0; codeIndex < codes.length; codeIndex++) {
    const code = codes[codeIndex];

    if (!code) {
      continue; // Skip empty codes
    }

    // Create a unique canvas for each barcode
    const uniqueId = `barcode_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    let canvas = document.createElement("canvas");
    canvas.id = uniqueId;
    container.appendChild(canvas);

    try {
      // Generate the barcode
      JsBarcode(`#${uniqueId}`, code, {
        format: "code128",
        width: 2,
        height: 100,
        displayValue: false,
      });
    } catch (error) {
      container.removeChild(canvas);
      appState &&
        appState.setAlert(
          `Error generating barcode for code: ${code}`,
          "error",
          5000,
        );
      continue; // Skip to next code
    }

    const fontSize = text1 ? fontSizeWithAdditionalText : defaultFontSize;
    const barcodeRatio = text1
      ? barcodeRatioWithAdditionalText
      : defaulltBarcodeRatio;

    let vCursor = verticalBorder;
    let hCursor = horizontalBorder;
    let barcodesizeH = width - 2 * horizontalBorder;
    let barcodesizeV = barcodesizeH / barcodeRatio;

    // Add the barcode image to the PDF
    doc.addImage(
      canvas.toDataURL(),
      "PNG",
      hCursor,
      vCursor,
      barcodesizeH,
      barcodesizeV,
    );

    doc.setFontSize(fontSize);
    const linespace = 3 / 100;
    vCursor += barcodesizeV + fontSize / 100;

    // Center the code text below the barcode
    doc.text(code, width / 2, vCursor, { align: "center" });

    // Add additional texts if provided
    if (text1) {
      vCursor += linespace * 2 + fontSize / 100;
      doc.text(truncate(text1, textTruncateLength), horizontalBorder, vCursor);
    }
    if (text2) {
      vCursor += linespace + fontSize / 100;
      doc.text(truncate(text2, textTruncateLength), horizontalBorder, vCursor);
    }

    // Remove the canvas after processing
    container.removeChild(canvas);

    // Add a new page if not the last code
    if (codeIndex < codes.length - 1) {
      doc.addPage();
    }
  }

  // Clean up the container
  document.body.removeChild(container);

  if (print === false) {
    // Save the multi-page PDF
    doc.save(`${codes[0]}-barcodes.pdf`);
    appState && appState.removeLoading();
  } else {
    // Prepare the PDF for printing
    const out = doc.output("datauristring");
    const cpj = new JSPM.ClientPrintJob();
    let myPrinter;
    if (printer) {
      myPrinter = new JSPM.InstalledPrinter(printer);
    } else {
      myPrinter = new JSPM.DefaultPrinter();
    }
    cpj.clientPrinter = myPrinter;

    // Add each page as separate print files
    for (let i = 0; i < noOfCopies; i++) {
      const myFile = new JSPM.PrintFilePDF(
        out,
        JSPM.FileSourceType.URL,
        `labels_${i}.pdf`,
        1,
      );
      cpj.files.push(myFile);
    }

    // Send the print job to the client
    cpj.sendToClient();
    appState && appState.removeLoading();
  }
};
