import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const CustomSwitch = ({
  leftSideText,
  rightSideText,
  checked,
  onChange,
  disabled = false,
  leftColor = "bg-gray-500",
  rightColor = "bg-green-500",
  labelClasses = "text-base text-gray-500",
  switchClasses = "w-16 h-6",
  handleClasses = "w-4 h-4",
}) => {
  return (
    <div className="flex items-center">
      <span className={twMerge("mr-2", labelClasses)}>{leftSideText}</span>
      <div
        className={twMerge(
          `relative inline-flex cursor-pointer items-center rounded-full transition-colors duration-300`,
          checked ? rightColor : leftColor,
          switchClasses,
          disabled ? "cursor-not-allowed opacity-50" : "",
        )}
        onClick={() => {
          if (!disabled) onChange(!checked);
        }}>
        <div
          className={twMerge(
            `absolute left-1 top-1 rounded-full bg-white transition-transform duration-300 ease-in-out`,
            handleClasses,
            checked ? "translate-x-full" : "translate-x-0",
          )}></div>
      </div>
      {rightSideText && (
        <span className={twMerge("ml-2", labelClasses)}>{rightSideText}</span>
      )}
    </div>
  );
};

CustomSwitch.propTypes = {
  leftSideText: PropTypes.string,
  rightSideText: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  leftColor: PropTypes.string,
  rightColor: PropTypes.string,
  labelClasses: PropTypes.string,
  switchClasses: PropTypes.string,
  handleClasses: PropTypes.string,
};

export default CustomSwitch;
