import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import SearchSortFilter from "../common/SearchSortFilter";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import Pagination from "#components/common/Pagination";
import moment from "moment-timezone";
import QuickFilters from "../common/QuickFilters";

const getStatusChip = (activated) => {
  const chipStyle = "p-1 rounded-full m-auto text-center w-24";

  return (
    <div
      className={
        chipStyle +
        (activated
          ? " bg-activeTextColor/20 text-activeTextColor"
          : " bg-inActiveTextColor/20 text-inActiveTextColor")
      }>
      {activated ? "Active" : "Inactive"}
    </div>
  );
};

const UoMList = ({
  stations,
  headers,
  noValuesText,
  customers,
  warehouses,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
}) => {
  const sortedStations = [...stations].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );
  stations = sortedStations;

  return (
    <div className="rounded-xl border border-gray-300 bg-E2E2E2 px-4 pb-20">
      <CustomTableWithTheme>
        <thead className="sticky top-0 bg-primaryAccent">
          <tr className="text-textWhite">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className={`px-4 py-3 text-left font-medium tracking-wider ${
                  headerIdx === 0
                    ? "pl-6"
                    : headerIdx === headers.length - 1
                      ? "pr-6 text-right"
                      : ""
                }`}
                style={{ width: headerIdx === 1 ? "0%" : "auto" }}
                key={headerIdx}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {stations.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) => (
                <td
                  className={`px-4 py-3 text-left font-medium tracking-wider text-5F666B ${
                    headerIdx === 0
                      ? "rounded-bl rounded-tl border-l-8 border-F4C261 pl-6"
                      : ""
                  }`}
                  key={headerIdx}>
                  {headerIdx === 0 ? noValuesText : ""}
                </td>
              ))}
            </tr>
          ) : null}
          {stations.map((station, index) => (
            <tr
              key={station.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`px-4 py-2 text-left font-semibold tracking-wider ${
                  index % 2 === 0
                    ? "rounded-bl rounded-tl border-l-8 border-F4C261 pl-6"
                    : "rounded-bl rounded-tl border-l-8 border-primaryAccent pl-6"
                }`}>
                {station.name}
              </td>
              <td className="-ml-4 w-24 px-4 py-3 text-center font-medium tracking-wider">
                {getStatusChip(station.isActive)}
              </td>
              <td className="px-4 py-3 pr-6 text-right font-medium tracking-wider">
                {moment(station.createdAt).format("MMM Do YYYY")}
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default UoMList;
