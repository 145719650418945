import React from "react";
import FbaInbound from "../../newUiComponents/fbaInbound/FbaInbound";
function FbaInboundPage() {
  return (
    <div className="h-100v bg-white font-inter">
      <FbaInbound />
    </div>
  );
}

export default FbaInboundPage;
