import React, { useState, useEffect, useMemo } from "react";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { Tooltip } from "antd";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import dayjs from "dayjs";
const FbaProductTableReadOnly = ({
  inventoryData,
  isSearchable = true,
  fromPackingScreen = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const transformedData = useMemo(
    () =>
      inventoryData.map((item) => ({
        id: item.productId,
        productId: item.productId,
        images: item?.images,
        productName: item?.name || `SKU: ${item.sku} ASIN: ${item.asin}`,
        expiryDate:
          item.expiryDate || item.bestByDate
            ? dayjs(item.expiryDate || item.bestByDate).isValid()
              ? dayjs(item.expiryDate || item.bestByDate).format("YYYY-MM-DD")
              : "-"
            : "-",
        unitsAvailable: item.availableToShip || "-",
        unitsEligibleToShip: item.quantity || item.availableQuantity || "-",
        fnSku: item.fnSku || item.fnsku || "",
        sku: item.sku || "",
        sellerSku: item.SellerSKU || "-",
        // Add these for search functionality
        asin: item.asin || "",
        name: item.name || "",
        upc: item.upc || "",
        lpn: item.lpn || "",
      })),
    [inventoryData],
  );

  const checkForUnitsAvailableColumn = (inventoryData) => {
    return inventoryData.some((product) => product.availableToShip);
  };
  const columns = [
    {
      key: "productName",
      title: "Product Name",
      render: (value, row) => (
        <div className="flex items-center">
          <img
            src={
              row.images
                ? row?.images[0]?.display_url ||
                  row?.images[0]?.url ||
                  row?.images[0]
                : FALLBACK_IMAGE_URL
            }
            alt="Product"
            className="mr-3 h-12 w-12 object-cover"
          />
          <div>
            {" "}
            {/* Using max-w-96 for larger width */}
            <Tooltip
              title={value}
              placement="topLeft"
              trigger={["hover"]}
              mouseEnterDelay={0.5}
              overlayClassName="max-w-lg" // Limits tooltip width
            >
              <p
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordWrap: "break-word",
                  width: "300px",
                }}
                className="max-w-full truncate text-base font-medium">
                {value}
              </p>
            </Tooltip>
            <p className="text-sm text-gray-500">{`ASIN: ${row.asin}`}</p>
            <p className="text-sm text-gray-500">{`FNSKU: ${row.fnSku}`}</p>
            <p className="text-sm text-gray-500">{`SKU: ${row.sku}`}</p>
          </div>
        </div>
      ),
    },
    {
      key: "expiryDate",
      title: "Expiry Date",
      render: (value) => <span className="text-sm text-gray-700">{value}</span>,
    },
    checkForUnitsAvailableColumn(inventoryData) && !fromPackingScreen
      ? {
          key: "unitsAvailable",
          title: "Units Available",
          render: (value) => (
            <span className="rounded-full bg-blue-100 px-2 py-1 text-sm text-blue-600">
              {value}
            </span>
          ),
        }
      : null,
    {
      key: "unitsEligibleToShip",
      title: fromPackingScreen ? "No of Units" : "Units Eligible to Ship",
      render: (value) => (
        <span className="rounded-full bg-green-100 px-2 py-1 text-sm text-green-600">
          {value}
        </span>
      ),
    },
  ].filter(Boolean);

  const filteredData = useMemo(() => {
    if (!searchTerm || searchTerm.trim() === "") {
      return transformedData;
    }

    const searchTermLower = searchTerm.toLowerCase().trim();

    return transformedData.filter(
      (item) =>
        item.asin.toLowerCase().includes(searchTermLower) ||
        item.sku.toLowerCase().includes(searchTermLower) ||
        item.fnSku.toLowerCase().includes(searchTermLower) ||
        item.name.toLowerCase().includes(searchTermLower) ||
        item.upc.includes(searchTermLower) ||
        item.lpn.includes(searchTermLower),
    );
  }, [transformedData, searchTerm]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  return (
    <div className="flex h-full flex-col justify-between font-inter">
      <CustomTable
        columns={columns}
        data={filteredData}
        isSearchable={isSearchable}
        isFilters={false}
        isPagination={false}
        isInfiniteScroll={false}
        pageSizeOptions={["5", "10", "20"]}
        onChangeSearchTerm={handleSearch}
        totalDataCountLable="Total Products: "
        searchPlaceholder={"Search by ASIN/SKU/FNSKU/Name"}
        totalDataCount={transformedData.length}
      />
    </div>
  );
};

export default FbaProductTableReadOnly;
