export const OrderSource = {
  HOPSTACK: "Hopstack",
  FBA: "FBA",
  SHOPIFY: "Shopify",
  SALLA: "Salla",
  NETSUITE: "NetSuite",
  NETSUITE_RA: "NetSuite-RA",
  ZID: "Zid",
  APPARELMAGIC: "ApparelMagic",
  SHIP_COMPLIANT: "ShipCompliant",
  SAP_B1_TYM: "SAP-B1-TYM",
  E_BAY: "eBay",
  HOPSTACK_SALES_PORTAL: "HOPSTACK_SALES_PORTAL",
  COMMERCE_HUB: "CommerceHub",
  VAULT_JMI: "Vault-JMI",
  SALESFORCE: "Salesforce",
};
