import React from "react";

const DropdownInput = ({
  options,
  valueKey,
  labelKey,
  width,
  name,
  onChange,
  value,
  styles,
  disabled,
}) => {
  return (
    <select
      className={`cursor-pointer rounded border-gray-300 outline-none ${width || ""} ${
        styles || ""
      } ${disabled ? "bg-gray-200" : ""}`}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}>
      {options.map((o) => (
        <option key={o[valueKey]} value={o[valueKey]}>
          {o[labelKey]}
        </option>
      ))}
    </select>
  );
};

export default DropdownInput;
