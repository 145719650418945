import React from "react";

function InboundPlanInfo({ selectedPlan, warehouses, customers }) {
  const warehouse = warehouses?.find((w) => w.id === selectedPlan?.warehouse);
  const customer = customers?.find((c) => c.id === selectedPlan?.customer);
  return (
    <div className="mb-6 rounded-lg border border-gray-200 p-6 font-inter">
      <h2 className="mb-4 text-lg font-medium text-gray-900">
        Inbound Plan Info
      </h2>

      <div className="grid grid-cols-4 gap-6">
        {/* First Row */}
        <div>
          <p className="text-sm text-gray-600">Inbound Plan ID</p>
          <p className="mt-1 text-sm font-medium">
            {selectedPlan?.inboundPlanId}
          </p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Customer</p>
          <div className="mt-1 flex items-center gap-1">
            <p className="text-sm font-medium">{customer?.name || "-"}</p>
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-600">Warehouse</p>
          <p className="mt-1 text-sm font-medium">{warehouse?.name || "-"}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Status</p>
          <p className="mt-1 text-sm font-medium text-green-600">
            {selectedPlan?.status?.replace(/_/g, " ")}
          </p>
        </div>

        {/* Second Row */}
        <div>
          <p className="text-sm text-gray-600">Seller ID</p>
          <p className="mt-1 text-sm font-medium">
            {selectedPlan?.sellerId || "-"}
          </p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Marketplace</p>
          <p className="mt-1 text-sm font-medium">
            {selectedPlan?.marketplace || "-"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default InboundPlanInfo;
