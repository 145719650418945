import Modal from "#components/utils/Modal";
import { useContext, useEffect, useState } from "react";
const config = require("config");
import QRCode from "react-qr-code";
import { AuthContext } from "#contexts/auth";
import { getHeaderObject } from "../../../utils/getHeaderObject";
import { renderStatus } from "../../../utils/renderStatus";
import moment from "moment-timezone";

const ExpandInventoryOperationsSummaryBatch = ({
  batchItems,
  negativeAction,
  tableName,
  toShowActivity = true,
  isBundlePicking,
  isBundling,
  isBundlePutaway,
}) => {
  // console.log("totalConsumedQuantity", totalConsumedQuantity);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (isBundlePicking) {
      setHeaders([
        getHeaderObject("Sku", "sku"),
        getHeaderObject("Product Name", "productName"),
        getHeaderObject("Picked Quantity", "pickedQuantity"),
        getHeaderObject("Quantity", "quantity"),
        getHeaderObject("Bin Location", "binLocation"),
        getHeaderObject("Status", "status"),
      ]);
    } else if (isBundling) {
      setHeaders([
        getHeaderObject("Sku", "sku"),
        getHeaderObject("Product Name", "productName"),
        getHeaderObject("Bundling Quantity", "totalConsumedQuantity"),
        getHeaderObject("Total Quantity", "quantity"),
        getHeaderObject("Status", "status"),
      ]);
    } else if (isBundlePutaway) {
      setHeaders([
        getHeaderObject("Sku", "sku"),
        getHeaderObject("Product Name", "productName"),

        getHeaderObject("Putaway Quantity", "totalConsumedQuantity"),
        getHeaderObject("Total Quantity", "totalScannedQuantity"),
        // getHeaderObject("Bin Location", "binLocation"),
        getHeaderObject("Status", "status"),
      ]);
    }
  }, [isBundlePicking, isBundling, isBundlePutaway]);

  const auth = useContext(AuthContext);

  const [editColumns, setEditColumns] = useState(false);
  const [showHeaders, setShowHeaders] = useState(headers);

  return (
    <>
      <Modal
        onClose={() => {}}
        negativeAction={negativeAction}
        title={`Batch Details`}
        noPadding={true}
        positiveAction={negativeAction}
        positiveText="OK">
        <div className="bg-EFE9DC p-4">
          <div className="pb-2 text-xl text-black">
            Line Items ({batchItems.length})
          </div>
          <table className="min-w-full divide-y divide-gray-200 px-2">
            <thead className="rounded-full bg-primaryAccent p-4 px-12">
              <tr className="border-left px-12 font-montserrat text-textWhite">
                {headers.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {batchItems.map((item, index) => {
                console.log(item, "item");
                return (
                  <tr
                    key={item.id}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                    {headers.map((header, idx) => {
                      let value = header.correspondingValue;
                      let cellStyle =
                        (value == "id"
                          ? "text-primaryAccent cursor-pointer"
                          : "text-5F666B") +
                        (idx == 0
                          ? ` p-5 text-left font-semibold tracking-widerrounded-tl rounded-bl border-l-8  ${
                              index % 2 === 0
                                ? "border-F4C261"
                                : "border-primaryAccent"
                            }`
                          : " pl-4 px-1 py-1 text-left font-medium  tracking-wider rounded-br rounded-tr");
                      if (value === "sku") {
                        return <td className={cellStyle}>{item.sku}</td>;
                      }
                      if (value === "productName") {
                        return (
                          <td className={cellStyle}>{item.productName}</td>
                        );
                      }
                      if (value === "pickedQuantity") {
                        if (item.status == "COMPLETE") {
                          return <td className={cellStyle}>{item.quantity}</td>;
                        } else {
                          return <td className={cellStyle}>0</td>;
                        }
                      }

                      if (value === "totalConsumedQuantity") {
                        return (
                          <td className={cellStyle}>
                            {item.totalConsumedQuantity}
                          </td>
                        );
                      }

                      if (value === "totalScannedQuantity") {
                        return (
                          <td className={cellStyle}>
                            {item.totalScannedQuantity}
                          </td>
                        );
                      }
                      if (value === "quantity") {
                        return <td className={cellStyle}>{item.quantity}</td>;
                      }
                      if (value === "binLocation") {
                        return (
                          <td className={cellStyle}>{item.binLocation}</td>
                        );
                      }

                      if (value === "status") {
                        return (
                          <td className={cellStyle}>
                            {renderStatus(item.status)}
                          </td>
                        );
                      }
                      // return <td>{item[value]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};
export default ExpandInventoryOperationsSummaryBatch;
