import { useState, useEffect, useRef } from "react";
import { XIcon } from "@heroicons/react/outline";

/**
 * Renders a multiselect component with text input for adding custom labels.
 * @param {Array<string>} values - An array of selected values (strings).
 * @param {boolean} disabled - If set true, the whole component is disabled and acts unresponsive.
 * @param {Function} setValues - A function passed from consuming component to set values in the parent component.
 * @returns {JSX.Element}
 */
const MultiSelectAutoCompleteV2 = ({
  values: selectedValues,
  disabled,
  setValues,
}) => {
  selectedValues = selectedValues || [];

  const [searchText, setSearchText] = useState("");
  const [selectedLabels, setLabels] = useState(selectedValues || []);
  const [cursor, setCursor] = useState(-1);
  const ref = useRef();

  useEffect(() => {
    const listener = (e) => {
      if (!ref || !ref.current || !ref.current.contains(e.target)) {
        setCursor(-1);
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, []);

  const select = (selectedValue) => {
    if (
      selectedValue &&
      !selectedLabels.includes(selectedValue) &&
      !/\s/.test(selectedValue)
    ) {
      setValues([...selectedLabels, selectedValue]);
      setLabels([...selectedLabels, selectedValue]);
      setSearchText("");
    }
  };

  const handleChange = (text) => {
    setSearchText(text);
  };

  const removeItem = (removedValue) => {
    const currentValues = [...selectedLabels];
    const foundIndex = currentValues.indexOf(removedValue);
    if (foundIndex !== -1) {
      currentValues.splice(foundIndex, 1);
      setValues(currentValues);
      setLabels(currentValues);
    }
  };

  const SelectedValues = () => {
    return (
      <div className="pr-6">
        {selectedLabels.map((label, i) => (
          <div
            className="m-1 inline-block rounded-full bg-primaryAccent text-white"
            key={label + i}>
            <div className="flex h-8 items-center justify-between">
              <div className="flex-2 pl-3 pr-5">{label}</div>
              <XIcon
                className="mr-2 h-5 w-5 cursor-pointer"
                onClick={() => removeItem(label)}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      aria-disabled={disabled}
      className={
        disabled ? "pointer-events-none relative opacity-50" : "relative"
      }
      ref={ref}>
      <div className="relative w-full overflow-visible rounded-lg border-2 border-2C7695 outline">
        <SelectedValues />
        <input
          type="text"
          className="inline-block appearance-none border-0 bg-transparent p-4 font-montserrat text-lg focus:border-0 focus:outline-none focus:ring-0"
          onKeyDown={(e) => e.key === "Enter" && select(searchText)}
          onChange={(e) => handleChange(e.target.value)}
          value={searchText}
          placeholder="Start Typing"
        />
      </div>
      <div className="mt-2 flex justify-center">
        <button
          className="rounded bg-primaryAccent px-4 py-2 text-white"
          onClick={() => select(searchText)}>
          Add
        </button>
      </div>
    </div>
  );
};

export default MultiSelectAutoCompleteV2;
