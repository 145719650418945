import { useState, useEffect, useContext } from "react";
import {
  GET_SKU_BIN_MAPPINGS,
  GET_BIN_LOCATIONS,
  GET_SKU,
  GET_WAREHOUSES,
  GET_CUSTOMERS,
  UPLOAD_SKU_BINS,
  GET_ALL_SKU_BIN_MAPPINGS,
  GET_SKU_BIN_MAPPING,
  GET_SKU_BIN_MAPPING_FIELDS,
  GET_INTEGRATIONS,
  GET_UOM_BREAKDOWN_DATA,
  GET_BIN_TRANSFER_ELIGIBILITY,
} from "#queries";
import {
  SAVE_SKU_BIN_MAPPING,
  DELETE_SKU_BIN_MAPPING,
  UPDATE_INVENTORY,
  BULK_UPLOAD_SKU_BIN_MAPPINGS,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { useQuery } from "#hooks/useQuery";
import readXlsxFile from "read-excel-file";
import moment from "moment-timezone";
import { ExportToCsv } from "export-to-csv";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";
import { UOM_BREAKDOWN } from "#mutations/index";

const ALERT_TIMEOUT_IN_MS = 5000;

const ExportSkuBinMappingFields = [
  {
    name: "SKU",
    id: "sku",
    correspondingValue: "sku",
    enabled: true,
    editable: false,
  },
  {
    name: "Pallet ID",
    id: "palletId",
    correspondingValue: "palletId",
    enabled: true,
    editable: true,
  },
  {
    name: "Lot ID/ Batch ID",
    id: "lotId",
    correspondingValue: "lotId",
    enabled: true,
    editable: true,
  },
  {
    name: "Serial Number",
    id: "serialNumber",
    correspondingValue: "serialNumber",
    enabled: true,
    editable: true,
  },
  {
    name: "PO ID",
    id: "poId",
    correspondingValue: "poId",
    enabled: true,
    editable: true,
  },
  {
    name: "PO Line ID",
    id: "lineItemId",
    correspondingValue: "lineItemId",
    enabled: true,
    editable: true,
  },
  {
    name: "Tote",
    id: "tote",
    correspondingValue: "tote",
    enabled: true,
    editable: true,
  },
  {
    name: "Location",
    id: "binLocation",
    correspondingValue: "binLocation",
    enabled: true,
    editable: true,
  },
  {
    name: "Allocated Quantity",
    id: "allocatedQuantity",
    correspondingValue: "allocatedQuantity",
    enabled: true,
    editable: true,
  },
  {
    name: "Unallocated Quantity",
    id: "quantity",
    correspondingValue: "unallocatedQuantity",
    enabled: true,
    editable: true,
  },
  {
    name: "Created At",
    id: "createdAt",
    correspondingValue: "createdAt",
    enabled: true,
    editable: true,
  },
  {
    name: "Expiry Date",
    id: "bestByDate",
    correspondingValue: "bestByDate",
    enabled: true,
    editable: true,
  },
  {
    name: "LPN Form Factor",
    id: "nestedFormFactor",
    correspondingValue: "nestedFormFactor",
    enabled: true,
    editable: true,
  },
  {
    name: "LPN",
    id: "nestedFormFactorId",
    correspondingValue: "nestedFormFactorId",
    enabled: true,
    editable: true,
  },
  {
    name: "Client",
    id: "customer",
    correspondingValue: "customer",
    enabled: true,
    editable: true,
  },
  {
    name: "Warehouse",
    id: "warehouse",
    correspondingValue: "warehouse",
    enabled: true,
    editable: true,
  },
];

const withSkuBinMappingsLogic = (WrappedComponent) => {
  return (props) => {
    const auth = useContext(AuthContext);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [skuDetails, setSku] = useState(null);
    const [fetchedSku, setFetchedSku] = useState(null);
    const [binLocationDetails, setBinLocation] = useState(null);
    const [skuBinMapDetails, setSkuBinMap] = useState(null);
    const [binBarcode, setBinBarcode] = useState(null);
    const [skuQrCode, setSkuQrCode] = useState(null);
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const [selectedSkuMapping, setSelectedSkuMapping] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const mainQuery = useQuery(GET_SKU_BIN_MAPPINGS);
    const allSkuBinMappingsQuery = useQuery(GET_ALL_SKU_BIN_MAPPINGS);
    const saveSkuBinMappingQuery = useQuery(SAVE_SKU_BIN_MAPPING);
    const saveSkuBinMappingQueryMultiple = useQuery(SAVE_SKU_BIN_MAPPING);
    const getSpecificInventory = useQuery(GET_SKU_BIN_MAPPING);
    const getUomBreakdown = useQuery(GET_UOM_BREAKDOWN_DATA);
    const [fetchedInventory, setFetchedInventory] = useState(null);
    const [fetchedUomBreakdown, setFetchedUomBreakdown] = useState(null);
    const [stockChangeType, setStockChangeType] = useState("add");
    const updateInventory = useQuery(UPDATE_INVENTORY);
    const updateUomBreakdown = useQuery(UOM_BREAKDOWN);
    const binLocationsQuery = useQuery(GET_BIN_LOCATIONS);
    const deleteSkuBinMappingQuery = useQuery(DELETE_SKU_BIN_MAPPING);
    const getSku = useQuery(GET_SKU);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const warehousesQuery = useQuery(GET_WAREHOUSES);
    const uploadSkuBinsQuery = useQuery(UPLOAD_SKU_BINS);
    const getSkuBinMappingFields = useQuery(GET_SKU_BIN_MAPPING_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_SKU_BIN_MAPPINGS);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [
      showExportSkuBinMappingFieldSelector,
      setShowExportSkuBinMappingFieldSelector,
    ] = useState(false);
    const [skuBinMappingExportFields, setSkuBinMappingExportFields] = useState([
      ...ExportSkuBinMappingFields,
    ]);
    const [multiAccountSupportEnabled, setMultiAccountSupportEnabled] =
      useState(false);
    const [marketplaces, setMarketplaces] = useState([]);
    const [sellerIds, setSellerIds] = useState([]);
    const getBinTransferEligibility = useQuery(GET_BIN_TRANSFER_ELIGIBILITY);
    const [binTransferEligibility, setBinTransferEligibility] = useState(null);

    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (
        tenantSettings?.multiAccountIntegrationSupportEnabled &&
        !multiAccountSupportEnabled
      ) {
        setMultiAccountSupportEnabled(true);
      }
    }, [appState]);

    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "SkuBinMappings" }),
    );
    const [validationResult, setValidationResult] = useState(null);

    useEffect(() => {
      if (integrationsQuery.data) {
        const integrations = integrationsQuery.data.integrations;
        const marketplacesFromIntegrations = _.uniq(
          integrations.map((integration) => integration.marketplaces).flat(),
        ).filter((marketplace) => marketplace);
        const sellerIdsFromIntegrations = integrations
          .map((integration) => integration.sellerId)
          .filter((sellerId) => sellerId);
        setMarketplaces(marketplacesFromIntegrations);
        setSellerIds(sellerIdsFromIntegrations);
      }
    }, [
      integrationsQuery.data,
      integrationsQuery.loading,
      integrationsQuery.error,
    ]);

    useEffect(() => {
      if (getSkuBinMappingFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getSkuBinMappingFields.data) {
        setDashboardFields(getSkuBinMappingFields.data.skuBinMappingFields);
      }
    }, [
      getSkuBinMappingFields.loading,
      getSkuBinMappingFields.data,
      getSkuBinMappingFields.error,
    ]);

    useEffect(() => {
      if (uploadBulk.data) {
        mainQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadSkuBinMappings.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadSkuBinMappings
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      customersQuery.fetchData();
      warehousesQuery.fetchData();
      getSkuBinMappingFields.fetchData();
    }, []);

    useEffect(() => {
      loadFirstTimeData();
    }, [auth]);

    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const warehouses = auth.user.warehousesList;
        const customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }

        const filtersSet = {
          customer: auth.user.customersList.map((item) => item.id),
          warehouse: auth.user.warehousesList.map((item) => item.id),
        };
        entity.setFilters(filtersSet);
        mainQuery.fetchData({
          perPage: entity.perPage,
          filters: filtersSet,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }
    };

    useEffect(() => {
      if (getSku.data && getSku.data.specificInventory) {
        setFetchedSku(getSku.data.specificInventory);
      }

      if (getSku.error) {
        appState.setAlert(getSku.error.message, "error", 5000);
      }
    }, [getSku.loading, getSku.error, getSku.data]);

    useEffect(() => {
      if (deleteSkuBinMappingQuery.data) {
        appState.hideConfirmation();
        appState.setAlert(
          deleteSkuBinMappingQuery.data.deleteSkuBinMapping.message,
          "success",
          5000,
        );
        mainQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }

      if (deleteSkuBinMappingQuery.error) {
        appState.setAlert(
          deleteSkuBinMappingQuery.error.message,
          "success",
          5000,
        );
      }

      if (deleteSkuBinMappingQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      deleteSkuBinMappingQuery.data,
      deleteSkuBinMappingQuery.loading,
      deleteSkuBinMappingQuery.error,
    ]);

    useEffect(() => {
      if (
        getSpecificInventory.data &&
        getSpecificInventory.data.skuBinMapping
      ) {
        setFetchedInventory(getSpecificInventory.data.skuBinMapping);
      }

      if (getSpecificInventory.error) {
        appState.setAlert(getSpecificInventory.error.message, "error", 5000);
      }
    }, [
      getSpecificInventory.loading,
      getSpecificInventory.error,
      getSpecificInventory.data,
    ]);

    useEffect(() => {
      if (getUomBreakdown.data && getUomBreakdown.data.skuBinMapping) {
        setFetchedUomBreakdown(getUomBreakdown.data.skuBinMapping);
      }

      if (getUomBreakdown.error) {
        appState.setAlert(getUomBreakdown.error.message, "error", 5000);
      }
    }, [getUomBreakdown.loading, getUomBreakdown.error, getUomBreakdown.data]);

    useEffect(() => {
      if (updateInventory.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (updateInventory.data) {
        appState.setAlert(
          updateInventory.data.updateInventory.message,
          "success",
          5000,
        );
        setFetchedInventory(null);
        mainQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }

      if (updateInventory.error) {
        appState.setAlert(updateInventory.error.message, "error", 5000);
      }
    }, [updateInventory.error, updateInventory.loading, updateInventory.data]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this SKU-Location Map?",
        () => {
          deleteSkuBinMappingQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (mainQuery.data) {
        entity.setEntities({
          ...mainQuery.data.skuBinMappings,
          ...mainQuery.variables,
        });
      }
    }, [mainQuery.loading, mainQuery.error, mainQuery.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return mainQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const uploadSkuBinMappings = async (files) => {
      appState.setLoading();
      const rows = await readXlsxFile(files);
      for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
          continue;
        }

        const row = rows[i];
        if (!row[0] || !row[1]) {
          continue;
        }

        saveSkuBinMappingQueryMultiple.fetchData({
          sku: row[0],
          binLocation: row[1],
          size: row[2],
        });
      }
      const vars = {
        perPage: entity.perPage,
        pageNumber: 1,
        filters: entity.filters,
        paginated: false,
      };
      appState.setLoading();
      setTimeout(() => {
        mainQuery.fetchData(vars);
        appState.removeLoading();
      }, 1500);
    };

    useEffect(() => {
      if (
        saveSkuBinMappingQuery.data &&
        saveSkuBinMappingQuery.data.saveSkuBinMapping
      ) {
        appState.setAlert(
          saveSkuBinMappingQuery.data.saveSkuBinMapping.message,
          "success",
        );
        const vars = {
          perPage: entity.perPage,
          pageNumber: 1,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        };
        mainQuery.fetchData(vars);
        setSkuBinMap(null);
      }
      if (saveSkuBinMappingQuery.error) {
        appState.setAlert(saveSkuBinMappingQuery.error.message, "error", 5000);
      }
    }, [
      saveSkuBinMappingQuery.loading,
      saveSkuBinMappingQuery.error,
      saveSkuBinMappingQuery.data,
    ]);

    const onChangeMultiSelect = (field, value) => {
      const skuBinMapping = {
        ...selectedSkuMapping,
      };
      skuBinMapping[field] = value;
      setSelectedSkuMapping(skuBinMapping);
    };

    useEffect(() => {
      if (uploadSkuBinsQuery.loading) {
        appState.setLoading();
      }

      if (uploadSkuBinsQuery.error) {
        appState.setAlert(uploadSkuBinsQuery.error.message, "error", 5000);
        appState.removeLoading();
      }

      if (uploadSkuBinsQuery.data) {
        setTimeout(() => {
          appState.setAlert(
            uploadSkuBinsQuery.data.uploadSkuBins.message,
            "success",
            5000,
          );
          appState.removeLoading();
          entity.setFilters({});
          mainQuery.fetchData({
            perPage: entity.perPage,
            filters: {},
            paginated: false,
            pageNumber: 1,
            sort: entity.sort,
          });
        }, 4000);
      }
    }, [
      uploadSkuBinsQuery.data,
      uploadSkuBinsQuery.loading,
      uploadSkuBinsQuery.error,
    ]);

    useEffect(() => {
      if (allSkuBinMappingsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (
        allSkuBinMappingsQuery.data &&
        allSkuBinMappingsQuery.data.exportSkuBinMappings?.entities.length
      ) {
        allSkuBinMappingsQuery.data.exportSkuBinMappings =
          allSkuBinMappingsQuery.data.exportSkuBinMappings.entities.map(
            (item) => {
              let transformedItem = {};
              skuBinMappingExportFields.forEach((field) => {
                if (!field.nestedKey) {
                  if (field.enabled) {
                    transformedItem[field.name] =
                      item[field.correspondingValue];
                    if (item[field.correspondingValue] === null) {
                      transformedItem[field.name] = "N/A";
                    }

                    // Custom Transformations
                    if (
                      field.id === "customer" &&
                      item[field.correspondingValue] !== null &&
                      item[field.correspondingValue] !== undefined
                    ) {
                      transformedItem[field.name] =
                        auth && auth.user && auth.user.customersList
                          ? auth.user.customersList.find(
                              (i) => item[field.correspondingValue] === i.id,
                            )?.name
                          : "N/A";
                    }

                    if (
                      field.id === "warehouse" &&
                      item[field.correspondingValue] !== null &&
                      item[field.correspondingValue] !== undefined
                    ) {
                      transformedItem[field.name] =
                        auth && auth.user && auth.user.warehousesList
                          ? auth.user.warehousesList.find(
                              (i) => item[field.correspondingValue] === i.id,
                            )?.name
                          : "N/A";
                    }

                    if (
                      field.id === "createdAt" &&
                      item[field.correspondingValue]
                    ) {
                      const dateObject = moment(item[field.correspondingValue]);
                      transformedItem[field.name] = dateObject.isValid()
                        ? dateObject.format("YYYY-MM-DD hh:mm A")
                        : "N/A";
                    }
                  }
                } else {
                  if (field.enabled) {
                    transformedItem[field.name] =
                      item[field.nestedKey][field.correspondingValue];
                    if (
                      item[field.nestedKey][field.correspondingValue] ===
                        null ||
                      item[field.nestedKey][field.correspondingValue] ===
                        undefined
                    ) {
                      transformedItem[field.name] = "N/A";
                    }
                  }
                }
              });
              return transformedItem;
            },
          );
        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: true,
          title: "SKU-Location Report",
          useTextFile: false,
          useBom: true,
          // useKeysAsHeaders: true,
          filename: "SKU-Location Report - ".concat(moment().format("ll")),
          headers: skuBinMappingExportFields
            .filter((item) => item.enabled)
            .map((item) => item.name),
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(
          allSkuBinMappingsQuery.data.exportSkuBinMappings,
        );
      }
    }, [
      allSkuBinMappingsQuery.loading,
      allSkuBinMappingsQuery.error,
      allSkuBinMappingsQuery.data,
    ]);

    useEffect(() => {
      if (updateUomBreakdown.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (updateUomBreakdown.data) {
        appState.setAlert(
          updateUomBreakdown.data.uomBreakdown.message,
          "success",
          5000,
        );

        mainQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
      }

      if (updateUomBreakdown?.error) {
        appState.setAlert(updateUomBreakdown?.error?.message, "error", 5000);
      }
    }, [
      updateUomBreakdown?.error,
      updateUomBreakdown?.loading,
      updateUomBreakdown?.data,
    ]);

    const onChangeSkuBinMappingExportField = (key, enabled) => {
      setSkuBinMappingExportFields([
        ...skuBinMappingExportFields.map((item) => {
          if (item.id === key) {
            return { ...item, enabled };
          }
          return { ...item };
        }),
      ]);
    };

    const submitFetchedInventory = () => {
      appState.showConfirmation(
        "Confirm",
        `Are you sure you want to modify the stock for this SKU?`,
        () => {
          updateInventory.fetchData({
            id: fetchedInventory.id,
            stock:
              stockChangeType === "add"
                ? Math.abs(fetchedInventory.revisedStock)
                : -Math.abs(fetchedInventory.revisedStock),
            user: auth.user?.name,
            reason: fetchedInventory.reason,
            otherReason: fetchedInventory.otherReason,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const submitUomBreakdown = () => {
      appState.showConfirmation(
        "Confirm",
        `Are you sure you want to modify the UOM Breakdown for this SKU?`,
        () => {
          updateUomBreakdown.fetchData({
            id: fetchedUomBreakdown.id,
            quantity: fetchedUomBreakdown.quantity,
            user: auth.user?.name,
            adjustmentUom: fetchedUomBreakdown.adjustmentUom,
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const isValidLpn = () => {
      const { formFactor, nestedFormFactor, nestedFormFactorId } =
        skuBinMapDetails;
      const formFactorList = ["Each", "Case", "Carton", "Pallet"];
      // No need to validate if LPN details are not added
      if (!nestedFormFactor && !nestedFormFactorId) {
        return { isValid: true };
      }

      // Check for valid values
      if (!nestedFormFactorId || nestedFormFactorId.trim() === "") {
        return { isValid: false, error: "Missing 'LPN'" };
      }
      if (!nestedFormFactor) {
        return { isValid: false, error: "Missing 'LPN Form Factor'" };
      }
      if (nestedFormFactor && !formFactor) {
        return {
          isValid: false,
          error: "You can't add LPN without a Form Factor",
        };
      }
      if (!formFactorList.includes(formFactor)) {
        return {
          isValid: false,
          error: `Invalid 'Form Factor' : ${formFactor}`,
        };
      }
      if (!formFactorList.includes(nestedFormFactor)) {
        return {
          isValid: false,
          error: `Invalid 'LPN Form Factor' : ${nestedFormFactor}`,
        };
      }

      // check logic
      if (
        formFactorList.indexOf(nestedFormFactor) <
        formFactorList.indexOf(formFactor)
      ) {
        return {
          isValid: false,
          error: `Please ensure the 'LPN Form Factor' (${nestedFormFactor}) \
should not be smaller than the 'Form Factor' (${formFactor}). \
Choose a different 'LPN Form Factor'.`,
        };
      }
      return { isValid: true };
    };

    const validateSkuBinMap = () => {
      if (!skuBinMapDetails.sku) {
        return appState.setAlert(`SKU is missing`, "error", 5000);
      }
      if (!skuBinMapDetails.binLocation) {
        return appState.setAlert(`Bin Location is missing`, "error", 5000);
      }
      if (!skuBinMapDetails.formFactor) {
        return appState.setAlert(`Provide a form factor`, "error", 5000);
      }
      const validationResponse = isValidLpn();

      if (!validationResponse.isValid) {
        appState.setAlert(validationResponse.error, "error", 5000);
        return false;
      }
      return true;
    };

    const submitSkuBinMap = () => {
      if (!validateSkuBinMap()) {
        return;
      }
      saveSkuBinMappingQuery.fetchData({
        id: skuBinMapDetails.id,
        sku: skuBinMapDetails.sku,
        binLocation: skuBinMapDetails.binLocation,
        unallocatedQuantity: skuBinMapDetails.unallocatedQuantity,
        warehouses: skuBinMapDetails.warehouses,
        customers: skuBinMapDetails.customers,
        palletId: skuBinMapDetails.palletId,
        customer: skuBinMapDetails.customer,
        warehouse: skuBinMapDetails.warehouse,
        tote: skuBinMapDetails.tote,
        lotId: skuBinMapDetails.lotId,
        serialNumber: skuBinMapDetails.serialNumber,
        poId: skuBinMapDetails.poId,
        lineItemId: skuBinMapDetails.lineItemId,
        bestByDate: skuBinMapDetails.bestByDate,
        formFactor: skuBinMapDetails.formFactor,
        nestedFormFactor: skuBinMapDetails.nestedFormFactor,
        nestedFormFactorId: skuBinMapDetails.nestedFormFactorId,
        marketplace: skuBinMapDetails.marketplace,
        sellerId: skuBinMapDetails.sellerId,
      });
    };

    useEffect(() => {
      if (getBinTransferEligibility.data) {
        setBinTransferEligibility(
          getBinTransferEligibility.data.getBinTransferEligibility,
        );
      }
      if (getBinTransferEligibility.error) {
        appState.setAlert(
          getBinTransferEligibility.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      if (getBinTransferEligibility.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getBinTransferEligibility.data,
      getBinTransferEligibility.loading,
      getBinTransferEligibility.error,
    ]);

    const checkTransferSkuBinMapping = (skuBinMappingId) => {
      getBinTransferEligibility.fetchData({
        skuBinMappingId,
      });
    };

    return (
      <WrappedComponent
        binLocations={
          binLocationsQuery.data ? binLocationsQuery.data.binLocations : []
        }
        skuBinMappings={entity.entities}
        displaySkuBinMappings={entity.displayEntities}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        //
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        //
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          mainQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        uploadSkuBinMappings={() => uploadSkuBinsQuery.fetchData()}
        binBarcode={binBarcode}
        skuQrCode={skuQrCode}
        setBinBarcode={setBinBarcode}
        setSkuQrCode={setSkuQrCode}
        getSku={(id) => {
          getSku.fetchData({ id });
        }}
        fetchedSku={fetchedSku}
        setFetchedSku={setFetchedSku}
        setSkuBinMap={(updatedSkuBinMap) => {
          if (!updatedSkuBinMap) {
            setSkuBinMap(null);
            return;
          }

          if (
            updatedSkuBinMap.customer &&
            updatedSkuBinMap.warehouse &&
            multiAccountSupportEnabled
          ) {
            integrationsQuery.fetchData({
              filters: {
                customer: updatedSkuBinMap.customer,
                warehouse: updatedSkuBinMap.warehouse,
              },
            });
          }
          setSkuBinMap(updatedSkuBinMap);
        }}
        setSku={setSku}
        setBinLocation={setBinLocation}
        skuDetails={skuDetails}
        binLocationDetails={binLocationDetails}
        skuBinMapDetails={skuBinMapDetails}
        getSpecificInventory={(id) => {
          getSpecificInventory.fetchData({ id });
        }}
        getUomBreakdown={(id, productId) => {
          getUomBreakdown.fetchData({ id });
          getSku.fetchData({ id: productId });
        }}
        fetchedInventory={fetchedInventory}
        setFetchedInventory={setFetchedInventory}
        fetchedUomBreakdown={fetchedUomBreakdown}
        setFetchedUomBreakdown={setFetchedUomBreakdown}
        updateUomBreakdown={updateUomBreakdown}
        stockChangeType={stockChangeType}
        setStockChangeType={setStockChangeType}
        submitFetchedInventory={submitFetchedInventory}
        submitUomBreakdown={submitUomBreakdown}
        submitSkuBinMap={submitSkuBinMap}
        deleteButtonClicked={deleteButtonClicked}
        submitFilters={() => {
          setShowFilters(false);
          mainQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          mainQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            mainQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });

          mainQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        onChangeMultiSelect={onChangeMultiSelect}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          // setFilters({
          //   ...filters,
          //   customers: [e],
          // });
          // skuBinMappingsQuery.fetchData({
          //   filters: {
          //     ...filters,
          //     customers: [e],
          //   },
          // });
        }}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={(e) => {
          setSelectedWarehouse(e);
          // setFilters({
          //   ...filters,
          //   warehouses: [e],
          // });
          // skuBinMappingsQuery.fetchData({
          //   filters: {
          //     ...filters,
          //     warehouses: [e],
          //   },
          // });
        }}
        exportToCsv={() => setShowExportSkuBinMappingFieldSelector(true)}
        showExportSkuBinMappingFieldSelector={
          showExportSkuBinMappingFieldSelector
        }
        setShowExportSkuBinMappingFieldSelector={
          setShowExportSkuBinMappingFieldSelector
        }
        skuBinMappingExportFields={skuBinMappingExportFields}
        onSubmitSkuBinMappingExportFields={() => {
          allSkuBinMappingsQuery.fetchData({
            filters: { ...entity.filters, all: true },
          });
          setShowExportSkuBinMappingFieldSelector(false);
        }}
        onChangeSkuBinMappingExportField={onChangeSkuBinMappingExportField}
        writable={props.writable}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        marketplaces={marketplaces}
        sellerIds={sellerIds}
        appState={appState}
        checkTransferSkuBinMapping={checkTransferSkuBinMapping}
        binTransferEligibility={binTransferEligibility}
        setBinTransferEligibility={setBinTransferEligibility}
      />
    );
  };
};

export default withSkuBinMappingsLogic;
