import { useEffect, useState, useContext } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { AppStateContext } from "#contexts/appState";
import SlideOverPanel from "#components/common/SlideOverPanel";
import { Tooltip } from "antd";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import OrderHistoryTable from "#components/salesManagement/OrderHistoryTable";
import CrmOrderHistoryTable from "#components/salesManagement/CrmOrderHistoryTable";
import { Country, State } from "country-state-city";
import { useQuery } from "#hooks/useQuery";
import SlideOverModal from "#components/common/SlideOverModal";
import {
  EDIT_CRM_SHIPPING_ADDRESS,
  DELETE_CRM_SHIPPING_ADDRESS,
  EDIT_CRM_ACCOUNT_INFO,
  CREATE_CRM_SHIPPING_ADDRESS,
  GET_PLANTATION_ZONE_CODE,
} from "#mutations/index";
import { CRM_CUSTOMERS } from "#queries";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import DatePicker from "#newUiComponents/commons/DatePicker";
import { Navbar } from "./NavBar";
import moment from "moment-timezone";
import { generatePlantingZoneCodes } from "#utils/helper-functions";

const ALERT_VISIBILITY_IN_MS = 5000;

const tabsEnums = {
  SHIPPING_ADDRESS: "shippingAddress",
  ORDER: "order",
};

const editEnum = {
  CUSTOMER_DETAILS: "customerDetails",
  CONTACT_DETAILS: "contactDetails",
  CATALOG_DETAILS: "catalogDetails",
  PAYMENT_DETAILS: "paymentDetails",
  SHIPPING_ADDRESS: "shippingAddress",
  CREATE_SHIPPING_ADDRESS: "createShippingAddress",
};

const initialAddressInput = {
  country: "US",
};

const orderHistoryType = {
  SYSTEM: "system",
  CRM: "crm",
};

const CustomerDetails = ({
  crmCustomerData,
  onShowFullOrderHistory,
  setCrmCustomerData,
}) => {
  const appState = useContext(AppStateContext);

  const crmCustomersQuery = useQuery(CRM_CUSTOMERS);
  const editCrmShippingAddressQuery = useQuery(EDIT_CRM_SHIPPING_ADDRESS);
  const deleteCrmCustomerQuery = useQuery(DELETE_CRM_SHIPPING_ADDRESS);
  const editCrmAccountInfo = useQuery(EDIT_CRM_ACCOUNT_INFO);
  const createCrmShippingAddressQuery = useQuery(CREATE_CRM_SHIPPING_ADDRESS);
  const getPlantationZoneCodeQuery = useQuery(GET_PLANTATION_ZONE_CODE);

  const [activeTab, setActiveTab] = useState(tabsEnums.SHIPPING_ADDRESS);
  const [acitveEditSection, setActiveEditSection] = useState(null);
  const [addressInput, setAddressInput] = useState(initialAddressInput);
  const [shippingAddressId, setShippingAddressId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(
    initialAddressInput.country,
  );
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(crmCustomerData);
  const [selectedOrderHistoryType, setSelectedOrderHistoryType] = useState(
    orderHistoryType.SYSTEM,
  );
  const [showShippingHistoryPanel, setShowShippingHistoryPanel] =
    useState(false);
  const [editNotes, setEditNotes] = useState(null);
  const [previousNotes, setPreviousNotes] = useState(null);
  const [catalogSuppressionList, setCatalogSuppressionList] = useState([
    {
      label: "Dead",
      value: "Dead",
    },
    {
      label: "Bad Customer",
      value: "Bad Customer",
    },
    {
      label: "Don't Send",
      value: "Don't Send",
    },
  ]);
  const [paymentTermsList, setPaymentTermsList] = useState([
    {
      label: "Prepaid",
      value: "Prepaid",
    },
    {
      label: "Net 30",
      value: "Net 30",
    },
  ]);
  const [editCustomerDetailsSection, setEditCustomerDetailsSection] =
    useState(null);
  const [notesValidateError, setNotesValidateError] = useState(false);
  const plantingZoneCodes = generatePlantingZoneCodes();

  const [steps, setSteps] = useState([
    {
      tag: "customerDetails",
      name: "Customer Details",
      href: "#",
      id: "01",
      status: "current",
      visible: true,
    },
    {
      tag: "accountPreferences",
      name: "Account Preferences",
      href: "#",
      id: "02",
      status: "upcoming",
      visible: true,
    },
  ]);
  const [selectedStep, setSelectedStep] = useState(steps[0]);
  const [showSubmit, setShowSubmit] = useState(false);

  useEffect(() => {
    const updateStates = () => {
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry);
        setFilteredStates(states);
        const stateIsValid = states.some(
          (state) => state.isoCode === selectedState,
        );
        if (!stateIsValid) {
          setSelectedState("");
        }
      } else {
        setFilteredStates([]);
        setSelectedState("");
      }
    };

    updateStates();
  }, [selectedCountry]);

  useEffect(() => {
    if (editCrmShippingAddressQuery.error) {
      appState.setAlert(
        editCrmShippingAddressQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (editCrmShippingAddressQuery.data) {
      appState.setAlert(
        editCrmShippingAddressQuery.data.editCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      closeEditSection();
    }

    if (editCrmShippingAddressQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    editCrmShippingAddressQuery.error,
    editCrmShippingAddressQuery.data,
    editCrmShippingAddressQuery.loading,
  ]);

  useEffect(() => {
    if (editCrmAccountInfo.error) {
      appState.setAlert(
        editCrmAccountInfo.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (editCrmAccountInfo.data) {
      appState.setAlert(
        editCrmAccountInfo.data.editCrmCustomer.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      closeEditSection();
    }

    if (editCrmAccountInfo.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    editCrmAccountInfo.error,
    editCrmAccountInfo.data,
    editCrmAccountInfo.loading,
  ]);

  useEffect(() => {
    if (deleteCrmCustomerQuery.error) {
      appState.setAlert(
        deleteCrmCustomerQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (deleteCrmCustomerQuery.data) {
      appState.setAlert(
        deleteCrmCustomerQuery.data.deleteCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
    }

    if (deleteCrmCustomerQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    deleteCrmCustomerQuery.error,
    deleteCrmCustomerQuery.data,
    deleteCrmCustomerQuery.loading,
  ]);

  useEffect(() => {
    if (createCrmShippingAddressQuery.error) {
      appState.setAlert(
        createCrmShippingAddressQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (createCrmShippingAddressQuery.data) {
      appState.setAlert(
        createCrmShippingAddressQuery.data.createCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      closeEditSection();
    }

    if (createCrmShippingAddressQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    createCrmShippingAddressQuery.error,
    createCrmShippingAddressQuery.data,
    createCrmShippingAddressQuery.loading,
  ]);

  useEffect(() => {
    if (selectedCustomer && crmCustomersQuery?.data?.crmCustomers) {
      setSelectedCustomer(crmCustomersQuery.data.crmCustomers?.entities[0]);
      setCrmCustomerData(crmCustomersQuery.data.crmCustomers?.entities[0]);
    }

    if (crmCustomersQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    crmCustomersQuery.error,
    crmCustomersQuery.data,
    crmCustomersQuery.loading,
  ]);

  const closeEditSection = () => {
    setActiveEditSection(null);
    setEditCustomerDetailsSection(null);
    // Reset form values
    setAddressInput(initialAddressInput);
    setPreviousNotes(null);
    setSteps(
      steps.map((step) => ({
        ...step,
        status: step.tag === "customerDetails" ? "current" : "upcoming",
      })),
    );
    setSelectedStep(steps[0]);
  };

  const handleDeleteAddress = (address) => {
    if (activeTab === tabsEnums.SHIPPING_ADDRESS) {
      deleteCrmCustomerQuery.fetchData({
        deleteCrmShippingAddressId: address.id,
        oldCrmShippingAddress: address,
        crmCustomerId: selectedCustomer.hrid,
      });
    }
  };

  const getPopupTitle = () => {
    switch (acitveEditSection) {
      case editEnum.CUSTOMER_DETAILS:
        return "Edit Customer Details";
      case editEnum.CONTACT_DETAILS:
        return "Edit Contact Details";
      case editEnum.CATALOG_DETAILS:
        return "Edit Catalog Details";
      case editEnum.PAYMENT_DETAILS:
        return "Edit Payment Details";
      case editEnum.SHIPPING_ADDRESS:
        return "Edit Shipping Address";
      case editEnum.CREATE_SHIPPING_ADDRESS:
        return "Add Shipping Address";
      default:
        return "";
    }
  };

  const validateAddress = () => {
    if (!addressInput) {
      return false;
    }

    const { firstName, lastName, line1, state, country, zip, city } =
      addressInput;

    const isValid =
      firstName && lastName && line1 && state && country && zip && city;

    if (!isValid) {
      appState.setAlert(
        "Address entered is invalid.",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    return isValid;
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));

    setAddressInput((prev) => ({
      ...prev,
      country: newCountry,
      state: "",
    }));
  };

  const handleStateChange = (newState) => {
    setSelectedState(newState);

    setAddressInput((prev) => ({
      ...prev,
      state: newState,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setAddressInput((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleEditAddress = (address) => {
    setAddressInput(address);
    setShippingAddressId(address?.id);
    setActiveEditSection(editEnum.SHIPPING_ADDRESS);
  };

  const popupSaveHandler = () => {
    if (!validateAddress()) {
      return;
    }
    const SaveCustomerDetailsCase =
      acitveEditSection || editCustomerDetailsSection;
    switch (SaveCustomerDetailsCase) {
      case editEnum.CUSTOMER_DETAILS:
      case editEnum.CONTACT_DETAILS:
      case editEnum.CATALOG_DETAILS:
      case editEnum.PAYMENT_DETAILS:
        const {
          businessName,
          notes,
          isVIP,
          dataSharing,
          paymentTerms,
          taxExemption,
          catalogRequestedJS,
          catalogRequestedKGS,
          catalogRequestedVE,
          catalogSuppressionJS,
          catalogSuppressionKGS,
          catalogSuppressionVE,
          lastCatalogSentDateJS,
          lastCatalogSentDateKGS,
          lastCatalogSentDateVE,
          taxExemptCertificateExpiryDate,
          ...rest
        } = addressInput;

        const { hrid, ...restDetails } = selectedCustomer;
        const editedNotes = restDetails?.notes
          ? editNotes
            ? restDetails?.notes + "|" + editNotes
            : restDetails?.notes
          : editNotes
            ? editNotes
            : null;
        const editedNotesDateTimestamp = restDetails?.notesDateTimestamp
          ? editNotes
            ? restDetails?.notesDateTimestamp +
              "|" +
              moment().utc().unix().toString()
            : restDetails?.notesDateTimestamp
          : editNotes
            ? moment().utc().unix().toString()
            : null;

        const updatedCatalogMetadata = {
          catalogRequestedVE,
          catalogRequestedJS,
          catalogRequestedKGS,
          catalogSuppressionVE,
          catalogSuppressionJS,
          catalogSuppressionKGS,
          lastCatalogSentDateVE,
          lastCatalogSentDateJS,
          lastCatalogSentDateKGS,
        };

        let editCrmCustomerInput = {
          ...restDetails,
          notes: editedNotes,
          isVIP,
          dataSharing,
          paymentTerms,
          taxExemption,
          taxExemptCertificateExpiryDate,
          notesDateTimestamp: editedNotesDateTimestamp,
          accountAddress: {
            ...rest,
            contactNumber: rest?.contactNumber ? rest?.contactNumber : null,
            email: rest?.email ? rest?.email : null,
            line2: rest?.line2 ? rest?.line2 : null,
            plantingZoneCode: rest?.plantingZoneCode
              ? rest?.plantingZoneCode
              : null,
          },
          catalogMetadata: updatedCatalogMetadata,
        };
        const oldCrmCustomer = { ...selectedCustomer };
        delete oldCrmCustomer.hrid;
        editCrmAccountInfo.fetchData({
          editCrmCustomerInput,
          oldCrmCustomer,
          crmCustomerId: selectedCustomer.hrid,
        });
        break;
      case editEnum.SHIPPING_ADDRESS:
        validateDuplicateShippingAddress(
          editEnum.SHIPPING_ADDRESS,
          addressInput,
        );
        break;
      case editEnum.CREATE_SHIPPING_ADDRESS:
        validateDuplicateShippingAddress(
          editEnum.CREATE_SHIPPING_ADDRESS,
          addressInput,
        );
      default:
    }
  };

  const nameEdit =
    acitveEditSection == editEnum.CONTACT_DETAILS ||
    acitveEditSection == editEnum.CUSTOMER_DETAILS ||
    acitveEditSection == editEnum.SHIPPING_ADDRESS ||
    editEnum.CREATE_SHIPPING_ADDRESS;

  const contactEdit =
    acitveEditSection == editEnum.CONTACT_DETAILS ||
    acitveEditSection == editEnum.SHIPPING_ADDRESS ||
    editEnum.CREATE_SHIPPING_ADDRESS;

  const addressOnlyEdit =
    acitveEditSection == editEnum.SHIPPING_ADDRESS ||
    editEnum.CREATE_SHIPPING_ADDRESS;

  const setEditFormValues = () => {
    const addressInputValue = {
      ...selectedCustomer?.accountAddress,
      notes: selectedCustomer?.notes,
      businessName: selectedCustomer?.businessName,
      isVIP: selectedCustomer?.isVIP === null ? "no" : selectedCustomer?.isVIP,
      dataSharing:
        selectedCustomer?.dataSharing === null
          ? "no"
          : selectedCustomer?.dataSharing,
      paymentTerms: selectedCustomer?.paymentTerms,
      taxExemption:
        selectedCustomer?.taxExemption === null
          ? "no"
          : selectedCustomer?.taxExemption,
      catalogRequestedJS:
        selectedCustomer?.catalogMetadata?.catalogRequestedJS === null
          ? "no"
          : selectedCustomer?.catalogMetadata?.catalogRequestedJS,
      catalogRequestedKGS:
        selectedCustomer?.catalogMetadata?.catalogRequestedKGS === null
          ? "no"
          : selectedCustomer?.catalogMetadata?.catalogRequestedKGS,
      catalogRequestedVE:
        selectedCustomer?.catalogMetadata?.catalogRequestedVE === null
          ? "no"
          : selectedCustomer?.catalogMetadata?.catalogRequestedVE,
      catalogSuppressionJS:
        selectedCustomer?.catalogMetadata?.catalogSuppressionJS,
      catalogSuppressionKGS:
        selectedCustomer?.catalogMetadata?.catalogSuppressionKGS,
      catalogSuppressionVE:
        selectedCustomer?.catalogMetadata?.catalogSuppressionVE,
      lastCatalogSentDateJS:
        selectedCustomer?.catalogMetadata?.lastCatalogSentDateJS,
      lastCatalogSentDateKGS:
        selectedCustomer?.catalogMetadata?.lastCatalogSentDateKGS,
      lastCatalogSentDateVE:
        selectedCustomer?.catalogMetadata?.lastCatalogSentDateVE,
      taxExemptCertificateExpiryDate:
        selectedCustomer?.taxExemptCertificateExpiryDate,
    };
    setAddressInput(addressInputValue);
    setEditNotes(null);
    setSelectedStep(steps[0]);
    if (selectedCustomer?.notes && selectedCustomer?.notesDateTimestamp) {
      const previousNotesList = selectedCustomer?.notes?.split("|");
      const previousNotesDateList =
        selectedCustomer?.notesDateTimestamp?.split("|");

      const previousNotes = previousNotesList
        .map((note, index) => ({
          note: note,
          date: previousNotesDateList[index],
        }))
        .sort((a, b) => parseInt(b.date) - parseInt(a.date))
        .map((note, index) => ({
          note: note?.note,
          date: note?.date
            ? moment.unix(note?.date).format("MMM DD, YYYY")
            : null,
        }));
      setPreviousNotes(previousNotes);
    }
  };

  const getPlantationZoneCode = async (isPlantingZoneAddress, zipCode) => {
    if (zipCode && isPlantingZoneAddress) {
      try {
        const getPlantationZoneCodeResponse =
          await getPlantationZoneCodeQuery.fetchData({
            zipcode: zipCode,
          });
        if (getPlantationZoneCodeResponse.error) {
        } else if (getPlantationZoneCodeResponse?.data) {
          if (
            getPlantationZoneCodeResponse?.data?.getZipPlantationZoneMapping
              ?.mapping &&
            getPlantationZoneCodeResponse?.data?.getZipPlantationZoneMapping
              ?.mapping?.length !== 0
          ) {
            const baseHz =
              getPlantationZoneCodeResponse?.data?.getZipPlantationZoneMapping
                ?.mapping[0]["hz"];
            const matchPlantingZoneCode = plantingZoneCodes?.find(
              (code) => code.hz === baseHz,
            );
            handleInputChange({
              target: {
                name: "plantingZoneCode",
                value: matchPlantingZoneCode
                  ? matchPlantingZoneCode?.value
                  : "",
                type: "text",
              },
            });
          } else {
            handleInputChange({
              target: {
                name: "plantingZoneCode",
                value: "",
                type: "text",
              },
            });
          }
        }
      } catch (error) {}
    }
  };

  const validateDuplicateShippingAddress = (
    shippingAddressModalType,
    addressInput,
  ) => {
    let duplicateShippingAddress = selectedCustomer?.shippingAddresses?.filter(
      (addr) => {
        return (
          addr?.line1 === addressInput.line1 &&
          addr?.zip === addressInput?.zip &&
          addr?.firstName === addressInput?.firstName &&
          addr?.lastName === addressInput?.lastName
        );
      },
    );

    if (shippingAddressModalType === editEnum.CREATE_SHIPPING_ADDRESS) {
      duplicateShippingAddress = duplicateShippingAddress?.length > 0;
    }
    if (shippingAddressModalType === editEnum.SHIPPING_ADDRESS) {
      duplicateShippingAddress =
        duplicateShippingAddress?.length > 1
          ? true
          : duplicateShippingAddress?.length === 1 &&
              duplicateShippingAddress[0]["id"] !== shippingAddressId
            ? true
            : false;
    }

    if (duplicateShippingAddress) {
      appState.showNewConfirmation(
        shippingAddressModalType === editEnum.CREATE_SHIPPING_ADDRESS
          ? "Add Shipping Address"
          : shippingAddressModalType === editEnum.SHIPPING_ADDRESS
            ? "Edit Shipping Address"
            : "",
        shippingAddressModalType === editEnum.CREATE_SHIPPING_ADDRESS ? (
          <span>
            The shipping address you are attempting to add is already present in
            the system. Would you like to proceed with adding this address
            again?
          </span>
        ) : shippingAddressModalType === editEnum.SHIPPING_ADDRESS ? (
          <span>
            The shipping address you are attempting to edit is already present
            in the system. Would you like to proceed with editing this address
            again?
          </span>
        ) : (
          ""
        ),
        () => {
          addEditShippingAddressDetails(shippingAddressModalType, addressInput);
        },
        () => {},
        "Cancel",
        "Proceed",
      );
    } else {
      addEditShippingAddressDetails(shippingAddressModalType, addressInput);
    }
  };
  const addEditShippingAddressDetails = (
    shippingAddressModalType,
    addressInput,
  ) => {
    if (shippingAddressModalType === editEnum.CREATE_SHIPPING_ADDRESS) {
      createCrmShippingAddressQuery.fetchData({
        createCrmShippingAddressInput: {
          crmCustomerId: selectedCustomer.id,
          shippingAddress: addressInput,
        },
      });
    } else if (shippingAddressModalType === editEnum.SHIPPING_ADDRESS) {
      const oldCrmShippingAddress = selectedCustomer?.shippingAddresses?.find(
        (address) => address.id === shippingAddressId,
      );
      editCrmShippingAddressQuery.fetchData({
        editCrmShippingAddressInput: {
          ...addressInput,
          id: shippingAddressId,
        },
        oldCrmShippingAddress,
        crmCustomerId: selectedCustomer.hrid,
      });
    }
  };

  const setNextStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 2);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setShowSubmit(false);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const FooterActionForm = ({
    selectedCustomer,
    steps,
    selectedStep,
    setPrevStep,
    setNextStep,
    showSubmit,
    onSubmit,
    onCancel,
    notesValidateError,
  }) => {
    return (
      <div className="mt-4 flex w-full items-center justify-between space-x-4 bg-white">
        <button
          className="p-2 text-base font-semibold underline"
          onClick={onCancel}>
          Cancel
        </button>

        <div className="flex gap-4">
          {selectedStep.id !== steps[0].id && (
            <button
              onClick={setPrevStep}
              className={`rounded-md px-4 py-2 text-base font-semibold ${
                selectedStep?.id === steps[0]?.id
                  ? "cursor-not-allowed border border-mediumGray text-mediumGray"
                  : "cursor-pointer border border-primaryAccent text-primaryAccent"
              }`}>
              Previous
            </button>
          )}

          {selectedStep.id === steps[steps.length - 2].id && (
            <button
              onClick={setNextStep}
              disabled={
                notesValidateError ||
                !(
                  selectedCustomer?.firstName &&
                  selectedCustomer?.lastName &&
                  selectedCustomer?.line1 &&
                  selectedCustomer?.state &&
                  selectedCustomer?.country &&
                  selectedCustomer?.zip &&
                  selectedCustomer?.city &&
                  selectedCustomer?.email &&
                  selectedCustomer?.contactNumber
                )
              }
              className={`rounded-md px-4 py-2 text-base font-semibold text-white ${
                notesValidateError ||
                !(
                  selectedCustomer?.firstName &&
                  selectedCustomer?.lastName &&
                  selectedCustomer?.line1 &&
                  selectedCustomer?.state &&
                  selectedCustomer?.country &&
                  selectedCustomer?.zip &&
                  selectedCustomer?.city &&
                  selectedCustomer?.email &&
                  selectedCustomer?.contactNumber
                )
                  ? "cursor-not-allowed border bg-gray-400"
                  : "cursor-pointer bg-primaryAccent"
              }`}>
              Next
            </button>
          )}

          {showSubmit && selectedStep.id !== steps[0].id && (
            <button
              onClick={onSubmit}
              disabled={
                selectedCustomer?.taxExemption === "yes" &&
                !selectedCustomer?.taxExemptCertificateExpiryDate
              }
              className={`rounded-md px-4 py-2 text-base font-semibold text-white ${
                selectedCustomer?.taxExemption === "yes" &&
                !selectedCustomer?.taxExemptCertificateExpiryDate
                  ? "cursor-not-allowed border bg-gray-400"
                  : "cursor-pointer bg-primaryAccent"
              }`}>
              Save
            </button>
          )}
        </div>
      </div>
    );
  };

  const getPopupSubTitle = () => {
    switch (acitveEditSection) {
      case editEnum.CUSTOMER_DETAILS:
        return "This screen will be used to update customer account information.";
      case editEnum.CONTACT_DETAILS:
        return "This screen will be used to update customer contact information.";
      case editEnum.CATALOG_DETAILS:
        return "This screen will be used to update catalog information.";
      case editEnum.PAYMENT_DETAILS:
        return "This screen will be used to update payment information.";
      case editEnum.SHIPPING_ADDRESS:
        return "This screen will be used to update shipping address information.";
      case editEnum.ADD_SHIPPING_ADDRESS:
        return "This screen will be used to add shipping address information.";
      case editEnum.CREATE_SHIPPING_ADDRESS:
        return "This screen will be used to add shipping address information.";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (crmCustomerData && crmCustomerData?.accountAddress?.plantingZoneCode) {
      const plantingZoneCodeHZ =
        crmCustomerData?.accountAddress?.plantingZoneCode;
      const matchPlantingZoneCode = plantingZoneCodes?.find(
        (code) => code.hz === plantingZoneCodeHZ,
      );
      if (matchPlantingZoneCode) {
        setSelectedCustomer((prev) => ({
          ...prev,
          accountAddress: {
            ...prev?.accountAddress,
            plantingZoneCode: matchPlantingZoneCode?.value,
          },
        }));
      }
    }
  }, []);

  return (
    <div className="w-full flex-col items-center p-5">
      <div className="details-container flex flex-col items-center gap-8">
        <div className="flex w-full flex-col items-center gap-8">
          <div className="grid w-full grid-cols-2 gap-12">
            <div
              className="col-span-1 rounded-md border border-gray-300 p-4"
              style={{ height: "15rem" }}>
              <div className="flex justify-between">
                <div className="flex w-full flex-col items-start gap-1">
                  <p className="text-xl font-semibold text-primaryAccent">
                    {selectedCustomer?.accountAddress?.firstName}{" "}
                    {selectedCustomer?.accountAddress?.lastName}
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Business Name: {selectedCustomer?.businessName}
                  </p>
                  <p className="text-sm font-medium text-gray-500">
                    Customer ID: {selectedCustomer?.hrid}
                  </p>
                  {/* TODO: Add the other fields, for now it's hard-coded */}
                  <div className="flex w-full flex-row justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      VE Customer ID: ARHON298
                    </p>
                    <p className="text-sm font-medium text-gray-900">
                      Joining Date: 12 Aug 2022
                    </p>
                  </div>
                  <div className="flex w-full flex-row justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      JS Customer ID: ARHON298
                    </p>
                    <p className="text-sm font-medium text-gray-900">
                      Joining Date: 12 Aug 2022
                    </p>
                  </div>
                  <div className="flex w-full flex-row justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      KGS Customer ID: ARHON298
                    </p>
                    <p className="text-sm font-medium text-gray-900">
                      Joining Date: 12 Aug 2022
                    </p>
                  </div>
                </div>
                <EditButton
                  onClick={() => {
                    setEditFormValues();
                    setEditCustomerDetailsSection(editEnum.CUSTOMER_DETAILS);
                  }}
                />
              </div>
            </div>
            <div
              className="col-span-1 rounded-md border border-gray-300 p-4"
              style={{ height: "15rem" }}>
              <div className="flex justify-between">
                <div className="flex w-full flex-col items-start gap-1">
                  <p className="text-xl font-semibold text-primaryAccent">
                    Contact Details
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Phone Number:{" "}
                    {selectedCustomer?.accountAddress?.contactNumber || "N/A"}
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Email Address:{" "}
                    {selectedCustomer?.accountAddress?.email || "N/A"}
                  </p>
                  <p className="mt-5 text-sm font-medium text-gray-900">
                    Mailing Address
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    {selectedCustomer?.accountAddress?.line1 || ""}
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    {selectedCustomer?.accountAddress?.line2 || ""}
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    {selectedCustomer?.accountAddress?.city || ""},{" "}
                    {selectedCustomer?.accountAddress?.state || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add this section after removing hardcodings 
        <div className="flex w-full flex-col items-center gap-8">
          <div className="grid w-full grid-cols-2 gap-12">
            <div
              className="col-span-1 rounded-md border border-gray-300 p-4"
              style={{ height: "15rem" }}>
              <div className="flex justify-between">
                <div className="flex w-full flex-col items-start gap-1">
                  <p className="text-xl font-semibold text-primaryAccent">
                    Catalog & Privacy
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Catalog Requested:
                  </p>
                  <div className="flex w-full flex-row justify-between">
                    <p className="text-sm font-medium text-gray-900">VE: Yes</p>
                    <p className="text-sm font-medium text-gray-900">
                      Last Sent: 12 Aug 2022
                    </p>
                  </div>
                  <div className="flex w-full flex-row justify-between">
                    <p className="text-sm font-medium text-gray-900">JS: Yes</p>
                    <p className="text-sm font-medium text-gray-900">
                      Last Sent: 12 Aug 2022
                    </p>
                  </div>
                  <div className="flex w-full flex-row justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      KSG: Yes
                    </p>
                    <p className="text-sm font-medium text-gray-900">
                      Last Sent: 12 Aug 2022
                    </p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    Privacy: No
                  </p>
                </div>
                <EditButton />
              </div>
            </div>
            <div
              className="col-span-1 rounded-md border border-gray-300 p-4"
              style={{ height: "15rem" }}>
              <div className="flex justify-between gap-1">
                <div className="flex w-full flex-col items-start gap-1">
                  <p className="text-xl font-semibold text-primaryAccent">
                    Payment & Tax Status
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Payment Terms: Net 30
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Tax Exepmtion Certificate Expiry: 09 Sep 2025
                  </p>
                </div>
                <EditButton />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="details-tab-container flex w-full flex-col items-center py-8">
        <div className="flex w-full border-b border-gray-300">
          <nav className="flex space-x-4" aria-label="Tabs">
            <button
              onClick={() => setActiveTab(tabsEnums.SHIPPING_ADDRESS)}
              className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                activeTab === tabsEnums.SHIPPING_ADDRESS
                  ? "border-224E73 text-224E73"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              }`}>
              Associated Shipping Address Book
            </button>
            <button
              onClick={() => setActiveTab(tabsEnums.ORDER)}
              className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                activeTab === tabsEnums.ORDER
                  ? "border-224E73 text-224E73"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              }`}>
              Order History
            </button>
          </nav>
        </div>
        {activeTab === tabsEnums.SHIPPING_ADDRESS && (
          <div className="flex w-full flex-col items-center gap-8">
            <div className="flex w-full flex-row items-end justify-between py-4">
              <p className="text-xl font-semibold text-primaryAccent">
                Associated Shipping Address Book
              </p>
              {selectedCustomer?.shippingAddresses?.length > 3 && (
                <p
                  onClick={() => {
                    setShowShippingHistoryPanel(true);
                  }}
                  className="cursor-pointer text-sm font-medium text-primaryAccent">
                  See All Addresses &rarr;
                </p>
              )}
            </div>
            <div className="flex w-full flex-col gap-8">
              {selectedCustomer?.shippingAddresses
                ?.slice(0, 3)
                .map((address, index) => (
                  <div
                    key={index}
                    className="relative border border-gray-300 py-4">
                    <div className="flex items-center">
                      <label
                        htmlFor="address"
                        className="pointer-events-none ml-3 flex-grow">
                        <div className="flex flex-row gap-4 py-2">
                          <p className="text-xl font-medium leading-none text-black">
                            {[address?.firstName, address?.lastName]
                              .filter(Boolean)
                              .join(" ")}
                          </p>
                          <p className="text-sm font-medium text-darkBlue">
                            {address?.plantingZoneCode
                              ? address?.plantingZoneCode
                              : ""}
                          </p>
                        </div>
                        <p className="text-sm text-gray-500">
                          {[
                            address?.line1,
                            address?.line2,
                            address?.city,
                            address?.state,
                            address?.country,
                            address?.zip,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                        <p className="text-sm text-gray-500">
                          {[address?.contactNumber, address?.email]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                      </label>
                    </div>
                    <div className="absolute right-4 top-1/2 flex -translate-y-1/2 transform space-x-2">
                      <PencilIcon
                        className="h-5 w-5 cursor-pointer"
                        style={{ color: "#224E73" }}
                        onClick={() => {
                          handleEditAddress(address);
                        }}
                      />
                      <TrashIcon
                        className="h-5 w-5 cursor-pointer"
                        style={{ color: "#C93A31" }}
                        onClick={() => {
                          appState.showConfirmation(
                            "Delete",
                            "Are you sure you want to delete this address?",
                            () => {
                              handleDeleteAddress(address);
                              appState.hideConfirmation();
                            },
                            appState.hideConfirmation,
                            "Yes, delete",
                            "No, cancel",
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex w-full justify-start">
              <button
                type="button"
                onClick={() => {
                  setAddressInput(initialAddressInput);
                  setActiveEditSection(editEnum.CREATE_SHIPPING_ADDRESS);
                }}
                className={`cursor-pointer py-2 text-base font-semibold text-primaryAccent underline`}>
                + Add Shipping Address
              </button>
            </div>
          </div>
        )}
        {activeTab === tabsEnums.ORDER && (
          <div className="order-tab-container flex w-full flex-col items-center py-8">
            <div className="flex w-full">
              <nav className="flex space-x-4" aria-label="Tabs">
                <button
                  onClick={() =>
                    setSelectedOrderHistoryType(orderHistoryType.SYSTEM)
                  }
                  className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                    selectedOrderHistoryType === orderHistoryType.SYSTEM
                      ? "border-224E73 text-224E73"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}>
                  System Order History
                </button>
                <button
                  onClick={() =>
                    setSelectedOrderHistoryType(orderHistoryType.CRM)
                  }
                  className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                    selectedOrderHistoryType === orderHistoryType.CRM
                      ? "border-224E73 text-224E73"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}>
                  CRM Order History
                </button>
              </nav>
            </div>
            {selectedOrderHistoryType === orderHistoryType.SYSTEM && (
              <div className="flex w-full flex-col items-center gap-8">
                <div className={"w-full"} style={{ height: "35rem" }}>
                  <OrderHistoryTable
                    crmCustomerId={selectedCustomer.id}
                    full={false}
                    onShowFull={() =>
                      onShowFullOrderHistory(orderHistoryType.SYSTEM)
                    }
                  />
                </div>
              </div>
            )}
            {selectedOrderHistoryType === orderHistoryType.CRM && (
              <div className="flex w-full flex-col items-center gap-8">
                <div className={"w-full"} style={{ height: "35rem" }}>
                  <CrmOrderHistoryTable
                    crmCustomerId={selectedCustomer.id}
                    full={false}
                    onShowFull={() =>
                      onShowFullOrderHistory(orderHistoryType.CRM)
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {!!acitveEditSection && (
        <SlideOverModal
          containerStyle="!max-w-4xl"
          open={!!acitveEditSection}
          onClose={() => {
            closeEditSection();
          }}
          title={getPopupTitle()}
          subtitle={
            <div className="!text-base !font-light !text-gray-400">
              {getPopupSubTitle()}
            </div>
          }
          staticBackdrop={true}>
          <div className="relative flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-4 font-inter">
            <div className="h-full space-y-1">
              <div className="flex h-full flex-col">
                {acitveEditSection == editEnum.CUSTOMER_DETAILS && (
                  <div className="flex gap-4 space-y-1 py-2">
                    <div className="w-1/2 space-y-1">
                      <label
                        htmlFor="businessName"
                        className="block text-sm font-medium text-gray-700">
                        Business Name
                      </label>
                      <input
                        type="text"
                        name="businessName"
                        id="businessName"
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        value={addressInput?.businessName}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </div>
                    <div className="!mt-0 w-1/2 space-y-1"></div>
                  </div>
                )}

                <div className="flex gap-4 py-2">
                  {nameEdit && (
                    <div className="w-1/4 space-y-1">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700">
                        First Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="first-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="Doe"
                        value={addressInput?.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                  {nameEdit && (
                    <div className="w-1/4 space-y-1">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700">
                        Last Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        id="last-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="John"
                        value={addressInput?.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                  {contactEdit && (
                    <div className="w-1/4 space-y-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="XYZ123@gmail.com"
                        value={addressInput?.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                  {contactEdit && (
                    <div className="w-1/4 space-y-1">
                      <label
                        htmlFor="contact-number"
                        className="block text-sm font-medium text-gray-700">
                        Contact Number
                      </label>
                      <input
                        type="tel"
                        name="contactNumber"
                        id="contact-number"
                        value={addressInput?.contactNumber}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="9876543210"
                        onChange={(e) => {
                          const inputValue = e?.target?.value
                            ? e?.target?.value
                                .replace(/[^0-9]/g, "")
                                .slice(0, 10)
                            : "";
                          handleInputChange({
                            target: {
                              name: "contactNumber",
                              value: inputValue,
                              type: "text",
                            },
                          });
                        }}
                        onKeyDown={(e) => {
                          if (["e", "E", "."].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  )}
                </div>

                {contactEdit && (
                  <div className="flex gap-4 space-y-1 py-2">
                    <div className="w-1/2 space-y-1">
                      <label
                        htmlFor="address-line1"
                        className="block text-sm font-medium text-gray-700">
                        Address Line 1<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="line1"
                        id="address-line1"
                        required
                        value={addressInput?.line1}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="!mt-0 w-1/2 space-y-1">
                      <label
                        htmlFor="address-line2"
                        className="block text-sm font-medium text-gray-700">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        name="line2"
                        id="address-line2"
                        value={addressInput?.line2}
                        className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}

                {contactEdit && (
                  <div className="flex gap-4 py-2">
                    <div className="w-1/4 space-y-1">
                      <label
                        htmlFor="address-city"
                        className="block text-sm font-medium text-gray-700">
                        City<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="address-city"
                        value={addressInput?.city}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="w-1/4 space-y-1">
                      <label
                        htmlFor="zip-code"
                        className="block text-sm font-medium text-gray-700">
                        Zip Code<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="zip"
                        id="zip-code"
                        required
                        value={addressInput?.zip}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="560075"
                        onChange={handleInputChange}
                        onBlur={() =>
                          getPlantationZoneCode(
                            addressInput?.isPlantingZoneAddress,
                            addressInput?.zip,
                          )
                        }
                      />
                    </div>
                    <div className="w-1/4 space-y-1">
                      <Tooltip
                        title={
                          !selectedCountry
                            ? "Please select a country first"
                            : ""
                        }
                        placement="top">
                        <div style={{ display: "inline-block", width: "100%" }}>
                          <label
                            htmlFor="state"
                            className="block text-sm font-medium text-gray-700">
                            State<span className="text-red-500">*</span>
                          </label>
                          <AutocompleteDropdownV2
                            options={filteredStates}
                            labelKey="name"
                            valueKey="isoCode"
                            onChange={handleStateChange}
                            value={addressInput?.state || ""}
                            placeholder="Select a State"
                            id="name"
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="w-1/4 space-y-1">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700">
                        Country<span className="text-red-500">*</span>
                      </label>
                      <AutocompleteDropdownV2
                        options={Country.getAllCountries()}
                        id="name"
                        labelKey="name"
                        valueKey="isoCode"
                        onChange={handleCountryChange}
                        value={addressInput?.country || ""}
                        placeholder="Select a Country"
                      />
                    </div>
                  </div>
                )}

                {addressOnlyEdit && (
                  <div className="flex gap-4 space-y-1 py-2">
                    <div className="w-1/2 space-y-1">
                      <div className="flex h-5 items-center space-x-2">
                        <input
                          id="planting-zone"
                          name="isPlantingZoneAddress"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                          value={addressInput?.isPlantingZoneAddress}
                          checked={
                            addressInput?.isPlantingZoneAddress
                              ? addressInput?.isPlantingZoneAddress
                              : false
                          }
                          onChange={(e) => {
                            handleInputChange(e);
                            if (e.target.checked) {
                              getPlantationZoneCode(
                                e.target.checked,
                                addressInput?.zip,
                              );
                            }
                          }}
                        />
                        <label
                          htmlFor="isPlantingZoneAddress"
                          className="font-medium text-gray-700">
                          Mark the address as a planting zone
                        </label>
                      </div>
                    </div>
                    <div className="!mt-0 w-1/2 space-y-1"></div>
                  </div>
                )}

                {addressOnlyEdit && (
                  <div className="flex gap-4 space-y-1 py-2">
                    <div className="w-1/2 space-y-1">
                      <label
                        htmlFor="planting-zone"
                        className="block text-sm font-medium text-gray-700">
                        Planting Zone Code
                      </label>
                      <AutocompleteDropdownV2
                        options={plantingZoneCodes}
                        id="planting-zone"
                        labelKey="label"
                        valueKey="value"
                        onChange={(value) => {
                          if (value) {
                            handleInputChange({
                              target: {
                                name: "plantingZoneCode",
                                value: value,
                                type: "text",
                              },
                            });
                          }
                        }}
                        value={addressInput?.plantingZoneCode || ""}
                        placeholder="Select Planting Zone Code"
                        sortOptions={false}
                      />
                    </div>
                    <div className="!mt-0 w-1/2 space-y-1"></div>
                  </div>
                )}

                {acitveEditSection == editEnum.CUSTOMER_DETAILS && (
                  <div className="flex gap-4 space-y-1 py-2">
                    <div className="w-full space-y-1">
                      <label
                        htmlFor="notes"
                        className="block text-sm font-medium text-gray-700">
                        Notes
                      </label>
                      <textarea
                        type="text"
                        name="notes"
                        id="notes"
                        placeholder="Enter any custom notes here"
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 text-mediumGray placeholder-gray-400 shadow-sm sm:text-sm"
                        value={addressInput?.notes}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="mt-auto flex items-center justify-end space-x-4 py-2">
                  <button
                    type="button"
                    onClick={closeEditSection}
                    className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2">
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={popupSaveHandler}
                    disabled={
                      !addressInput?.firstName ||
                      !addressInput?.lastName ||
                      !addressInput?.line1 ||
                      !addressInput?.state ||
                      !addressInput?.country ||
                      !addressInput?.zip ||
                      !addressInput?.city
                    }
                    className="rounded-md border border-transparent bg-[#224E73] px-4 py-2 text-sm font-medium text-white hover:bg-[#224E73] focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SlideOverModal>
      )}
      {!!editCustomerDetailsSection && (
        <SlideOverModal
          open={!!editCustomerDetailsSection}
          onClose={() => {
            closeEditSection();
          }}
          title="Edit Customer Details"
          subtitle={
            <div className="!text-base !font-light !text-gray-400">
              This screen will be used to update customer account information.
            </div>
          }
          staticBackdrop={true}>
          <div className="relative flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-4 font-inter">
            <header className="space-y-2 py-4">
              <Navbar steps={steps} />
            </header>
            <main>
              <div className="mt-2 h-full space-y-1">
                {selectedStep?.tag === "customerDetails" && (
                  <div className="flex h-full flex-col">
                    <div className="flex gap-4 space-y-1 py-2">
                      <div className="w-1/2 space-y-1">
                        <label
                          htmlFor="businessName"
                          className="block text-sm font-medium text-gray-700">
                          Business Name
                        </label>
                        <input
                          type="text"
                          name="businessName"
                          id="businessName"
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          value={addressInput?.businessName}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        />
                      </div>
                      <div className="!mt-0 w-1/2 space-y-1"></div>
                    </div>

                    <div className="flex gap-4 py-2">
                      <div className="w-1/4 space-y-1">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium text-gray-700">
                          First Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="first-name"
                          required
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="Doe"
                          value={addressInput?.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-1/4 space-y-1">
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-medium text-gray-700">
                          Last Name<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="last-name"
                          required
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="John"
                          value={addressInput?.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-1/4 space-y-1">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700">
                          Email Address<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="XYZ123@gmail.com"
                          value={addressInput?.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-1/4 space-y-1">
                        <label
                          htmlFor="contact-number"
                          className="block text-sm font-medium text-gray-700">
                          Contact Number<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="tel"
                          name="contactNumber"
                          id="contact-number"
                          value={addressInput?.contactNumber}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="9876543210"
                          onChange={(e) => {
                            const inputValue = e?.target?.value
                              ? e?.target?.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 10)
                              : "";
                            handleInputChange({
                              target: {
                                name: "contactNumber",
                                value: inputValue,
                                type: "text",
                              },
                            });
                          }}
                          onKeyDown={(e) => {
                            if (["e", "E", "."].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex gap-4 space-y-1 py-2">
                      <div className="w-1/2 space-y-1">
                        <label
                          htmlFor="address-line1"
                          className="block text-sm font-medium text-gray-700">
                          Address Line 1<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="line1"
                          id="address-line1"
                          required
                          value={addressInput?.line1}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="!mt-0 w-1/2 space-y-1">
                        <label
                          htmlFor="address-line2"
                          className="block text-sm font-medium text-gray-700">
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          name="line2"
                          id="address-line2"
                          value={addressInput?.line2}
                          className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="flex gap-4 py-2">
                      <div className="w-1/4 space-y-1">
                        <label
                          htmlFor="address-city"
                          className="block text-sm font-medium text-gray-700">
                          City<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="address-city"
                          value={addressInput?.city}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-1/4 space-y-1">
                        <label
                          htmlFor="zip-code"
                          className="block text-sm font-medium text-gray-700">
                          Zip Code<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="zip"
                          id="zip-code"
                          required
                          value={addressInput?.zip}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                          placeholder="560075"
                          onChange={handleInputChange}
                          onBlur={() =>
                            getPlantationZoneCode(
                              addressInput?.isPlantingZoneAddress,
                              addressInput?.zip,
                            )
                          }
                        />
                      </div>
                      <div className="w-1/4 space-y-1">
                        <div>
                          <Tooltip
                            title={
                              !selectedCountry
                                ? "Please select a country first"
                                : ""
                            }
                            placement="top">
                            <div
                              style={{
                                display: "inline-block",
                                width: "100%",
                              }}>
                              <label
                                htmlFor="state"
                                className="block text-sm font-medium text-gray-700">
                                State<span className="text-red-500">*</span>
                              </label>
                              <AutocompleteDropdownV2
                                options={filteredStates}
                                labelKey="name"
                                valueKey="isoCode"
                                onChange={handleStateChange}
                                value={addressInput?.state || ""}
                                placeholder="Select a State"
                                id="name"
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="w-1/4 space-y-1">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700">
                          Country<span className="text-red-500">*</span>
                        </label>
                        <AutocompleteDropdownV2
                          options={Country.getAllCountries()}
                          id="name"
                          labelKey="name"
                          valueKey="isoCode"
                          onChange={handleCountryChange}
                          value={addressInput?.country || ""}
                          placeholder="Select a Country"
                        />
                      </div>
                    </div>

                    <div className="flex gap-4 space-y-1 py-2">
                      <div className="w-1/2 space-y-1">
                        <div className="flex h-5 items-center space-x-2">
                          <input
                            id="planting-zone"
                            name="isPlantingZoneAddress"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                            value={addressInput?.isPlantingZoneAddress}
                            checked={
                              addressInput?.isPlantingZoneAddress
                                ? addressInput?.isPlantingZoneAddress
                                : false
                            }
                            onChange={(e) => {
                              handleInputChange(e);
                              if (e.target.checked) {
                                getPlantationZoneCode(
                                  e.target.checked,
                                  addressInput?.zip,
                                );
                              }
                            }}
                          />
                          <label
                            htmlFor="isPlantingZoneAddress"
                            className="font-medium text-gray-700">
                            Mark the address as a planting zone
                          </label>
                        </div>
                      </div>
                      <div className="!mt-0 w-1/2 space-y-1"></div>
                    </div>

                    <div className="flex gap-4 space-y-1 py-2">
                      <div className="w-1/2 space-y-1">
                        <label
                          htmlFor="planting-zone"
                          className="block text-sm font-medium text-gray-700">
                          Planting Zone Code
                        </label>
                        <AutocompleteDropdownV2
                          options={plantingZoneCodes}
                          id="planting-zone"
                          labelKey="label"
                          valueKey="value"
                          onChange={(value) => {
                            if (value) {
                              handleInputChange({
                                target: {
                                  name: "plantingZoneCode",
                                  value: value,
                                  type: "text",
                                },
                              });
                            }
                          }}
                          value={addressInput?.plantingZoneCode || ""}
                          placeholder="Select Planting Zone Code"
                          sortOptions={false}
                        />
                      </div>
                      <div className="!mt-0 w-1/2 space-y-1">
                        <label
                          htmlFor="isVIP"
                          className="block text-sm font-medium text-gray-700">
                          Is the customer a VIP ?
                        </label>
                        <div className="mb-4 flex items-center">
                          <input
                            type="radio"
                            id="VIPCustomerIndicatorYes"
                            name="isVIP"
                            className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                            value={"yes"}
                            checked={addressInput?.isVIP === "yes"}
                            onChange={(e) => {
                              handleInputChange({
                                target: {
                                  name: "isVIP",
                                  value: "yes",
                                  type: "radio",
                                },
                              });
                            }}
                          />
                          <label
                            htmlFor="VIPCustomerIndicatorYes"
                            className="ml-2 mr-4">
                            <span className="inline-block p-2 text-sm font-medium text-gray-700">
                              Yes
                            </span>
                          </label>
                          <input
                            type="radio"
                            id="VIPCustomerIndicatorNo"
                            name="isVIP"
                            className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                            value={"no"}
                            checked={addressInput?.isVIP === "no"}
                            onChange={(e) => {
                              handleInputChange({
                                target: {
                                  name: "isVIP",
                                  value: "no",
                                  type: "radio",
                                },
                              });
                            }}
                          />
                          <label
                            htmlFor="VIPCustomerIndicatorNo"
                            className="ml-2">
                            <span className="inline-block p-2 text-sm font-medium text-gray-700">
                              No
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4 space-y-1 py-2">
                      <div className="w-full space-y-1">
                        <label
                          htmlFor="notes"
                          className="block text-sm font-medium text-gray-700">
                          Notes
                        </label>
                        <textarea
                          name="notes"
                          id="notes"
                          type="text"
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 text-mediumGray placeholder-gray-400 shadow-sm sm:text-sm"
                          placeholder="Enter any custom notes here"
                          value={editNotes}
                          onChange={(e) => {
                            if (e?.target?.value?.includes("|")) {
                              setNotesValidateError(true);
                            } else {
                              setNotesValidateError(false);
                            }
                            setEditNotes(e.target.value);
                          }}
                        />
                        {notesValidateError && (
                          <div className="mt-1 text-sm text-red-600">
                            The '|' character is not allowed in notes. Please
                            remove it and try again.
                          </div>
                        )}
                      </div>
                    </div>

                    {previousNotes && previousNotes.length > 0 && (
                      <div className="mb-2 mt-4">
                        <div className="mb-2 flex w-full space-y-1">
                          <div className="flex flex-col items-start">
                            <label
                              htmlFor="previous-notes"
                              className="block text-sm font-medium text-gray-700">
                              Previously Added Notes
                            </label>
                          </div>
                        </div>
                        <div className="flex h-64 gap-4 space-y-1 overflow-y-auto py-2">
                          <div className="w-full space-y-1 px-2 py-2">
                            {previousNotes?.map((noteRow, index) => (
                              <div
                                key={index}
                                className="rounded-lg border bg-white p-4 shadow-sm">
                                <div className="grid grid-cols-7 gap-1">
                                  <div className="col-span-6">
                                    <p className="text-sm text-gray-700">
                                      {noteRow.note}
                                    </p>
                                  </div>
                                  <div className="col-span-1">
                                    <div className="mt-2 text-right text-sm text-gray-500">
                                      {noteRow.date}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {selectedStep?.tag === "accountPreferences" && (
                  <div className="flex h-full flex-col">
                    <div className="mb-4 space-y-2">
                      <div className="flex flex-col">
                        <div className="flex w-full flex-row items-center">
                          <label
                            htmlFor="Catalog-Request-Options"
                            className="!w-[16.5rem] text-base font-semibold text-primaryAccent">
                            Catalog Request Options
                          </label>
                          <hr className="w-full border border-solid bg-gray-200" />
                        </div>
                        <div className="flex flex-row items-start">
                          <label
                            htmlFor="Catalog-Request-Options-sub-txt"
                            className="text-sm font-normal text-gray-400">
                            Records whether the customer has requested a catalog
                            from each Business Unit.
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-4 space-y-1 py-2">
                        <div className="w-1/3 space-y-1">
                          <label
                            htmlFor="askForCatalogVE"
                            className="block text-sm font-medium text-gray-700">
                            Catalog Requested from VE?
                          </label>
                          <div className="mb-4 flex items-center">
                            <input
                              type="radio"
                              id="askForCatalogVEYes"
                              name="catalogRequestedVE"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"yes"}
                              checked={
                                addressInput?.catalogRequestedVE === "yes"
                              }
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "catalogRequestedVE",
                                    value: "yes",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="askForCatalogVEYes" className="">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                Requested
                              </span>
                            </label>
                            <input
                              type="radio"
                              id="askForCatalogVENo"
                              name="catalogRequestedVE"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"no"}
                              checked={
                                addressInput?.catalogRequestedVE === "no"
                              }
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "catalogRequestedVE",
                                    value: "no",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="askForCatalogVENo" className="">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                Not Requested
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1">
                          <label
                            htmlFor="askForCatalogJS"
                            className="block text-sm font-medium text-gray-700">
                            Catalog Requested from JS?
                          </label>
                          <div className="mb-4 flex items-center">
                            <input
                              type="radio"
                              id="askForCatalogJSYes"
                              name="catalogRequestedJS"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"yes"}
                              checked={
                                addressInput?.catalogRequestedJS === "yes"
                              }
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "catalogRequestedJS",
                                    value: "yes",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="askForCatalogJSYes" className="">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                Requested
                              </span>
                            </label>
                            <input
                              type="radio"
                              id="askForCatalogJSNo"
                              name="catalogRequestedJS"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"no"}
                              checked={
                                addressInput?.catalogRequestedJS === "no"
                              }
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "catalogRequestedJS",
                                    value: "no",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="askForCatalogJSNo" className="">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                Not Requested
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1">
                          <label
                            htmlFor="askForCatalogKGS"
                            className="block text-sm font-medium text-gray-700">
                            Catalog Requested from KGS?
                          </label>
                          <div className="mb-4 flex items-center">
                            <input
                              type="radio"
                              id="askForCatalogKGSYes"
                              name="catalogRequestedKGS"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"yes"}
                              checked={
                                addressInput?.catalogRequestedKGS === "yes"
                              }
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "catalogRequestedKGS",
                                    value: "yes",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="askForCatalogKGSYes" className="">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                Requested
                              </span>
                            </label>
                            <input
                              type="radio"
                              id="askForCatalogKGSNo"
                              name="catalogRequestedKGS"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"no"}
                              checked={
                                addressInput?.catalogRequestedKGS === "no"
                              }
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "catalogRequestedKGS",
                                    value: "no",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="askForCatalogKGSNo" className="">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                Not Requested
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 space-y-2">
                      <div className="flex flex-col">
                        <div className="flex w-full flex-row items-center">
                          <label
                            htmlFor="Catalog-Suppression"
                            className="!w-[13.5rem] text-base font-semibold text-primaryAccent">
                            Catalog Suppression
                          </label>
                          <hr className="w-full border border-solid bg-gray-200" />
                        </div>
                        <div className="flex flex-row items-start">
                          <label
                            htmlFor="Catalog-Suppression-sub-txt"
                            className="text-sm font-normal text-gray-400">
                            Determine if a catalog should be sent based on
                            company discreation and customer interaction.
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-4 space-y-1 py-2">
                        <div className="w-1/3 space-y-1">
                          <label
                            htmlFor="catalogSuppressionVE"
                            className="block text-sm font-medium text-gray-700">
                            Catalog Suppression for VE
                          </label>
                          <div className="mb-4 flex items-center">
                            <AutoCompleteSingleSelect
                              options={catalogSuppressionList}
                              value={addressInput?.catalogSuppressionVE || ""}
                              onChange={(value) => {
                                if (value) {
                                  handleInputChange({
                                    target: {
                                      name: "catalogSuppressionVE",
                                      value: value,
                                      type: "text",
                                    },
                                  });
                                }
                              }}
                              labelKey="label"
                              valueKey="value"
                              placeholder="Select Suppression Status"
                              labelText=""
                              required={false}
                              shouldDeselect={false}
                              disabled={false}
                              error=""
                              infoText=""
                              showNotSelectedError={false}
                              parentClasses="flex flex-col w-full mb-4"
                              labelClasses="text-base text-gray-500"
                            />
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1">
                          <label
                            htmlFor="catalogSuppressionJS"
                            className="block text-sm font-medium text-gray-700">
                            Catalog Suppression for JS
                          </label>
                          <div className="mb-4 flex items-center">
                            <AutoCompleteSingleSelect
                              options={catalogSuppressionList}
                              value={addressInput?.catalogSuppressionJS || ""}
                              onChange={(value) => {
                                if (value) {
                                  handleInputChange({
                                    target: {
                                      name: "catalogSuppressionJS",
                                      value: value,
                                      type: "text",
                                    },
                                  });
                                }
                              }}
                              labelKey="label"
                              valueKey="value"
                              placeholder="Select Suppression Status"
                              labelText=""
                              required={false}
                              shouldDeselect={false}
                              disabled={false}
                              error=""
                              infoText=""
                              showNotSelectedError={false}
                              parentClasses="flex flex-col w-full mb-4"
                              labelClasses="text-base text-gray-500"
                            />
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1">
                          <label
                            htmlFor="catalogSuppressionKGS"
                            className="block text-sm font-medium text-gray-700">
                            Catalog Suppression for KGS
                          </label>
                          <div className="mb-4 flex items-center">
                            <AutoCompleteSingleSelect
                              options={catalogSuppressionList}
                              value={addressInput?.catalogSuppressionKGS || ""}
                              onChange={(value) => {
                                if (value) {
                                  handleInputChange({
                                    target: {
                                      name: "catalogSuppressionKGS",
                                      value: value,
                                      type: "text",
                                    },
                                  });
                                }
                              }}
                              labelKey="label"
                              valueKey="value"
                              placeholder="Select an option"
                              labelText=""
                              required={false}
                              shouldDeselect={false}
                              disabled={false}
                              error=""
                              infoText=""
                              showNotSelectedError={false}
                              parentClasses="flex flex-col w-full mb-4"
                              labelClasses="text-base text-gray-500"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 space-y-2">
                      <div className="flex flex-col">
                        <div className="flex w-full flex-row items-center">
                          <label
                            htmlFor="Last-Catalog-Sent-Date"
                            className="!w-[14.5rem] text-base font-semibold text-primaryAccent">
                            Last Catalog Sent Date
                          </label>
                          <hr className="w-full border border-solid bg-gray-200" />
                        </div>
                        <div className="flex flex-row items-start">
                          <label
                            htmlFor="Last-Catalog-Sent-Date-sub-txt"
                            className="text-sm font-normal text-gray-400">
                            Enter the last date a catalog was sent to each
                            Business Unit.
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-4 space-y-1 py-2">
                        <div className="w-1/3 space-y-1">
                          <label
                            htmlFor="lastCatalogSentDateVE"
                            className="block text-sm font-medium text-gray-700">
                            VE Last Sent Date
                          </label>
                          <div className="mb-4">
                            <DatePicker
                              label=""
                              infoText=""
                              value={addressInput?.lastCatalogSentDateVE || ""}
                              onChange={(date) => {
                                if (date) {
                                  handleInputChange({
                                    target: {
                                      name: "lastCatalogSentDateVE",
                                      value: date,
                                      type: "text",
                                    },
                                  });
                                }
                              }}
                              error=""
                              parentClasses="mb-4"
                              labelClasses="text-base text-gray-500"
                              required={false}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1">
                          <label
                            htmlFor="lastCatalogSentDateJS"
                            className="block text-sm font-medium text-gray-700">
                            JS Last Sent Date
                          </label>
                          <div className="mb-4">
                            <DatePicker
                              label=""
                              infoText=""
                              value={addressInput?.lastCatalogSentDateJS || ""}
                              onChange={(date) => {
                                if (date) {
                                  handleInputChange({
                                    target: {
                                      name: "lastCatalogSentDateJS",
                                      value: date,
                                      type: "text",
                                    },
                                  });
                                }
                              }}
                              error=""
                              parentClasses="mb-4"
                              labelClasses="text-base text-gray-500"
                              required={false}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1">
                          <label
                            htmlFor="lastCatalogSentDateKGS"
                            className="block text-sm font-medium text-gray-700">
                            KGS Last Sent Date
                          </label>
                          <div className="mb-4">
                            <DatePicker
                              label=""
                              infoText=""
                              value={addressInput?.lastCatalogSentDateKGS || ""}
                              onChange={(date) => {
                                if (date) {
                                  handleInputChange({
                                    target: {
                                      name: "lastCatalogSentDateKGS",
                                      value: date,
                                      type: "text",
                                    },
                                  });
                                }
                              }}
                              error=""
                              parentClasses="mb-4"
                              labelClasses="text-base text-gray-500"
                              required={false}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 space-y-2">
                      <div className="flex flex-col">
                        <div className="flex w-full flex-row items-center">
                          <label
                            htmlFor="Privacy-Preference"
                            className="!w-[12rem] text-base font-semibold text-primaryAccent">
                            Privacy Preference
                          </label>
                          <hr className="w-full border border-solid bg-gray-200" />
                        </div>
                        <div className="flex flex-row items-start">
                          <label
                            htmlFor="Privacy-Preference-sub-txt"
                            className="text-sm font-normal text-gray-400">
                            Record if the customer consents to data sharing.
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-4 space-y-1 py-2">
                        <div className="w-1/3 space-y-1">
                          <label
                            htmlFor="privacy"
                            className="block text-sm font-medium text-gray-700">
                            Consent to Date Sharing?
                          </label>
                          <div className="mb-4 flex items-center">
                            <input
                              type="radio"
                              id="privacyYes"
                              name="privacy"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"yes"}
                              checked={addressInput?.dataSharing === "yes"}
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "dataSharing",
                                    value: "yes",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="privacyYes" className="ml-2 mr-4">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                Yes
                              </span>
                            </label>
                            <input
                              type="radio"
                              id="privacyNo"
                              name="privacy"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"no"}
                              checked={addressInput?.dataSharing === "no"}
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "dataSharing",
                                    value: "no",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="privacyNo" className="ml-2">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                No
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1"></div>
                        <div className="!mt-0 w-1/3 space-y-1"></div>
                      </div>
                    </div>

                    <div className="mb-4 space-y-2">
                      <div className="flex flex-col">
                        <div className="flex w-full flex-row items-center">
                          <label
                            htmlFor="Payment-Terms-and-Tax-Exemption"
                            className="!w-[27rem] text-base font-semibold text-primaryAccent">
                            Payment Terms and Tax Exemption
                          </label>
                          <hr className="w-full border border-solid bg-gray-200" />
                        </div>
                        <div className="flex flex-row items-start">
                          <label
                            htmlFor="Payment-Terms-and-Tax-Exemption-sub-txt"
                            className="text-sm font-normal text-gray-400">
                            Set the customer's payment terms and indicate if
                            they qualify for tax exemption.
                          </label>
                        </div>
                      </div>
                      <div className="flex gap-4 space-y-1 py-2">
                        <div className="w-1/3 space-y-1">
                          <label
                            htmlFor="paymentTerms"
                            className="block text-sm font-medium text-gray-700">
                            Payment Terms
                          </label>
                          <div className="mb-4 flex items-center">
                            <AutoCompleteSingleSelect
                              options={paymentTermsList}
                              value={addressInput?.paymentTerms || ""}
                              onChange={(value) => {
                                if (value) {
                                  handleInputChange({
                                    target: {
                                      name: "paymentTerms",
                                      value: value,
                                      type: "text",
                                    },
                                  });
                                }
                              }}
                              labelKey="label"
                              valueKey="value"
                              placeholder="Select an option"
                              labelText=""
                              required={false}
                              shouldDeselect={false}
                              disabled={false}
                              error=""
                              infoText=""
                              showNotSelectedError={false}
                              parentClasses="flex flex-col w-full mb-4"
                              labelClasses="text-base text-gray-500"
                            />
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1">
                          <label
                            htmlFor="taxExemption"
                            className="block text-sm font-medium text-gray-700">
                            Tax Exemption
                          </label>
                          <div className="mb-4 flex items-center">
                            <input
                              type="radio"
                              id="taxExemptionYes"
                              name="taxExemption"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"yes"}
                              checked={addressInput?.taxExemption === "yes"}
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "taxExemption",
                                    value: "yes",
                                    type: "radio",
                                  },
                                });
                              }}
                            />
                            <label
                              htmlFor="taxExemptionYes"
                              className="ml-2 mr-4">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                Yes
                              </span>
                            </label>
                            <input
                              type="radio"
                              id="taxExemptionNo"
                              name="taxExemption"
                              className="h-4 w-4 cursor-pointer border-primaryAccent text-primaryAccent"
                              value={"no"}
                              checked={addressInput?.taxExemption === "no"}
                              onChange={(e) => {
                                handleInputChange({
                                  target: {
                                    name: "taxExemption",
                                    value: "no",
                                    type: "radio",
                                  },
                                });
                                handleInputChange({
                                  target: {
                                    name: "taxExemptCertificateExpiryDate",
                                    value: null,
                                    type: "text",
                                  },
                                });
                              }}
                            />
                            <label htmlFor="taxExemptionNo" className="ml-2">
                              <span className="inline-block p-2 text-sm font-normal text-gray-500">
                                No
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="!mt-0 w-1/3 space-y-1">
                          {addressInput?.taxExemption === "yes" && (
                            <div className="space-y-2">
                              <label
                                htmlFor="tax-exempt-certification-expiry-date"
                                className="block text-sm font-medium text-gray-700">
                                Tax Exempt Certification Expiry Date
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="mb-4">
                                <DatePicker
                                  label=""
                                  infoText=""
                                  value={
                                    addressInput?.taxExemptCertificateExpiryDate ||
                                    ""
                                  }
                                  onChange={(date) => {
                                    if (date) {
                                      handleInputChange({
                                        target: {
                                          name: "taxExemptCertificateExpiryDate",
                                          value: date,
                                          type: "text",
                                        },
                                      });
                                    }
                                  }}
                                  error=""
                                  parentClasses="mb-4"
                                  labelClasses="text-base text-gray-500"
                                  required={false}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </main>
            <footer className="flex h-16 items-center justify-end">
              <FooterActionForm
                selectedCustomer={addressInput}
                setNextStep={setNextStep}
                setPrevStep={setPrevStep}
                selectedStep={selectedStep}
                showSubmit={showSubmit}
                steps={steps}
                onSubmit={popupSaveHandler}
                onCancel={closeEditSection}
                notesValidateError={notesValidateError}
              />
            </footer>
          </div>
        </SlideOverModal>
      )}
      <SlideOverModal
        open={showShippingHistoryPanel}
        onClose={() => {
          setShowShippingHistoryPanel(false);
        }}
        title="All Associated Shipping Addresses"
        subtitle={
          <div className="!text-base !font-light !text-gray-400">
            This screen will be used to view all associated shipping addresses
          </div>
        }
        staticBackdrop={true}>
        <div className="mt-8 flex w-full flex-col gap-8 p-4">
          {selectedCustomer?.shippingAddresses?.map((address, index) => (
            <div
              key={index}
              className="relative border border-gray-300 py-4 font-inter">
              <div className="flex items-center">
                <label
                  htmlFor="address"
                  className="pointer-events-none ml-3 flex-grow">
                  <div className="flex flex-row gap-4 py-2">
                    <p className="text-xl font-medium leading-none text-black">
                      {[address?.firstName, address?.lastName]
                        .filter(Boolean)
                        .join(" ")}
                    </p>
                    <p className="text-sm font-medium text-darkBlue">
                      {address?.plantingZoneCode
                        ? address?.plantingZoneCode
                        : ""}
                    </p>
                  </div>
                  <p className="text-sm text-gray-500">
                    {[
                      address?.line1,
                      address?.line2,
                      address?.city,
                      address?.state,
                      address?.country,
                      address?.zip,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                  <p className="text-sm text-gray-500">
                    {[address?.contactNumber, address?.email]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                </label>
              </div>
              <div className="absolute right-4 top-1/2 flex -translate-y-1/2 transform space-x-2">
                <PencilIcon
                  className="h-5 w-5 cursor-pointer"
                  style={{ color: "#224E73" }}
                  onClick={() => {
                    handleEditAddress(address);
                  }}
                />
                <TrashIcon
                  className="h-5 w-5 cursor-pointer"
                  style={{ color: "#C93A31" }}
                  onClick={() => {
                    appState.showConfirmation(
                      "Delete",
                      "Are you sure you want to delete this address?",
                      () => {
                        handleDeleteAddress(address);
                        appState.hideConfirmation();
                      },
                      appState.hideConfirmation,
                      "Yes, delete",
                      "No, cancel",
                    );
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </SlideOverModal>
    </div>
  );
};

const EditButton = ({ onClick }) => {
  return (
    <PencilIcon
      className="h-6 w-6 cursor-pointer"
      style={{ color: "#224E73" }}
      onClick={onClick || (() => {})}
    />
  );
};

export default CustomerDetails;
