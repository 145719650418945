import {
  ArrowLeftIcon,
  InformationCircleIcon,
  DuplicateIcon,
  DotsVerticalIcon,
  PlusIcon,
  TrashIcon,
  PencilIcon,
  RefreshIcon,
  PrinterIcon,
} from "@heroicons/react/outline";
import { useContext } from "react";
import { AppStateContext } from "#contexts/appState";
import HollowButton from "../../utils/hollowButton";
import FilledButton from "../../utils/filledButton";
import TextInput from "../common/TextInput";
import DropdownInput from "../common/DropdownInput";
import MessageDisplay from "../common/MessageDisplay";
import * as SlateTable from "../common/SlateTable";
import { Modal } from "../common/Modal";
import { useEffect, useState } from "react";
import _, { set } from "lodash";
import { Menu } from "@headlessui/react";
import { CheckboxInput } from "../common/CheckboxInput";
import { LocationCapacityUnitsEnum } from "../../constants/location-capacity";
import STORAGE_SECTION_STATUSES from "#constants/storage-section-statuses";
import ReactTooltip from "react-tooltip";
import { log } from "util";
import { UPDATE_STORAGE_SECTION } from "#mutations/index";
import { useQuery } from "#hooks/useQuery";
import LocationReservationMenu from "./LocationReservationMenu";
import { Column, Table, AutoSizer } from "react-virtualized";
import PrintSettingsModal from "#components/common/PrintSettingsModal";

const CreateStorageSection = ({
  selectedStorageSection,
  setSelectedStorageSection,
  onChangeStorageSection,
  storageTypes,
  locationTree,
  setLocationTree,
  selectedHierarchy,
  setSelectedHierarchy,
  locationTypes,
  onChangeHierarchy,
  onChangeHierarchyLocation,
  submitSaveHierarchy,
  getCurrentHierarchyArray,
  deleteLocation,
  showAlert,
  submitCreateStorageSection,
  confirmSubmitCreateStorageSection,
  setConfirmSubmitCreateStorageSection,
  submitEditStorageSection,
  locationDeletions,
  setLocationDeletions,
  currentHierarchyArray,
  setSelectedLocations,
  selectedLocations,
  shouldSubmitEdit,
  setShouldSubmitEdit,
  submitStorageSectionStatusEdit,
  customers,
  reserveLocation,
  searchReservations,
  locationReservations,
  selectedPrints,
  setSelectedPrints,
  printSelectedBarcodes,
  queueMultiplePrints,
  queueSinglePrint,
  onChangeHierarchyError,
  setOnChangeHierarchyError,
}) => {
  const [showStorageStatusUpdate, setShowStorageStatusUpdate] = useState(false);
  const [
    showLocationDeletionConfirmation,
    setShowLocationDeletionConfirmation,
  ] = useState(false);
  const [storageLocation, setStorageLocation] = useState(null);
  const [locationsToDelete, setLocationsToDelete] = useState(null);
  const [locationStatusUpdates, setLocationStatusUpdates] = useState({});
  const [inactiveReason, setInactiveReason] = useState("unavailable");
  const [showReservationsMenu, setShowReservationsMenu] = useState(false);
  const appState = useContext(AppStateContext);
  const [rangeInput, setRangeInput] = useState({
    rangeX: "",
    rangeY: "",
    rangeZ: "",
  });

  const onChangeRangeInput = (e) => {
    const { name, value } = e.target;
    setRangeInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    onChangeHierarchy(e);
  };

  useEffect(() => {
    if (selectedHierarchy) {
      setRangeInput({
        rangeX: selectedHierarchy.rangeX,
        rangeY: selectedHierarchy.rangeY,
        rangeZ: selectedHierarchy.rangeZ,
      });
    }
  }, [selectedHierarchy]);

  // import { Column, Table, AutoSizer } from "react-virtualized";

  const hierarchyLocationMenu = [
    // {
    //   icon: DuplicateIcon,
    //   text: "Copy Capacity to All",
    //   isShown: (location) => {
    //     return true;
    //   },
    //   action: (location) => {
    //     setSelectedHierarchy({
    //       ...selectedHierarchy,
    //       locations: selectedHierarchy.locations.map((locationIter) => ({
    //         ...locationIter,
    //         capacity: {
    //           ...location.capacity,
    //         },
    //       })),
    //     });
    //   },
    // },
  ];

  const hierarchyMenu = [
    {
      icon: PrinterIcon,
      text: "Print Barcode",
      isShown: () => true,
      action: (location) => queueSinglePrint(location.code),
    },
    {
      icon: PrinterIcon,
      text: "Print All Barcodes",
      isShown: () => true,
      action: (location) => queueMultiplePrints(location),
    },
    {
      icon: PencilIcon,
      text: "Edit Hierarchy",
      isShown: (location) => {
        return location.parent.code === "root";
      },
      action: (location) => {
        const locationType = locationTypes.find(
          (type) => type.id === location.type,
        );

        const type = locationType.category;
        const subType = locationType.id;

        const ranges = {};
        if (locationType.threeDimRange) {
          const lastChildHierarchicalPath = location.hierarchicalPath;
          const rangesFromLastChild = lastChildHierarchicalPath
            .split(location.parent.hierarchicalPath + "-")[1]
            .split(".")
            .slice(-3);
          ranges.rangeX = parseInt(rangesFromLastChild[0]);
          ranges.rangeY = parseInt(rangesFromLastChild[1]);
          ranges.rangeZ = parseInt(rangesFromLastChild[2]);
        } else {
          ranges.rangeX = location.parent.children.length;
        }
        setSelectedHierarchy({
          edit: true,
          parent: location.parent,
          type,
          subType,
          originalSubType: subType,
          ...ranges,
          locations: location.parent.children.map((locationNode) => ({
            name: locationNode.name,
            hierarchicalPath: locationNode.hierarchicalPath,
            code: locationNode.code,
            capacity: locationNode.capacity,
          })),
          disableRangeChange: true,
          disableTypeChange: true,
          disableSubTypeChange: true,
        });
      },
    },
    {
      icon: PlusIcon,
      text: "Add Sub Hierarchy",
      isShown: (location) => {
        return location.children.length === 0;
      },
      action: (location) => {
        const type = _.uniq(
          locationTypes.map((locationType) => locationType.category),
        )[0];
        const subType = locationTypes.find(
          (locationType) => locationType.category === type,
        ).id;
        setSelectedHierarchy({
          parent: location,
          type,
          subType,
          locations: [],
        });
      },
    },
    {
      icon: PencilIcon,
      text: "Edit Sub Hierarchy",
      isShown: (location) => {
        return location.children.length > 0;
      },
      action: (location) => {
        const locationType = locationTypes.find(
          (type) => type.id === location.children[0].type,
        );

        const type = locationType.category;
        const subType = locationType.id;

        const ranges = {};
        if (locationType.threeDimRange) {
          const lastChildHierarchicalPath =
            location.children[location.children.length - 1].hierarchicalPath;
          const rangesFromLastChild = lastChildHierarchicalPath
            .split(location.hierarchicalPath + "-")[1]
            .split(".")
            .slice(-3);
          ranges.rangeX = parseInt(rangesFromLastChild[0]);
          ranges.rangeY = parseInt(rangesFromLastChild[1]);
          ranges.rangeZ = parseInt(rangesFromLastChild[2]);
        } else {
          ranges.rangeX = location.children.length;
        }
        setSelectedHierarchy({
          edit: true,
          parent: location,
          type,
          subType,
          originalSubType: subType,
          ...ranges,
          locations: location.children.map((locationNode) => ({
            name: locationNode.name,
            hierarchicalPath: locationNode.hierarchicalPath.split(
              location.hierarchicalPath + "-",
            )[1],
            code: locationNode.code,
            capacity: locationNode.capacity,
          })),
        });
      },
    },
    {
      icon: TrashIcon,
      text: "Delete Hierarchy",
      isShown: () => true,
      action: (location) => {
        setLocationsToDelete(location);
        setShowLocationDeletionConfirmation(true);
      },
    },
    {
      icon: RefreshIcon,
      text: "Update Status",
      isShown: () => true,
      action: (location) => {
        if (location?.inactiveReason?.reason) {
          setInactiveReason(location.inactiveReason.reason);
        } else {
          setInactiveReason("unavailable");
        }
        setSelectedLocations(null);
        setShowStorageStatusUpdate(true);
        setStorageLocation(location);
      },
    },
    {
      icon: DuplicateIcon,
      text: "Manage Reservations",
      isShown: () => true,
      action: async (location) => {
        setSelectedLocations(null);
        setStorageLocation(location);
        await searchReservations(location.backendId);
        setShowReservationsMenu(true);
      },
    },
  ];

  useEffect(() => {
    if (
      storageLocation &&
      typeof storageLocation.activationStatus === "boolean"
    ) {
      setLocationStatusUpdates((prevUpdates) => ({
        ...prevUpdates,
        [storageLocation.backendId]: storageLocation.activationStatus
          ? "available"
          : "unavailable",
      }));
    }
  }, [storageLocation]);

  const updateLocationStatus = async (location) => {
    let updatedLocations = selectedLocations ? [...selectedLocations] : [];
    const inactiveReasonType = inactiveReason || "unavailable";
    setInactiveReason(inactiveReasonType);

    const commonProperties = (loc, status, reason) => ({
      backendId: loc.backendId,
      parent: loc.parent,
      name: loc.name,
      code: loc.code,
      capacity: loc.capacity,
      activationStatus: status === "available",
      ...(status === "unavailable" && {
        inactiveReason: { inactiveType: "unavailable", reason: reason },
      }),
    });

    const getLocationStatus = (loc) =>
      locationStatusUpdates[loc.backendId] ||
      (loc.activationStatus ? "available" : "unavailable");

    const locationStatus = getLocationStatus(location);

    const updateChildrenStatus = (loc, status, reason) => {
      loc.children.forEach((child) => {
        updatedLocations = updatedLocations.filter(
          (l) => l.code !== child.code,
        );
        updatedLocations.push(commonProperties(child, status, reason));
        if (child.children.length > 0) {
          updateChildrenStatus(child, status, reason);
        }
      });
    };

    const updateParentStatus = (loc, status, reason) => {
      if (loc.parent) {
        let parentStatus;
        if (status === "available") {
          parentStatus = "available";
        } else {
          const allSiblingsUnavailable = loc.parent.children.every(
            (sibling) => sibling.code === loc.code || !sibling.activationStatus,
          );
          parentStatus = allSiblingsUnavailable ? "unavailable" : "available";
        }

        updatedLocations = updatedLocations.filter(
          (l) => l.code !== loc.parent.code,
        );
        updatedLocations.push(
          commonProperties(loc.parent, parentStatus, reason),
        );

        if (parentStatus !== loc.parent.activationStatus) {
          updateParentStatus(loc.parent, parentStatus, reason);
        }
      }
    };

    // Ensure reason is provided if marking unavailable
    if (locationStatus === "unavailable" && !inactiveReasonType) {
      showAlert(
        "Please provide a reason for marking the location as unavailable.",
      );
      return;
    }

    // Update the location itself
    updatedLocations = updatedLocations.filter(
      (loc) => loc.code !== location.code,
    );
    updatedLocations.push(
      commonProperties(location, locationStatus, inactiveReasonType),
    );
    const markedLocations = updatedLocations;
    // Propagate status change to children
    if (location.children.length > 0) {
      updateChildrenStatus(location, locationStatus, inactiveReasonType);
    }

    // // Propagate status change to parents
    updateParentStatus(location, locationStatus, inactiveReasonType);

    setSelectedLocations(markedLocations);
    setStorageLocation({
      ...location,
      activationStatus: locationStatus === "available",
    });

    setShouldSubmitEdit(true);

    // Check and set storage section status
    let allLocationsAreAvailable = true;
    let allLocationsAreUnavailable = true;

    await locationTree.dfsTraverseAsync(async (loc) => {
      const updatedLoc = updatedLocations.find((l) => l.code === loc.code);
      const locStatus = updatedLoc
        ? updatedLoc.activationStatus
        : loc.activationStatus;
      if (locStatus) {
        allLocationsAreUnavailable = false;
      } else {
        allLocationsAreAvailable = false;
      }
    });

    const storageSectionId = selectedStorageSection.id;

    if (allLocationsAreAvailable) {
      submitStorageSectionStatusEdit(
        storageSectionId,
        "available",
        inactiveReason,
      );
    } else if (allLocationsAreUnavailable) {
      submitStorageSectionStatusEdit(
        storageSectionId,
        "unavailable",
        inactiveReason,
      );
    } else {
      submitStorageSectionStatusEdit(storageSectionId, "available", null, true);
    }
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setLocationStatusUpdates((prevUpdates) => ({
      ...prevUpdates,
      [storageLocation.backendId]: value,
    }));
  };

  const handleLocationDeletion = async (location) => {
    if (showLocationDeletionConfirmation === true) {
      const deletions = [...locationDeletions, location];
      setLocationDeletions(deletions);
      setShowLocationDeletionConfirmation(false);
      setLocationsToDelete(null);
    }

    setShouldSubmitEdit(true);
  };

  return (
    <>
      <div className="min-h-screen bg-white px-3 py-5 pb-24">
        <div className="flex w-full">
          <div className="flex">
            <ArrowLeftIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => setSelectedStorageSection(null)}
            />
            <div className="ml-4 text-xl font-semibold">
              {selectedStorageSection.edit ? "Edit" : "Create"} Storage Section
            </div>
          </div>
          <div className="ml-auto flex">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setSelectedStorageSection(null);
              }}
            />
            <FilledButton
              text={"Submit"}
              colour={"primaryAccent"}
              onClick={() => {
                if (!selectedStorageSection.name) {
                  showAlert(
                    "Please fill in the Storage Section Name",
                    "error",
                    3000,
                  );
                  return;
                }
                if (!selectedStorageSection.code) {
                  showAlert(
                    "Please fill in the Storage Section Code",
                    "error",
                    3000,
                  );
                  return;
                }
                if (locationTree.isEmpty()) {
                  showAlert("Please add at least one hierarchy", "error", 3000);
                  return;
                }
                setConfirmSubmitCreateStorageSection(true);
              }}
              className="ml-4"
            />
          </div>
        </div>
        <div className="mt-6 text-base font-semibold">
          1. Storage Section Details
        </div>
        <div className="mt-4">
          <div className="flex">
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Storage Section Name
                </div>
              </div>
              <TextInput
                name={"name"}
                onChange={onChangeStorageSection}
                value={selectedStorageSection["name"]}
                width={"w-80"}
              />
            </div>
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Storage Section Code
                </div>
              </div>
              <TextInput
                name={"code"}
                onChange={onChangeStorageSection}
                value={selectedStorageSection["code"]}
                width={"w-44"}
                disabled={selectedStorageSection.edit}
              />
            </div>
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Storage Section Charactersistics
                </div>
              </div>
              <DropdownInput
                name={"type"}
                onChange={onChangeStorageSection}
                value={selectedStorageSection["type"]}
                width={"w-80"}
                options={Object.values(storageTypes).map((type) => ({
                  name: type,
                }))}
                valueKey={"name"}
                labelKey={"name"}
              />
            </div>
          </div>
          <div className="mt-1 text-sm font-normal text-gray-500">
            Each Storage Type has a unique code and are exclusive to a single
            hierarchy
          </div>
          {selectedStorageSection.edit && (
            <div className="mt-1 text-sm font-normal text-gray-500">
              While editing, the Storage Section Code cannot be changed
            </div>
          )}
        </div>
        <div className="mt-6 text-base font-semibold">2. Hierarchy Details</div>
        <div className="flex">
          <MessageDisplay customStyles={"min-w-64 w-max h-20 ml-auto"}>
            <div className="leading-17 ml-4 mr-4 mt-4 text-left text-base font-medium tracking-normal text-gray-500">
              Location Hierarchy
            </div>
            <div className="leading-19 ml-4 mr-4 mt-1 text-left text-lg font-semibold tracking-normal">
              {locationTree
                ?.getHierarchyArray()
                ?.map((type) => locationTypes.find((t) => t.id === type).name)
                .length
                ? locationTree
                    ?.getHierarchyArray()
                    ?.map(
                      (type) => locationTypes.find((t) => t.id === type).name,
                    )
                    .join(" > ")
                : "-"}
            </div>
          </MessageDisplay>
        </div>
        <div className="mt-4 flex">
          <MessageDisplay customStyles={"flex w-full py-4 px-2"} border={true}>
            <InformationCircleIcon className="h-8 w-8" />
            <div className="leading-17 ml-2 text-left text-sm font-normal tracking-normal text-lightGray">
              As you build on the hierarchy, you can "Add Hierarchy" to add to
              the same level of Hierarchy. If you want to add another level
              below the current hierarchy, use "+" to build sub-levels.
            </div>
          </MessageDisplay>
        </div>
        <HierarchyTable
          locationTree={locationTree}
          locationTypes={locationTypes}
          setSelectedHierarchy={setSelectedHierarchy}
          hierarchyMenu={hierarchyMenu}
          searchReservations={searchReservations}
          setShowReservationsMenu={setShowReservationsMenu}
          setStorageLocation={setStorageLocation}
          setLocationsToDelete={setLocationsToDelete}
          setShowLocationDeletionConfirmation={
            setShowLocationDeletionConfirmation
          }
        />
        {locationTree?.root?.children?.length > 0 && (
          <div
            className={`fixed bottom-0 left-0 flex h-20 w-screen bg-white ${appState?.sidebarOpen ? "md:pl-72" : "md:pl-20"}`}>
            <div
              className={`cursor-pointer pl-3 text-left text-sm font-semibold leading-6 tracking-normal text-blueBackground`}
              onClick={() => {
                const location = locationTree.root?.children?.[0];
                const locationType = locationTypes.find(
                  (type) => type.id === location.type,
                );

                const type = locationType.category;
                const subType = locationType.id;

                const ranges = {};
                if (locationType.threeDimRange) {
                  const lastChildHierarchicalPath = location.hierarchicalPath;
                  const rangesFromLastChild = lastChildHierarchicalPath
                    .split(location.parent.hierarchicalPath + "-")[1]
                    .split(".")
                    .slice(-3);
                  ranges.rangeX = parseInt(rangesFromLastChild[0]);
                  ranges.rangeY = parseInt(rangesFromLastChild[1]);
                  ranges.rangeZ = parseInt(rangesFromLastChild[2]);
                } else {
                  ranges.rangeX = location.parent.children.length;
                }
                setSelectedHierarchy({
                  edit: true,
                  parent: location.parent,
                  type,
                  subType,
                  originalSubType: subType,
                  ...ranges,
                  locations: location.parent.children.map((locationNode) => ({
                    name: locationNode.name,
                    hierarchicalPath: locationNode.hierarchicalPath,
                    code: locationNode.code,
                    capacity: locationNode.capacity,
                  })),
                  disableTypeChange: true,
                  disableSubTypeChange: true,
                  disableRangeChange: false,
                  title: "Add Hierarchy",
                });
              }}>
              <u>Add Hierarchy</u>
            </div>
            <div>
              <InformationCircleIcon className="h-6 w-6" />
            </div>
          </div>
        )}
      </div>
      {selectedHierarchy && (
        <Modal
          title={
            selectedHierarchy.title ||
            (selectedHierarchy.parent.code === "root"
              ? `${selectedHierarchy.edit ? "Edit" : "Add"} Hierarchy`
              : `${selectedHierarchy.edit ? "Edit" : "Add"} Sub Hierarchy`)
          }
          onClose={() => {
            setSelectedHierarchy(null);
            setSelectedHierarchy(null);
            setRangeInput({
              rangeX: "",
              rangeY: "",
              rangeZ: "",
            });
            setOnChangeHierarchyError("");
            setOnChangeHierarchyError("");
          }}
          minWidth={"745px"}
          maxWidth={"745px"}>
          <div className="flex">
            <MessageDisplay
              customStyles={"flex w-full py-4 px-2"}
              border={true}>
              <InformationCircleIcon className="h-8 w-8" />
              <div className="leading-17 ml-2 text-left text-sm font-normal tracking-normal text-lightGray">
                Effortlessly initiate your warehouse hierarchy on our SaaS
                platform. Start with the first level, define ranges, assign
                names and codes for efficient location barcoding, streamlining
                operations for warehouse administrators
              </div>
            </MessageDisplay>
          </div>
          <div className="mt-8 flex">
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Select Type
                </div>
              </div>
              <DropdownInput
                name={"type"}
                onChange={onChangeHierarchy}
                value={selectedHierarchy["type"]}
                width={"w-60"}
                options={_.uniq(
                  locationTypes.map((locationType) => locationType.category),
                )
                  .map((category) => ({
                    name: category,
                  }))
                  .sort((a, b) => a?.name?.localeCompare(b.name))}
                valueKey={"name"}
                labelKey={"name"}
                disabled={selectedHierarchy.disableTypeChange}
              />
            </div>
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Select Sub Type
                </div>
              </div>
              <DropdownInput
                name={"subType"}
                onChange={onChangeHierarchy}
                value={selectedHierarchy["subType"]}
                width={"w-60"}
                options={locationTypes.filter(
                  (locationType) =>
                    locationType.category === selectedHierarchy.type,
                )}
                valueKey={"id"}
                labelKey={"name"}
                disabled={selectedHierarchy.disableSubTypeChange}
              />
            </div>
            <div className="mr-8">
              <div className="flex">
                <div className="mt-1 text-sm font-normal text-gray-500">
                  Select Range
                </div>
              </div>
              <div className="flex">
                <TextInput
                  name={"rangeX"}
                  onChange={onChangeRangeInput}
                  value={rangeInput["rangeX"]}
                  width={"w-20"}
                  placeholder={"x"}
                  styles={"mr-2"}
                  type={"number"}
                  min={0}
                  step={1}
                  disabled={selectedHierarchy.disableRangeChange}
                />
                {locationTypes.find(
                  (locationType) =>
                    locationType.id === selectedHierarchy.subType,
                )?.threeDimRange && (
                  <>
                    <TextInput
                      name={"rangeY"}
                      onChange={onChangeRangeInput}
                      value={rangeInput["rangeY"]}
                      width={"w-20"}
                      placeholder={"y"}
                      styles={"mr-2"}
                      type={"number"}
                      min={0}
                      step={1}
                      disabled={selectedHierarchy.disableRangeChange}
                    />
                    <TextInput
                      name={"rangeZ"}
                      onChange={onChangeRangeInput}
                      value={rangeInput["rangeZ"]}
                      width={"w-20"}
                      placeholder={"z"}
                      styles={"mr-2"}
                      type={"number"}
                      min={0}
                      step={1}
                      disabled={selectedHierarchy.disableRangeChange}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          {onChangeHierarchyError && (
            <div className="mt-1 text-sm font-normal text-red-500">
              {onChangeHierarchyError}
            </div>
          )}
          <div className="mt-8 h-96 max-h-96 overflow-y-auto">
            <SlateTable.SlateTable>
              <SlateTable.SlateTableHeader sticky={true}>
                <SlateTable.SlateTableHeaderCell>
                  Name
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Code
                </SlateTable.SlateTableHeaderCell>
                {/* <SlateTable.SlateTableHeaderCell>
                  Capacity
                </SlateTable.SlateTableHeaderCell> */}
                {/* <SlateTable.SlateTableHeaderCell>
                  Actions
                </SlateTable.SlateTableHeaderCell> */}
              </SlateTable.SlateTableHeader>
              {selectedHierarchy.locations.length > 0 ? (
                <SlateTable.SlateTableBody>
                  {selectedHierarchy.locations.map((location, index) => (
                    <SlateTable.SlateTableBodyRow>
                      <SlateTable.SlateTableBodyRowCell>
                        <TextInput
                          name={"name"}
                          onChange={(e) => {
                            onChangeHierarchyLocation(e, index);
                          }}
                          value={location["name"]}
                          width={"w-40"}
                        />
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        <TextInput
                          name={"code"}
                          onChange={(e) => {
                            onChangeHierarchyLocation(e, index);
                          }}
                          value={location["code"]}
                          width={"w-40"}
                        />
                      </SlateTable.SlateTableBodyRowCell>
                      {/* <SlateTable.SlateTableBodyRowCell>
                        <div className="flex">
                          <TextInput
                            name={"capacity-amount"}
                            onChange={(e) => {
                              onChangeHierarchyLocation(e, index);
                            }}
                            value={location["capacity"]["amount"]}
                            width={"w-24"}
                            type={"number"}
                            min={0}
                            styles={"rounded-l rounded-r-none"}
                          />
                          <DropdownInput
                            name={"capacity-unit"}
                            onChange={(e) => {
                              onChangeHierarchyLocation(e, index);
                            }}
                            value={location["capacity"]["unit"]}
                            options={Object.values(
                              LocationCapacityUnitsEnum,
                            ).map((unit) => ({
                              value: unit,
                            }))}
                            labelKey={"value"}
                            valueKey={"value"}
                            width={"w-40"}
                            styles={"rounded-r rounded-l-none ml-0"}
                          />
                        </div>
                      </SlateTable.SlateTableBodyRowCell> */}
                      {/* <SlateTable.SlateTableBodyRowCell>
                        <Menu as="div" className="">
                          <Menu.Button>
                            <DotsVerticalIcon className="h-4 w-4 cursor-pointer" />
                          </Menu.Button>
                          <>
                            <Menu.Items
                              as="div"
                              className="absolute right-8 z-10 w-max origin-top-right rounded border border-solid border-grayBorder bg-white p-2">
                              {hierarchyLocationMenu.map((menu) =>
                                menu.isShown(location) ? (
                                  <Menu.Item
                                    key={menu.text}
                                    as="div"
                                    className={`cursor-pointer`}
                                    onClick={() => {
                                      menu.action(location);
                                    }}>
                                    {({ active }) => (
                                      <div
                                        className={`flex rounded transition ${
                                          active ? "bg-lightBlueMenu" : ""
                                        } h-9`}>
                                        <menu.icon
                                          className="mx-2 my-1.5 h-6 w-6 cursor-pointer"
                                          color={active ? "#224E73" : "#737579"}
                                        />
                                        <span
                                          className={`my-2 mr-2 text-sm ${
                                            active
                                              ? "text-primaryAccent"
                                              : "text-grayText"
                                          }`}>
                                          {menu.text}
                                        </span>
                                      </div>
                                    )}
                                  </Menu.Item>
                                ) : null,
                              )}
                            </Menu.Items>
                          </>
                        </Menu>
                      </SlateTable.SlateTableBodyRowCell> */}
                    </SlateTable.SlateTableBodyRow>
                  ))}
                </SlateTable.SlateTableBody>
              ) : (
                <SlateTable.SlateTableBodyRow noBottomBorder={true}>
                  <SlateTable.SlateTableBodyRowCell
                    className="mt-8 w-full"
                    colspan={6}>
                    <div className="flex h-max w-full">
                      <div className="m-auto mt-16">
                        <img
                          src="https://hopstack-pub.s3.amazonaws.com/ContentNotFound.png"
                          className="mx-auto mb-2"
                        />
                        <div className="mx-auto mb-2 text-center text-sm font-normal leading-4 tracking-normal text-lightGray">
                          Complete the fields above to proceed
                        </div>
                      </div>
                    </div>
                  </SlateTable.SlateTableBodyRowCell>
                </SlateTable.SlateTableBodyRow>
              )}
            </SlateTable.SlateTable>
          </div>
          <div>
            {selectedHierarchy.parent.code !== "root" &&
              !selectedHierarchy.edit && (
                <CheckboxInput
                  text={
                    "Apply the same hierarchy structure to the other parent hierarchies in this Sorage Type"
                  }
                  name="applyToSiblings"
                  value={selectedHierarchy.applyToSiblings}
                  onChange={onChangeHierarchy}
                />
              )}
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setSelectedHierarchy(null);
                setRangeInput({
                  rangeX: "",
                  rangeY: "",
                  rangeZ: "",
                });
                setOnChangeHierarchyError("");
              }}
              styles="ml-auto"
            />
            <div
              className={`ml-4 rounded px-4 py-2 text-white ${
                onChangeHierarchyError
                  ? "cursor-not-allowed bg-gray-400"
                  : "cursor-pointer bg-primaryAccent"
              }`}
              onClick={!!onChangeHierarchyError ? null : submitSaveHierarchy}>
              Save
            </div>
          </div>
        </Modal>
      )}
      {confirmSubmitCreateStorageSection && (
        <Modal
          title="Confirm"
          onClose={() => {
            setConfirmSubmitCreateStorageSection(false);
          }}
          minWidth={"945px"}
          maxWidth={"945px"}>
          <div className="text-left text-base font-normal leading-6 tracking-normal">
            Are you sure you want to{" "}
            {selectedStorageSection.edit ? "save" : "create new"} storage type “
            <span className="font-semibold">{selectedStorageSection.name}</span>
            ” ?
          </div>
          <div className="mt-2 flex">
            <MessageDisplay customStyles={"min-w-64 w-max h-max pb-4"}>
              <div className="leading-17 ml-4 mr-4 mt-4 text-left text-base font-medium tracking-normal text-gray-500">
                Location Hierarchy
              </div>
              <div className="leading-19 ml-4 mr-4 mt-1 text-left text-lg font-semibold tracking-normal">
                {getCurrentHierarchyArray().length
                  ? getCurrentHierarchyArray().join(" > ")
                  : "-"}
              </div>
              <div className="ml-4 mr-4 mt-1 text-left text-base font-normal leading-5 tracking-normal">
                Ex:{" "}
                {`${selectedStorageSection.code} > ${getCurrentHierarchyArray()
                  .map((locationType) => {
                    const type = locationTypes.find(
                      (type) => type.name === locationType,
                    );
                    let code = type.codeType === "NUMERIC" ? "1" : "A";
                    if (type.threeDimRange) {
                      code = "1.1.1";
                    }
                    return `${locationType}-${code}`;
                  })
                  .join(" > ")}`}
              </div>
            </MessageDisplay>
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setConfirmSubmitCreateStorageSection(false);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Submit"}
              colour={"primaryAccent"}
              onClick={
                selectedStorageSection.edit
                  ? submitEditStorageSection
                  : submitCreateStorageSection
              }
              className="ml-4"
            />
          </div>
        </Modal>
      )}
      {showStorageStatusUpdate && (
        <Modal
          title={`Update Status`}
          onClose={() => {
            setShowStorageStatusUpdate(false);
            setStorageLocation(null);
          }}
          minWidth={"845px"}
          maxWidth={"845px"}>
          <div className="flex flex-col">
            <div className="mb-4">
              <div className="leading-22 text-left text-base font-medium tracking-normal text-[#717679]">
                If a location is marked as available, it will be used for
                workflows; if marked as unavailable, it will be excluded, and
                you will be able to add reason for unavailability
              </div>
            </div>
            <div className="mb-4 flex items-center">
              <input
                type="radio"
                id="available"
                name="status"
                value="available"
                checked={
                  locationStatusUpdates[storageLocation.backendId] ===
                  "available"
                }
                onChange={handleStatusChange}
              />
              <label htmlFor="available" className="ml-2 mr-4">
                <span className="inline-block rounded-2xl bg-green-100 p-2 text-green-800">
                  Available
                </span>
              </label>
              <input
                type="radio"
                id="unavailable"
                name="status"
                value="unavailable"
                checked={
                  locationStatusUpdates[storageLocation.backendId] ===
                  "unavailable"
                }
                onChange={handleStatusChange}
              />
              <label htmlFor="unavailable" className="ml-2">
                <span className="inline-block rounded-2xl bg-red-100 p-2 text-red-800">
                  Unavailable
                </span>
              </label>
            </div>
            {locationStatusUpdates[storageLocation.backendId] ===
              "available" && (
              <div
                className="mb-4 rounded border p-4"
                style={{ borderColor: "#E3A400", backgroundColor: "#FFF9E9" }}>
                <div className="mb-2 flex items-center font-medium">
                  <i className="fas fa-info-circle mr-2"></i> Points to note
                </div>
                <ul className="ml-4 list-disc">
                  <li>
                    All the sub hierarchies under this location will be made
                    available
                  </li>
                </ul>
              </div>
            )}
            {locationStatusUpdates[storageLocation.backendId] ===
              "unavailable" && (
              <div>
                <div
                  className="mb-4 rounded border p-4"
                  style={{
                    borderColor: "#E3A400",
                    backgroundColor: "#FFF9E9",
                  }}>
                  <div className="mb-2 flex items-center font-medium">
                    <i className="fas fa-info-circle mr-2"></i> Points to note
                  </div>
                  <ul className="ml-4 list-disc">
                    <li>
                      All the sub hierarchies coming under this location will be
                      made unavailable.
                    </li>
                    <li>
                      Changing any sub hierarchies location will have an impact
                      on the main hierarchy if switched from unavailable to
                      available.
                    </li>
                  </ul>
                </div>
                <div className="mb-4">
                  <div className="leading-22 mb-2 text-left text-base font-medium tracking-normal">
                    Reason
                  </div>
                  <textarea
                    className="w-full rounded border p-2"
                    placeholder="Enter reason for making this section unavailable..."
                    onChange={(e) => {
                      setInactiveReason(e.target.value);
                    }}
                    value={inactiveReason || ""}
                  />
                </div>
              </div>
            )}
            <div className="flex w-full">
              <HollowButton
                text={"Cancel"}
                colour={"primaryAccent"}
                onClick={() => {
                  setShowStorageStatusUpdate(false);
                  setStorageLocation(null);
                }}
                styles="ml-auto"
              />
              <FilledButton
                text={"Save"}
                colour={"primaryAccent"}
                onClick={() => {
                  updateLocationStatus(storageLocation);
                  setShowStorageStatusUpdate(false);
                }}
                className="ml-4"
              />
            </div>
          </div>
        </Modal>
      )}
      {showLocationDeletionConfirmation && (
        <Modal
          title="Confirm"
          onClose={() => {
            setShowLocationDeletionConfirmation(false);
            setLocationsToDelete(null);
          }}
          minWidth={"845px"}
          maxWidth={"845px"}>
          <div className="text-left text-base font-normal leading-6 tracking-normal">
            Are you sure you want to delete the selected hierarchy?
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setShowLocationDeletionConfirmation(false);
                setLocationsToDelete(null);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Delete"}
              colour={"primaryAccent"}
              onClick={() => {
                handleLocationDeletion(locationsToDelete);
              }}
              className="ml-4"
            />
          </div>
        </Modal>
      )}
      {showReservationsMenu && (
        <LocationReservationMenu
          storageLocation={storageLocation}
          showReservationsMenu={showReservationsMenu}
          setShowReservationsMenu={setShowReservationsMenu}
          setStorageLocation={setStorageLocation}
          customers={customers}
          reserveLocation={reserveLocation}
          locationReservations={locationReservations}
        />
      )}
      {selectedPrints && (
        <PrintSettingsModal
          setSelectedPrints={setSelectedPrints}
          selectedPrints={selectedPrints}
          printSelectedBarcodes={printSelectedBarcodes}
          disablePreview={selectedPrints.barcodes.length > 5}
        />
      )}
    </>
  );
};

const HierarchyTable = ({
  locationTree,
  locationTypes,
  setSelectedHierarchy,
  hierarchyMenu,
}) => {
  // Use dfsTraverse to create a flat list of locations
  const locationList = [];
  locationTree.dfsTraverse((location) => {
    locationList.push(location);
  });

  const rowGetter = ({ index }) => locationList[index];

  const renderCell = ({ cellData, columnIndex, rowData }) => {
    const locationType = locationTypes.find((type) => type.id === rowData.type);
    switch (columnIndex) {
      case 0:
        return (
          <div style={{ paddingLeft: `${rowData.depth * 20}px` }}>
            {locationType?.category}
          </div>
        );
      case 1:
        return (
          <div style={{ paddingLeft: `${rowData.depth * 20}px` }}>
            {locationType?.name}
          </div>
        );
      case 2:
        return (
          <div style={{ paddingLeft: `${rowData.depth * 20}px` }}>
            {rowData.name}
          </div>
        );
      case 3:
        return (
          <div style={{ paddingLeft: `${rowData.depth * 20}px` }}>
            {rowData.code}
          </div>
        );
      case 4:
        return (
          <StorageSectionColorBadge
            status={rowData.activationStatus ? "AVAILABLE" : "UNAVAILABLE"}
            reason={
              rowData.activationStatus
                ? ""
                : rowData.inactiveReason?.reason || "unavailable"
            }
          />
        );
      case 5:
        const clientCount = rowData.reservationsDetail?.reduce(
          (count, res) => count + (res.customers?.length || 0),
          0,
        );
        const productCount = rowData.reservationsDetail?.reduce(
          (count, res) => count + (res.products?.length || 0),
          0,
        );
        const clientCodes = rowData.reservationsDetail?.flatMap(
          (res) => res.customers?.map((client) => client.code) || [],
        );
        const productSKUs = rowData.reservationsDetail?.flatMap(
          (res) => res.products?.map((product) => product.sku) || [],
        );

        return (
          <>
            {clientCount > 0 && (
              <span
                data-tip={clientCodes?.join(", ") || ""}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#224E73",
                }}>
                {`${clientCount} ${clientCount === 1 ? "Client" : "Clients"}`}
              </span>
            )}
            {productCount > 0 && (
              <span
                data-tip={productSKUs?.join(", ") || ""}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#224E73",
                }}>
                {`${productCount} ${productCount === 1 ? "Product" : "Products"}`}
              </span>
            )}
            <ReactTooltip />
          </>
        );
      case 6:
        return (
          <Menu as="div" className="">
            <Menu.Button>
              <DotsVerticalIcon className="h-4 w-4 cursor-pointer" />
            </Menu.Button>
            <Menu.Items
              as="div"
              className="absolute right-8 z-10 w-max origin-top-right rounded border border-solid border-grayBorder bg-white p-2">
              {hierarchyMenu
                .filter((menuItem) => menuItem.isShown(rowData))
                .map((menu) => (
                  <Menu.Item
                    as="div"
                    className="cursor-pointer"
                    onClick={() => menu.action(rowData)}>
                    {({ active }) => (
                      <div
                        className={`flex rounded transition ${active ? "bg-lightBlueMenu" : ""} h-9`}>
                        <menu.icon
                          className="mx-2 my-1.5 h-6 w-6 cursor-pointer"
                          color={active ? "#224E73" : "#737579"}
                        />
                        <span
                          className={`my-2 mr-2 text-sm ${active ? "text-primaryAccent" : "text-grayText"}`}>
                          {menu.text}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                ))}
            </Menu.Items>
          </Menu>
        );
      default:
        return null;
    }
  };

  return locationList.length === 0 ? (
    <div className="mt-4 max-w-full overflow-x-auto">
      <div className="flex h-max w-full">
        <div className="m-auto">
          <img
            src="https://hopstack-pub.s3.amazonaws.com/ContentNotFound.png"
            className="mx-auto mb-2"
          />
          <div className="mx-auto mb-2 text-center text-base font-semibold leading-5 tracking-normal">
            No Hierarchy Created
          </div>
          <div className="mx-auto mb-2 text-center text-sm font-normal leading-4 tracking-normal text-lightGray">
            Click below to start adding hierarchies
          </div>
          <div
            className="cursor-pointer text-center text-sm font-semibold leading-6 tracking-normal text-blueBackground"
            onClick={() => {
              const type = _.uniq(
                locationTypes.map((locationType) => locationType.category),
              )[0];
              const subType = locationTypes.find(
                (locationType) => locationType.category === type,
              ).id;
              setSelectedHierarchy({
                parent: locationTree.root,
                type,
                subType,
                locations: [],
              });
            }}>
            <u>Add Hierarchy</u>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="mt-4 max-w-full overflow-x-auto">
      {/* Render the table headers separately */}
      <div className="flex w-full bg-gray-50">
        <div className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray">
          Type
        </div>
        <div className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray">
          Sub Type
        </div>
        <div className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray">
          Name
        </div>
        <div className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray">
          Code
        </div>
        <div className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray">
          Status
        </div>
        <div className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray">
          Reservations
        </div>
        <div className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray">
          Action
        </div>
      </div>

      <AutoSizer disableHeight>
        {({ width }) => (
          <Table
            width={width}
            height={400}
            headerHeight={0}
            rowHeight={50}
            rowCount={locationList.length}
            rowGetter={rowGetter}
            className="w-full rounded-t"
            rowClassName="border-b border-gray-100 flex h-20">
            <Column
              label="Type"
              dataKey="type"
              className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray"
              cellRenderer={renderCell}
            />
            <Column
              label="Sub Type"
              dataKey="subType"
              className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray"
              cellRenderer={renderCell}
            />
            <Column
              label="Name"
              dataKey="name"
              cellRenderer={renderCell}
              className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray"
            />
            <Column
              label="Code"
              dataKey="code"
              cellRenderer={renderCell}
              className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray"
            />
            <Column
              label="Status"
              dataKey="activationStatus"
              cellRenderer={renderCell}
              className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray"
            />
            <Column
              label="Reservations"
              dataKey="reservationsDetail"
              cellRenderer={renderCell}
              className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray"
            />
            <Column
              label="Action"
              dataKey="action"
              cellRenderer={renderCell}
              className="grow rounded-t p-4 text-left text-sm font-medium text-lightGray"
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

const StorageSectionColorBadge = ({ status, reason }) => {
  let modifiedStatusToDisplay = status.replace(/_/g, " ");
  // Capitalize every word
  modifiedStatusToDisplay = modifiedStatusToDisplay
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  switch (status) {
    case "AVAILABLE":
      return <GreenBadge text={modifiedStatusToDisplay} />;
    case "UNAVAILABLE":
      return (
        <div className="flex items-center">
          <RedBadge text={modifiedStatusToDisplay} />
          <div data-tip={reason}>
            <InformationCircleIcon className="ml-2 h-5 w-5 text-red-800" />
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
      );
    default:
      return null;
  }
};

const GreenBadge = ({ text }) => {
  return (
    <div className="me-2 h-8 w-max rounded-full bg-green-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-green-800">
      {text}
    </div>
  );
};

const RedBadge = ({ text }) => {
  return (
    <div className="me-2 h-8 w-max rounded-full bg-red-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-red-800">
      {text}
    </div>
  );
};

export default CreateStorageSection;
