import React from "react";
import NewModal from "#newUiComponents/commons/NewModal";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";

const FbaConfirmationModals = ({
  isOpen,
  onClose,
  maxWidth = "640px",
  title,
  subtitle,
  description,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonText,
  secondaryButtonAction,
}) => {
  return (
    <NewModal
      isOpen={isOpen}
      max
      onClose={onClose}
      maxWidth={maxWidth}
      title={title}
      subtitle={subtitle}>
      <div className="font-inter">
        <p>{description}</p>
        <div className="mt-5 flex w-full items-center justify-between bg-white">
          <div className="mt-4 flex items-center justify-end gap-4"></div>
          <div className="flex gap-6">
            <PrimaryButton
              height="3rem"
              minWidth="13rem"
              maxWidth="20rem"
              className="mt-2 text-base font-medium"
              onClick={() => {
                primaryButtonAction();
                onClose();
              }}>
              {primaryButtonText}
            </PrimaryButton>
            {secondaryButtonAction && (
              <PrimaryButton
                height="3rem"
                minWidth="13rem"
                maxWidth="20rem"
                variant="primary"
                className="mt-2 text-base font-medium"
                onClick={() => {
                  secondaryButtonAction();
                  onClose();
                }}>
                {secondaryButtonText}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </NewModal>
  );
};

export default FbaConfirmationModals;
