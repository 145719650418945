import { useState, useContext, useEffect } from "react";
import PageTitle from "#components/utils/PageTitle";
import { PlusIcon, MinusIcon } from "@heroicons/react/outline";
import _ from "lodash";
import BarcodeForm from "#components/receiver/BarcodeForm";
import ToteSelectForm from "#components/receiver/ToteSelectForm";
import moment from "moment-timezone";
import Dropdown from "#components/utils/Dropdown";
import ReceiverNotes from "./ReceiverNotes";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import Modal from "#components/utils/Modal";
import { AppStateContext } from "#contexts/appState";
import { SUPPORTED_SIZES_WITHOUT_CANVAS } from "#utils/printCanvasLabelPdf";
import AmazonPrepInstructions from "#components/products/AmazonPrepInstructions";
import NumberField from "#components/utils/NumberField";
import ReactTooltip from "react-tooltip";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";

const AddingFormFactor = ({
  setAddingFormFactor,
  addingFormFactor,
  printCode,
  handleLpnAdded,
}) => {
  const { item, idx, updateItem } = addingFormFactor;

  return (
    <Modal
      title={`Add LPN to Product`}
      negativeAction={() => {
        setAddingFormFactor(null);
        updateItem(idx, "nestedFormFactor", null);
        updateItem(idx, "nestedFormFactorId", null);
        updateItem(idx, "dimensions", null);
        updateItem(idx, "numberOfCodes", null);
      }}
      positiveAction={handleLpnAdded}
      id="userFormModal">
      {item.nestedFormFactorId && (
        <img
          id={`label-placeholder-${item.nestedFormFactorId}`}
          style={{ position: "fixed", top: 10000 }}
        />
      )}
      <div>
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Form Factor
          </span>
        </label>
        <Dropdown
          // TODO: HOP-6031
          placeholder={"Select"}
          list={[{ name: "Case" }, { name: "Carton" }, { name: "Pallet" }]}
          labelKey="name"
          valueKey="name"
          name="formFactor"
          setSelected={(e) => updateItem(idx, "nestedFormFactor", e)}
          selectedValue={item.nestedFormFactor}
        />
      </div>
      <div className="mt-2 flex space-x-2">
        <input
          type="text"
          onChange={(e) =>
            updateItem(idx, "nestedFormFactorId", e.target.value)
          }
          placeholder="Code"
          value={item.nestedFormFactorId}
          className="p-4 text-lg"
        />
        <button
          type="button"
          className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
          onClick={() =>
            updateItem(
              idx,
              "nestedFormFactorId",
              Math.floor(10000000 + Math.random() * 90000000).toString(),
            )
          }>
          Auto-Generate
        </button>
      </div>
      <div className="mt-4 font-montserrat text-lg font-medium text-454A4F">
        Optional Print
      </div>
      <div className="flex space-x-2">
        <Dropdown
          placeholder={"Select Dimensions"}
          list={SUPPORTED_SIZES_WITHOUT_CANVAS.map((i) => ({
            name: i,
          }))}
          labelKey="name"
          valueKey="name"
          name="printSize"
          setSelected={(e) => updateItem(idx, "dimensions", e)}
          selectedValue={item.dimensions}
        />
        <NumberField
          onChange={(e) =>
            updateItem(idx, "numberOfCodes", parseInt(e.target.value))
          }
          placeholder="# to print"
          value={item.numberOfCodes}
          className="p-4 text-lg"
          min="0"
        />
        <button
          type="button"
          className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
          onClick={() =>
            printCode(
              item.nestedFormFactorId,
              item.numberOfCodes,
              item.dimensions,
              true,
            )
          }>
          Print
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
          onClick={() =>
            printCode(
              item.nestedFormFactorId,
              item.numberOfCodes,
              item.dimensions,
              false,
            )
          }>
          Download
        </button>
      </div>
    </Modal>
  );
};

export const renderVariantDetails = (parentProduct, productVariant) => {
  if (!parentProduct) {
    return null;
  }

  if (!parentProduct.variantCharacteristics) {
    return null;
  }

  if (!productVariant.attributes) {
    return null;
  }

  return (
    <div className="m-auto flex w-full space-x-1 shadow-md">
      <div
        className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 border-F4C261 bg-white p-4 text-2C7695`}>
        Variant Details
      </div>
      <div className={`flex-1 rounded-br-md rounded-tr-md border bg-white p-4`}>
        {Object.keys(productVariant.attributes).map((attribute) => {
          if (
            parentProduct.variantCharacteristics
              .map((i) => i.toLowerCase())
              .includes(attribute.toLowerCase())
          ) {
            return (
              <div key={attribute}>
                <span className="font-bold">{attribute?.toUpperCase()}:</span>{" "}
                {productVariant.attributes[attribute]}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

const DetailedView = ({
  currentSku,
  confirmItem,
  cancelItem,
  scanBarcode,
  currentItems,
  updateItem,
  removeItem,
  addItem,
  specificConsignment,
  currentProduct,
  printCode,
  prepEnabled,
  confirmPrepItem,
  eligibleConsignments,
  tenant,
  productSkuBinMappings,
  setCurrentSku,
  currentParentProduct,
  handleLpnAdded,
  addingFormFactor,
  setAddingFormFactor,
}) => {
  const [activeUomLabelsForPtoduct, setActiveUomLabelsForProduct] = useState(
    [],
  );
  const [manualValue, setManualValue] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [notes, setNotes] = useState(null);
  const [confirmedPrep, setConfirmedPrep] = useState(false);
  // Initialize state for each item in currentItems array
  const [productHasSerialNumberArray, setProductHasSerialNumberArray] =
    useState(currentItems.map(() => false));

  const handleSerialNumberChange = (index, item) => {
    const newProductHasSerialNumberArray = [...productHasSerialNumberArray];
    newProductHasSerialNumberArray[index] = item?.serialNumber?.length > 0;
    setProductHasSerialNumberArray(newProductHasSerialNumberArray);
  };

  useEffect(() => {
    const applicableUomLabels = [
      currentProduct?.baseUom,
      ...(currentProduct?.uomConfiguration?.map((config) => config.targetUom) ??
        []),
    ];

    const uniqueLabels = [...new Set(applicableUomLabels)];

    setActiveUomLabelsForProduct(
      uniqueLabels.map((label) => ({ name: label })),
    );
  }, currentProduct);

  useEffect(() => {
    // Item can be added or removed from beginning, between or middle, so have to traverse the array to check for serial number
    const newProductHasSerialNumberArray = [];
    for (const item of currentItems) {
      item?.serialNumber?.length > 0
        ? newProductHasSerialNumberArray.push(true)
        : newProductHasSerialNumberArray.push(false);
    }
    setProductHasSerialNumberArray(newProductHasSerialNumberArray);
  }, [currentItems]);

  const formFactorsFromConsignment = [currentProduct?.baseUom || "Each"];

  if (eligibleConsignments) {
    for (const consignment of eligibleConsignments) {
      for (const item of consignment.workingList.filter(
        (i) => i.sku === currentSku.sku && i.availableQuantity > 0,
      )) {
        if (item.formFactor) {
          formFactorsFromConsignment.push(item.formFactor);
        }
      }
    }
  }

  return (
    <div className="mx-auto max-w-7xl flex-col items-center justify-center">
      {barcode && barcode?.field !== "tote" && (
        <BarcodeForm
          onChange={(e) =>
            setBarcode({
              ...barcode,
              data: e,
            })
          }
          onSubmit={() => {
            scanBarcode({ data: barcode.data });
            setBarcode(null);
          }}
          onClose={() => setBarcode(null)}
          title="Enter Value"
        />
      )}

      {addingFormFactor && (
        <AddingFormFactor
          addingFormFactor={addingFormFactor}
          setAddingFormFactor={setAddingFormFactor}
          printCode={printCode}
          handleLpnAdded={handleLpnAdded}
        />
      )}
      <div className="pt-8">
        <PageTitle>Product Details</PageTitle>
        <div className="grid grid-cols-3">
          <div
            className={`w-full flex-1 bg-gray-100 ${
              currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
            }`}>
            <div className="space-y-2 text-xl font-medium text-454A4F">
              {currentSku.asin && (
                <LineItemValue value={currentSku.asin} title="ASIN" idx={7} />
              )}

              <LineItemValue value={currentSku.name} title="Name" idx={2} />
              <LineItemValue value={currentSku.sku} title="SKU" idx={3} />
              {currentSku.fnSku && (
                <LineItemValue
                  value={currentSku.fnSku}
                  title="Fn SKU"
                  idx={4}
                />
              )}

              {currentSku.upc && (
                <LineItemValue
                  value={currentSku?.upc?.join(", ")}
                  title="UPC"
                  idx={8}
                />
              )}
              {currentParentProduct &&
                renderVariantDetails(currentParentProduct, currentSku)}
              {specificConsignment && (
                <>
                  <LineItemValue
                    value={_.sumBy(
                      specificConsignment.workingList.filter(
                        (item) => item.sku === currentSku.sku,
                      ),
                      "quantity",
                    )}
                    title="Expected Qty"
                    idx={3}
                  />
                  <LineItemValue
                    value={
                      _.sumBy(
                        specificConsignment.workingList.filter(
                          (item) => item.sku === currentSku.sku,
                        ),
                        "quantity",
                      ) -
                      _.sumBy(
                        specificConsignment.workingList.filter(
                          (item) => item.sku === currentSku.sku,
                        ),
                        "availableQuantity",
                      )
                    }
                    title="Received Qty"
                    idx={2}
                  />
                  {specificConsignment.notes && (
                    <LineItemValue
                      value={specificConsignment.notes}
                      title="Client Notes"
                      idx={7}
                    />
                  )}
                  {prepEnabled &&
                    currentProduct &&
                    currentProduct.prepInstructions &&
                    currentProduct.prepInstructions.length > 0 && (
                      <AmazonPrepInstructions
                        prepInstructions={currentProduct.prepInstructions}
                      />
                    )}
                </>
              )}
              {!specificConsignment?.dropship && (
                <>
                  <LineItemValueWithOptions
                    value={currentSku.tote}
                    title="Tote (optional)"
                    idx={5}
                    setBarcode={setBarcode}
                    field="tote"
                    scanBarcode={scanBarcode}
                    barcode={barcode}
                    clearValue={() =>
                      setCurrentSku({ ...currentSku, tote: null })
                    }
                  />
                  {tenant?.settings?.activities?.receiving
                    ?.allowBinLocationScan !== false && (
                    <LineItemValueWithOptions
                      value={currentSku.binLocation}
                      title="Bin (optional)"
                      idx={6}
                      setBarcode={setBarcode}
                      field="bin"
                    />
                  )}
                </>
              )}
              {!specificConsignment?.dropship && (
                <div className="flex-col items-center justify-center bg-white p-10 pb-5 pt-5">
                  <div>
                    <TextField
                      type="text"
                      id="name"
                      label={
                        tenant?.settings?.activities?.receiving
                          ?.allowBinLocationScan !== false
                          ? "Scan Tote/Bin"
                          : "Scan Tote"
                      }
                      placeholder=" "
                      onChange={(e) => setManualValue(e.target.value)}
                      value={manualValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          scanBarcode({ data: manualValue });
                          setManualValue("");
                        } else {
                          console.log("else");
                        }
                      }}
                      autoFocus={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {currentProduct?.images && (
            <div className="flex flex-1 items-center justify-center bg-gray-300">
              <WorkflowProductImages
                images={[
                  currentProduct.images.find(
                    (i) => i.purpose === "OPERATIONS",
                  ) || currentProduct.images[0],
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-8">
        {currentItems &&
          currentItems.map((item, idx) => (
            <>
              <div className="flex-col">
                <div className="flex flex-wrap items-center gap-3 space-x-4 bg-white p-4 shadow-md">
                  <div className="w-40">
                    <label className="mb-2 block text-left">
                      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                        Form Factor
                      </span>
                    </label>
                    <Dropdown
                      placeholder={"Select"}
                      list={activeUomLabelsForPtoduct}
                      labelKey="name"
                      valueKey="name"
                      name="formFactor"
                      setSelected={(e) => updateItem(idx, "formFactor", e)}
                      selectedValue={item.formFactor}
                    />
                  </div>
                  <div data-for={"quantity"} data-tip={true}>
                    <label className="mb-2 block text-left">
                      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                        Quantity
                      </span>
                    </label>
                    <NumberField
                      onChange={(e) =>
                        updateItem(
                          idx,
                          "quantity",
                          isNaN(
                            parseInt(
                              productHasSerialNumberArray[idx] ||
                                item.attributes?.serialNumberRequired
                                ? 1
                                : e.target.value,
                            ),
                          ) === false
                            ? parseInt(
                                productHasSerialNumberArray[idx] ||
                                  item.attributes?.serialNumberRequired
                                  ? 1
                                  : e.target.value,
                              )
                            : null,
                          true,
                        )
                      } //Set immutable value for quantity to 1 for item when the serialNumberRequired flag is true
                      placeholder="Qty"
                      disabled={
                        productHasSerialNumberArray[idx] ||
                        item.attributes?.serialNumberRequired
                      }
                      value={item.quantity}
                      className="w-24 rounded-md p-4 text-lg"
                      min="0"
                    />
                    {/* Below message to shown upon hovering on quantity field */}
                    {(productHasSerialNumberArray[idx] ||
                      item.attributes?.serialNumberRequired) && (
                      <ReactTooltip
                        id="quantity"
                        place="top"
                        type="info"
                        effect="solid">
                        <div className="max-w-xl whitespace-normal text-xl">
                          Each item is associated with a unique serial number.
                          Therefore, when a serial number is required or added,
                          the quantity cannot exceed 1
                        </div>
                      </ReactTooltip>
                    )}
                  </div>
                  <div className="w-48">
                    <label className="mb-2 block text-left">
                      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                        Condition
                      </span>
                    </label>
                    <Dropdown
                      placeholder={"Select"}
                      list={[
                        { name: "Good", status: "CONFIRMED" },
                        { name: "Used", status: "USED" },
                        { name: "Damaged", status: "DAMAGED" },
                        {
                          name: "Box Damaged",
                          status: "BOX_DAMAGED",
                        },
                        {
                          name: "No Box",
                          status: "NO_BOX",
                        },
                        {
                          name: "Plain Box",
                          status: "PLAIN_BOX",
                        },
                        {
                          name: "Wrong Item",
                          status: "WRONG_ITEM",
                        },
                      ]}
                      labelKey="name"
                      valueKey="status"
                      name="status"
                      setSelected={(e) => updateItem(idx, "status", e)}
                      selectedValue={item.status}
                    />
                  </div>
                  {tenant?.settings?.activities?.receiving
                    ?.expiryDateEnabled !== false && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          Expiry Date
                        </span>
                      </label>
                      <input
                        type="date"
                        onChange={(e) =>
                          updateItem(idx, "bestByDate", e.target.value)
                        }
                        placeholder="Select Expiry date"
                        value={item.bestByDate}
                        className="rounded-md p-4 text-lg"
                        min={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                  )}

                  {tenant?.settings?.activities?.receiving?.lotIdEnabled !==
                    false && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          Lot ID
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          updateItem(idx, "lotId", e.target.value)
                        }
                        placeholder="Lot ID"
                        value={item.lotId}
                        className="w-36 rounded-md p-4 text-lg"
                      />
                    </div>
                  )}
                  {tenant?.settings?.activities?.receiving
                    ?.serialNumberEnabled !== false && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          Serial Number
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          updateItem(idx, "serialNumber", e.target.value);
                          if (e.target.value.length > 0) {
                            updateItem(idx, "quantity", 1);
                            handleSerialNumberChange(idx, {
                              ...item,
                              serialNumber: e.target.value,
                            });
                          } else {
                            handleSerialNumberChange(idx, {
                              ...item,
                              serialNumber: e.target.value,
                            });
                          }
                        }}
                        placeholder="Serial Number"
                        value={item.serialNumber}
                        className="w-36 rounded-md p-4 text-lg"
                      />
                    </div>
                  )}
                  <div>
                    <label className="mb-2 block text-left">
                      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                        PO ID
                      </span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => updateItem(idx, "poId", e.target.value)}
                      placeholder="PO ID"
                      value={item.poId}
                      className="w-36 rounded-md p-4 text-lg"
                    />
                  </div>
                  <div>
                    <label className="mb-2 block text-left">
                      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                        PO Line ID
                      </span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        updateItem(idx, "lineItemId", e.target.value)
                      }
                      placeholder="PO Line ID"
                      value={item.lineItemId}
                      className="w-36 rounded-md p-4 text-lg"
                    />
                  </div>
                  {(item.formFactor === "Pallet" ||
                    tenant?.settings?.activities?.receiving
                      ?.allowPalletIdAgainstLineItem === true) && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          Pallet ID
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          updateItem(idx, "palletId", e.target.value)
                        }
                        placeholder="Pallet ID"
                        value={item.palletId}
                        className="w-36 rounded-md p-4 text-lg"
                      />
                    </div>
                  )}

                  <div className="mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-2xl font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
                      onClick={() => removeItem(idx)}>
                      -
                    </button>
                  </div>
                  <div className="mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-2xl font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
                      onClick={addItem}>
                      +
                    </button>
                  </div>
                </div>
                <div className="flex items-center space-x-4 bg-white p-4 shadow-md">
                  <div>
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border-transparent bg-FC8862 px-4 py-2 text-center font-montserrat text-xl font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
                      onClick={() => setNotes({ idx, remarks: item.remarks })}>
                      + Notes
                    </button>
                  </div>
                  {item.nestedFormFactor && (
                    <>
                      <div className="font-montserrat text-xl">
                        LPN Form Factor: {item.nestedFormFactor}
                      </div>
                      <div className="font-montserrat text-xl">
                        LPN: {item.nestedFormFactorId}
                      </div>
                    </>
                  )}
                  <div>
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border-transparent bg-FC8862 px-4 py-2 text-center font-montserrat text-xl font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
                      onClick={() =>
                        setAddingFormFactor({ idx, item, updateItem })
                      }>
                      {item.nestedFormFactor && item.nestedFormFactorId
                        ? `Edit LPN`
                        : `Add LPN`}
                    </button>
                  </div>

                  {tenant?.settings?.activities?.receiving
                    ?.putawayBinLocation === true && (
                    <div>
                      <AutocompleteSingleSelectDropdown
                        options={productSkuBinMappings}
                        value={item.putawayBinLocation}
                        onChange={(e) =>
                          updateItem(idx, "putawayBinLocation", e)
                        }
                        labelKey="binLocation"
                        valueKey="binLocation"
                        placeholder={
                          item.putawayBinLocation || "Select Putaway Location"
                        }
                        showValueAsSelected={true}
                        clearSelection={() =>
                          updateItem(idx, "putawayBinLocation", "")
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}
      </div>

      <div className="mt-20 flex items-center justify-center space-x-6">
        {prepEnabled &&
          currentProduct?.prepInstructions?.length > 0 &&
          !confirmedPrep && (
            <div className="w-64 py-4 text-center text-2xl text-black">
              Have you prepped this item?
            </div>
          )}
        {prepEnabled && !confirmedPrep && currentProduct?.source === "FBA" && (
          <div
            className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
            onClick={() => {
              confirmPrepItem();
              setConfirmedPrep(true);
            }}>
            Yes
          </div>
        )}
        <div
          className="w-64 rounded-md bg-red-600 py-4 text-center text-2xl text-white"
          onClick={cancelItem}>
          Cancel
        </div>

        {(!prepEnabled ||
          confirmedPrep ||
          currentProduct?.source !== "FBA") && (
          <div
            className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
            onClick={() => confirmItem("CONFIRMED")}>
            Confirm
          </div>
        )}

        {(!prepEnabled || confirmedPrep) &&
          currentProduct?.source === "FBA" && (
            <div
              className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
              onClick={() => confirmPrepItem(true)}>
              {tenant?.settings?.activities?.receiving?.disableAutomaticPrinting
                ? "Print FNSKU"
                : "Re-Print FNSKU"}
            </div>
          )}
      </div>
      {notes && (
        <ReceiverNotes
          title="Add Note"
          onClose={() => setNotes(null)}
          onSubmit={() => {
            updateItem(notes.idx, "remarks", notes.remarks);
            setNotes(null);
          }}
          notes={notes.remarks}
          onChange={(e) =>
            setNotes({
              ...notes,
              remarks: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="m-auto flex w-full space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className={`flex-1 rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

const LineItemValueWithOptions = ({
  title,
  value,
  idx,
  setBarcode,
  field,
  scanBarcode,
  barcode,
  clearValue,
}) => (
  <div className="m-auto flex w-full space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="flex-1 space-x-4 rounded-br-md rounded-tr-md border bg-white p-4">
      {field !== "tote" || value ? (
        <>
          {value}
          {field === "tote" && (
            <button
              type="button"
              className="ml-4 inline-flex items-center rounded-md font-montserrat text-lg font-medium text-red-600 outline-none ring-0 focus:outline-none focus:ring-0"
              onClick={clearValue}>
              {value ? "Change" : "Enter"}
            </button>
          )}
        </>
      ) : (
        <ToteSelectForm
          onChange={(e) => scanBarcode({ data: e })}
          onSubmit={() => {
            scanBarcode({ data: barcode.data });
          }}
          title="Enter Value"
          value={value}
        />
      )}
    </div>
    {field !== "tote" && (
      <div className="flex-1 rounded-br-md rounded-tr-md border bg-white p-4">
        Scan or{" "}
        <span
          className="cursor-pointer text-blue-600"
          onClick={() => setBarcode({ data: "" })}>
          Enter Manually
        </span>
      </div>
    )}
  </div>
);

export default DetailedView;
