import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import TextField from "#components/utils/TextField";
import InformationIcon from "#components/utils/InformationIcon";
import CurrentPackBoxes from "#components/packer/CurrentPackBoxes";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import { isDelmarTenant } from "#utils/tenantCheck";
import AmazonPrepInstructions from "#components/products/AmazonPrepInstructions";
import BundlePrepDetails from "#components/prepper/BundlePrepDetails";
import NumberField from "#components/utils/NumberField";

const DetailedView = ({
  currentBatch,
  currentItemMismatch,
  setCurrentItemMismatch,
  confirmPackItem,
  setAddBox,
  weight,
  setWeight,
  order,
  changeQuantity,
  subdomain,
  onChangeValue,
  submitOrderLineItem,
  scanBarcode,
  cancelItem,
  restartActivity,
  pauseActivity,
  removeBox,
  unpackItem,
  packItems,
  setItemsToPack,
  itemsToPack,
  duplicateBox,
  setWeightOfBox,
  handleFindAndPack,
  isAddItemQuantityExceed,
  isSetAddItemQuantityExceed,
  itemsToUnPack,
  setItemsToUnPack,
  handleFindAndUnpackItem,
  currentProduct,
  tenant,
  warehouses,
  print2DBarcode,
  numberOfBoxesToDuplicate,
  setNumberOfBoxesToDuplicate,
  prepEnabled,
  simulateItemScan,
  confirmPrepItem,
  checkFbaBoxWeightLimits,
}) => {
  const [manualValue, setManualValue] = useState(null);
  const [confirmedPack, setConfirmedPack] = useState(false);

  const getSubTote = (orderId) => {
    if (currentBatch?.attributes?.subTotes) {
      return currentBatch.attributes.subTotes[orderId];
    }
    return null;
  };

  const filteredWorkingList = () => {
    if (!currentBatch) return;
    const returnDivs = [];
    if (
      currentBatch.workingList &&
      currentBatch.attributes?.multipleLineItemBatch &&
      currentBatch.currentSubTote
    ) {
      const newWorkingList = currentBatch.workingList.filter(
        (item) => getSubTote(item.order) === currentBatch.currentSubTote,
      );
      const groupWorkingList = _.groupBy(newWorkingList, "sku");
      for (const key in groupWorkingList) {
        const statuses = [
          ...new Set(groupWorkingList[key].map((item) => item.status)),
        ];
        returnDivs.push(
          <tr
            key={key}
            className="border-1 bg-white px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
            <td className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
              {key}
            </td>
            <td className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
              {_.sumBy(groupWorkingList[key], "quantity")}
            </td>
            <td className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
              {statuses.length === 1 ? statuses[0] : "PARTIAL"}
            </td>
          </tr>,
        );
      }
    }
    return returnDivs;
  };

  if (currentBatch && !currentBatch.currentItem) {
    return (
      <>
        {tenant?.features?.packerRestartOptionsEnabled === false &&
          pauseActivity && (
            <div
              className="cursor-pointer rounded-md bg-red-600 px-4 py-4 text-center text-2xl text-white"
              onClick={pauseActivity}>
              Transfer Batch to another station
            </div>
          )}
        <div className="mt-6 flex w-full max-w-6xl items-center justify-center">
          <div className="flex space-x-4">
            <div className="max-h-xs rounded-2xl border bg-white shadow-lg">
              <div className="flex items-center justify-center pb-5 pt-5">
                <div className="border-r border-gray-400 p-5">
                  <div className="rounded-full bg-primaryAccent p-6 shadow-md">
                    <img
                      src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                      className="w-14"
                    />
                  </div>
                </div>
                <div className="flex-col">
                  <div className="px-5 py-5 pb-1 text-xl font-medium text-454A4F">
                    {currentBatch?.attributes?.multipleLineItemBatch &&
                    !currentBatch.currentSubTote
                      ? `Please Scan a Sub-Tote`
                      : `Please Scan the Next Item`}
                  </div>
                  {currentBatch.tote && (
                    <div className="px-5 pb-1 text-xl font-medium text-green-500">
                      Warehouse:{" "}
                      {warehouses?.find((i) => i.id === currentBatch.warehouse)
                        ?.name || `-`}
                    </div>
                  )}
                  {currentBatch.warehouse && (
                    <div className="px-5 pb-1 text-xl font-medium text-green-500">
                      Current Tote: {currentBatch.tote}
                    </div>
                  )}
                  {currentBatch.currentSubTote && (
                    <div className="px-5 pb-1 text-xl font-medium text-454A4F">
                      Current Sub-Tote: {currentBatch.currentSubTote}
                    </div>
                  )}

                  <div className="block px-5 py-0 text-xl font-medium text-454A4F">
                    Completion: {currentBatch.completion}%
                  </div>
                </div>
              </div>
              <div className="items-center text-center font-montserrat text-2xl">
                OR ENTER MANUALLY
              </div>
              <div className="flex-col items-center justify-center p-10 pb-5 pt-5">
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Enter Value"
                    placeholder=" "
                    onChange={(e) => setManualValue(e.target.value)}
                    value={manualValue}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        scanBarcode(manualValue);
                        setManualValue("");
                      }
                    }}
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {order &&
          order.boxInformationNeeded !== false &&
          (order.source === "FBA" ||
            tenant?.settings?.activities?.packing?.boxSelection) && (
            <CurrentPackBoxes
              currentBatch={currentBatch}
              order={order}
              setAddBox={setAddBox}
              weight={weight}
              setWeight={setWeight}
              duplicateBox={duplicateBox}
              removeBox={removeBox}
              unpackItem={unpackItem}
              packItems={packItems}
              setItemsToPack={setItemsToPack}
              itemsToPack={itemsToPack}
              setWeightOfBox={setWeightOfBox}
              handleFindAndPack={handleFindAndPack}
              isAddItemQuantityExceed={isAddItemQuantityExceed}
              isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
              itemsToUnPack={itemsToUnPack}
              setItemsToUnPack={setItemsToUnPack}
              handleFindAndUnpackItem={handleFindAndUnpackItem}
              print2DBarcode={print2DBarcode}
              numberOfBoxesToDuplicate={numberOfBoxesToDuplicate}
              setNumberOfBoxesToDuplicate={setNumberOfBoxesToDuplicate}
              checkFbaBoxWeightLimits={checkFbaBoxWeightLimits}
            />
          )}

        {currentBatch.currentSubTote && (
          <table className="mt-2 divide-y divide-gray-200">
            <thead className="rounded-full p-4">
              <tr className="font-montserratborder-left bg-primaryAccent text-white">
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  SKU
                </th>
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Quantity
                </th>
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>{filteredWorkingList()}</tbody>
          </table>
        )}

        <div className="mt-8 flex items-center justify-center space-x-4">
          {tenant?.features?.packerRestartOptionsEnabled !== false &&
            pauseActivity && (
              <div
                className="w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
                onClick={pauseActivity}>
                Pause Packing
              </div>
            )}
          {tenant?.features?.packerRestartOptionsEnabled !== false &&
            restartActivity && (
              <div
                className="w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
                onClick={restartActivity}>
                Restart Packing
              </div>
            )}
        </div>
      </>
    );
  }

  const { currentItem } = currentBatch;

  return (
    <div className="mx-auto max-w-7xl flex-col items-center justify-center">
      <div className="pt-8">
        <PageTitle>Product Details</PageTitle>
        {isDelmarTenant(tenant.subdomain) &&
          order?.customAttributes?.resizingOrder === true && (
            <div className="mb-2 flex space-y-2 bg-white p-2 text-3xl font-medium text-yellow-600">
              <InformationIcon className="h-10 w-10" /> This is a resizing
              order. Do NOT pack into jewelery box.
            </div>
          )}
        <div className="grid grid-cols-3">
          <div
            className={`w-full flex-1 bg-gray-100 ${
              currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
            }`}>
            <div className="space-y-2 text-xl font-medium text-454A4F">
              {order?.classification && (
                <LineItemDesc
                  title={"Classification"}
                  value={order?.classification}
                  idx={2}
                />
              )}
              {order?.warehouse && (
                <LineItemDesc
                  title={"Warehouse"}
                  value={
                    warehouses?.find((i) => i.id === order?.warehouse)?.name ||
                    "-"
                  }
                  idx={2}
                />
              )}
              <LineItemDesc title={"SKU"} value={currentItem.sku} idx={1} />
              {currentItem.productAlias && (
                <LineItemDesc
                  title={"Customer Id"}
                  value={currentItem.productAlias}
                  idx={1}
                />
              )}
              <LineItemDesc
                title={"Product"}
                value={truncate(currentItem.productName)}
                idx={2}
              />
              {currentItem.upc && (
                <LineItemDesc
                  value={currentItem?.upc?.join(", ")}
                  title="UPC"
                  idx={2}
                />
              )}
              {order && order.source === "FBA" && (
                <LineItemDesc
                  title={"FN SKU"}
                  value={currentItem.fnSku}
                  idx={1}
                />
              )}
              {order && order.source === "FBA" && (
                <LineItemDesc title={"ASIN"} value={currentItem.asin} idx={1} />
              )}
              <LineItemDesc
                title={"Total Quantity"}
                value={
                  currentBatch.workingList &&
                  _.sumBy(
                    currentBatch.workingList.filter(
                      (item) =>
                        item.sku.toString() === currentItem.sku.toString() &&
                        item.order.toString() === currentItem.order.toString(),
                    ),
                    "quantity",
                  )
                }
                idx={2}
              />
              <LineItemDesc
                title={"Remaining Quantity"}
                value={
                  currentBatch.workingList &&
                  _.sumBy(
                    currentBatch.workingList.filter(
                      (item) =>
                        item.sku.toString() === currentItem.sku.toString() &&
                        item.status === "UNPROCESSED" &&
                        item.order === currentItem.order,
                    ),
                    "quantity",
                  )
                }
                idx={2}
              />
              {subdomain === "kingly" && (
                <LineItemWithManualEntry
                  title="Serial Number"
                  value={
                    order?.orderLineItems?.find(
                      (item) => item.sku === currentItem.sku,
                    )?.serialNumber
                  }
                  onChangeValue={onChangeValue}
                  submitOrderLineItem={submitOrderLineItem}
                />
              )}
              <LineItemDesc title={"Order ID"} value={order?.orderId} idx={2} />

              {currentBatch?.attributes?.multipleLineItemBatch &&
                currentBatch?.currentSubTote && (
                  <LineItemDesc
                    title={"Sub-Tote"}
                    value={currentBatch?.currentSubTote}
                    idx={2}
                  />
                )}
              {currentItem?.customAttributes?.stockMoveSize && (
                <LineItemDesc
                  title={"Size"}
                  value={currentItem?.customAttributes?.stockMoveSize}
                  idx={2}
                />
              )}
              {currentItem?.customAttributes?.originalSize && (
                <LineItemDesc
                  title={"Order Size"}
                  value={currentItem?.customAttributes?.originalSize}
                  idx={3}
                />
              )}
              {currentItem?.customAttributes?.packingBoxCode && (
                <LineItemDesc
                  title={"Recommended Box"}
                  value={currentItem?.customAttributes?.packingBoxCode}
                  idx={3}
                />
              )}
              {prepEnabled &&
                currentProduct &&
                currentProduct.prepInstructions &&
                currentProduct.prepInstructions.length > 0 && (
                  <AmazonPrepInstructions
                    prepInstructions={currentProduct.prepInstructions}
                  />
                )}
            </div>
          </div>
          {currentProduct?.images && (
            <div className="flex flex-1 items-center justify-center bg-gray-300">
              <WorkflowProductImages
                images={[
                  currentProduct.images.find(
                    (i) => i.purpose === "OPERATIONS",
                  ) || currentProduct.images[0],
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>
      </div>
      {prepEnabled &&
        currentItem.validScans &&
        currentItem.validScans.length > 1 && (
          <BundlePrepDetails
            currentItem={currentItem}
            currentBatch={currentBatch}
            simulateItemScan={simulateItemScan}
          />
        )}
      {tenant?.settings?.activities?.packing?.allowManualQuantityEntry !==
        false && (
        <div className="mt-10 flex items-center justify-center text-center">
          <div className="pr-2 text-xl">Quantity</div>
          <NumberField
            onChange={(e) => changeQuantity(parseInt(e.target.value))}
            placeholder="Quantity"
            value={currentItem.quantity}
            className="p-4 text-2xl"
            min="0"
          />
        </div>
      )}

      {currentItemMismatch === null && (
        <>
          <div>
            <div className="mt-4 flex items-center justify-center space-x-4">
              <div className="w-64 py-4 text-center text-2xl text-black">
                {prepEnabled && currentProduct?.prepInstructions?.length > 0 ? (
                  <div className="w-64 py-4 text-center text-2xl text-black">
                    Have you prepped this item?
                  </div>
                ) : (
                  `Is this the correct item?`
                )}
              </div>
              <div
                className="w-64 rounded-md bg-red-500 py-4 text-center text-2xl text-white"
                onClick={() => setCurrentItemMismatch("MISMATCH")}>
                No
              </div>
              <div
                className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                onClick={() => {
                  setConfirmedPack(true);
                  setCurrentItemMismatch("CONFIRMED");
                }}>
                Yes
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-4">
            <div
              className="mt-4 w-64 rounded-md bg-red-600 py-4 text-center text-2xl text-white"
              onClick={cancelItem}>
              Back
            </div>
          </div>
        </>
      )}
      {currentItemMismatch !== null && (
        <div>
          <div className="mt-8 flex items-center justify-center space-x-4">
            <div
              className="w-64 rounded-md bg-red-600 py-4 text-center text-2xl text-white"
              onClick={() => setCurrentItemMismatch(null)}>
              Back
            </div>
            <div
              className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
              onClick={confirmPackItem}>
              Confirm Pack
            </div>
            {prepEnabled &&
              confirmedPack &&
              currentProduct?.source === "FBA" && (
                <div
                  className="w-64 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                  onClick={() => confirmPrepItem(true)}>
                  {tenant?.settings?.activities?.packing
                    ?.disableAutomaticPrinting
                    ? "Print FNSKU"
                    : "Re-Print FNSKU"}
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};
export default DetailedView;

const LineItemDesc = ({ title, value, idx, info }) => (
  <div className="flex w-full space-x-1 shadow-md">
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div
      className={`flex flex-1 items-center rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
      {info && (
        <div className="ml-2 mt-2">
          <InformationIcon message={info} />
        </div>
      )}
    </div>
  </div>
);

const LineItemWithManualEntry = ({
  title,
  value,
  idx,
  onChangeValue,
  submitOrderLineItem,
}) => (
  <div className="flex w-full space-x-1 shadow-md">
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="flex flex-1 items-center rounded-br-md rounded-tr-md border bg-white p-4">
      <input
        type="text"
        onChange={(e) => onChangeValue("serialNumber", e.target.value)}
        placeholder="Serial Number"
        value={value}
        className="p-2 text-lg"
      />
      <div
        className={`ml-2 w-48 rounded-md bg-2C7695 py-2 text-center text-xl text-white`}
        onClick={submitOrderLineItem}>
        Submit
      </div>
    </div>
  </div>
);

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};
