import { useState } from "react";
import Accordian from "#components/utils/Accordian";
import { InformationCircleIcon } from "@heroicons/react/outline";
import OrderDetailsTable from "./OrderDetailsTable";
import Modal from "#components/utils/Modal";

const FulfillableOrders = ({
  orders,
  refetchOrderDetails,
  setRefetchOrderDetails,
  updatedOrderBatchingDetails,
  setUpdatedOrderBatchingDetails,
  setOrderQuantityModified,
  tenant,
  qtyError,
  setQtyError,
}) => {
  if (!orders || orders.length === 0) {
    return <p>No Fulfillable Orders</p>;
  }

  const [activeIdx, setActiveIdx] = useState(0);
  const [expandedBackOrder, setExpandedBackOrder] = useState(null);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="border-gray mb-4 flex h-48 w-max items-center rounded border bg-gray-100 p-3">
        <InformationCircleIcon className="text-gray h-6 w-6" />
        <p className="ml-2 text-sm font-normal text-primaryAccent">
          These orders will get batched as they meet enough inventory to
          fulfill.
        </p>
      </div>

      {orders.map(
        (
          {
            order,
            originalOrder,
            originalOrderFulfillmentDetails,
            availableLineItemsQuantity,
          },
          idx,
        ) => {
          if (originalOrder && !originalOrder.splitOrders) {
            // this is a back order that can't be fulfilled.
            const originalOrderTitleComponent = (
              <div className="flex gap-x-2">
                <div className="flex flex-col">
                  <p className="text-lg font-semibold">
                    {originalOrder.orderId}
                  </p>
                  {activeIdx === idx && (
                    <p
                      className="font-base text-sm text-primaryAccent underline"
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpandedBackOrder({
                          originalOrder,
                          originalOrderFulfillmentDetails,
                        });
                      }}>
                      View Original Order Details
                    </p>
                  )}
                </div>
              </div>
            );
            return (
              <div key={order.id}>
                <Accordian
                  key={activeIdx}
                  titleComponent={originalOrderTitleComponent}
                  isActive={activeIdx === idx}
                  toggleCallback={(index) => {
                    if (index === activeIdx) setActiveIdx(-1);
                    else setActiveIdx(index);
                  }}
                  index={idx}>
                  <OrderDetailsTable
                    orderDetails={order.orderDetails}
                    availableLineItemsQuantity={availableLineItemsQuantity}
                    type="backOrders"
                    refetchOrderDetails={refetchOrderDetails}
                    setRefetchOrderDetails={setRefetchOrderDetails}
                    updatedOrderBatchingDetails={updatedOrderBatchingDetails}
                    setUpdatedOrderBatchingDetails={
                      setUpdatedOrderBatchingDetails
                    }
                    setOrderQuantityModified={setOrderQuantityModified}
                    tenant={tenant}
                    qtyError={qtyError}
                    setQtyError={setQtyError}
                  />
                </Accordian>
              </div>
            );
          }

          return (
            <div>
              <Accordian
                key={activeIdx}
                title={order.orderId}
                isActive={activeIdx === idx}
                toggleCallback={(index) => {
                  if (index === activeIdx) setActiveIdx(-1);
                  else setActiveIdx(index);
                }}
                index={idx}>
                <OrderDetailsTable
                  currentOrder={order}
                  orderDetails={order.orderDetails}
                  availableLineItemsQuantity={availableLineItemsQuantity}
                  type="fulfillableOrders"
                  refetchOrderDetails={refetchOrderDetails}
                  setRefetchOrderDetails={setRefetchOrderDetails}
                  updatedOrderBatchingDetails={updatedOrderBatchingDetails}
                  setUpdatedOrderBatchingDetails={
                    setUpdatedOrderBatchingDetails
                  }
                  setOrderQuantityModified={setOrderQuantityModified}
                  tenant={tenant}
                  qtyError={qtyError}
                  setQtyError={setQtyError}
                />
              </Accordian>
            </div>
          );
        },
      )}

      {expandedBackOrder && (
        <Modal
          negativeAction={() => setExpandedBackOrder(null)}
          positiveAction={() => setExpandedBackOrder(null)}
          title={`Order Details (${expandedBackOrder.originalOrder.orderId})`}
          noPadding={true}
          positiveText="OK">
          <div className="space-y-4 p-4">
            <OrderDetailsTable
              orderDetails={expandedBackOrder.originalOrder.orderDetails}
              fulfillmentDetails={
                expandedBackOrder.originalOrderFulfillmentDetails
              }
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FulfillableOrders;
