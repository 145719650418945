import React, { useState } from "react";
import PropTypes from "prop-types";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
const PackingGroupReview = ({ groups }) => {
  const [expandedGroups, setExpandedGroups] = useState([]);

  const toggleGroup = (groupName) => {
    setExpandedGroups((prev) =>
      prev.includes(groupName)
        ? prev.filter((group) => group !== groupName)
        : [...prev, groupName],
    );
  };

  const columnsForPackingGroup = [
    { title: "Product Info", key: "productInfo", isSort: false },
    {
      title: "Prepping Instructions",
      key: "preppingInstructions",
      isSort: false,
    },
    { title: "Units", key: "units", isSort: false, align: "center" },
  ];

  const mapGroupProductsToTableData = (products) => {
    return products.map((product) => ({
      productInfo: (
        <div className="flex items-center">
          <img
            src={product.image}
            alt={product.name}
            className="mr-4 h-12 w-12 rounded-md object-cover"
          />
          <div>
            <div className="text-sm font-semibold text-primaryAccent">
              {product.name}
            </div>
            <div className="text-sm text-gray-500">
              FNSKU: {product.fnSku || product.fnsku}
            </div>
            <div className="text-sm text-gray-500">SKU: {product.sku}</div>
            <div className="text-sm text-gray-500">ASIN: {product.asin}</div>
          </div>
        </div>
      ),
      preppingInstructions: (
        <div className="flex flex-wrap gap-2">
          {product.preppingInstructions.map((instruction, idx) => (
            <div
              key={idx}
              style={{
                backgroundColor: "#FFF8E7",
                color: "#B8860B",
                borderRadius: "9999px",
                padding: "4px 12px",
                fontWeight: 500,
                fontSize: "14px",
              }}>
              {instruction
                .replace("ITEM_", "")
                .split("_")
                .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                .join(" ")}
            </div>
          ))}
        </div>
      ),
      units: (
        <div className="text-center">
          <span className="rounded-full bg-green-100 px-3 py-1 text-sm text-green-700">
            {product.units}
          </span>
        </div>
      ),
    }));
  };

  // Transforming the groups data to match the required structure
  const transformedGroups = groups?.map((group, index) => ({
    groupName: `Group ${index + 1}`,
    totalItems: group.items?.length,
    totalUnits: group.items?.reduce((total, item) => total + item.quantity, 0),
    products: group?.items?.map((item) => ({
      image: item?.image || FALLBACK_IMAGE_URL,
      name: item.name || item.productName || item.msku,
      sku: item.fnsku,
      asin: item.asin,
      fnsku: item.fnsku || item.fnsku,
      preppingInstructions:
        item.prepInstructions?.map((instruction) => instruction.prepType) ||
        "No Instructions",
      units: item.quantity,
    })),
  }));

  return (
    <div className="p-6 font-inter">
      {/* Header */}
      <div className="mb-4 flex items-center gap-5">
        <span className="text-sm text-gray-400">
          Total No. of Groups: {transformedGroups?.length}
        </span>
        <span className="text-sm text-gray-400">
          Total Units:{" "}
          {transformedGroups?.reduce(
            (total, group) => total + group.totalUnits,
            0,
          )}
        </span>
      </div>

      {/* Group List */}
      <div className="space-y-4">
        {transformedGroups.map((group, index) => (
          <div
            key={group.groupName}
            className="rounded-lg border border-gray-200 bg-gray-50">
            {/* Group Header */}
            <div
              className="flex cursor-pointer items-center justify-between p-4 hover:bg-gray-100"
              onClick={() => toggleGroup(group.groupName)}>
              <div className="flex items-center">
                {/* Expand Icon */}
                {expandedGroups.includes(group.groupName) ? (
                  <ChevronDownIcon className="mr-2 h-5 w-5" />
                ) : (
                  <ChevronRightIcon className="mr-2 h-5 w-5" />
                )}
                <span className="text-base font-semibold text-gray-800">
                  {group.groupName}
                </span>
                <span className="ml-2 text-sm text-gray-500">
                  {group.totalUnits} Items
                </span>
              </div>
            </div>

            {/* Group Details */}
            {expandedGroups.includes(group.groupName) && (
              <div className="bg-white p-4">
                <CustomTable
                  columns={columnsForPackingGroup}
                  data={mapGroupProductsToTableData(group?.products)}
                  isPagination={false}
                  isFilters={false}
                  isSearchable={false}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

PackingGroupReview.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      packingGroupId: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          asin: PropTypes.string.isRequired,
          fnsku: PropTypes.string.isRequired,
          msku: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired,
          image: PropTypes.string,
          prepInstructions: PropTypes.arrayOf(
            PropTypes.shape({
              prepType: PropTypes.string.isRequired,
              prepOwner: PropTypes.string.isRequired,
            }),
          ),
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default PackingGroupReview;
