import React, { useState } from "react";
import CustomTable from "#newUiComponents/commons/CustomTable";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import dayjs from "dayjs";

const SelectDeliveryOptions = ({
  data = [],
  selectedDeliveryWindow,
  setSelectedDeliveryWindow,
}) => {
  const selectedPlacementOptionData = JSON.parse(
    localStorage.getItem("selectedPlacementOptionData"),
  );

  const transportOptions = JSON.parse(
    localStorage.getItem("transportationOptions"),
  );

  const getFilteredDeliveryOptions = (deliveryOptions, shipmentId) => {
    // Find the relevant transport option for this shipment ID
    const transportOption = transportOptions.find(
      (option) => option.shipmentId === shipmentId,
    );

    // Check carrierAppointment endTime
    const carrierAppointmentEndTime =
      transportOption?.carrierAppointment?.endTime;

    // Filter delivery options
    if (carrierAppointmentEndTime) {
      const endTime = dayjs(carrierAppointmentEndTime);
      const filtered = deliveryOptions.filter((option) =>
        dayjs(option.endDate).isAfter(endTime),
      );
      return filtered;
    }

    // If no carrierAppointment or endTime, return all options
    return deliveryOptions;
  };

  const formatDeliveryWindow = (start, end) => {
    if (!start || !end) {
      return ""; // Return empty string if start or end is missing
    }
    const formattedStart = dayjs(start).format("MMM D, YYYY");
    const formattedEnd = dayjs(end).format("MMM D, YYYY");
    return `${formattedStart} - ${formattedEnd}`;
  };

  const columns = [
    {
      title: "Destination",
      key: "destination",
    },
    {
      title: "Shipment ID",
      key: "shipmentId",
    },
    {
      title: "Carrier Type",
      key: "carrierType",
    },
    {
      title: "Delivery Window Selection",
      key: "deliveryWindowSelection",
      render: (_, row) => {
        const filteredOptions = getFilteredDeliveryOptions(
          row.deliveryWindowOptions || [],
          row.shipmentId,
        );

        const options = filteredOptions.map((option) => ({
          label: formatDeliveryWindow(option.startDate, option.endDate),
          value: option.deliveryWindowOptionId,
        }));

        return (
          <AutoCompleteSingleSelect
            options={options}
            onChange={(selectedOption) => {
              setSelectedDeliveryWindow((prev) => ({
                ...prev,
                [row.shipmentId]: selectedOption,
              }));
            }}
            value={selectedDeliveryWindow[row.shipmentId]}
            labelKey="label"
            valueKey="value"
            placeholder="Select Delivery Window"
          />
        );
      },
    },
  ];

  const tableData = data.map((item) => ({
    ...item,
    destination:
      selectedPlacementOptionData?.shipmentsWithBoxesAndItems?.find(
        (shipment) => shipment.shipmentId === item.shipmentId,
      )?.destination?.address?.city || "",
    fc:
      selectedPlacementOptionData?.shipmentsWithBoxesAndItems?.find(
        (shipment) => shipment.shipmentId === item.shipmentId,
      )?.destination?.warehouseId || "",
    carrierType:
      transportOptions.find((option) => option.shipmentId === item.shipmentId)
        ?.shippingSolution === "AMAZON_PARTNERED_CARRIER"
        ? "Partnered"
        : "Non-Partnered",
    // units: 20,
  }));

  return (
    <div className="flex h-full flex-col">
      <div className="flex-1">
        <CustomTable data={tableData} columns={columns} />
      </div>
    </div>
  );
};

export default SelectDeliveryOptions;
