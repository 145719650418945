import React, { useContext, useEffect, useState } from "react";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import Modal from "#components/utils/Modal";
import { useQuery } from "#hooks/useQuery";
import { GET_PRODUCT_SALES_PRICING } from "#queries/index";
import { AppStateContext } from "#contexts/appState";
import LoadingIndicator from "#components/utils/LoadingIndicator";

const SalesOrderAddToCart = ({
  dismiss,
  product,
  products,
  handleAddToCart,
  setProducts,
  cart,
  salesCustomers,
  selectedConsignment,
}) => {
  const appState = useContext(AppStateContext);
  const getProductSalesPricing = useQuery(GET_PRODUCT_SALES_PRICING);

  const salesChannel = selectedConsignment?.company;
  const entity = salesCustomers.find((cus) => cus.name === salesChannel).entity;

  const [productUnitPricing, setProductUnitPricing] = useState([]);
  const [isGiftBoxProduct, setIsGiftBoxProduct] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(1);
  const [customEntities, setCustomEntities] = useState("");
  const [customEntityPrice, setCustomEntityPrice] = useState(null);
  const [customEntityError, setCustomEntityError] = useState("");

  const fetchCustomUnitPrice = () => {
    if (!customEntities || !productUnitPricing.length) return;

    const smallestUnit = productUnitPricing[0][entity];

    // custom unit should be a multiple of smallest unit
    if (customEntities % smallestUnit !== 0) {
      setCustomEntityError(
        `Custom ${entity} should be a multiple of the smallest ${smallestUnit}`,
      );
      return;
    } else if (customEntities < 1) {
      setCustomEntityError("Please enter a value greater than 0.");
      return;
    } else {
      const nearestEntity = [...productUnitPricing]
        .reverse()
        .find((product) => customEntities >= product[entity]);

      if (nearestEntity) {
        const customEntityPrice =
          (customEntities * nearestEntity?.price) / nearestEntity?.[entity];

        setCustomEntityPrice(customEntityPrice);
      }

      setCustomEntityError("");
    }
  };

  const getProductQuantity = () => {
    if (isGiftBoxProduct && selectedEntity === "custom") {
      const smallestUnit = productUnitPricing[0];
      const nearestEntity = [...productUnitPricing]
        .reverse()
        .find((product) => customEntities >= product[entity]);

      const unitEntities =
        Number(customEntities) % Number(nearestEntity?.[entity]);

      const wholeEntities =
        ((Number(customEntities) - Number(unitEntities)) /
          Number(nearestEntity?.[entity])) *
        nearestEntity?.bulbs;

      const totalEntities =
        Number(wholeEntities) + Number(unitEntities * smallestUnit.bulbs);

      return totalEntities;
    } else if (!isGiftBoxProduct && selectedEntity === "custom") {
      if (salesChannel === "VE")
        return customEntities * productUnitPricing[0].bulbs;
      else if (salesChannel === "JS") return customEntities;
      else return parseInt(customEntities);
    } else {
      if (["VE", "JS"].includes(salesChannel)) {
        return productUnitPricing.find(
          (product) => product[entity] === Number(selectedEntity),
        ).bulbs;
      } else {
        return productUnitPricing.find(
          (product) => product[entity] === Number(selectedEntity),
        ).each;
      }
    }
  };

  useEffect(() => {
    if (cart?.length > 0 && productUnitPricing.length > 0) {
      const productInCart = cart.find((item) => product.sku === item.sku);

      if (productInCart) {
        const productEntity = productInCart[entity];

        if (
          productUnitPricing.find(
            (pricing) => pricing[entity] === productEntity,
          )
        ) {
          setSelectedEntity(productEntity);
        } else {
          setCustomEntities(productEntity);
          setCustomEntityPrice(productInCart.price);
          setSelectedEntity("custom");
        }
      }
    }
  }, [cart, productUnitPricing]);

  useEffect(() => {
    const updatedProducts = products.map((p) =>
      p.sku === product.sku
        ? {
            ...p,
            [entity]:
              selectedEntity === "custom" ? customEntities : selectedEntity,
          }
        : p,
    );

    console.log("Updating products:", updatedProducts);
    setProducts(updatedProducts);
  }, [selectedEntity, customEntities]);

  useEffect(() => {
    fetchCustomUnitPrice();
  }, [customEntities, productUnitPricing]);

  useEffect(() => {
    (async () => {
      if (!product) return;

      const res = await getProductSalesPricing.fetchData({
        filters: {
          keyword: product?.sku,
          salesChannel,
        },
        perPage: 1,
        pageNumber: 1,
      });

      if (res.data.getProductSalesPricing.entities[0]?.prices.length > 0) {
        setProductUnitPricing(
          res.data.getProductSalesPricing.entities[0].prices,
        );
        setIsGiftBoxProduct(
          res.data.getProductSalesPricing.entities[0]?.isGiftBoxProduct,
        );
      } else {
        appState.setAlert(
          `No pricing found for SKU: ${product.sku}`,
          "error",
          5000,
        );
      }
    })();
  }, [product]);

  if (!productUnitPricing.length) return null;

  return (
    <Modal
      onClose={dismiss}
      title={product.name}
      isTerms={true}
      maxWidth="640px">
      <div className="mb-4">
        <label className="mb-2 block font-medium text-gray-700">
          {entity.toUpperCase()}
        </label>
        <div className="space-y-4">
          {getProductSalesPricing.loading ? (
            <LoadingIndicator shouldShowOnPage={false} />
          ) : (
            productUnitPricing?.map((product) => {
              const productPricePerEntity = product.price / product[entity];

              return (
                <div
                  key={product[entity]}
                  className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id={product[entity]}
                      name={entity}
                      type="radio"
                      value={product[entity]}
                      checked={Number(selectedEntity) === product[entity]}
                      onChange={(e) => {
                        setSelectedEntity(e.target.value);
                      }}
                      className="form-radio h-4 w-4 text-blue-600"
                    />
                    <label
                      htmlFor={product[entity]}
                      className="ml-2 flex flex-col">
                      <span>
                        {product[entity]} {entity}
                      </span>

                      {salesChannel === "VE" && (
                        <span className="text-xs text-gray-500">
                          {product.bulbs} Bulbs
                        </span>
                      )}
                    </label>
                  </div>
                  <div className="text-right">
                    <span className="font-semibold">
                      ${Number(product.price).toFixed(2)}
                    </span>
                    {productPricePerEntity > 0 && (
                      <div className="text-xs text-gray-500">
                        ${productPricePerEntity.toFixed(2)}/{entity}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="custom"
                name={entity}
                type="radio"
                value="custom"
                checked={selectedEntity === "custom"}
                onChange={() => setSelectedEntity("custom")}
                className="form-radio h-4 w-4 text-blue-600"
              />
              <label htmlFor="custom" className="ml-2 flex flex-col">
                <span>Custom</span>
                <div>
                  <input
                    type="number"
                    min={1}
                    placeholder="Capacity"
                    value={customEntities}
                    onChange={(e) => setCustomEntities(e.target.value)}
                    className="mt-1 w-28 rounded-md border p-1"
                    disabled={selectedEntity !== "custom"}
                  />
                  <span className="ml-3">{entity}</span>
                </div>

                {customEntityError && (
                  <span className="text-sm text-red-500">
                    {customEntityError}
                  </span>
                )}
              </label>
            </div>
            {!customEntityError && customEntities && (
              <div className="text-right">
                <span className="font-semibold">
                  ${Number(customEntityPrice).toFixed(2)}
                </span>
                <div className="text-xs text-gray-500">
                  ${(customEntityPrice / customEntities).toFixed(2)}/{entity}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end pt-4">
        <div className="flex justify-between gap-4">
          <button
            onClick={dismiss}
            className="rounded-md border border-primaryAccent bg-white px-4 py-2 font-semibold text-primaryAccent">
            Cancel
          </button>
          <button
            className="flex items-center gap-2 rounded-md bg-primaryAccent px-4 py-2 font-semibold text-white disabled:cursor-not-allowed disabled:bg-gray-500"
            onClick={() => {
              if (
                !entity ||
                (!selectedEntity && !customEntities) ||
                !productUnitPricing
              ) {
                console.error("Select entity");
                return;
              }

              handleAddToCart({
                ...product,
                quantity: getProductQuantity(),
                [entity]:
                  selectedEntity === "custom"
                    ? customEntities
                    : productUnitPricing.find(
                        (product) => product[entity] === Number(selectedEntity),
                      )[entity],
                price:
                  selectedEntity === "custom"
                    ? customEntityPrice
                    : productUnitPricing.find(
                        (product) => product[entity] === Number(selectedEntity),
                      )?.price,
              });
              dismiss();
            }}
            disabled={
              !selectedEntity ||
              (selectedEntity === "custom" && !customEntities)
            }>
            <span> Add To Cart</span>
            <ShoppingCartIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SalesOrderAddToCart;
