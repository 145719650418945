import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import BarcodeForm from "#components/receiver/BarcodeForm";
import NumberField from "#components/utils/NumberField";
import TextField from "#components/utils/TextField";
import WorkflowProductImages from "#components/products/WorkflowProductImages";

const DetailedView = ({
  currentSku,
  confirmItem,
  setCurrentSku,
  scanBarcode,
  putawayAvailableQty,
  setPutawayAvailableQty,
  cancelItem,
  currentProduct,
}) => {
  const [barcode, setBarcode] = useState(null);

  return (
    <div className="mx-auto w-full lg:w-1/2">
      <PageTitle>Product Details</PageTitle>
      <div className="grid grid-cols-3">
        <div
          className={`w-full flex-1 bg-gray-100 ${
            currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-4"
          }`}>
          <div className="space-y-2 text-xl font-medium text-454A4F">
            <LineItemValue
              value={currentSku.productName}
              title="Name"
              idx={1}
            />
            <LineItemValue value={currentSku.sku} title="SKU" idx={2} />

            <LineItemValue value={currentSku.fnSku} title="Fn SKU" idx={3} />

            <LineItemValue
              value={currentSku.formFactor}
              title="Form Factor"
              idx={4}
            />

            {currentSku.binLocation && (
              <LineItemValueWithOptions
                value={currentSku.binLocation}
                title="Bin"
                idx={5}
                setBarcode={setBarcode}
              />
            )}
            <LineItemValue
              value={putawayAvailableQty}
              title="Qty available for Putaway"
              idx={6}
            />
          </div>
        </div>

        {currentProduct?.images?.length > 0 && (
          <div className="flex flex-1 items-center justify-center bg-gray-300">
            <WorkflowProductImages
              images={[
                currentProduct.images.find((i) => i.purpose === "OPERATIONS") ||
                  currentProduct.images[0],
              ].filter(Boolean)}
            />
          </div>
        )}
      </div>

      <div className="mx-auto mt-8 flex w-96 items-center justify-center bg-white">
        <TextField
          type="text"
          id="name"
          label="Scan/Enter a Location"
          placeholder=" "
          onChange={(e) => setBarcode(e.target.value)}
          value={barcode}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              const enteredBarcode = e.target.value;
              const response = await scanBarcode({
                barcode: enteredBarcode,
                formFactor: currentSku.formFactor,
              });
              if (
                response.data.scanBundlePutawayItem.message !==
                "Invalid bin location"
              ) {
                setCurrentSku({
                  ...currentSku,
                  binLocation: enteredBarcode,
                });
              } else {
                setCurrentSku({
                  ...currentSku,
                });
              }
              setBarcode(null);
            }
          }}
          autoFocus={true}
        />
      </div>

      <div className="mt-10 flex items-center justify-center text-center">
        <div>
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-2xl font-medium text-454A4F">
              Quantity
            </span>
          </label>
          <NumberField
            onChange={(e) =>
              setCurrentSku({
                ...currentSku,
                scannedQuantity: !isNaN(parseInt(e.target.value))
                  ? parseInt(e.target.value)
                  : null,
              })
            }
            placeholder="Qty"
            value={currentSku.scannedQuantity}
            className="w-36 rounded-md p-4 text-lg"
            min="0"
          />
        </div>
      </div>

      <div className="mt-20 flex items-center justify-center space-x-6">
        <div
          className="w-64 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
          onClick={confirmItem}>
          Confirm
        </div>

        <div
          className="w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
          onClick={cancelItem}>
          Cancel
        </div>
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="flex w-full max-w-4xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="flex-1 rounded-br-md rounded-tr-md border bg-white p-4">
      {value}
    </div>
  </div>
);

const LineItemValueWithOptions = ({ title, value, idx, setBarcode }) => (
  <div className="flex w-full max-w-4xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="flex-1 rounded-br-md rounded-tr-md border bg-white p-4">
      {value}
    </div>
  </div>
);

export default DetailedView;
