import { v4 as uuidv4 } from "uuid";

export const getEntityStatusColor = (status) => {
  switch (status) {
    case "UNPROCESSED":
      return { bgColor: "bg-[#E8E8E8]", color: "text-[#656867]" };
    case "IN-PROCESS":
      return { bgColor: "bg-[#EBF0FA]", color: "text-[#2264E5]" };
    case "COMPLETED":
      return { bgColor: "bg-[#E1FCEF]", color: "text-[#14804A]" };
    case "CANCELLED":
      return { bgColor: "bg-[#FFF4F3]", color: "text-[#CA3A31]" };
    case "BATCHED":
      return { bgColor: "bg-[#FBECFF]", color: "text-[#8D0F92]" };
    case "INCOMPLETE":
      return { bgColor: "bg-[#FFE4CD]", color: "text-[#FF780A]" };
    case "ON_HOLD":
      return { bgColor: "bg-[#FFF3CD]", color: "text-[#856404]" };
    case "EXCEPTION":
      return { bgColor: "bg-[#E4F8FF]", color: "text-[#1290CF]" };
    case "DRAFT":
      return { bgColor: "bg-[#B6B6B6]", color: "text-[#232222]" };
    case "QUEUED_FOR_BATCHING":
      return { bgColor: "bg-[#FFF9BC]", color: "text-[#998204]" };
    case "BATCHED_PARTIALLY":
      return { bgColor: "bg-[#FFE4FC]", color: "text-[#CF12BC]" };
    case "RECEIVING_STARTED":
      return { bgColor: "bg-[#E6FFD9]", color: "text-[#10C914]" };
    case "CLOSED":
      return { bgColor: "bg-[#FFE0C6]", color: "text-[#61330A]" };
    default:
      return { bgColor: "bg-[#EBF0FA]", color: "text-[#2264E5]" };
  }
};

export const getHighlightedText = ({ text, activeTab, searchHighlights }) => {
  if (!searchHighlights?.length) return text;

  const textsFound = searchHighlights?.find(
    (hightlight) => hightlight?.path === activeTab,
  )?.texts;

  if (!textsFound?.length) return text;

  const finalText = textsFound?.map((text) => (
    <span
      key={uuidv4()}
      className={`${text?.type === "hit" ? "bg-green-100 font-medium" : ""}`}>
      {text?.value}
    </span>
  ));

  return { text: finalText, textsFound };
};

export const getTotalCount = (total) => {
  if (total <= 100) return total;
  else if (total > 100 && total <= 1000) return `${total?.toString()[0]}00+`;
  else return "1000+";
};
