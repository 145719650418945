import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_UOM_LABELS } from "#queries";
import { SAVE_UOM_LABELS } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { MasterDataContext } from "#contexts/masterData";
import { AuthContext } from "#contexts/auth";

const withUoMLogic = (WrappedComponent) => {
  return (props) => {
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const appState = useContext(AppStateContext);
    const masterData = useContext(MasterDataContext);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [selectedStation, setSelectedStation] = useState(null);
    const uomQuery = useQuery(GET_UOM_LABELS);
    const saveUomQuery = useQuery(SAVE_UOM_LABELS);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
      uomQuery.fetchData({
        perPage: entity.perPage,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    }, []);

    useEffect(() => {
      if (uomQuery.data && uomQuery.data.getUomLabels) {
        const data = uomQuery.data;
        const { labels, totalLabels } = uomQuery.data.getUomLabels;

        // Assuming entity.setEntities expects an object with 'labels' and 'totalLabels' properties
        entity.setEntities({
          entities: labels,
          total: totalLabels,
        });

        // Remove the loading state
        appState.removeLoading();
      }
    }, [uomQuery.loading, uomQuery.error, uomQuery.data]);

    useEffect(() => {
      if (saveUomQuery.data) {
        setSelectedStation(null);
        uomQuery.fetchData({
          perPage: entity.perPage,
          filters: { ...entity.filters },
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (saveUomQuery.error) {
        appState.removeLoading();
        appState.setAlert(saveUomQuery.error.message, "error", 5000);
      }

      if (saveUomQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [saveUomQuery.loading, saveUomQuery.data, saveUomQuery.error]);

    const onChangeMultiSelect = (field, value) => {
      const station = {
        ...selectedStation,
      };
      station[field] = value;
      setSelectedStation(station);
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return uomQuery.fetchData({ vars });
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        stations={entity.displayEntities}
        masterData={masterData}
        selectedStation={selectedStation}
        setSelectedStation={setSelectedStation}
        saveStation={(station) => {
          const labelsObject = { ...station.labels };
          const labelsArray = Object.values(labelsObject);
          saveUomQuery.fetchData({ labels: labelsArray });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        onChangeMultiSelect={onChangeMultiSelect}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        selectedCustomer={selectedCustomer}
        writable={props.writable}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          uomQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
      />
    );
  };
};

export default withUoMLogic;
