import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Popover, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";

const CustomPopover = ({
  triggerElement,
  actions,
  styles = { right: "5rem" },
  bgColorVisible = true,
}) => {
  return (
    <div className="relative">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={` ${open && bgColorVisible ? ":focus:border-none bg-gray-100" : ":focus:border-none"} `}
              as="button">
              {(typeof triggerElement === "function"
                ? triggerElement(open)
                : triggerElement) || <DotsVerticalIcon className="h-6 w-6" />}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel
                style={{ ...styles }}
                className="absolute right-0 z-50 mt-2 w-56 rounded-md bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5">
                <div className="relative">
                  <div className="arrow-up absolute left-1/2 top-0 -translate-x-1/2 -translate-y-full transform"></div>
                  <div className="py-1">
                    {actions.map((action, idx) => (
                      <button
                        key={idx}
                        onClick={action.onClick}
                        disabled={action.disabled}
                        className={`flex w-full items-start px-4 py-2 text-left text-sm hover:bg-blue-100 hover:text-blue-700 ${
                          action.disabled
                            ? "cursor-not-allowed text-gray-200"
                            : "text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                        }`}>
                        {action.icon && (
                          <span className="mr-2">{action.icon}</span>
                        )}
                        <div className="flex flex-col">
                          <span>{action.name}</span>
                          {action.description && (
                            <span className="text-xs text-gray-500">
                              {action.description}
                            </span>
                          )}
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

CustomPopover.propTypes = {
  triggerElement: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.node,
      description: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  styles: PropTypes.object,
  bgColorVisible: PropTypes.bool,
};

export default CustomPopover;
