import React, { useState, useEffect } from "react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import CustomNotification from "#newUiComponents/commons/CustomNotification";

const FbaPalletManagement = ({ locations = [], onSave }) => {
  const [expandedLocation, setExpandedLocation] = useState(null);
  const [hasValidPallets, setHasValidPallets] = useState(false);
  const notify = CustomNotification();

  const [palletInfo, setPalletInfo] = useState(() => {
    const initialData = {};
    locations.forEach((location) => {
      initialData[location.shipmentId] = [];
    });
    return initialData;
  });

  useEffect(() => {
    // Check if at least one location has at least one pallet
    const isValid = Object.values(palletInfo).some(
      (pallets) => pallets.length > 0,
    );
    setHasValidPallets(isValid);
  }, [palletInfo]);

  const handleReset = () => {
    const initialData = {};
    locations.forEach((location) => {
      initialData[location.shipmentId] = [];
    });
    setPalletInfo(initialData);
  };

  const handleAddPallet = (shipmentId) => {
    setPalletInfo((prev) => ({
      ...prev,
      [shipmentId]: [
        ...(prev[shipmentId] || []),
        { length: "48", breadth: "40", height: "0", weight: "0" },
      ],
    }));
    // Set the expandedLocation to the shipmentId when adding a pallet
    setExpandedLocation(shipmentId);
    notify.success(
      `New pallet added to ${locations.find((loc) => loc.shipmentId === shipmentId)?.cityName}`,
    );
  };

  const handleRemovePallet = (shipmentId, index) => {
    setPalletInfo((prev) => ({
      ...prev,
      [shipmentId]: prev[shipmentId].filter((_, i) => i !== index),
    }));
  };

  const handleDimensionChange = (shipmentId, palletIndex, dimension, value) => {
    setPalletInfo((prev) => ({
      ...prev,
      [shipmentId]: prev[shipmentId].map((pallet, i) =>
        i === palletIndex ? { ...pallet, [dimension]: value } : pallet,
      ),
    }));
  };

  const handleSave = () => {
    const payload = Object.entries(palletInfo).map(([shipmentId, pallets]) => ({
      shipmentId,
      pallets: pallets.map((pallet) => ({
        quantity: 1,
        weight: {
          unit: "LB",
          value: parseFloat(pallet.weight) || 0,
        },
        dimensions: {
          length: parseFloat(pallet.length) || 0,
          width: parseFloat(pallet.breadth) || 0,
          height: parseFloat(pallet.height) || 0,
          unitOfMeasurement: "IN",
        },
      })),
    }));
    onSave(payload);
  };

  return (
    <div className="rounded-lg border border-gray-200 bg-white">
      <div className="border-b border-gray-200 p-4">
        <h2 className="text-lg font-medium text-gray-900">
          LTL Pallet Management
        </h2>
      </div>

      <div className="divide-y divide-gray-200">
        {locations.map((location) => (
          <div key={location.shipmentId}>
            <div
              className="flex cursor-pointer items-center justify-between bg-gray-50 p-4"
              onClick={() =>
                setExpandedLocation(
                  expandedLocation === location.shipmentId
                    ? null
                    : location.shipmentId,
                )
              }>
              <div className="flex items-center gap-2">
                {expandedLocation === location.shipmentId ? (
                  <ChevronDownIcon className="h-4 w-4 text-gray-600" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4 text-gray-600" />
                )}
                <span className="text-sm font-medium text-gray-900">
                  {location.cityName}
                </span>
              </div>
              <button
                className="text-sm font-medium text-primaryAccent hover:text-primaryAccent/80"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddPallet(location.shipmentId);
                }}>
                + Add Pallet
              </button>
            </div>

            {expandedLocation === location.shipmentId && (
              <div className="px-8 py-2">
                {palletInfo[location.shipmentId]?.map((pallet, index) => (
                  <div key={index} className="flex items-center gap-6 py-3">
                    <div className="flex w-96 items-center gap-2">
                      <span className="text-base text-gray-600">
                        Pallet {index + 1}: (l" x w" x h") & (Weight lbs)
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        type="number"
                        className="w-16 rounded border border-gray-300 px-2 py-1 text-sm"
                        value={pallet.length}
                        onChange={(e) =>
                          handleDimensionChange(
                            location.shipmentId,
                            index,
                            "length",
                            e.target.value,
                          )
                        }
                        placeholder="L"
                      />
                      <span className="text-gray-400">×</span>
                      <input
                        type="number"
                        className="w-16 rounded border border-gray-300 px-2 py-1 text-sm"
                        value={pallet.breadth}
                        onChange={(e) =>
                          handleDimensionChange(
                            location.shipmentId,
                            index,
                            "breadth",
                            e.target.value,
                          )
                        }
                        placeholder="B"
                      />
                      <span className="text-gray-400">×</span>
                      <input
                        type="number"
                        className="w-16 rounded border border-gray-300 px-2 py-1 text-sm"
                        value={pallet.height}
                        onChange={(e) =>
                          handleDimensionChange(
                            location.shipmentId,
                            index,
                            "height",
                            e.target.value,
                          )
                        }
                        placeholder="H"
                      />
                    </div>

                    <input
                      type="number"
                      className="w-32 rounded border border-gray-300 px-2 py-1 text-sm"
                      value={pallet.weight}
                      onChange={(e) =>
                        handleDimensionChange(
                          location.shipmentId,
                          index,
                          "weight",
                          e.target.value,
                        )
                      }
                      placeholder="Enter Weight"
                    />

                    <button
                      onClick={() =>
                        handleRemovePallet(location.shipmentId, index)
                      }
                      className="text-gray-400 hover:text-red-600">
                      <TrashIcon className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-end gap-4 border-t border-gray-200 p-4">
        <PrimaryButton
          height="2.5rem"
          minWidth="10rem"
          maxWidth="15rem"
          variant="secondary"
          className="text-sm font-medium"
          onClick={handleReset}>
          Reset
        </PrimaryButton>
        <PrimaryButton
          height="2.5rem"
          minWidth="10rem"
          maxWidth="15rem"
          variant="primary"
          className="text-sm font-medium"
          onClick={handleSave}
          disabled={!hasValidPallets}>
          Save and Regenerate Rates
        </PrimaryButton>
      </div>
    </div>
  );
};

export default FbaPalletManagement;
