import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import { ChevronDownIcon, ArrowLeftIcon } from "@heroicons/react/outline";

import CustomerSearchBar from "#components/salesManagement/CustomerSearchBar";
import AuditHistory from "#components/salesManagement/AuditHistory";
import SlideOverModal from "#components/common/SlideOverModal";
import { CreateCustomer } from "#components/salesManagement/CreateCustomer";
import CustomerDetails from "#components/salesManagement/CustomerDetails";
import OrderHistoryTable from "#components/salesManagement/OrderHistoryTable";
import CrmOrderHistoryTable from "#components/salesManagement/CrmOrderHistoryTable";
import DownloadHistory from "#components/salesManagement/DownloadHistory";
import Popover from "#components/utils/Popover";
import vipBadge from "#static/images/vipBadge.png";

const orderHistoryType = {
  SYSTEM: "system",
  CRM: "crm",
};

export const SALES_MANAGEMENT_ACTIONS_TYPE_ENUM = {
  DOWNLOAD_HISTORY: "DOWNLOAD_HISTORY",
  CREATE_NEW_CUSTOMER: "CREATE_NEW_CUSTOMER",
};
export const SALES_MANAGEMENT_ACTION_TYPES = [
  {
    name: "Create New Customer",
    value: "CREATE_NEW_CUSTOMER",
    title: "Create New Customer",
    subTitle: "",
  },
  {
    name: "Download History",
    value: "DOWNLOAD_HISTORY",
    title: "Download History",
    subTitle: "",
  },
];

const SalesManagement = () => {
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showFullOrderHistory, setShowFullOrderHistory] = useState(false);
  const [showDownloadHistory, setShowDownloadHistory] = useState(false);
  const [selectedOrderHistoryType, setSelectedOrderHistoryType] = useState(
    orderHistoryType.SYSTEM,
  );

  const unselectCustomer = () => setSelectedCustomer(null);
  const hideOrderHistory = () => setShowFullOrderHistory(false);
  const openModal = () => setShowCreateCustomer(true);
  const closeModal = () => setShowCreateCustomer(false);
  const showFullOrderHistoryTable = (type) => {
    setSelectedOrderHistoryType(type);
    setShowFullOrderHistory(true);
  };

  const openDownloadHistoryModal = () => setShowDownloadHistory(true);
  const closeDownloadHistoryModal = () => setShowDownloadHistory(false);

  const handleManageActionClick = (actionType) => {
    switch (actionType) {
      case SALES_MANAGEMENT_ACTIONS_TYPE_ENUM?.CREATE_NEW_CUSTOMER:
        openModal();
        break;
      case SALES_MANAGEMENT_ACTIONS_TYPE_ENUM?.DOWNLOAD_HISTORY:
        openDownloadHistoryModal();
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="p-8">
        <div className="mb-8 flex items-start justify-between">
          <div>
            {selectedCustomer && !showFullOrderHistory && (
              <div className="flex">
                <PageTitle>
                  Customer Details{` > ${selectedCustomer?.hrid}`}
                </PageTitle>
                {selectedCustomer?.isVIP === "yes" && (
                  <img className="ml-1 mt-1 h-6 w-6" src={vipBadge} />
                )}
              </div>
            )}
            {selectedCustomer && showFullOrderHistory && (
              <PageTitle>
                Customer Details
                {` > ${selectedCustomer?.hrid} > Order History`}
              </PageTitle>
            )}
            {!selectedCustomer && (
              <p className="text-gray-600">
                Search and modify customer details from your CRM integration
                here
              </p>
            )}
          </div>
          <div className="flex justify-end">
            {selectedCustomer && !showFullOrderHistory && (
              <button
                onClick={unselectCustomer}
                className="inline-flex w-full cursor-pointer items-center gap-x-1 rounded-lg bg-primaryAccent px-3 py-2 font-medium text-white">
                <ArrowLeftIcon className="h-5 w-5" />
              </button>
            )}
            {selectedCustomer && showFullOrderHistory && (
              <button
                onClick={hideOrderHistory}
                className="inline-flex w-full cursor-pointer items-center gap-x-1 rounded-lg bg-primaryAccent px-3 py-2 font-medium text-white">
                <ArrowLeftIcon className="h-5 w-5" />
              </button>
            )}
            {!selectedCustomer && (
              <div className="mb-4 flex items-center justify-end space-x-2">
                {SALES_MANAGEMENT_ACTION_TYPES && (
                  <Popover
                    title={"Manage Actions"}
                    showChevron={true}
                    panelClassName="mt-2 bg-bgWhite z-10 
                    overflow-auto rounded-lg p-1 
                    border border-borderGray w-[17rem]"
                    showOverlay={true}>
                    {SALES_MANAGEMENT_ACTION_TYPES?.map((action) => {
                      return (
                        <div
                          key={action.name}
                          className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                          onClick={() => handleManageActionClick(action.value)}>
                          {action.name}
                        </div>
                      );
                    })}
                  </Popover>
                )}
              </div>
            )}
          </div>
        </div>
        {!selectedCustomer && (
          <>
            <CustomerSearchBar
              setShowCreateCustomer={openModal}
              setSelectedCustomer={setSelectedCustomer}
            />
            <AuditHistory />
          </>
        )}
        {selectedCustomer && !showFullOrderHistory && (
          <CustomerDetails
            crmCustomerData={selectedCustomer}
            onShowFullOrderHistory={showFullOrderHistoryTable}
            setCrmCustomerData={setSelectedCustomer}
          />
        )}
        {selectedCustomer && showFullOrderHistory && (
          <div className="h-[80vh]">
            {selectedOrderHistoryType === orderHistoryType.SYSTEM && (
              <OrderHistoryTable
                crmCustomerId={selectedCustomer.id}
                full={true}
              />
            )}
            {selectedOrderHistoryType === orderHistoryType.CRM && (
              <CrmOrderHistoryTable
                crmCustomerId={selectedCustomer.id}
                full={true}
              />
            )}
          </div>
        )}
      </div>
      <SlideOverModal
        open={showCreateCustomer}
        onClose={closeModal}
        title={"Add Customer"}
        subtitle={
          <div className="!text-base !font-light !text-gray-400">
            This screen will be used to add customer account information.
          </div>
        }>
        <CreateCustomer onCancel={setShowCreateCustomer} />
      </SlideOverModal>

      <SlideOverModal
        open={showDownloadHistory}
        onClose={closeDownloadHistoryModal}
        title={"Download History"}>
        <DownloadHistory onCancel={setShowDownloadHistory} />
      </SlideOverModal>
    </>
  );
};

export default SalesManagement;
