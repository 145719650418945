import { useContext } from "react";

import Notification from "#components/utils/Notification";
import ActionableNotification from "#components/utils/ActionableNotification";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import ConfirmationDialog from "#components/utils/ConfirmationDialog";
import NewConfirmationDialog from "#newUiComponents/commons/NewConfirmationDialog";
import CustomLoadingOverlay from "#newUiComponents/commons/CustomLoadingOverlay";
import { AppStateContext } from "#contexts/appState";

const Layout = ({ children }) => {
  const appState = useContext(AppStateContext);
  return (
    <div className="h-full">
      {(appState.loading === true || appState.pageLoading === true) && (
        <LoadingIndicator />
      )}
      <div>
        <div>{children}</div>
      </div>

      <Notification alerts={appState.alerts} />
      {appState.actionableAlert && (
        <ActionableNotification
          text1={appState.actionableAlert.title}
          text2={appState.actionableAlert.description}
          mainAction={appState.actionableAlert.mainAction}
          dismissAction={appState.removeActionableAlert}
        />
      )}
      <ConfirmationDialog confirmation={appState.confirmation} />
      <NewConfirmationDialog newConfirmation={appState.newConfirmation} />
      <CustomLoadingOverlay
        loadingOverlayProps={appState.loadingOverlayProps}
      />
    </div>
  );
};

export default Layout;
