import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { PlusIcon, MinusIcon, TrashIcon } from "@heroicons/react/solid";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import CustomPopconfirm from "#newUiComponents/commons/CustomPopConfirm";
import { Tooltip } from "antd";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";

const EditBoxItems = ({ box, group, onSave, onCancel }) => {
  const notify = CustomNotification();

  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState(() => {
    const currentBoxItems = box.items;
    const groupedData = currentBoxItems.reduce((acc, item) => {
      const key = item.sku;
      if (!acc[key]) {
        const groupItem = group.items.find((gi) => gi.sku === item.sku);
        const currentQuantityInBox = currentBoxItems.filter(
          (bi) => bi.sku === item.sku,
        ).length;
        const availableQuantity = groupItem?.quantity || 0;
        acc[key] = {
          msku: item.sku,
          fnsku: item.fnSku,
          asin: item.asin,
          name: item.productName,
          image: item.image,
          quantity: currentQuantityInBox,
          maxQuantity: currentQuantityInBox + availableQuantity,
          currentInBox: currentQuantityInBox,
          originalItems: currentBoxItems.filter((bi) => bi.sku === item.sku),
          availableItems:
            groupItem?.originalItems?.filter(
              (i) => i.status === "UNPROCESSED",
            ) || [],
          prepInstructions: item.prepInstructions || [],
        };
      }
      return acc;
    }, {});

    return Object.values(groupedData);
  });

  const handleQuantityChange = (msku, operation, directValue = null) => {
    setData((prevData) =>
      prevData.map((item) => {
        if (item.msku === msku) {
          let newQuantity;

          if (directValue !== null) {
            newQuantity = parseInt(directValue, 10);
            if (isNaN(newQuantity) || newQuantity < 0) return item;
          } else {
            newQuantity =
              operation === "increment"
                ? item.quantity + 1
                : Math.max(0, item.quantity - 1);
          }

          // Can't exceed what's in box + what's available
          if (newQuantity > item.maxQuantity) {
            notify.warning("Cannot exceed available quantity");
            return item;
          }

          return {
            ...item,
            quantity: newQuantity,
          };
        }
        return item;
      }),
    );
  };

  const handleConfirm = () => {
    const itemsToAdd = [];

    data.forEach((item) => {
      const currentQuantityInBox = item.originalItems.length;

      if (item.quantity === 0) {
        return;
      }

      if (item.quantity === currentQuantityInBox) {
        itemsToAdd.push(...item.originalItems);
      } else if (item.quantity < currentQuantityInBox) {
        itemsToAdd.push(...item.originalItems.slice(0, item.quantity));
      } else {
        const additionalNeeded = item.quantity - currentQuantityInBox;
        const availableUnprocessed =
          group.items
            .find((gi) => gi.sku === item.sku || gi.msku === item.msku)
            ?.originalItems?.filter((i) => i.status === "UNPROCESSED") || [];

        itemsToAdd.push(
          ...item.originalItems,
          ...availableUnprocessed.slice(0, additionalNeeded),
        );
      }
    });

    onSave({
      updatedItems: itemsToAdd.map((item) => ({
        id: item.id,
        quantity: 1,
      })),
    });
  };
  const filteredData = data.filter((item) =>
    (item.msku || "").toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleDeleteItem = (msku) => {
    setData((prevData) => prevData.filter((item) => item.msku !== msku));
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const columns = [
    {
      key: "productInfo",
      title: "Product Name",
      render: (_, row) => (
        <div className="flex items-center">
          <img
            src={row.image || FALLBACK_IMAGE_URL}
            alt={row.msku}
            className="mr-4 h-16 w-16 rounded-md object-cover"
          />
          <div>
            <Tooltip title={row.productName}>
              <div className="w-56 truncate text-base font-medium text-gray-800">
                {row.productName}
              </div>
            </Tooltip>
            <div className="text-sm text-gray-500">
              FNSKU: {row.fnSku || row.fnsku}
            </div>
            <div className="text-sm text-gray-500">
              SKU: {row.msku || row.sku}
            </div>
            <div className="text-sm text-gray-500">ASIN: {row.asin}</div>
          </div>
        </div>
      ),
    },
    {
      key: "prepInstructions",
      title: "Prep Instructions",
      render: (_, row) => (
        <div className="flex flex-wrap gap-2">
          {row.prepInstructions?.map((instruction, idx) => (
            <div
              key={idx}
              style={{
                backgroundColor: "#FFF8E7",
                color: "#B8860B",
                borderRadius: "9999px",
                padding: "4px 12px",
                fontWeight: 500,
                fontSize: "14px",
              }}>
              {instruction.prepType
                .replace("ITEM_", "")
                .split("_")
                .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                .join(" ")}
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "maxQuantity",
      title: "Available Quantity",
      render: (_, row) => <span>{row.maxQuantity}</span>,
    },
    {
      key: "quantity",
      title: "Quantity",
      render: (_, row) => (
        <div className="flex items-center gap-2">
          <button
            onClick={() => handleQuantityChange(row.msku, "decrement")}
            className="rounded border border-gray-300 p-3"
            disabled={row.quantity <= 1}>
            <MinusIcon
              className={`h-4 w-4 ${
                row.quantity <= 1 ? "text-gray-300" : "text-primaryAccent"
              }`}
            />
          </button>

          <input
            type="number"
            value={row.quantity}
            min="1"
            max={row.maxQuantity}
            className="w-20 rounded-md border border-gray-300 px-2 py-1 text-center"
            onChange={(e) => {
              const value = e.target.value.replace(/-/g, "");
              handleQuantityChange(row.msku, null, value);
            }}
            onBlur={(e) => {
              if (!e.target.value || parseInt(e.target.value) < 1) {
                handleQuantityChange(row.msku, null, "1");
              }
            }}
          />

          <button
            onClick={() => handleQuantityChange(row.msku, "increment")}
            className="rounded border border-gray-300 p-3"
            disabled={row.quantity >= row.maxQuantity}>
            <PlusIcon
              className={`h-4 w-4 ${
                row.quantity >= row.maxQuantity
                  ? "text-gray-300"
                  : "text-primaryAccent"
              }`}
            />
          </button>
        </div>
      ),
    },
    {
      key: "actions",
      title: "Actions",
      render: (_, row) => (
        <CustomPopconfirm
          title="Are you sure to remove this item?"
          onConfirm={() => handleDeleteItem(row.msku)}
          okText="Yes"
          cancelText="No"
          placement="left"
          okButtonProps={{
            className: "bg-primaryAccent text-white",
          }}
          cancelButtonProps={{
            className:
              "bg-white border rounded border-primaryAccent text-primaryAccent",
          }}>
          <TrashIcon className="h-5 w-5 cursor-pointer text-red-500 hover:text-red-600" />
        </CustomPopconfirm>
      ),
    },
  ];

  return (
    <div className="p-6 font-inter">
      <div className="mb-4 flex items-center gap-5">
        <span className="text-sm text-gray-400">
          Total No. of Products: {filteredData.length}
        </span>
        <span className="text-sm text-gray-400">
          Total Quantity:{" "}
          {filteredData.reduce((total, item) => total + item.quantity, 0)}
        </span>
      </div>

      <div className="flex-grow" style={{ height: "calc(100vh - 18rem)" }}>
        <CustomTable
          columns={columns}
          data={filteredData}
          isSearchable
          searchTerm={searchTerm}
          searchPlaceholder="Search with SKU..."
          onChangeSearchTerm={handleSearch}
        />
      </div>

      <div className="sticky bottom-0 z-30 flex w-full items-center justify-between bg-white p-4">
        <div className="flex w-full justify-end gap-6">
          <PrimaryButton
            height="3rem"
            width="7rem"
            className="text-base font-medium"
            onClick={onCancel}>
            Cancel
          </PrimaryButton>
          <PrimaryButton
            height="3rem"
            width="7rem"
            variant="primary"
            className="text-base font-medium"
            onClick={handleConfirm}>
            Confirm
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

EditBoxItems.propTypes = {
  box: PropTypes.shape({
    boxName: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        asin: PropTypes.string.isRequired,
        fnsku: PropTypes.string.isRequired,
        msku: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        image: PropTypes.string,
        prepInstructions: PropTypes.arrayOf(
          PropTypes.shape({
            prepType: PropTypes.string.isRequired,
            prepOwner: PropTypes.string,
          }),
        ),
      }),
    ).isRequired,
    dimensions: PropTypes.object.isRequired,
    weight: PropTypes.number,
    assignedQuantity: PropTypes.number,
  }).isRequired,
  group: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditBoxItems;
