import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { Indicator } from "#components/utils/LoadingIndicator";
import { twMerge } from "tailwind-merge";

const CustomLoadingOverlay = ({
  loadingOverlayProps: {
    isVisible,
    loaderTitle,
    description,
    htmlContent,
    loaderClasses,
    spinnerSize,
    colorClasses,
  },
}) => {
  // Create a portal container at the same level as headlessui-portal-root
  useEffect(() => {
    // Create container if it doesn't exist
    if (!document.getElementById("custom-loader-portal")) {
      const portalContainer = document.createElement("div");
      portalContainer.id = "custom-loader-portal";
      document.body.appendChild(portalContainer);
    }

    return () => {
      // Cleanup on unmount
      const portalContainer = document.getElementById("custom-loader-portal");
      if (portalContainer) {
        document.body.removeChild(portalContainer);
      }
    };
  }, []);

  if (!isVisible) return null;

  const LoaderContent = (
    <div
      className="fixed inset-0 overflow-hidden"
      style={{
        zIndex: 99999,
        position: "fixed",
        inset: 0,
      }}>
      <div className="fixed inset-0 flex items-center justify-center font-inter">
        {/* Backdrop */}
        <div
          className="absolute inset-0 bg-gray-800 bg-opacity-50 backdrop-blur-sm"
          onClick={(e) => e.stopPropagation()}
        />

        {/* Content */}
        <div
          className={`relative rounded-lg bg-white p-8 shadow-lg ${loaderClasses}`}
          style={{
            minWidth: "400px",
            maxWidth: "600px",
          }}>
          <div className="flex flex-col items-center space-y-6">
            <Spin
              indicator={
                <Indicator color={colorClasses?.loaderColor || "#f5a623"} />
              }
              size={spinnerSize}
              className="text-[#f5a623]"
            />
            <div>
              <h3
                style={{ color: !colorClasses?.titleColor ? "#f5a623" : "" }}
                className={twMerge(
                  "text-lg font-semibold text-orange-400",
                  colorClasses?.titleColor,
                )}>
                {loaderTitle}
              </h3>
              <p
                className={twMerge(
                  "mt-2 text-center text-sm text-gray-500",
                  colorClasses?.descriptionColor,
                )}>
                {description}
              </p>
            </div>
          </div>
          {htmlContent}
        </div>
      </div>
    </div>
  );

  // Create portal
  return createPortal(
    LoaderContent,
    document.getElementById("custom-loader-portal") || document.body,
  );
};

CustomLoadingOverlay.propTypes = {
  loadingOverlayProps: PropTypes.shape({
    isVisible: PropTypes.bool,
    loaderTitle: PropTypes.string,
    description: PropTypes.string,
    loaderClasses: PropTypes.string,
    spinnerSize: PropTypes.oneOf(["small", "default", "large"]),
  }).isRequired,
};

export default CustomLoadingOverlay;
