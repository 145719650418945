import React, { useEffect, useState } from "react";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import CheckBox from "#newUiComponents/commons/CheckBox";
import CustomBadge from "#newUiComponents/commons/CustomBadge";
import { ExclamationIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import CustomNotification from "#newUiComponents/commons/CustomNotification";

const FbaProductRow = ({
  record,
  setFilteredInventory,
  setSelectedInventoryIds,
  selectedInventoryIds,
}) => {
  const notify = CustomNotification();
  let rowClasses = "bg-white";
  let statusIcon = null; // Renamed from errorIcon to statusIcon as it can show both error and warning

  const [unitsError, setUnitsError] = useState("");

  // Access restriction details and hazmat info
  const { restrictionDetails, marketplaceAttributes } = record;
  const isEligible = restrictionDetails ? restrictionDetails?.isEligible : true;
  const isHazmat = marketplaceAttributes?.attributes?.hazmat === true;
  const errorMessages = restrictionDetails
    ? restrictionDetails?.errors?.filter((error) => error.severity === "ERROR")
    : [];

  // Handle error and warning states with priority
  if (!isEligible && errorMessages?.length > 0) {
    rowClasses = "bg-red-50";
    const errorMessage = errorMessages[0].details;
    statusIcon = (
      <Tooltip title={errorMessage}>
        <ExclamationIcon className="ml-2 h-6 w-6 text-red-500" />
      </Tooltip>
    );
  } else if (isHazmat) {
    // Show hazmat warning if no errors
    rowClasses = "bg-white";
    statusIcon = (
      <Tooltip title="Hazmat Product">
        <ExclamationIcon className="ml-2 h-6 w-6 text-orange-500" />
      </Tooltip>
    );
  }

  const columnClasses = `whitespace-nowrap px-6 py-6 text-base text-gray-600`;

  // Automatically deselect ineligible items from selectedInventoryIds
  useEffect(() => {
    if (!isEligible) {
      setSelectedInventoryIds((prevIds) =>
        prevIds.filter((id) => id !== record.id),
      );
    }
  }, [isEligible, record.id, setSelectedInventoryIds]);

  const handleExpirationDateChange = (selectedDate) => {
    const selectedOption = record.expirationDates.find(
      (date) => date.expirationDate === selectedDate,
    );

    setFilteredInventory((prevInventory) =>
      prevInventory.map((item) =>
        item.id === record.id
          ? {
              ...item,
              selectedExpirationDate: selectedDate,
              selectedQuantity: selectedOption ? selectedOption.quantity : 0,
              unitsToShip: selectedOption ? selectedOption.quantity : 0,
            }
          : item,
      ),
    );
  };

  const handleUnitsToShipChange = (e) => {
    let unitsToShip = e.target.value === "" ? "" : parseInt(e.target.value, 10);

    // Check for negative value
    if (unitsToShip < 0) {
      setUnitsError("Value cannot be negative");
      return;
    } else {
      setUnitsError("");
    }

    // Enforce max limit based on selectedQuantity
    if (unitsToShip > record.selectedQuantity) {
      unitsToShip = record.selectedQuantity;
      notify.error(`Cannot exceed available units: ${record.selectedQuantity}`);
    }

    setFilteredInventory((prevInventory) =>
      prevInventory.map((item) =>
        item.id === record.id ? { ...item, unitsToShip } : item,
      ),
    );
  };

  return (
    <tr className={`${rowClasses} hover:bg-gray-100`}>
      <td
        className={`${columnClasses} sticky left-0 z-auto bg-white shadow-md`}>
        <CheckBox
          checked={selectedInventoryIds.includes(record.id)}
          disabled={!isEligible} // Disable checkbox if item is not eligible
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked) {
              setSelectedInventoryIds([...selectedInventoryIds, record.id]);
            } else {
              setSelectedInventoryIds(
                selectedInventoryIds.filter((id) => id !== record.id),
              );
            }
          }}
        />
      </td>
      <td className={columnClasses}>
        <div className="flex items-center space-x-2">
          <img
            src={
              record?.images?.length
                ? record.images[0]?.display_url || record.images[0]?.url
                : FALLBACK_IMAGE_URL
            }
            alt={record.name}
            className="h-10 w-10 rounded"
          />
          <div>
            <Tooltip title={record.name}>
              <p
                className="max-w-xs truncate font-semibold"
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {record.name}
              </p>
            </Tooltip>
            <p className="text-sm text-gray-500">SKU: {record.sku || "-"}</p>
            <p className="text-sm text-gray-500">
              FNSKU: {record.fnSku || "-"}
            </p>
            <p className="text-sm text-gray-500">ASIN: {record.asin || "-"}</p>
            <p className="text-sm text-gray-500">
              UOM: {record.uom || record.baseUom || ""}
            </p>
          </div>
        </div>
      </td>
      <td className={columnClasses}>
        {record?.expirationDates?.length > 0 ? (
          <AutoCompleteSingleSelect
            options={
              record.expirationDates.length > 0
                ? record.expirationDates.map((date) => ({
                    label: date.expirationDate,
                    value: date.expirationDate,
                    quantity: date.quantity,
                  }))
                : [{ label: "-", value: "-", quantity: 0 }]
            }
            value={record.selectedExpirationDate || ""}
            shouldDeselect={false}
            onChange={handleExpirationDateChange}
            labelKey="label"
            valueKey="value"
            placeholder="Exp Date"
            labelText=""
            parentClasses="w-48"
          />
        ) : (
          "-"
        )}
      </td>
      <td className={columnClasses}>
        <CustomBadge
          color="green"
          label={
            record.selectedQuantity ? record.selectedQuantity.toString() : "0"
          }
          textSize="text-xs"
          className="px-2 py-1"
        />
      </td>
      <td className={columnClasses}>
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              type="number"
              value={record.unitsToShip ?? ""}
              disabled={!isEligible}
              min={0}
              onChange={handleUnitsToShipChange}
              className={`w-36 rounded border ${
                unitsError ? "border-red-500" : "border-gray-300"
              } px-2`}
            />
            {statusIcon && <div className="ml-2">{statusIcon}</div>}
          </div>
          {unitsError && (
            <span className="mt-1 text-sm text-red-500">{unitsError}</span>
          )}
        </div>
      </td>
    </tr>
  );
};

export default FbaProductRow;
