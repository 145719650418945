import axios from "axios";
import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import promiseState from "./reduxReducerHelper";

export const graphqlRequest = async (query, variables = {}) => {
  try {
    const baseUrl = localStorage.getItem("baseUrl");
    if (!baseUrl) throw new Error("Base URL not found in localStorage");

    const response = await axios.post(
      `${baseUrl}/graphql`,
      { query, variables },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    // Check if there are GraphQL errors in the response
    if (response.data.errors) {
      // Extract the first error message from the GraphQL errors array
      throw new Error(
        response.data.errors[0].message || "GraphQL error occurred",
      );
    }

    return response.data.data;
  } catch (error) {
    // Check if the error is an AxiosError
    if (error.response) {
      // Error caused by the server's response
      const status = error.response.status;
      const message = error.response.data?.message || "Request failed";

      throw new Error(`Error ${status}: ${message}`);
    } else if (error.request) {
      // Error caused by no response from the server
      throw new Error("No response from server. Please check your network.");
    } else {
      // General error, like setting up the request
      throw new Error(error.message || "An unexpected error occurred");
    }
  }
};

export const createApiThunk = (typePrefix, query, transformData) =>
  createAsyncThunk(
    typePrefix,
    async (
      { variables, successCallback, errorCallback },
      { rejectWithValue },
    ) => {
      try {
        const data = await graphqlRequest(query, variables);
        const result = transformData ? transformData(data) : data;
        try {
          if (successCallback) successCallback(result);
        } catch (callbackError) {
          console.error("Error in successCallback:", callbackError.message);
        }
        return result;
      } catch (error) {
        if (errorCallback) errorCallback(error.message);
        return rejectWithValue(error.message);
      }
    },
  );

export // Helper function to add promise state handlers
const addPromiseHandlers = (builder, action, stateProp) => {
  builder
    .addCase(action.pending, (state) => {
      state[stateProp] = { ...promiseState(true, false, false, []) };
    })
    .addCase(action.fulfilled, (state, action) => {
      state[stateProp] = {
        ...promiseState(false, true, false, action.payload),
      };
    })
    .addCase(action.rejected, (state, action) => {
      state[stateProp] = {
        ...promiseState(false, false, true, action.payload),
      };
    });
};
axios.interceptors.request.use(
  function (config) {
    const { token, subdomain, tenant } = localStorage;
    let sanitizedTenant = tenant;
    if (tenant) {
      sanitizedTenant = JSON.stringify(_.omit(JSON.parse(tenant), "profile"));
    }
    if (!!token) config.headers["authorization"] = `Bearer ${token}`;
    if (!!!subdomain) throw new Error(`Access denied`);
    config.headers["subdomain"] = subdomain;
    config.headers["tenant"] = sanitizedTenant;
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);
