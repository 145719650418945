import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import React from "react";
import { handleModifyInboundForm, inboundFormEnums } from "./FbaUtils";
import LoadingIndicator from "#components/utils/LoadingIndicator";

const InboundForm = ({
  inboundForm,
  setInboundForm,
  formData,
  fbaIntegrations,
  isFilterOnly,
}) => {
  return (
    // <div className="h-auto w-full overflow-x-auto">
    <div className="flex flex-wrap items-center gap-6">
      <AutoCompleteSingleSelect
        options={formData?.warehousesOptions}
        value={inboundForm?.warehouse}
        onChange={(value) =>
          setInboundForm(
            handleModifyInboundForm(
              inboundForm,
              inboundFormEnums.WAREHOUSE,
              value,
            ),
          )
        }
        labelKey="label"
        valueKey="value"
        placeholder="Select Warehouse"
        labelText="Warehouse"
        required={false}
        disabled={false}
        infoText="Select a Warehouse"
        error={""}
        showNotSelectedError={false}
        parentClasses="flex flex-col w-60 mb-4"
        labelClasses="text-base text-gray-500"
      />
      <AutoCompleteSingleSelect
        options={formData?.clientsOptions}
        value={inboundForm?.client}
        onChange={(value) =>
          setInboundForm(
            handleModifyInboundForm(
              inboundForm,
              inboundFormEnums.CLIENT,
              value,
            ),
          )
        }
        labelKey="label"
        valueKey="value"
        placeholder="Select Client"
        labelText="Client"
        required={false}
        disabled={false}
        infoText="Select a Client"
        error={""}
        showNotSelectedError={false}
        parentClasses="flex flex-col w-60 mb-4"
        labelClasses="text-base text-gray-500"
      />
      {!isFilterOnly && fbaIntegrations && fbaIntegrations.length ? (
        <>
          <AutoCompleteSingleSelect
            options={formData?.sellerIdOptions}
            value={inboundForm?.sellerId}
            onChange={(value) =>
              setInboundForm(
                handleModifyInboundForm(
                  inboundForm,
                  inboundFormEnums.SELLER_ID,
                  value,
                ),
              )
            }
            labelKey="label"
            valueKey="value"
            placeholder="Select Seller Id"
            labelText="Seller Id"
            required={false}
            disabled={false}
            infoText="Select a Seller ID"
            error={""}
            showNotSelectedError={false}
            parentClasses="flex flex-col w-60 mb-4"
            labelClasses="text-base text-gray-500"
          />
          <AutoCompleteSingleSelect
            options={formData?.marketplaceOptions}
            value={inboundForm?.marketPlace}
            onChange={(value) =>
              setInboundForm(
                handleModifyInboundForm(
                  inboundForm,
                  inboundFormEnums.MARKETPLACE,
                  value,
                ),
              )
            }
            labelKey="label"
            valueKey="value"
            placeholder="Select Marketplace"
            labelText="Marketplace"
            required={false}
            disabled={false}
            infoText="Select a Marketplace"
            error={""}
            showNotSelectedError={false}
            parentClasses="flex flex-col w-60 mb-4"
            labelClasses="text-base text-gray-500"
          />
        </>
      ) : null}

      {/* <AutoCompleteSingleSelect
        options={formData?.shippingModeOptions}
        value={inboundForm?.shippingMode}
        onChange={(value) =>
          setInboundForm(
            handleModifyInboundForm(
              inboundForm,
              inboundFormEnums.SHIPPING_MODE,
              value,
            ),
          )
        }
        labelKey="label"
        valueKey="value"
        placeholder="Select Shipping Mode"
        labelText="Shipping Mode"
        required={true}
        disabled={false}
        infoText="Select a Shipping Mode"
        error={""}
        showNotSelectedError={false}
        parentClasses="flex flex-col w-60 mb-4"
        labelClasses="text-base text-gray-500"
      /> */}
    </div>
    // </div>
  );
};

export default InboundForm;
