import { useEffect, useState, useContext } from "react";
import PageTitle from "#components/utils/PageTitle";
import { AuthContext } from "#contexts/auth";
import { useLDClient } from "launchdarkly-react-client-sdk";

const FeatureFlagsList = () => {
  const auth = useContext(AuthContext);
  const [flags, setFlags] = useState({});
  const ldClient = useLDClient();

  useEffect(() => {
    const getAllFlags = async () => {
      if (ldClient) {
        const allFlags = ldClient.allFlags();
        setFlags(allFlags);
      }
    };

    // Initial fetch of flags
    getAllFlags();

    // Set up listener for flag changes
    const updateHandler = (settings) => {
      setFlags(ldClient.allFlags());
      console.log("Flags updated:", settings);
    };

    if (ldClient) {
      ldClient.on("change", updateHandler);
    }

    // Cleanup listener on component unmount
    return () => {
      if (ldClient) {
        ldClient.off("change", updateHandler);
      }
    };
  }, [ldClient]);

  return (
    <div className="p-4">
      <div className="flex items-center">
        <div className="flex w-full">
          <PageTitle>Feature Flags</PageTitle>
        </div>
      </div>

      <div className="mt-6">
        <h2 className="mb-4 text-xl font-semibold">Feature Flags</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full rounded-lg bg-white shadow">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Flag Key
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Value
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Type
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {Object.entries(flags).map(([key, value]) => (
                <tr key={key} className="hover:bg-gray-50">
                  <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                    {key}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {typeof value === "boolean" ? (
                      <span
                        className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                          value
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}>
                        {String(value)}
                      </span>
                    ) : typeof value === "object" ? (
                      JSON.stringify(value)
                    ) : (
                      String(value)
                    )}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {typeof value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FeatureFlagsList;
