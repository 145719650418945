export const pluralize = (count, noun, suffix = "s") => {
  if (count === 1) return `${count} ${noun}`;
  return `${count} ${noun}${suffix}`;
};

export const handlePositiveValue = (value, type = "int") => {
  if (isNaN(value) || parseFloat(value) < 0) {
    return 0;
  }

  return type === "int" ? parseInt(value) : parseFloat(value);
};

export const getBulkUploadTemplateURL = (fileName) => {
  let templateEnvironmentBucket = "uat";
  if (process.env.NODE_ENV === "uat") {
    templateEnvironmentBucket = "uat";
  } else if (process.env.NODE_ENV === "development") {
    templateEnvironmentBucket = "uat";
  } else if (process.env.NODE_ENV === "production") {
    templateEnvironmentBucket = "prod";
  }
  return `https://templates-onboarding.s3.amazonaws.com/${templateEnvironmentBucket}/${fileName}.csv`;
};

export const generatePlantingZoneCodes = () => {
  return Array.from({ length: 13 }, (_, i) => {
    const baseHz = i + 1;
    return [
      { label: `${baseHz}a`, value: `${baseHz}a`, hz: baseHz + 0.1 },
      { label: `${baseHz}b`, value: `${baseHz}b`, hz: baseHz + 0.2 },
    ];
  }).flat();
};
