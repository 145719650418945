import PageTitle from "#components/utils/PageTitle";
import moment from "moment-timezone";
import PaginationNavigationButtons from "#components/common/PaginationNavigationButtons";

const headers = [
  "Batch ID",
  // "Batch Name",
  "Batched Date",
  "Picker",
  "Action",
];

const BatchListView = ({
  setBatchesDataFilters,
  searchBatches,
  batchesData,
  checkPagination,
  usersMap,
  workOnBatch,
}) => {
  const filters = batchesData.filters;
  const batches = batchesData.batches;
  return (
    <div className="m-4 flex justify-center">
      <div className="max-w-4xl flex-1">
        <div className="flex justify-between">
          <PageTitle>Total ({batchesData.total})</PageTitle>
          <PaginationNavigationButtons
            perPage={batchesData.perPage}
            pageNumber={batchesData.pageNumber}
            total={batchesData.total}
            nextPage={() => checkPagination("forward")}
            prevPage={() => checkPagination("backward")}
          />
        </div>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              aria-hidden="true"
              className="h-5 w-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="text-md block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            placeholder="Search"
            onChange={(e) =>
              setBatchesDataFilters({
                ...filters,
                keyword: e.target.value,
              })
            }
            value={filters["keyword"]}
            onKeyDown={(e) => (e.key === "Enter" ? searchBatches() : {})}
          />
        </div>
        <div className="mt-10 rounded-md bg-EBEBEB">
          <table className="-mt-4 min-w-full divide-y divide-gray-200">
            <thead className="rounded-full p-4">
              <tr className="font-montserratborder-left text-primaryAccent">
                {headers.map((header, headerIdx) => (
                  <th
                    key={headerIdx}
                    scope="col"
                    className="px-1 py-3 pl-4 text-left text-lg font-medium tracking-wider">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {batches.length === 0 && (
                <tr>
                  <td
                    className="tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261 p-5 text-left font-semibold text-primaryAccent"
                    colSpan={5}>
                    No Batches.
                  </td>
                </tr>
              )}
              {batches &&
                batches.map((batch, index) => (
                  <tr
                    key={batch.id}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}>
                    <td
                      className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {batch.id || "-"}
                    </td>
                    {/* <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      {batch.batchName || "-"}
                    </td> */}
                    <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      {batch.createdAt &&
                        moment(batch.createdAt).format("YYYY-MM-DD hh:mm A")}
                    </td>
                    <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      {batch.user ? usersMap[batch.user]?.name : "Unassigned"}
                    </td>
                    <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      <div
                        className="cursor-pointer whitespace-nowrap rounded-full bg-blue-500 px-2 py-1 text-center text-lg text-white"
                        onClick={() =>
                          workOnBatch(batch.id, batch.warehouse[0])
                        }>
                        Work on this
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BatchListView;
